








import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { IMolTableActionLinkProps } from "./props";
import { MolTableActionLinkTypeEnum } from "./type.enum";

const css = bemBuilder("mol-table-action-link");

export default defineComponent<IMolTableActionLinkProps>({
  name: "MolTableActionLink",
  components: {
    AtomIcon,
  },
  props: {
    route: {
      type: Object as PropType<IMolTableActionLinkProps["route"]>,
      required: true,
    },
    type: {
      type: String as PropType<MolTableActionLinkTypeEnum>,
      default: MolTableActionLinkTypeEnum.PRIMARY,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
