







































































import { t } from "@/i18n";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomLink,
  AtomLinkSizeEnum,
  AtomLinkTypeEnum,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  OrgFormInput,
} from "@/v2/new-design-system";
import { createBusiness } from "@/v2/repo/business-settings";
import { report } from "@chatfood/bug-reporter";
import { RepoBusinessSettingsErrorEnum } from "@/v2/repo/business-settings/error-response";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("sidebar-create-business");

export default defineComponent({
  name: "SidebarCreateBusiness",
  components: {
    AtomModal,
    AtomText,
    AtomLink,
    AtomButton,
    OrgFormInput,
  },
  props: {
    onSave: {
      type: Function as PropType<(id: string, name: string) => void>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const isSaving = ref<boolean>(false);
    const businessName = ref<string>("");
    const errorMessage = ref<string>("");

    const validateForm = (): void => {
      errorMessage.value = "";

      if (businessName.value.length < 3) {
        errorMessage.value = t("module.sidebar.create_business.error");
      }
    };

    const handleSave = async () => {
      validateForm();

      if (errorMessage.value) return;

      isSaving.value = true;
      try {
        const id = await createBusiness(businessName.value);
        await props.onSave(id, businessName.value);
      } catch (e: any) {
        const { error = {} } = e;

        if (error.validationError && error.validationError.businessName) {
          const mapErrors: Record<string, string> = {
            [RepoBusinessSettingsErrorEnum.NAME_ALREADY_USED]: t(
              "module.sidebar.create_business.error_name_already_used"
            ),
          };

          errorMessage.value = mapErrors[error.validationError.businessName];
          return;
        }

        generalErrorToast(e);

        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isSaving.value = false;
      }
    };

    return {
      t,
      css,
      isSaving,
      businessName,
      errorMessage,
      handleSave,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
