import { http } from "../../http";
import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";

interface IBusinessResponse {
  analytics_link: string;
  category: string;
  country: string;
  created_at: string;
  cuisines: Array<{ id: string; name: string }>;
  dashboard_version: string;
  domain_name: string;
  email: string;
  features: Array<string>;
  has_inbox_service: boolean;
  id: string;
  language: string;
  logo: string | null;
  name: string;
  onboarding_status: string;
  ordering_link: string;
  organization_id: string;
  timezone: string;
  trial_ends_at: string;
}

interface IBusiness {
  analyticsLink: string;
  category: string;
  country: string;
  createdAt: string;
  cuisines: Array<{ id: string; name: string }>;
  dashboardVersion: string;
  website: string;
  email: string;
  features: Array<string>;
  hasInboxService: boolean;
  id: string;
  language: string;
  logo: string | null;
  name: string;
  onboardingStatus: string;
  orderingLink: string;
  organizationId: string;
  timezone: string;
  trialEndsAt: string;
}

const mapBusinessValues = (response: IBusinessResponse): IBusiness => ({
  analyticsLink: response.analytics_link,
  category: response.category,
  country: response.country,
  createdAt: response.created_at,
  cuisines: response.cuisines,
  dashboardVersion: response.dashboard_version,
  website: response.domain_name || "",
  email: response.email,
  features: response.features,
  hasInboxService: response.has_inbox_service,
  id: response.id,
  language: response.language,
  logo: response.logo,
  name: response.name,
  onboardingStatus: response.onboarding_status,
  orderingLink: response.ordering_link,
  organizationId: response.organization_id,
  timezone: response.timezone,
  trialEndsAt: response.trial_ends_at,
});

export class FetchBusinessError extends RepoBusinessSettingsError {}

export async function fetchBusiness(businessId: string): Promise<IBusiness> {
  try {
    const business = await http.get(
      `/api/v3/businesses/${businessId}?include=cuisines`
    );
    return mapBusinessValues(business?.data?.data || {});
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage || "The request to fetch a business data failed.",
      response: response.data,
    };

    throw new FetchBusinessError(error);
  }
}
