import dayjs from "dayjs";
import extCalendar from "dayjs/plugin/calendar";
import extRelativeTime from "dayjs/plugin/relativeTime";
import extTimezone from "dayjs/plugin/timezone";
import extUtc from "dayjs/plugin/utc";

dayjs.extend(extUtc);
dayjs.extend(extRelativeTime);
dayjs.extend(extCalendar);
dayjs.extend(extTimezone);

const createDate = (dateString, ref) => {
  if (!dateString) {
    throw new TypeError(`${ref} filter: nil value provided.`);
  }

  const date = dayjs(dateString);

  if (!date.isValid()) {
    throw new Error(`${ref} filter: invalid date string provided.`);
  }

  return date;
};

/**
 * Format a string 8601
 *
 * @param {dateISO8601} date in string format
 * @returns {String}
 */
export const date = (dateISO8601, format = null) => {
  const date = createDate(dateISO8601, "date");

  if (!format) {
    return date.toISOString();
  }

  return date.format(format);
};

/**
 * Format a string 8601 as relative date
 *
 * @param {dateISO8601} date in string format
 * @returns {String}
 */
export const fromNow = (dateISO8601) => {
  const date = createDate(dateISO8601, "date");
  return date.fromNow();
};

/**
 * Format a string 8601 as calendar
 *
 * @param {dateISO8601} date in string format
 * @returns {String}
 */
export const dateCalendar = (dateISO8601) => {
  const date = createDate(dateISO8601, "calendar");
  return date.calendar();
};

/**
 * Format a string 8601 to the given timezone
 *
 * @param {dateISO8601} date in string format
 * @param {timezone} timezone in string format
 * @param {format} format to return the date
 * @returns {String}
 */
export const dateTz = (dateISO8601, timeZone, format) => {
  const date = createDate(dateISO8601, "date");
  return date.tz(timeZone).format(format);
};
