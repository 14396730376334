

























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { boolean, func, string } from "@/v2/util/prop/type";

const css = bemBuilder("atom-radio");

export default defineComponent({
  name: "AtomRadio",
  inheritAttrs: false,
  model: {
    prop: "modelValue",
  },
  props: {
    name: string().isRequired().use(),
    value: string().use(),
    modelValue: string().use(),
    disabled: boolean().use(),
    onChange: func().use(),
  },
  setup(props) {
    const isChecked = computed((): boolean => {
      return props.modelValue === props.value;
    });

    return {
      css,
      isChecked,
    };
  },
});
