<template>
  <Error v-if="hasGlobalError" />
  <div v-else class="register">
    <div class="register__form">
      <div class="register__wrapper">
        <Icon :size="164" icon="chatfood-logo-new" class="register__logo" />
        <slot />
      </div>
    </div>
    <div class="register__info">
      <div class="register__wrapper">
        <slot name="info" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@/design-system";
import Error from "./partials/Error.vue";
import { mapGetters } from "vuex";

export default {
  name: "RegisterLayout",
  components: {
    Icon,
    Error,
  },
  computed: {
    ...mapGetters({
      hasGlobalError: "hasGlobalError",
    }),
  },
};
</script>

<style lang="scss">
$primary: #1258ff;

.register {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;

  &__wrapper {
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
  }

  &__logo {
    margin: -30px 0 -20px;
  }

  &__form,
  &__info {
    display: flex;
    align-items: center;
    flex: 1 0 500px;
    max-width: 100%;
    padding: 4vmax 2.5vmax;
    min-height: 100vh;

    @media (max-width: 1000px) {
      min-height: 50vh;
    }
  }

  &__info {
    color: #fff;
    background-color: #010e2b;
    background-image: url("/img/register-bg-dots.svg"),
      url("/img/register-bg-dots.svg"), url("/img/register-bg-icon-red.svg"),
      url("/img/register-bg-icon-blue.svg");
    background-repeat: no-repeat;
    background-size: 8vmax, 8vmax, 12vmax, 12vmax;
    background-position: top left, bottom right, top right, bottom left;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    color: #071c4d;
    margin: 0;
  }

  &__subtitle {
    font-weight: 400;
    color: #071c4d;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0 40px;
  }

  &__text {
    color: #071c4d;
    text-align: center;
    margin-top: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;

    a {
      color: $primary !important;
    }
  }

  &__form {
    .form-field__label {
      line-height: 1.4;
      color: #071c4d;
    }

    .input__field,
    .input-password__field,
    .select__field {
      color: #071c4d;

      &:focus {
        border-color: $primary !important;
      }

      &--error,
      &--error:focus {
        border-color: #e42f46 !important;
        color: #e42f46;
      }
    }

    .select--error {
      .select__field,
      .select__field:focus {
        border-color: #e42f46 !important;
        color: #e42f46;
      }
    }

    .button {
      font-size: 14px;
      display: block;
      width: 100%;
      padding: 12.5px 25px;
      border-radius: 8px;
      line-height: 1.5;
      transition: all 0.5s;
      text-align: center;

      &--primary {
        background: $primary;

        &:hover,
        &:focus {
          background: #0042e0;
          box-shadow: 0 6px 50px rgba(0, 56, 190, 0.15);
        }
      }

      &--outline-white {
        background: #fff;
        border: 1px solid #dee2e6;
        color: #071c4d;

        &:hover,
        &:focus {
          color: $primary;
          box-shadow: none;
          background: #f3f6ff;
        }
      }
    }
  }
}
</style>
