import { t } from "@/i18n";
import { MolGuideLinkArticleEnum } from "./article.enum";
import { AtomIconNameEnum } from "../../atom/icon/icon.enum";

export const GuideLinkConfig: Record<
  MolGuideLinkArticleEnum,
  { link: string; label: () => string; iconName: string }
> = {
  [MolGuideLinkArticleEnum.FAQ_SUBSCRIPTION]: {
    link: "http://docs.chatfood.io/en/articles/4792724-faq-subscription",
    label: () => t("design_system.mol_guide_link.label_learn_billing"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.PRICING]: {
    link: "https://www.chatfood.io/pricing",
    label: () => t("design_system.mol_guide_link.label_learn_pricing"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.HOW_TO_MANAGE_DELIVERY_AREAS]: {
    link: "https://docs.chatfood.io/en/articles/4918305-how-to-manage-delivery-areas-uae",
    label: () => t("design_system.mol_guide_link.label_learn_delivery_areas"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.HOW_TO_MANAGE_LIVE_ORDERS]: {
    link: "https://docs.chatfood.io/en/articles/3368488-managing-your-live-orders",
    label: () => t("design_system.mol_guide_link.label_learn_live_orders"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.HOW_TO_MANAGE_OUTLETS]: {
    link: "https://docs.chatfood.io/en/articles/4043749-how-to-manage-your-outlet-settings",
    label: () => t("design_system.mol_guide_link.label_learn_outlets"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.LEARN_MORE_PAYMENT_LINKS]: {
    link: "https://docs.chatfood.io/en/articles/5645609-payment-links-for-merchants-selling-anything-online-in-a-few-minutes",
    label: () => t("design_system.mol_guide_link.label_learn_payment_links"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.LEARN_HOW_MANAGE_MENUS]: {
    link: "https://docs.chatfood.io/en/articles/3485445-how-to-create-menus-and-categories",
    label: () => t("design_system.mol_guide_link.label_learn_how_manage_menus"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.TERMS_AND_CONDITIONS]: {
    link: "https://docs.chatfood.io/en/articles/4373483-chatfood-payments-powered-by-checkout-com-terms-and-conditions",
    label: () => t("design_system.mol_guide_link.label_terms_and_conditions"),
    iconName: AtomIconNameEnum.HELP,
  },
  [MolGuideLinkArticleEnum.HOW_TO_MANAGE_USER]: {
    link: "http://docs.chatfood.io/en/articles/4784126-how-to-manage-your-dashboard-users",
    label: () => t("design_system.mol_guide_link.label_learn_users"),
    iconName: AtomIconNameEnum.KNOWLEDGE,
  },
  [MolGuideLinkArticleEnum.HOW_TO_CREATE_QR_CODES]: {
    link: "https://docs.chatfood.io/en/articles/5462049-how-to-create-qr-codes",
    label: () => t("design_system.mol_guide_link.label_learn_qr_codes"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.LEARN_MORE_INBOX]: {
    link: "https://docs.chatfood.io/en/articles/5540648-commerce-inbox-unleash-the-potential-of-conversational-commerce-to-grow-your-business",
    label: () => t("design_system.mol_guide_link.label_learn_inbox"),
    iconName: AtomIconNameEnum.HELP,
  },
  [MolGuideLinkArticleEnum.HOW_TO_CONNECT_INSTAGRAM_INBOX]: {
    link: "https://docs.chatfood.io/en/articles/5517216-connecting-your-instagram-to-commerce-inbox",
    label: () =>
      t("design_system.mol_guide_link.label_learn_connect_instagram_inbox"),
    iconName: AtomIconNameEnum.HELP,
  },
  [MolGuideLinkArticleEnum.UNDERSTAND_INBOX_MESSAGING_WINDOW]: {
    link: "https://docs.chatfood.io/en/articles/5553844-understanding-the-messaging-window-policy-on-whatsapp-instagram",
    label: () =>
      t("design_system.mol_guide_link.label_understand_inbox_messaging_window"),
    iconName: AtomIconNameEnum.HELP,
  },
  [MolGuideLinkArticleEnum.LEARN_BUSINESS_PROFILE]: {
    link: "https://docs.chatfood.io/en/articles/5601741-business-settings",
    label: () => t("design_system.mol_guide_link.label_learn_business_profile"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.LEARN_LOYALTY]: {
    link: "https://docs.chatfood.io/en/articles/5622089-how-to-use-the-loyalty-program",
    label: () => t("design_system.mol_guide_link.label_learn_loyalty"),
    iconName: AtomIconNameEnum.BOOK,
  },
  [MolGuideLinkArticleEnum.BOOK_DEMO]: {
    link: "javascript:;", // button target binded through Intercom
    label: () => t("design_system.mol_guide_link.label_book_demo"),
    iconName: AtomIconNameEnum.HEADPHONES,
  },
};
