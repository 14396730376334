import { sortBy, compose, prop, toLower, is } from "ramda";

/**
 * Sort ASC a collection of object by the given property.
 *
 * @param {Array} list
 * @param {String} property Porperty to be sort the list of objects by
 * @returns {Array}
 */
export const sortItBy = (list = [], property) => {
  if (!list.length) return list;

  return sortBy(compose(toLower, prop(property)), list);
};

/**
 * Convert a CamelCase string to SnakeCase.
 *
 * @param {String} string
 * @returns {String}
 */
export const camelToSnake = (string) => {
  return string
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
};

/**
 * Convert all the keys of a given object to SnakeCase.
 *
 * @param {Object} obj
 * @returns {Object}
 */
export const keysToSnakeCase = (obj) => {
  if (!is(Object, obj)) return obj;

  const n = {};

  Object.keys(obj).forEach((k) => {
    n[camelToSnake(k)] = obj[k];
  });

  return n;
};

/**
 * Convert all the keys of a given object in deep to SnakeCase.
 *
 * @param {Object} obj
 * @returns {Object}
 */
export const deepKeysToSnakeCase = (obj) => {
  if (is(Object, obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[camelToSnake(k)] = obj[k];
    });

    return n;
  } else if (is(Array, obj)) {
    return obj.map((i) => {
      return keysToSnakeCase(i);
    });
  }

  return obj;
};
