













import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { IAtomHelpTooltipProps } from "./props";
import { VTooltip } from "v-tooltip";
import { string } from "@/v2/util/prop/type";
import { AtomGraphic } from "@/v2/design-system/atom/graphic";

const css = bemBuilder("atom-help-tooltip");

/**
 * @deprecated
 * use `MolInfoBulb` from `v2/new-design-system` instead
 */

export default defineComponent<IAtomHelpTooltipProps>({
  name: "AtomHelpTooltip",
  components: {
    AtomGraphic,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    content: string().isRequired().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
