













































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { AtomText, AtomSwitcher } from "@/v2/new-design-system";

const css = bemBuilder("mol-switcher-text-options");

export default defineComponent({
  name: "MolSwitcherTextOptions",
  components: {
    AtomText,
    AtomSwitcher,
  },
  props: {
    firstLabel: {
      type: String,
      required: true,
    },
    lastLabel: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(state: boolean) => void>,
      required: true,
    },
  },
  setup(props) {
    const isChecked = ref(false);

    watch(
      () => props.checked,
      () => {
        isChecked.value = props.checked;
      },
      { immediate: true }
    );

    function handleOnChange(state: boolean) {
      if (state === isChecked.value) return;

      isChecked.value = state;
      props.onChange(isChecked.value);
    }

    return {
      css,
      isChecked,
      handleOnChange,
    };
  },
});
