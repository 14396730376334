import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { PaymentMethod } from "@stripe/stripe-js";

export async function fetchPaymentMethodSetupCompletion(
  subscriptionId: string,
  stripePaymentMethodId: string | PaymentMethod
): Promise<IFetchPaymentMethodSetupCompletionResponse | null> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        subscriptionId,
        stripePaymentMethodId,
      },
    });

    return response.data.checkPaymentMethodSetupCompletion;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchPaymentMethodSetupCompletionResponse {
  id: string;
  cardholderName: string;
  brand: string;
  last4: string;
  expireMonth: string;
  expireYear: string;
}
