
















import { useHelpers } from "@/v2/composable/use-helpers";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import { useSocket } from "../socket";
import { IInboxAuthorType } from "../domain/author-type";
import { pushNotification } from "@/v2/util";
import IconImage from "./assets/icon.png";
import { t } from "@/i18n";
import { fetchInboxSetup } from "@/v2/repo/inbox/fetch-inbox-setup";
import { report } from "@chatfood/bug-reporter";
import {
  RepoInboxError,
  RepoInboxErrorEnum,
} from "@/v2/repo/inbox/error-response";

const css = bemBuilder("inbox-sidebar-badge");

export default defineComponent({
  name: "InboxSidebarBadge",
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const audioEl = ref<HTMLAudioElement>();
    const unreadConversationsCount = ref<number>(0);

    const {
      connectSocket,
      disconnectSocket,
      isSocketConnected,
      joinRoom,
      leaveRoom,
      onEvent,
      offEvent,
      wsIncomingMessageAdapter,
    } = useSocket();

    const notifyUser = async (data: Record<string, any>) => {
      const incomingMessage = wsIncomingMessageAdapter(data);
      if (incomingMessage.authorType !== IInboxAuthorType.CONTACT) return;

      audioEl.value?.play();

      const notification = await pushNotification(
        t("module.inbox.new_message_notification_title", {
          businessName: business.value.name,
        }),
        {
          body: incomingMessage.content,
          icon: IconImage,
          badge: IconImage,
          tag: incomingMessage.id,
        }
      );

      if (!notification) {
        return;
      }

      notification.onclick = (event: Event) => {
        event.preventDefault();
        window.focus();
      };
    };

    const setUnreadConversation = (amount: number) =>
      (unreadConversationsCount.value = amount);

    const business = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId);
    });

    const leaveSocket = () => {
      disconnectSocket();
      offEvent("MESSAGE_RECEIVED", notifyUser);
      offEvent("UNREAD_CONVERSATIONS_COUNT", setUnreadConversation);
    };

    const joinSocket = () => {
      connectSocket();
      joinRoom(props.businessId);
      onEvent("MESSAGE_RECEIVED", notifyUser);
      onEvent("UNREAD_CONVERSATIONS_COUNT", setUnreadConversation);
    };

    const vm = getCurrentInstance();
    const isInboxConnected = computed(() => {
      return vm?.proxy.$store.state.isInboxConnected;
    });

    async function loadInboxSetup() {
      let isInboxConnected = false;

      try {
        const inboxSetup = await fetchInboxSetup(props.businessId);
        isInboxConnected =
          inboxSetup.isWhatsAppEnabled || inboxSetup.isInstagramEnabled;
      } catch (e: unknown) {
        if (
          !(e instanceof RepoInboxError) ||
          e.error.code !== RepoInboxErrorEnum.COUNTRY_NOT_AVAILABLE
        ) {
          report(e);
        }
      }

      vm?.proxy.$store.commit("SET_IS_INBOX_CONNECTED", isInboxConnected);
    }

    onMounted(async () => {
      await loadInboxSetup();
    });

    watch(
      () => isInboxConnected.value,
      async (val) => {
        if (!val && isSocketConnected()) {
          leaveSocket();
        }

        if (val) {
          joinSocket();
        }
      }
    );

    return {
      css,
      audioEl,
      unreadConversationsCount,
      isInboxConnected,
    };
  },
});
