var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',_vm._b({class:[
    _vm.css(),
    _vm.css('', _vm.type),
    _vm.css('', _vm.size),
    ( _obj = {}, _obj[_vm.css('', 'disabled')] = _vm.isDisabled, _obj[_vm.css('', 'wide')] = _vm.wide, _obj ) ],attrs:{"disabled":_vm.isDisabled,"event":!_vm.isDisabled ? 'click' : '',"data-test":"atom-button-link","to":_vm.route}},'router-link',Object.assign({}, _vm.newTabAttrs(),
    _vm.$attrs),false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }