import Vue, { VueConstructor } from "vue";
import VueRouter, { Route } from "vue-router";
import { routes } from "./routes";
import beforeEach from "./beforeEach";
import { getCurrentInstance } from "@vue/composition-api";

/**
 * It will silence NavigationDuplicated error and
 * thrown any other one;
 *
 * @param {Any} err
 * @returns {Any}
 */
const navigationDuplicatedSilencer = (err: any): any => {
  if (
    err instanceof Error &&
    (err.name === "NavigationDuplicated" ||
      err.message.includes("Avoided redundant navigation to current location"))
  ) {
    return;
  }

  throw err;
};

/**
 * VueRouter.push & VueRouter.replace decorator
 * https://github.com/vuejs/vue-router/issues/2881
 *
 * TODO: Add try catch everywhere [push] & [replace]
 * are called to handle NavigationDuplicated
 */
["push", "replace"].forEach((fn) => {
  // @ts-ignore
  const original = VueRouter.prototype[fn];
  // @ts-ignore
  VueRouter.prototype[fn] = function decorator(
    location: any,
    onResolve: any,
    onReject: any
  ): any {
    if (onResolve || onReject) {
      return original.call(this, location, onResolve, onReject);
    }

    return original.call(this, location).catch(navigationDuplicatedSilencer);
  };
});

export function useRoute(): Route {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("useRoute must be called in setup");

  return vm.proxy.$route;
}

export function useRouter(): VueRouter {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("useRouter must be called in setup");

  return vm.proxy.$router;
}

export const useVueRouter = (app: VueConstructor): void => {
  app.use(VueRouter);
};

export const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(beforeEach);

router.afterEach((to) => {
  // matched prop should be ignored
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { matched, name, meta, ...targetRouteProps } = to;

  const routeTitle = meta?.title ?? name;
  const flag = document.title.match(new RegExp(/\(.*\)\s/));
  const newTitle = document.title.replace(new RegExp(/.*(?=\s\|)/), routeTitle);
  document.title = flag ? flag + newTitle : newTitle;

  const route = {
    name: routeTitle,
    properties: targetRouteProps,
  };

  // @ts-ignore
  Vue.analytics.trackPageView(route);

  // @ts-ignore
  Vue.intercom.update({
    extra: { last_request_at: Math.round(new Date().getTime() / 1000) },
  });
});
