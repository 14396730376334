import {
  VIEW_TABLE_PAYMENTS,
  VIEW_TABLE_PAYMENT_HISTORY,
} from "@/utils/enums/permissions";

export default [
  {
    name: "table-payment.index",
    path: "/business/:businessId/table-payment",
    component: () =>
      import(
        /* webpackChunkName: "table-payment-module" */ "@/v2/module/pat/table-payment/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Table payments",
      permission: VIEW_TABLE_PAYMENTS,
    },
  },
  {
    name: "table-payment.outlet",
    path: "/business/:businessId/table-payment/:outletId",
    component: () =>
      import(
        /* webpackChunkName: "table-payment-module" */ "@/v2/module/pat/table-payment/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Table payments",
      permission: VIEW_TABLE_PAYMENTS,
    },
  },
  {
    name: "table-payment.history",
    path: "/business/:businessId/table-payment-history",
    component: () =>
      import(
        /* webpackChunkName: "table-payment-module" */ "@/v2/module/pat/history/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Payment History",
      permission: VIEW_TABLE_PAYMENT_HISTORY,
    },
  },
];
