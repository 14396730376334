import { VIEW_INVENTORY } from "@/utils/enums/permissions";
import { InventoryRouteEnum } from "./inventory-routes.enum";
import { optionalOutletFillerMiddleware } from "@/v2/components/outlet-switcher";
import { RouteConfig } from "vue-router";
import { t } from "@/i18n";

export const inventoryRoutes: Array<RouteConfig> = [
  {
    name: InventoryRouteEnum.MAIN,
    path: "/business/:businessId/inventory/:outletId?",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "inventory-module" */ "../view/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.inventory.main.page_title"),
      permission: VIEW_INVENTORY,
    },
  },
];
