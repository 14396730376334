/**
 * Returns the mutation type to reset the module
 *
 * @return {Object}
 */
export const mutationType = "reset";

/**
 * Returns the mutation to reset the module state.
 *
 * @param {Object} mutationType
 * @param {Object} initialState
 * @returns {Function}
 */
export const mutation = (mutationType, initialState) => ({
  [mutationType](state) {
    Object.assign(state, initialState);
  },
});

/**
 * Returns and action that calls the mutation reset module.
 *
 * @param {String} mutationType
 * @returns {Object}
 */
export const action = async (commit, mutationType) => {
  commit(mutationType);
};
