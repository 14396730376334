<template>
  <div class="input-password">
    <input
      v-bind="$attrs"
      :type="password"
      class="input-password__field"
      :class="{
        'input-password__field--error': hasErrors,
      }"
      v-on="listeners"
    />
    <a
      href="#"
      class="input-password__button"
      @click.prevent="toggleVisibility"
    >
      <Icon :key="iconVisibility" :icon="iconVisibility" color="#D8DFF2" />
    </a>
  </div>
</template>

<script>
import { Icon } from "@/design-system";

/**
 * @deprecated
 * use `MolInputPassword` from `@/v2/new-design-system` instead
 */

export default {
  name: "InputPassword",
  components: {
    Icon,
  },
  inheritAttrs: false,
  props: {
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    password: "password",
  }),
  computed: {
    showPassword() {
      return this.password === "password";
    },
    iconVisibility() {
      return this.showPassword ? "eye" : "eye-slash";
    },
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },
  methods: {
    toggleVisibility() {
      this.password = this.showPassword ? "text" : "password";
    },
  },
};
</script>

<style lang="scss" scoped>
.input-password {
  position: relative;

  &__field {
    display: block;
    width: 100%;
    color: #444444;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 11px 40px 11px 16px;
    font-size: 14px;
    line-height: 18px;

    &:not(&--error):focus {
      border-color: #00a8f8;
    }

    &--error {
      border-color: #e42f46;
      color: #e42f46;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      ::v-deep svg {
        fill: #1258ff;
      }
    }
  }
}
</style>
