




































































import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomIcon,
  AtomText,
  AtomTextColorEnum,
  MolSearchBox,
  MolSearchBoxEnum,
} from "@/v2/new-design-system";
import { ISidebarBrandSwitcher } from "@/v2/module/sidebar/props";
import { debounce } from "@/v2/util/debounce";
import SidebarBrandLogo from "../SidebarBrandLogo.vue";
// @ts-ignore
import { activeBusiness } from "@/utils/helpers/functions";
import { EventEnum } from "@/v2/enum";
import { createBusinessTrack } from "@/v2/util/analytics/createBusinessTrackEvent";

const css = bemBuilder("sidebar-brand-switcher");

export default defineComponent({
  name: "SidebarBrandSwitcher",
  components: {
    AtomText,
    AtomIcon,
    MolSearchBox,
    SidebarBrandLogo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    brands: {
      type: Array as PropType<Array<ISidebarBrandSwitcher>>,
      required: true,
    },
    onCreate: {
      type: Function as PropType<(state: boolean) => void>,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const removeSingle = (brands: Array<ISidebarBrandSwitcher>) => {
      if (brands.length === 1) {
        return brands.filter(
          ({ id }: ISidebarBrandSwitcher) => id !== props.businessId
        );
      }

      return brands;
    };

    const brandList = ref<Array<ISidebarBrandSwitcher>>(
      removeSingle(props.brands)
    );
    const showFullFunctionality = computed(() => props.brands?.length >= 10);
    const isActive = (id: string): boolean => props.businessId === id;

    const handleClick = async (brand: ISidebarBrandSwitcher): Promise<void> => {
      if (isActive(brand.id)) return;

      activeBusiness.set(brand.id);
    };

    watch(
      () => props.brands,
      () => {
        brandList.value = removeSingle(props.brands);
      }
    );

    const handleSearch = debounce((key: string): void => {
      if (!key) {
        brandList.value = props.brands;
      } else {
        const searchKey = key.toLowerCase();
        brandList.value = props.brands.filter(({ label }) =>
          label.toLowerCase().includes(searchKey)
        );
      }
    }, 250);

    const handleCreateBrand = () => {
      createBusinessTrack(
        // @ts-ignore
        vm?.proxy.$analytics,
        EventEnum.CLICKED_ON_BUTTON,
        props.businessId,
        {
          label: "Clicked on create a business from sidebar",
        }
      );

      props.onCreate(true);
    };

    return {
      t,
      css,
      brandList,
      showFullFunctionality,
      isActive,
      handleClick,
      handleCreateBrand,
      handleSearch,
      AtomTextColorEnum,
      MolSearchBoxEnum,
    };
  },
});
