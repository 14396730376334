import io from "socket.io-client";
import { globalConfig } from "@/v2/core/global-config";
// @ts-expect-error plain javascript with no type declaration
import { jwtToken } from "@/utils/helpers/functions";

export const socket = io(globalConfig.inboxWebsocketAddress, {
  autoConnect: false,
  transports: ["websocket", "polling"],
  withCredentials: true,
  auth: {
    token: jwtToken.get(),
  },
});
