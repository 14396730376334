


















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { string, array } from "@/v2/util/prop/type";
import SidebarSubmenuItem from "./SidebarSubmenuItem.vue";

const css = bemBuilder("sidebar-submenu");

export default defineComponent({
  name: "SidebarSubmenu",
  components: {
    SidebarSubmenuItem,
  },
  props: {
    businessId: string().isRequired().use(),
    label: string().use(),
    items: array().isRequired().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
