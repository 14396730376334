import Vue from "vue";

/**
 * Returns the mutation type for fetching resource
 *
 * @returns {Object}
 */
export const mutationType = {
  PENDING: `fetchPending`,
  SUCCESS: `fetchSuccess`,
  FAILURE: `fetchFailure`,
  DONE: `fetchDone`,
};

/**
 * Create a set of mutations needed for the fetch process.
 *
 * @param {Object} asyncAction
 * @returns {Function}
 */
export const mutation = (asyncAction) => {
  return Object.keys(asyncAction).reduce((mutations, asyncState) => {
    switch (asyncState) {
      case "PENDING":
        mutations[asyncAction[asyncState]] = (state) => {
          Vue.set(state, "loading", true);
        };
        break;
      case "SUCCESS":
        mutations[asyncAction[asyncState]] = (state, data) => {
          Vue.set(state, "error", null);
          Vue.set(state, "data", data);
          Vue.set(state, "loading", false);
        };
        break;
      case "FAILURE":
        mutations[asyncAction[asyncState]] = (state, error) => {
          Vue.set(state, "error", error);
          Vue.set(state, "loading", false);
        };
        break;
      case "DONE":
        mutations[asyncAction[asyncState]] = (state) => {
          Vue.set(state, "loading", false);
        };
        break;
    }
    return mutations;
  }, {});
};

/**
 * Create a the needed state for fetching resource.
 *
 * @returns {Object}
 */
export const state = () => ({
  data: null,
  error: null,
  loading: false,
});

/**
 * Action to fetch resource.
 *
 * @param {Function} commit
 * @param {Object} types
 * @param {Promise} request
 * @param {*} params
 * @returns {Object}
 */
export const action = async (commit, types, request, ...params) => {
  try {
    commit(types.PENDING);

    const resource = await request(...params);

    commit(types.SUCCESS, resource.data);

    return resource;
  } catch (err) {
    commit(types.FAILURE, err);

    throw err;
  }
};
