import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";

export type IFetchIntercomDataContract = {
  user_hash: string;
};

export type IFetchIntercomDataResponse = {
  userHash: string;
};

export class FetchIntercomDataError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchIntercomDataError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchIntercomData(): Promise<IFetchIntercomDataResponse> {
  let response: AxiosResponse<IFetchIntercomDataContract>;

  try {
    response = await http.post(`intercom/authorize`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchIntercomDataError(error);
  }

  return {
    userHash: response.data.user_hash,
  };
}
