import { http } from "../../http";
import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";

export class RemoveBusinessLogoError extends RepoBusinessSettingsError {}

export async function removeBusinessLogo(businessId: string): Promise<boolean> {
  try {
    return await http.delete(`/api/v3/businesses/${businessId}/delete-logo`);
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage || "The request to remove a business logo failed.",
      response: response.data,
    };

    throw new RemoveBusinessLogoError(error);
  }
}
