



















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";

const css = bemBuilder("atom-radio-button");

export default defineComponent({
  name: "AtomRadioButton",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const isChecked = computed((): boolean => {
      return props.modelValue === props.value;
    });

    return {
      css,
      isChecked,
    };
  },
});
