








































/* global google */
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { useGoogleMaps } from "@/v2/util/maps/google-maps";
import { AtomText, AtomTextColorEnum, AtomTextTypeEnum } from "../../atom/text";
import { MolSearchBox } from "../../mol/search-box";
import { IMolMapAutocompletePrediction } from "./props";
import { debounce } from "@/v2/util/debounce";

const css = bemBuilder("mol-map-autocomplete");
export default defineComponent({
  name: "MolMapAutocomplete",
  components: { AtomText, MolSearchBox },
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    country: {
      type: String as PropType<string>,
      required: true,
    },
    input: {
      type: String,
      default: "",
    },
    max: {
      type: Number as PropType<number>,
      default: 3,
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    },
    onSelect: {
      type: Function as PropType<
        (prediction: IMolMapAutocompletePrediction) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const gMaps = ref<typeof google.maps>();
    const gGeocoder = ref<google.maps.Geocoder>();
    const gAutoCompleteService = ref<google.maps.places.AutocompleteService>();
    const predictions = ref<Array<IMolMapAutocompletePrediction>>([]);
    const predictionsStorage = ref<Array<IMolMapAutocompletePrediction>>([]);
    const avoidNextList = ref(false);
    const search = ref<string>();
    const searchBox = ref();
    const hasPredictions = computed(() => predictions.value.length > 0);
    const onSearchChange = debounce((input: string): void => {
      search.value = input;
    }, 500);
    const clearPredictions = (): void => {
      predictions.value = [];
    };
    const onSearchFocus = () => {
      predictions.value = predictionsStorage.value;
      clearPredictions();
    };
    const onSearchBlur = () => {
      setTimeout(() => {
        predictionsStorage.value = predictions.value;
        clearPredictions();
      }, 200);
    };
    const selectPrediction = (
      prediction: IMolMapAutocompletePrediction
    ): void => {
      search.value = prediction.description;
      searchBox.value.query = prediction.description;
      avoidNextList.value = true;
      props.onSelect(prediction);
      clearPredictions();
    };
    const remapPredictions = (
      prediction: google.maps.places.AutocompletePrediction
    ): IMolMapAutocompletePrediction => ({
      description: prediction.description,
      placeId: prediction.place_id,
      reference: prediction.reference,
      mainText: prediction.structured_formatting.main_text,
      secondaryText: prediction.structured_formatting.secondary_text,
      types: prediction.types,
    });
    watch(search, (input = "") => {
      if (!input.trim()) {
        clearPredictions();
      } else {
        gAutoCompleteService.value?.getPlacePredictions(
          {
            input,
            componentRestrictions: {
              country: props.country,
            },
          },
          (
            results: Array<google.maps.places.AutocompletePrediction>,
            status
          ) => {
            if (
              status !== gMaps.value?.places.PlacesServiceStatus.OK ||
              avoidNextList.value
            ) {
              avoidNextList.value = false;
              clearPredictions();
            } else {
              predictions.value = results
                .slice(0, props.max)
                .map(remapPredictions);
            }
          }
        );
      }
    });

    watch(
      () => props.input,
      () => {
        searchBox.value.query = props.input;
      }
    );

    onMounted(async () => {
      const { getMaps } = useGoogleMaps(props.apiKey);
      gMaps.value = await getMaps();
      gGeocoder.value = new gMaps.value.Geocoder();
      gAutoCompleteService.value = new gMaps.value.places.AutocompleteService();
    });
    return {
      css,
      search,
      searchBox,
      onSearchChange,
      onSearchFocus,
      onSearchBlur,
      predictions,
      hasPredictions,
      selectPrediction,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
