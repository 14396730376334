/** @type {String} Order statuses */
export const PLACED = "placed";
export const ACCEPTED = "accepted";
export const COMPLETE = "complete";
export const REJECTED = "rejected";
export const CANCELLED = "cancelled";
export const READY = "ready";
export const SCHEDULED = "scheduled";

/** @type {Array} List of live statuses */
export const liveStatuses = [PLACED, ACCEPTED, READY, SCHEDULED];

/** @type {Array} List of all the order statuses */
export const statuses = [
  PLACED,
  ACCEPTED,
  COMPLETE,
  REJECTED,
  CANCELLED,
  READY,
  SCHEDULED,
];
