import {
  VIEW_BUSINESS_SETTINGS,
  VIEW_PAYOUT_PAYEE,
} from "@/utils/enums/permissions";
import { IBusinessSettingsRouteEnum } from "./business-settings-route.enum";

export default [
  {
    name: IBusinessSettingsRouteEnum.BUSINESS_SETTINGS,
    path: "/business/:businessId/business-settings",
    component: () =>
      import(
        /* webpackChunkName: "business-settings-module" */ "@/v2/module/business-settings/main/Main.vue"
      ),
    props: true,
    redirect: { name: IBusinessSettingsRouteEnum.GENERAL_SETTINGS },
    meta: {
      title: "Business Settings",
      permission: VIEW_BUSINESS_SETTINGS,
    },
    children: [
      {
        name: IBusinessSettingsRouteEnum.GENERAL_SETTINGS,
        path: "general-settings",
        component: () =>
          import(
            /* webpackChunkName: "business-settings-module" */ "@/v2/module/business-settings/general-settings/GeneralSettings.vue"
          ),
        props: true,
        meta: {
          title: "Business Settings - General Settings",
          permission: VIEW_BUSINESS_SETTINGS,
        },
      },
      {
        name: IBusinessSettingsRouteEnum.PAYOUTS,
        path: "payouts",
        component: () =>
          import(
            /* webpackChunkName: "business-settings-module" */ "@/v2/module/business-settings/payouts/Payouts.vue"
          ),
        props: true,
        meta: {
          title: "Business Settings - Payouts",
          permission: VIEW_PAYOUT_PAYEE,
        },
      },
    ],
  },
];
