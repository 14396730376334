import { VIEW_LIVE_TABS, VIEW_TABS_HISTORY } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { optionalOutletFillerMiddleware } from "@/v2/components/outlet-switcher";

export default [
  {
    name: "tabs.live",
    path: "/business/:businessId/outlet/:outletId?/live-tabs",
    alias: "/business/:businessId/tab-management/:outletId?",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "tab-module" */ "@/v2/module/tabs/live/Live.vue"
      ),
    props: true,
    meta: {
      title: "Live Tabs",
      permission: VIEW_LIVE_TABS,
    },
  },
  {
    name: "tabs.history",
    path: "/business/:businessId/outlet/:outletId?/tabs",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "tab-module" */ "@/v2/module/tabs/history/History.vue"
      ),
    props: true,
    meta: {
      title: "Tab History",
      permission: VIEW_TABS_HISTORY,
    },
  },
  {
    name: "tabs.view",
    path: "/business/:businessId/outlet/:outletId/tabs/:tabId",
    alias: "/business/:businessId/tab-management/:outletId/:tabId",
    component: () =>
      import(
        /* webpackChunkName: "tab-module" */ "@/v2/module/tabs/view/View.vue"
      ),
    props: true,
    meta: {
      title: "Tab",
      permission: VIEW_LIVE_TABS,
    },
  },
  {
    name: "tabs.add-items",
    path: "/business/:businessId/outlet/:outletId/tabs/:tabId/add-items",
    alias: "/business/:businessId/tab-management/:outletId/:tabId/add-items",
    component: () =>
      import(
        /* webpackChunkName: "tab-module" */ "@/v2/module/tabs/pos/POS.vue"
      ),
    props: true,
    meta: {
      title: "Tabs - Add Items",
      permission: VIEW_LIVE_TABS,
    },
  },
] as Array<RouteConfig>;
