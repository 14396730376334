













































































import { PropType, computed, defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomIcon,
  MolLinearProgress,
  MolCircleProgress,
} from "@/v2/new-design-system";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("org-rating-average");

export interface IRating {
  ratingValue: string;
  count: number;
}

export default defineComponent({
  name: "OrgRatingAverage",
  components: {
    AtomText,
    AtomIcon,
    MolLinearProgress,
    MolCircleProgress,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    rating: {
      type: Array as PropType<Array<IRating>>,
      required: true,
    },
    decimalPoint: {
      type: Number,
      default: 2,
    },
    progressMessage: {
      type: String,
      default: null,
    },
    onRatingClick: {
      type: Function as PropType<(rating: string) => void>,
      default: () => {},
    },
  },
  setup(props) {
    const list = computed(() =>
      [...props.rating].sort((a, b) => {
        if (a.ratingValue > b.ratingValue) return -1;
        if (b.ratingValue > a.ratingValue) return 1;
        return 0;
      })
    );

    const highestValue = computed(() => {
      const firstItem = list.value[0] || {};
      return Number(firstItem.ratingValue);
    });

    const total = computed(() =>
      props.rating.reduce((result, item) => result + item.count, 0)
    );

    const average = computed(() => {
      if (total.value === 0) return 0;

      const sum = props.rating.reduce((res, item, i) => {
        const k = Number(item.ratingValue);
        return res + item.count * k;
      }, 0);

      const result = sum / total.value;
      return Number(result.toFixed(props.decimalPoint));
    });

    const progressColor = computed(() => {
      switch (true) {
        case average.value >= 4.5:
          return "mint";
        case average.value >= 3:
          return "canary";
        case average.value > 0:
          return "coral";
        default:
          return "cloudy";
      }
    });

    return {
      css,
      total,
      list,
      average,
      highestValue,
      progressColor,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
