import { render, staticRenderFns } from "./PendingPaymentBanner.vue?vue&type=template&id=3dbfa5fe&scoped=true&"
import script from "./PendingPaymentBanner.vue?vue&type=script&lang=ts&"
export * from "./PendingPaymentBanner.vue?vue&type=script&lang=ts&"
import style0 from "./PendingPaymentBanner.vue?vue&type=style&index=0&id=3dbfa5fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbfa5fe",
  null
  
)

export default component.exports