import ApiServiceError from "./ApiServiceError";

class ValidationError extends ApiServiceError {
  constructor(response = {}, ...params) {
    // Pass remaining arguments to parent constructor
    super(response, ...params);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }

    this.name = "ValidationError";
    this.message = response?.data?.message;
    this.errors = response?.data?.errors;
    this.error = response?.data?.error;
  }
}

export default ValidationError;
