














import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { VTooltip } from "v-tooltip";
import { IMolTableActionProps } from "./props";
import { MolTableActionTypeEnum } from "./type.enum";

const css = bemBuilder("mol-table-action");

export default defineComponent<IMolTableActionProps>({
  name: "MolTableAction",
  components: {
    AtomIcon,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<MolTableActionTypeEnum>,
      default: MolTableActionTypeEnum.PRIMARY,
    },
    tooltipText: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    function handleClick(event: Event) {
      event.stopPropagation();
      props.onClick();
    }
    return {
      css,
      handleClick,
    };
  },
});
