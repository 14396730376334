import Vue from "vue";
import * as types from "./mutation_types";
import Orders from "@/api/orders";
import { ORDER_RECEIVED } from "@/plugins/analytics/events";
import { action as fetchAction } from "@/store/fetchBase";
import { action as resetAction } from "@/store/resetBase";
import { liveStatuses } from "@/utils/enums/order-status";
import { includes } from "ramda";
import { getLiveOrderStatusList } from "@/utils/auth/helpers";

const baseParams = (permissions) => ({
  status: getLiveOrderStatusList(permissions),
  limit: 999,
});

/**
 * Fetch a list of live orders based on the passed params
 *
 * @returns {Array}
 */
export const fetch = async ({ commit, rootGetters }) => {
  await fetchAction(
    commit,
    types.FETCH,
    Orders.get,
    baseParams(rootGetters["auth/getPermissions"])
  );
};

/**
 * Reset the module to the initial state.
 *
 * @returns {Void}
 */
export const reset = async ({ commit }) => resetAction(commit, types.RESET);

/**
 * Remove order with passed ID from data property.
 *
 * @param {String} id Order ID
 * @returns {void}
 */
export const destroy = async ({ commit }, id) => {
  await commit(types.DESTROY, id);
};

/**
 * Add the passed order object into the data property list or
 * update if it's already added before - unique by ID.
 *
 * @param {Object} order
 * @returns {void}
 */
export const save = async ({ getters, commit }, order) => {
  const orders = getters["getData"];

  if (!orders.find((obj) => obj.id === order.id)) {
    Vue.analytics.trackEvent(ORDER_RECEIVED, {
      id: order.id,
      status: order.status,
    });
  }

  await commit(types.SAVE, order);
};

/**
 * Update the the status for the given order ID passed throught
 * the object.
 *
 * @param {Object} order Must contain order id and new status { id: X, status: Y }
 * @returns {void}
 */
export const updateStatus = async ({ commit }, order) => {
  await commit(types.UPDATE_STATUS, order);
};

/**
 * Request an updated record from API and call save or destroy
 * based on order current status.
 * NOTE: It will keep in the list only if status is part of live statuses.
 *
 * @param {String} id Order ID
 * @returns {Object} Order
 */
export const refresh = async ({ dispatch }, id) => {
  const res = await Orders.find(id);
  const order = res.data;

  includes(order.status, liveStatuses)
    ? dispatch("save", order)
    : dispatch("destroy", order.id);

  return order;
};

/**
 * Mark the given order as acknowledged.
 *
 * @param {Number} orderId
 * @returns {void}
 */
export const markAsAcknowledged = ({ commit }, orderId) => {
  commit(types.ACKNOWLEDGE_ORDER, orderId);
};

/**
 * Add the given order to the list of orders to be acknowledged.
 *
 * @param {Number} orderId
 * @returns {void}
 */
export const addOrderToAcknowledge = ({ commit }, orderId) => {
  commit(types.ORDER_TO_ACKNOWLEDGE, orderId);
};
