import { VIEW_VOID_REASONS } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { VoidReasonRouteEnum } from "./void-reason-route.enum";
import { t } from "@/i18n";

export const voidReasonRoutes: Array<RouteConfig> = [
  {
    name: VoidReasonRouteEnum.MAIN,
    path: "void-reasons",
    component: () =>
      import(
        /* webpackChunkName: "void-reasons-module" */ "../view/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.void_reason.main.page_title"),
      permission: VIEW_VOID_REASONS,
    },
  },
];
