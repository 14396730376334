




















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { array, string } from "@/v2/util/prop/type";
import SidebarMenuItem from "./SidebarMenuItem.vue";

const css = bemBuilder("sidebar-menu");

export default defineComponent({
  name: "SidebarMenu",
  components: {
    SidebarMenuItem,
  },
  props: {
    businessId: string().isRequired().use(),
    menu: array().isRequired().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
