import { AxiosResponse } from "axios";

export enum RepoBusinessSettingsErrorEnum {
  NAME_ALREADY_USED = "business_name_already_used",
}

export interface IRepoBusinessSettingsErrorResponse {
  status: number;
  message: string;
  validationError?: Record<string, string>;
  request?: Record<string, any>;
  response?: Record<string, any>;
}

export interface IAxiosErrorResponse extends AxiosResponse {}

export class RepoBusinessSettingsError extends Error {
  error: IRepoBusinessSettingsErrorResponse;

  constructor(error: IRepoBusinessSettingsErrorResponse) {
    super(error.message);
    this.error = error;
    this.name = this.constructor.name;
  }
}
