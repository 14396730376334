export const PIN_ICON = `data:image/svg+xml;base64,
PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9
Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2QpIj4KICAg
ICAgICA8cGF0aCBkPSJNNDggMjEuNjEwNEM0OCAyNy4zNjUzIDQ1LjcwOTkgMzIuNTk0OCA0MS45NzYxIDM2LjQ2
NzVDMzcuOTY2NCA0MC42MjY1IDQxLjk3NjEgMzYuNDY3NSAyNiA1MkMxMC4wMjM5IDM2LjQ2NzUgMTQuMDMzNiA0
MC42MjY1IDEwLjAyMzkgMzYuNDY3NUM2LjI5MDA3IDMyLjU5NDggNCAyNy4zNjUzIDQgMjEuNjEwNEM0IDkuNjc1
MyAxMy44NDk3IDAgMjYgMEMzOC4xNTAzIDAgNDggOS42NzUzIDQ4IDIxLjYxMDRaIgogICAgICAgICAgICAgIGZp
bGw9IiMxMjU4RkYiLz4KICAgIDwvZz4KICAgIDxlbGxpcHNlIGN4PSIyNi4wMDEiIGN5PSIyMS42MDk5IiByeD0i
MTEiIHJ5PSIxMC44MDUyIiBmaWxsPSJ3aGl0ZSIvPgogICAgPGRlZnM+CiAgICAgICAgPGZpbHRlciBpZD0iZmls
dGVyMF9kIiB4PSIwIiB5PSIwIiB3aWR0aD0iNTIiIGhlaWdodD0iNjAiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VP
blVzZSIKICAgICAgICAgICAgICAgIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CiAgICAgICAg
ICAgIDxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CiAgICAgI
CAgICAgIDxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwID
AgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIi8+CiAgICAgICAgICAgIDxmZU9mZnNldCBkeT0iNCI
vPgogICAgICAgICAgICA8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIyIi8+CiAgICAgICAgICAgIDxmZUNv
bG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC
AwLjIgMCIvPgogICAgICAgICAgICA8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4
IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPgogICAgICAgICAgICA8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIG
luPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPgogICAgICAg
IDwvZmlsdGVyPgogICAgPC9kZWZzPgo8L3N2Zz4K`;
