import Intercom from "./intercom";

/**
 * Vue Plugin to integrate Intercom widget
 *
 * @param Vue
 * @param initConfig
 */
export default (Vue, initConfig = {}) => {
  const config = Object.assign(
    {
      appId: null,
    },
    initConfig
  );

  const intercom = new Intercom(config);

  Vue.prototype.$intercom = Vue.intercom = intercom;
};
