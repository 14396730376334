





















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SidebarLabel",
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    href: {
      type: String,
      default: "#",
    },
    hasSubmenu: {
      type: Boolean,
      default: false,
    },
  },
});
