<template>
  <div class="d-flex align-items-center">
    <label class="switcher" :for="_uid">
      <input
        :id="_uid"
        class="switcher__input"
        type="checkbox"
        v-bind="$attrs"
        :checked="isChecked"
        :value="value"
        data-test="input"
        @change="updateInput"
      />
      <span class="switcher__mark">
        <span v-if="onLabel" class="switcher__on" data-test="on-label">
          {{ onLabel }}
        </span>
        <span v-if="offLabel" class="switcher__off" data-test="off-label">
          {{ offLabel }}
        </span>
      </span>
      <div>
        <span v-if="label" class="switcher__label" data-test="label">
          {{ label }}
        </span>
        <slot />
      </div>
    </label>
    <HelpTooltip v-if="tooltip" class="ml-2" :content="tooltip" />
  </div>
</template>

<script>
import HelpTooltip from "./HelpTooltipDepricated.vue";

/**
 * @deprecated
 * use `AtomSwitcher` from `@/v2/new-design-system` instead
 */

export default {
  name: "Switcher",
  components: {
    HelpTooltip,
  },
  inheritAttrs: false,
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    modelValue: {
      type: [Boolean, Array],
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    onLabel: {
      type: String,
      default: "on",
    },
    offLabel: {
      type: String,
      default: "off",
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }

      return this.modelValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  $switcher: &;

  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 0;

  &__label {
    display: block;
    color: #444444;
    font-size: 13px;
    padding-left: 10px;
  }

  &__mark {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 60px;
    height: 30px;
    padding: 0 10px;
    border-radius: 20px;
    background: #bccbda;
    transition: all 0.3s ease-out;

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 3px;
      top: 3px;
      width: 24px; /* x*3 */
      height: 24px; /* x*3 */
      border-radius: 16px; /* x*2 */
      background: #ffffff;
      transition: all 0.3s ease-out;
    }

    span {
      font-size: 10px;
      font-weight: 500;
      color: #ffffff;
      transition: all 0.2s ease-out;

      &::first-letter {
        text-transform: capitalize;
      }

      &#{ $switcher }__on {
        opacity: 0;
      }

      &#{ $switcher }__off {
        text-align: right;
        opacity: 1;
        margin-left: -5px;
      }
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked + #{ $switcher }__mark {
      background: #70cc49;

      &:after {
        left: calc(100% - 3px);
        transform: translate(-100%, 0);
      }

      span {
        &#{ $switcher }__on {
          opacity: 1;
        }

        &#{ $switcher }__off {
          opacity: 0;
        }
      }
    }

    &:focus + #{ $switcher }__mark {
      box-shadow: 0 0 0 1px #00a8f8;
    }
  }
}
</style>
