import PusherSDK, { Channel } from "pusher-js";
import type { ChannelAuthorizationCallback } from "pusher-js";
import { globalConfig } from "@/v2/core/global-config";
// @ts-expect-error util/helpers not converted to ts yet
import { jwtToken } from "@/utils/helpers/functions";

const pusher = new PusherSDK(globalConfig.pusherKey, {
  cluster: globalConfig.pusherCluster,
  forceTLS: false,
  channelAuthorization: {
    endpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    transport: "ajax",
    async customHandler(
      params: { socketId: string; channelName: string },
      callback: ChannelAuthorizationCallback
    ): Promise<void> {
      const { socketId, channelName } = params;

      const body = new URLSearchParams({
        socket_id: socketId,
        channel_name: channelName,
      });

      const bearerToken: string = jwtToken.get() ?? "";

      const response = await fetch(
        `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
        {
          method: "post",
          body,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      const data = await response.json();
      callback(null, {
        auth: data.auth,
      });
    },
  },
});

type ITenantChannelEvent =
  | "order.new"
  | "order.rejected"
  | "order.accepted"
  | "order.marked-as-ready"
  | "order.complete"
  | "order.cancelled"
  | "order.closed"
  | "delivery.created"
  | "delivery.failed"
  | "delivery.pending"
  | "orderingService.updated"
  | "outletAvailability.created";

export type IBusinessChannelEvent =
  | "foodics.token-acquired"
  | "foodics.branches-synced";

export type ITabChannelEvent =
  | "tabs.started"
  | "tabs.discount-applied"
  | "tabs.discount-adjusted"
  | "tabs.discount-removed"
  | "tabs.line-item-voided"
  | "tabs.item-added"
  | "tabs.recalculated"
  | "tabs.moved-to-table"
  | "tabs.payment-failed"
  | "tabs.payment-added"
  | "tabs.payment-confirmed"
  | "tabs.settled"
  | "tabs.closed"
  | "tabs.line-item-moved-in"
  | "tabs.line-item-moved-out";

export type ISpotsOcuppancyChannelEvent =
  | "table-occupancy.occupied"
  | "table-occupancy.available"
  | "table.reserved"
  | "table.reservation-moved"
  | "table.reservation-deleted";

type IGeneralChannelEvent = "browser.reload" | "browser.ensure-version";

const getChannel = (name: string): Channel => {
  const channel = pusher.channels.channels?.[name];

  if (!channel) {
    throw new Error(
      `It is not subscribed to the pusher channel '${name}' yet.`
    );
  }

  return channel;
};

const isSubscribed = (channelName: string): boolean => {
  return Boolean(pusher.channels.channels?.[channelName]?.subscribed);
};

const channelNameTransformer = (
  name: string,
  isPrivate: boolean = false
): string => {
  return isPrivate ? `private-${name}` : name;
};

export const subscribe = (channel: string): Promise<Channel> => {
  return new Promise((resolve, reject) => {
    const subscription = pusher.subscribe(channel);

    subscription.bind("pusher:subscription_succeeded", () => {
      resolve(subscription);
    });

    subscription.bind("pusher:subscription_error", (e: any) => {
      reject(e);
    });
  });
};

export const connector = (): PusherSDK => {
  return pusher;
};

export const listenFromGeneral = async (
  events: Array<IGeneralChannelEvent>,
  callback: (payload: any, event: string) => any
): Promise<void> => {
  const channelName = "general";

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  events.forEach((event: string): void => {
    channel.bind(event, (payload: any) => callback(payload, event));
  });
};

export const listenFromTenant = async (
  tenantId: string,
  events: Array<ITenantChannelEvent>,
  callback: (payload: any) => any
): Promise<void> => {
  const channelName = channelNameTransformer(`tenant.${tenantId}`, true);

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  events.forEach((event: string): void => {
    channel.bind(event, callback);
  });
};

export const unlistenFromTenant = async (
  tenantId: string,
  events: Array<ITenantChannelEvent>,
  callback: (payload: any) => any | undefined
): Promise<void> => {
  const channelName = channelNameTransformer(`tenant.${tenantId}`, true);

  if (!isSubscribed(channelName)) {
    return;
  }

  const channel = getChannel(channelName);

  events.forEach((event: string): void => {
    channel.unbind(event, callback);
  });
};

export const unsubscribeAllPrivate = (): void => {
  const allChannels = pusher.channels.channels ?? [];
  const privateChannelNames = Object.keys(allChannels).filter((key) => {
    return key.startsWith("private-");
  });

  privateChannelNames.forEach((channelName) => {
    const channel = getChannel(channelName);
    channel.unbind();
    channel.unsubscribe();
  });
};

export const listenFromBusiness = async (
  businessId: string,
  events: Array<IBusinessChannelEvent>,
  callback: (payload: any) => any
): Promise<void> => {
  const channelName = channelNameTransformer(`business.${businessId}`);

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  events.forEach((event: string): void => {
    channel.bind(event, callback);
  });
};

export const unlistenFromBusiness = async (
  businessId: string,
  events: Array<IBusinessChannelEvent>,
  callback: (payload: any) => any | undefined
): Promise<void> => {
  const channelName = channelNameTransformer(`business.${businessId}`, true);

  if (!isSubscribed(channelName)) {
    return;
  }

  const channel = getChannel(channelName);

  events.forEach((event: string): void => {
    channel.unbind(event, callback);
  });
};

type ITabOptions = {
  tabId?: string;
  outletId?: string;
};

export const listenFromTab = async (
  options: ITabOptions,
  type: "TAB_VIEW" | "TAB_LIST",
  events: Array<ITabChannelEvent> | "*",
  callback: (payload: any) => any
): Promise<void> => {
  const isTabView = `tabs.${options.tabId}`;
  const isTabList = `tabs.outlet.${options.outletId}`;

  const setChannel = type === "TAB_VIEW" ? isTabView : isTabList;
  const channelName = channelNameTransformer(setChannel, true);

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  if (events === "*") {
    channel.bind_global(callback);
    return;
  }

  events.forEach((event: string): void => {
    channel.bind(event, callback);
  });
};

export const unlistenFromTab = async (
  options: ITabOptions,
  type: "TAB_VIEW" | "TAB_LIST",
  events: Array<ITabChannelEvent> | "*",
  callback: (payload: any) => any | undefined
): Promise<void> => {
  const isTabView = `tabs.${options.tabId}`;
  const isTabList = `tabs.outlet.${options.outletId}`;

  const setChannel = type === "TAB_VIEW" ? isTabView : isTabList;
  const channelName = channelNameTransformer(setChannel, true);

  if (!isSubscribed(channelName)) {
    return;
  }

  const channel = getChannel(channelName);

  if (events === "*") {
    channel.unbind_global(callback);
    return;
  }

  events.forEach((event: string): void => {
    channel.unbind(event, callback);
  });
};

export const listenFromSpotOccupancy = async (
  outletId: string,
  events: Array<ISpotsOcuppancyChannelEvent> | "*",
  callback: (payload: any) => any
): Promise<void> => {
  const channelName = channelNameTransformer(`tables.outlet.${outletId}`, true);

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  if (events === "*") {
    channel.bind_global(callback);
    return;
  }

  events.forEach((event: string): void => {
    channel.bind(event, callback);
  });
};

export const unlistenFromSpotOccupancy = async (
  outletId: string,
  events: Array<ISpotsOcuppancyChannelEvent> | "*",
  callback: (payload: any) => any | undefined
): Promise<void> => {
  const channelName = channelNameTransformer(`tables.outlet.${outletId}`, true);

  if (!isSubscribed(channelName)) {
    await subscribe(channelName);
  }

  const channel = getChannel(channelName);

  if (events === "*") {
    channel.unbind_global(callback);
    return;
  }

  events.forEach((event: string): void => {
    channel.unbind(event, callback);
  });
};
