import { t } from "@/i18n";
import { MolModalGuideLinkVideoEnum } from "./video.enum";
import { AtomIconNameEnum } from "@/v2/new-design-system/atom/icon/icon.enum";

export const ModalGuideLinkConfig: Record<
  MolModalGuideLinkVideoEnum,
  {
    link: string | { [key: string]: string };
    label: () => string;
    iconName: string;
  }
> = {
  [MolModalGuideLinkVideoEnum.INTRO]: {
    link: "https://fast.wistia.net/embed/iframe/48ximty63k",
    label: () => t("design_system.mol_modal_guide_link.label_intro_video"),
    iconName: AtomIconNameEnum.PLAY,
  },
  [MolModalGuideLinkVideoEnum.HOW_TO_MANAGE_DELIVERY_AREAS]: {
    link: "https://www.loom.com/embed/36688aac87df4156bc8aa08408d4ffa4?hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true",
    label: () =>
      t("design_system.mol_modal_guide_link.label_delivery_areas_video"),
    iconName: AtomIconNameEnum.PLAY,
  },
  [MolModalGuideLinkVideoEnum.HOW_TO_MANAGE_OUTLETS]: {
    link: "https://www.loom.com/embed/9d95dfab3d144965bff546d9f6b79582?hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true",
    label: () => t("design_system.mol_modal_guide_link.label_outlets_video"),
    iconName: AtomIconNameEnum.PLAY,
  },
};
