var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('AtomModal',{attrs:{"data-test":"billing-plan-modal","name":"billing-plan-modal","on-click-close":_vm.billingSetStageDormant,"show":true,"show-close-button":!_vm.isLoadingNextStep,"width":"1067px","height":"660px"}},[_c('MolLoadingZone',{attrs:{"loading":_vm.isLoadingPlans}},[_c('AtomText',{class:_vm.css('heading'),attrs:{"type":_vm.AtomTextTypeEnum.H2}},[_vm._v(" "+_vm._s(_vm.t("c.billing.choose_plan"))+" ")]),_c('AtomText',{class:_vm.css('subheading'),attrs:{"type":_vm.AtomTextTypeEnum.BODY,"color":_vm.AtomTextColorEnum.STORMY}},[_vm._v(" "+_vm._s(_vm.t("c.billing.choose_plan_desc"))+" ")]),_c('MolGuideLink',{class:_vm.css('learn-more'),attrs:{"article":_vm.MolGuideLinkArticleEnum.PRICING}}),_c('div',{class:_vm.css('plans')},_vm._l((_vm.plans),function(plan,index){return _c('BillingPlanCard',{key:plan.id,class:_vm.css('plan-card'),attrs:{"show-badge":index === 1,"is-loading-next-step":_vm.isLoadingNextStep,"disabled":_vm.currentOutletCount > plan.maxOutlets,"cta-label":_vm.currentOutletCount > plan.maxOutlets
              ? _vm.t('c.billing.active_outlets_notice', {
                  outletCount: _vm.currentOutletCount,
                })
              : null,"heading":plan.name,"price":((plan.price) + "/" + (_vm.t('c.billing.month_short'))),"decor":index + 1,"on-click-cta":function () { return _vm.onSelectPlan(plan); },"cta-price":_vm.billingMethodPlanCalculateFinalPrice(
              plan.priceRaw,
              plan.extraOutlets,
              plan.extraOutletPriceRaw,
              plan.currency
            ),"extra-outlet-count":plan.extraOutlets,"included-order-count":plan.maxOrders,"included-outlet-count":plan.maxOutlets,"price-per-extra-order":plan.extraOrderPrice,"price-per-extra-outlet":plan.extraOutletPrice}})}),1),_c('div',{class:_vm.css('footer')},[_c('AtomText',[_vm._v(_vm._s(_vm.t("c.billing.need_more_than_10")))]),_vm._v(" "+_vm._s("\xa0")+" "),_c('a',{attrs:{"id":"plan_modal_contact_us","href":"#"}},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.LINK,"color":_vm.AtomTextColorEnum.CHATFOOD}},[_vm._v(" "+_vm._s(_vm.t("c.billing.contact_us"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }