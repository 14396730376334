import * as actions from "./actions";
import * as getters from "./getters";
import * as types from "./mutation_types";
import { clone } from "ramda";
import {
  mutation as fetchMutation,
  state as fetchState,
} from "@/store/fetchBase";
import { mutation as resetMutation } from "@/store/resetBase";

/** @type {Object} Initial state for the module */
export const initialState = {
  ...fetchState(),
};

export default {
  // Clone of the initialState to set to the module
  state: clone(initialState),

  // List of available module mutations
  mutations: {
    ...fetchMutation(types.FETCH),
    ...resetMutation(types.RESET, initialState),
    [types.ATTACH_DELIVERECT_STATE](state, data) {
      const outlets = state.data;
      outlets.forEach((outlet) => {
        outlet.isDeliverectEnabled = Boolean(
          data.find(
            (obj) =>
              obj.outletId === outlet.id && obj.businessId === outlet.businessId
          )
        );
        return outlet;
      });
      state.data = outlets;
    },
    [types.ATTACH_MENU_INTEGRATION_STATE](state, data) {
      const outlets = state.data;
      outlets.forEach((outlet) => {
        outlet.isMenuIntegrated = Boolean(
          data.find(
            (obj) =>
              obj.outletId === outlet.id && obj.businessId === outlet.businessId
          )
        );
        return outlet;
      });
      state.data = outlets;
    },
  },

  // List of available module actions
  actions,

  // List of available module getters
  getters,

  // Add namespace into module
  namespaced: true,
};
