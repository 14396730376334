






























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { AtomDatepickerRange } from "../../atom/datepicker-range";
import { MolMultiselect } from "../../mol/multiselect";
import dayjs from "dayjs";
import { t } from "@/i18n";

const css = bemBuilder("mol-select-period");

interface IComparisonPeriod {
  id: string;
  name: string;
}

interface IRangePeriod {
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "MolSelectPeriod",
  components: {
    AtomIcon,
    MolMultiselect,
    AtomDatepickerRange,
  },
  props: {
    isYesterdayReference: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: [Date, String],
      default: "",
    },
    noCustomDate: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(rangePeriod: IRangePeriod) => void>,
      required: true,
    },
  },
  setup(props) {
    const rangePeriod = ref<IRangePeriod>({
      start: dayjs().startOf("day").toDate(),
      end: dayjs().endOf("day").toDate(),
    });

    const dateCustom = ref(false);

    // Set reference day to today
    let referenceDay = dayjs();

    // Set reference day to yesterday
    if (props.isYesterdayReference) {
      referenceDay = referenceDay.add(-1, "day");
    }

    const periods = computed(() => {
      let frequency = [
        {
          id: "currentMonth",
          name: t("design_system.mol_select_period.current_month"),
        },
        {
          id: "week",
          name: t("design_system.mol_select_period.last_7_days"),
        },
        {
          id: "month",
          name: t("design_system.mol_select_period.last_30_days"),
        },
      ];

      if (!props.noCustomDate) {
        frequency = [
          ...frequency,
          {
            id: "custom",
            name: t("design_system.mol_select_period.select_date_range"),
          },
        ];
      }

      return frequency;
    });

    const inputRange = computed(() => {
      return {
        start: dayjs(rangePeriod.value.start).startOf("day").toDate(),
        end: dayjs(rangePeriod.value.end).endOf("day").toDate(),
      };
    });

    function setComparisonPeriod(comparisonPeriod: IComparisonPeriod) {
      if (comparisonPeriod.id === "currentMonth") {
        rangePeriod.value = {
          start: referenceDay.startOf("month").toDate(),
          end: referenceDay.toDate(),
        };
      }

      if (comparisonPeriod.id === "week") {
        rangePeriod.value = {
          start: referenceDay.subtract(1, "week").toDate(),
          end: referenceDay.toDate(),
        };
      }

      if (comparisonPeriod.id === "month") {
        rangePeriod.value = {
          start: referenceDay.subtract(30, "day").toDate(),
          end: referenceDay.toDate(),
        };
      }

      if (comparisonPeriod.id === "custom") {
        rangePeriod.value = {
          start: rangePeriod.value.start,
          end: referenceDay.toDate(),
        };
        dateCustom.value = true;
        return;
      }

      selectedPeriod();
    }

    function setDateRange(dateRange: IRangePeriod) {
      rangePeriod.value = dateRange;

      selectedPeriod();
    }

    function selectedPeriod() {
      props.onChange(inputRange.value);
    }

    return {
      css,
      rangePeriod,
      dateCustom,
      referenceDay,
      periods,
      setComparisonPeriod,
      setDateRange,
    };
  },
});
