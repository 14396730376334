import { useStore } from "@/store";
import { IVuexBusiness } from "@/store/types";

export function canViewDashboard(
  hasViewDashboardPermission: boolean,
  hasViewOnboardingPermission: boolean
): boolean {
  const businesses: Array<IVuexBusiness> =
    useStore().getters["businesses/getData"];

  const areAllBusinessesOnboarded = Boolean(
    businesses.find(function (business) {
      return (
        business.onboardingStatus !== "Active Implementation" &&
        business.onboardingStatus !== "New"
      );
    })
  );

  return (
    (areAllBusinessesOnboarded && hasViewDashboardPermission) ||
    (!hasViewOnboardingPermission && hasViewDashboardPermission)
  );
}
