

















import { boolean, number, string } from "@/v2/util/prop/type";
import { defineComponent, onMounted } from "@vue/composition-api";
import { IAtomModalProps } from "./props";
import VueJsModal from "vue-js-modal";

/**
 * @deprecated
 * use `AtomModal` from `v2/new-design-system` instead
 */

export default defineComponent<IAtomModalProps>({
  name: "AtomModal",
  props: {
    name: string().isRequired().use(),
    show: boolean().isRequired().setDefault(false).use(),
    positionY: number().setDefault(0.5).use(),
    width: string().setDefault("600px").use(),
    height: string().setDefault("300px").use(),
    maxHeight: number().setDefault(Infinity).use(),
    maxWidth: number().setDefault(Infinity).use(),
  },
  setup(props, context) {
    function show(): void {
      // @ts-ignore
      context.root.$modal.show(props.name);
    }

    function hide(): void {
      // @ts-ignore
      context.root.$modal.show(props.name);
    }

    onMounted(() => {
      // @ts-ignore
      context.root.$modal.show(props.name);
    });
  },
});
