
































import { defineComponent } from "@vue/composition-api";
import { boolean, func } from "@/v2/util/prop/type";

/**
 * @deprecated
 * use `AtomCheckbox` from `v2/new-design-system`
 */

export default defineComponent({
  name: "AtomCheckbox",
  inheritAttrs: false,
  props: {
    checked: boolean().use(),
    striped: boolean().use(),
    rightContent: boolean().use(),
    disabled: boolean().use(),
    onChange: func().isRequired().use(),
  },
  computed: {
    hasContent(): boolean {
      return Boolean(this.$slots.default);
    },
  },
});
