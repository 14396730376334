import { mapGetters } from "vuex";
import { find, propOr, isNil, head } from "ramda";
import { activeBusiness } from "@/utils/helpers/functions";
import { fetchOnboardingSteps } from "@/v2/repo/fetch-onboarding-steps";
import { onboardingAccess } from "@/v2/composable/use-onboarding-step-warning";

/**
 * Get business from Vuex store.
 * NOTE: Component MUST provide businessId property.
 *
 * @return {Object} Vue mixin
 */
const getBusinessById = {
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return find((obj) => obj.id === this.businessId, this.businesses);
    },
  },
};

/**
 * Get active business from route, localStorege or vuex.
 *
 * @return {Object} Vue mixin
 */
const getActiveBusiness = {
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    activeBusiness() {
      let business;

      const urlBusinessId = propOr(null, "businessId", this.$route.params);
      if (
        !isNil(
          (business = find((obj) => obj.id === urlBusinessId, this.businesses))
        )
      ) {
        return business;
      }

      const lsBusinessId = activeBusiness.get();
      if (
        !isNil(
          (business = find((obj) => obj.id === lsBusinessId, this.businesses))
        )
      ) {
        return business;
      }

      return head(this.businesses);
    },
  },
};

/**
 * Get business currency from Vuex store.
 * NOTE: Component MUST provide businessId property.
 *
 * @return {Object} Vue mixin
 */
const getBusinessCurrencyById = {
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return find((obj) => obj.id === this.businessId, this.businesses);
    },
    currency() {
      return propOr({}, "currency", this.business);
    },
  },
};

/**
 * Prepare everything necessary to work with Uncompleted Step Warning Popup
 * NOTE: Component MUST provide businessId property.
 * @return {Object} Vue mixin
 */

const uncompletedStepWarningMixin = (type) => ({
  computed: {
    isOnboarding() {
      return onboardingAccess(this.businessId);
    },
    isActiveOnboarding() {
      return this.isOnboardingStepCompleted || !this.isOnboarding;
    },
  },
  data: () => ({
    isOnboardingStepCompleted: false,
  }),
  watch: {
    businessId: {
      immediate: true,
      handler: async function (val) {
        if (this.isOnboarding) {
          const result = await fetchOnboardingSteps(val);
          if (typeof type === "string") {
            const item = result?.find(({ title }) => title === type);

            if (item?.completedAt !== null)
              this.isOnboardingStepCompleted = true;
          } else {
            const isCompleted = result?.length
              ? result.every(({ completedAt }) => completedAt !== null)
              : false;

            if (isCompleted) this.isOnboardingStepCompleted = true;
          }
        }
      },
    },
  },
});

export {
  getBusinessById,
  getActiveBusiness,
  getBusinessCurrencyById,
  uncompletedStepWarningMixin,
};
