



































// @ts-ignore
import { ACCOUNT_OWNER } from "@/utils/enums/roles";
import { fetchCurrentSubscription } from "@/v2/repo/fetch-current-subscription";
import { bemBuilder } from "@/v2/util/bem-builder";
import { wait } from "@/v2/util/wait";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from "@vue/composition-api";
import { BillingPayment } from "../payment";
import { BillingPlanModal } from "../plan-modal";
import { BillingReviewModal } from "../review-modal";
import { MolFailModal } from "@/v2/new-design-system";
import {
  billingAction,
  billingGetState,
  billingInitState,
  billingSetState,
  BillingStageStateEnum,
} from "../state";
import { BillingSuccessModal } from "../success-modal";
import { t } from "@/i18n";
// @ts-ignore
import { activeBusiness } from "@/utils/helpers/functions";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("billing-main");

export default defineComponent({
  name: "BillingMain",
  components: {
    BillingPlanModal,
    BillingPayment,
    BillingReviewModal,
    BillingSuccessModal,
    MolFailModal,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    onBeforeUnmount(() => {
      billingInitState();
    });
    const loadPlans = async () => {
      try {
        await billingAction("loadPlans", [props.businessId]);
      } catch (error: any) {
        report(error.message, {
          context: {
            error: error,
          },
          tags: {
            "http.response_code": error.statusCode,
          },
        });
      }
    };

    onMounted(async () => {
      const businessesData = ctx.root.$store.getters["businesses/getData"];
      const userData = ctx.root.$store.getters["auth/getData"];

      billingInitState();
      await loadPlans();

      billingSetState("user", {
        name: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
      });

      const currentBusiness = businessesData.find(
        (business: any) => business.id === props.businessId
      );

      billingSetState("business", {
        id: currentBusiness.id,
        name: currentBusiness.name,
        logo: currentBusiness.logo,
      });

      if (!userData.roles.includes(ACCOUNT_OWNER)) return;

      billingSetState(
        "currentPlan",
        await fetchCurrentSubscription(billingGetState("business").value.id)
      );

      const currentPlan = billingGetState("currentPlan");
      if (!currentPlan.value) return;

      // Try to give the subscription time to be created
      if (currentPlan.value.state === "CREATED") {
        let tries = 0;
        while (tries < 3 && currentPlan.value?.state === "CREATED") {
          await wait(3000);

          billingSetState(
            "currentPlan",
            await fetchCurrentSubscription(billingGetState("business").value.id)
          );

          tries++;
        }
      }

      if (currentPlan.value.state === "CREATED") return;
      if (currentPlan.value.plan.type == "REGULAR") return;
      if (currentPlan.value.plan.type == "CUSTOM") return;

      if (currentPlan.value.state === "TRIAL_ENDED") {
        billingSetShowFlowStart();
        return;
      }

      if (currentPlan.value.state === "DRAFT") {
        activeBusiness.set(props.businessId);
      }

      if (!currentPlan.value.trialEnd) return;

      const trialEndTime = new Date(currentPlan.value.trialEnd).getTime();
      const timeNow = new Date().getTime();
      const secondMs = 1000;

      const diff = (trialEndTime - timeNow) / secondMs;
      const diffInDays = diff / 60 / 60 / 24;

      billingSetShowFlowStart();
      billingSetState("trialDays", diffInDays);
    });

    const billingSetStagePaymentMethod = () => {
      billingSetState("stage", BillingStageStateEnum.PAYMENT_METHOD);
    };

    const billingSetShowFlowStart = () => {
      billingSetState("showFlowStartCTA", true);
    };

    return {
      t,
      css,
      stage: billingGetState("stage"),
      BillingStageStateEnum,
      billingSetStagePaymentMethod,
    };
  },
});
