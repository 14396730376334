import { VIEW_OUTLETS } from "@/utils/enums/permissions";

export default [
  {
    name: "outlets.index",
    path: "/business/:businessId/outlets",
    component: () =>
      import(
        /* webpackChunkName: "outlet-module" */ "@/v2/module/outlet/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "View - Outlets",
      permission: VIEW_OUTLETS,
    },
  },
];
