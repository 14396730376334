












/* global google */
import { t } from "@/i18n";
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  provide,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { addListeners, useGoogleMaps } from "@/v2/util/maps/google-maps";
import {
  IAtomMapProps,
  IListeners,
} from "@/v2/new-design-system/atom/map/props";

const css = bemBuilder("atom-map");

export default defineComponent({
  name: "AtomMap",
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    initialPosition: {
      type: Object as PropType<IAtomMapProps["initialPosition"]>,
      default: () => ({}),
    },
    options: {
      type: Object as PropType<google.maps.MapOptions>,
      default: () => ({}),
    },
    centerMap: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => ({}),
    },
    onDoubleClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => ({}),
    },
    onRightClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => ({}),
    },
    onDrag: {
      type: Function as PropType<(coordinates?: google.maps.LatLng) => void>,
      default: () => ({}),
    },
    onDragStart: {
      type: Function as PropType<(coordinates?: google.maps.LatLng) => void>,
      default: () => ({}),
    },
    onDragEnd: {
      type: Function as PropType<(coordinates?: google.maps.LatLng) => void>,
      default: () => ({}),
    },
    onCenterChange: {
      type: Function as PropType<(coordinates?: google.maps.LatLng) => void>,
      default: () => ({}),
    },
    onBoundsChanged: {
      type: Function as PropType<
        (coordinates?: google.maps.LatLngBounds | null | undefined) => void
      >,
      default: () => ({}),
    },
    onZoomChanged: {
      type: Function as PropType<(zoom?: number) => void>,
      default: () => ({}),
    },
    onLoadFailed: {
      type: Function as PropType<() => void>,
      default: () => ({}),
    },
  },
  setup(props) {
    const gMaps = ref<typeof google.maps>();
    const gGeocoder = ref<google.maps.Geocoder>();
    const map = ref<google.maps.Map<Element>>();

    provide("gMaps", gMaps);
    provide("gGeocoder", gGeocoder);
    provide("map", map);

    const mapElement = ref<Element>()!;
    const error = ref("");
    const isLoading = ref(true);

    const errorHandler = (e: Error) => {
      props.onLoadFailed();
      error.value = e.message ?? e;
    };
    provide("errorHandler", errorHandler);

    const assignMapListeners = () => {
      const listeners: IListeners = {
        click: (e) => props.onClick(e),
        dblclick: (e) => props.onDoubleClick(e),
        rightclick: (e) => props.onRightClick(e),
        drag: () => props.onDrag(map.value?.getCenter()),
        dragstart: () => props.onDragStart(map.value?.getCenter()),
        dragend: () => props.onDragEnd(map.value?.getCenter()),
        center_changed: () => props.onCenterChange(map.value?.getCenter()),
        bounds_changed: () => props.onBoundsChanged(map.value?.getBounds()),
        zoom_changed: () => props.onZoomChanged(map.value?.getZoom()),
      };

      addListeners(listeners, map.value);
    };

    const fitMapBounds = (
      results: google.maps.GeocoderResult[],
      status: google.maps.GeocoderStatus
    ) => {
      if (status === gMaps.value?.GeocoderStatus.OK && results[0]) {
        map.value?.fitBounds(results[0]?.geometry?.viewport);
        props.onDragEnd(map.value?.getCenter());
        return;
      }
      bindMapFromString(props.country);
    };

    const bindMapFromPlaceId = (placeId: string) => {
      gGeocoder.value?.geocode(
        {
          placeId,
        },
        fitMapBounds
      );
    };

    const bindMapFromCoordinates = (coordinates: google.maps.LatLngLiteral) => {
      gGeocoder.value?.geocode(
        {
          location: {
            lat: coordinates?.lat,
            lng: coordinates?.lng,
          },
        },
        fitMapBounds
      );
    };

    const bindMapFromString = (position: string = "") => {
      gGeocoder.value?.geocode(
        {
          address: position,
          componentRestrictions: {
            country: props.country,
          },
        },
        fitMapBounds
      );
    };

    const centerMapAt = (position: IAtomMapProps["initialPosition"]) => {
      const { placeId, coordinates, location, country } = position;
      if (placeId) {
        return bindMapFromPlaceId(placeId);
      }
      if (coordinates) {
        return bindMapFromCoordinates(coordinates);
      }

      const centerAtLocation = location || country;
      return bindMapFromString(centerAtLocation);
    };

    const addCustomControls = () => {
      const controls = document.createElement("div");
      const zoomControls = document.createElement("div");
      zoomControls.className = css("zoom-controls");
      controls.appendChild(zoomControls);

      const zoomIn = document.createElement("div");
      zoomIn.className = css("zoom-in");
      zoomIn.onclick = () => map.value?.setZoom(map.value?.getZoom() + 1);
      zoomControls.appendChild(zoomIn);

      const zoomOut = document.createElement("div");
      zoomOut.className = css("zoom-out");
      zoomOut.onclick = () => map.value?.setZoom(map.value?.getZoom() - 1);
      zoomControls.appendChild(zoomOut);

      map.value?.controls[google.maps.ControlPosition.RIGHT_TOP].push(controls);
    };

    const mountMap = async () => {
      const { getMaps } = useGoogleMaps(props.apiKey);

      try {
        gMaps.value = await getMaps();
        // @ts-ignore
        map.value = new gMaps.value.Map(mapElement.value, props.options);
        gGeocoder.value = new gMaps.value.Geocoder();

        assignMapListeners();
        addCustomControls();

        if (props.centerMap) {
          centerMapAt(props.initialPosition);
        }
      } catch (e: any) {
        errorHandler(e);
      } finally {
        isLoading.value = false;
      }
    };

    const unmountMap = () => {
      try {
        // @ts-ignore
        gMaps.value?.event.clearInstanceListeners(map.value);
      } catch (e: any) {
        errorHandler(e);
      }
    };

    onMounted(() => mountMap());
    onUnmounted(() => unmountMap());

    watch(
      () => props.initialPosition,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;

        centerMapAt(newValue);
      },
      { deep: true }
    );

    watch(
      () => props.country,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;

        centerMapAt(props.initialPosition);
      },
      { deep: true }
    );

    watch(
      () => props.options,
      (newValue) => map.value?.setOptions(newValue),
      { deep: true }
    );

    return { t, css, mapElement, map, isLoading, error };
  },
});
