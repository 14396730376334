import { CurrencyCodeEnum, CurrencyPrecisionEnum } from "@/v2/enum";
import DineroFactory from "dinero.js";
import Dinero from "dinero.js";

export function billingPlanPriceFormatter(
  amountInCents = 0,
  currencyCode: CurrencyCodeEnum = CurrencyCodeEnum.USD,
  format = "$0",
  locale = "en-US"
): string {
  let money = Dinero({
    amount: amountInCents,
    precision: CurrencyPrecisionEnum[currencyCode] || 2,
    currency: currencyCode as DineroFactory.Currency,
  });

  // Set Dinero locale for output formatted strings
  money = money.setLocale(locale);
  return money.toFormat(format);
}
