var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('div',{class:_vm.css('title')},[(_vm.label)?_c('label',{class:[
        _vm.css('label'),
        ( _obj = {}, _obj[_vm.css('label', 'required')] = _vm.required, _obj[_vm.css('label', 'with-help-text')] = Boolean(_vm.helpText), _obj ) ],attrs:{"data-test":"label","for":_vm._uid}},[_c('AtomText',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.helpText,
          classes: 'new-tooltip',
          placement: 'top',
          popperOptions: {
            modifiers: {
              flip: {
                enabled: false,
              },
              preventOverflow: {
                escapeWithReference: true,
              },
            },
          },
        }),expression:"{\n          content: helpText,\n          classes: 'new-tooltip',\n          placement: 'top',\n          popperOptions: {\n            modifiers: {\n              flip: {\n                enabled: false,\n              },\n              preventOverflow: {\n                escapeWithReference: true,\n              },\n            },\n          },\n        }"}],attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(_vm.label)+" ")])],1):_vm._e(),(_vm.hint)?_c('span',{class:_vm.css('hint')},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e()]),_vm._t("default",null,{"id":_vm._uid}),(_vm.message)?_c('span',{class:[
      _vm.css('caption'),
      ( _obj$1 = {}, _obj$1[_vm.css('caption', 'error')] = _vm.errorMsg, _obj$1[_vm.css('caption', 'positive')] = _vm.successMsg, _obj$1 ) ],attrs:{"data-test":"message"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }