<template>
  <label
    class="upload-image"
    data-test="content"
    :class="[
      { 'upload-image--error': hasErrors },
      { 'upload-image--photo': url },
      { 'upload-image--loading': loading },
    ]"
    :style="`background-image: url(${imageUrl})`"
  >
    <div class="upload-image__content">
      <input
        type="file"
        class="upload-image__input"
        accept="image/x-png, image/jpeg"
        @change.prevent="feedFile"
      />
      <div v-if="loading">
        <div class="upload-image__circle"></div>
        <div class="upload-image__text">
          {{ $t("message.uploading_image") }}
        </div>
      </div>
      <div v-else>
        <Icon icon="shop" :size="40" color="#d0dbe3" />
        <!-- eslint-disable -->
        <div v-if="imageText" class="upload-image__text" v-html="imageText" />
        <!-- eslint-enable -->
      </div>
    </div>
    <a
      v-if="url"
      href="#"
      data-test="remove"
      class="upload-image__remove"
      @click.prevent="removeImage"
    >
      {{ $t("label.remove_image") }}
    </a>
  </label>
</template>

<script>
import { Icon } from "@/design-system";
import { pathOr, isNil } from "ramda";

export default {
  name: "LiveUploadImage",
  components: {
    Icon,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    imageText: {
      type: String,
      default: null,
    },
  },
  computed: {
    imageUrl() {
      if (isNil(this.url)) return "";

      const imagePath = process.env.VUE_APP_S3_BUCKET_PATH;
      return encodeURI(
        `${imagePath}${this.url}?w=438&h=220&fit=crop&crop=center`
      );
    },
  },
  methods: {
    feedFile(event) {
      const image = pathOr(null, ["target", "files", 0], event);

      if (isNil(image)) return;

      this.$emit("uploadImage", image);
    },
    removeImage() {
      this.$emit("removeImage");
    },
  },
};
</script>

<style lang="scss">
.upload-image {
  $upload: &;

  position: relative;
  display: block;
  cursor: pointer;
  text-align: center;
  margin: 0;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px dashed #dee2e6;
  transition: all 0.2s ease-out;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 220px;
    padding: 30px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s ease-out;
  }

  &__text {
    max-width: 200px;
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;
    margin: 15px auto 0;
    color: #84a0b2;

    > span {
      color: #00a8f8;
    }
  }

  &:not(&#{ $upload }--error),
  &:not(&#{ $upload }--photo) {
    &:hover {
      border-color: #00a8f8;
    }
  }

  &--photo:not(&--loading) {
    border-color: transparent;

    svg,
    #{ $upload }__text {
      position: relative;
      opacity: 0;
      visibility: hidden;
      transform: translate(0, 10px);
      transition: all 0.2s ease-out;
    }

    &:hover {
      border-color: transparent;

      #{ $upload }__content {
        background: rgba(0, 0, 0, 0.5);
      }

      svg,
      #{ $upload }__text {
        color: #fff;
        fill: #fff;
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
      }
    }
  }

  &--loading {
    background-size: 0% !important;
    border: 1px dashed #dee2e6;
  }

  &__circle {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    border: 3px solid #00a8f8;
    border-radius: 23px;
    clip: rect(0px 23px 46px 6px);
    mask-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(0, 168, 248, 0)),
      to(#00a8f8)
    );
    animation: loading 0.6s linear infinite;
  }

  &__remove {
    position: absolute;
    top: -25px;
    right: 0;
    padding: 3px;
    font-size: 13px;
    color: #84a0b2;

    &:hover,
    &:focus {
      color: #00a8f8;
    }
  }

  &--error {
    border-color: #e42f46;

    #{ $upload }__text,
    #{ $upload }__text span {
      color: #e42f46;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
