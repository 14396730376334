import { Line, mixins } from "vue-chartjs";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    gradient: null,
  }),
  mounted() {
    this.addPlugin([chartjsPluginAnnotation]);

    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(18, 88, 255, 0.15)");
    this.gradient.addColorStop(0.9, "rgba(255, 255, 255, 0)");

    const setChart = {
      datasets: [
        {
          ...this.chartData,
          backgroundColor: this.gradient,
        },
      ],
    };

    this.renderChart(setChart, this.options);
  },
};
