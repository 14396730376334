import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchStripeClientSecret(
  subscriptionId: string
): Promise<string> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        subscriptionId,
      },
    });

    return response.data.startSubscriptionPaymentMethodSetup.clientSecret;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
