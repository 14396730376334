


































































































import { t, tc } from "@/i18n";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { isNil, pathOr } from "ramda";
import {
  AtomButton,
  AtomIcon,
  AtomLoading,
  AtomText,
  AtomTextColorEnum,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

const css = bemBuilder("mol-upload-image");

export default defineComponent({
  name: "MolUploadImage",
  components: {
    AtomIcon,
    AtomText,
    AtomLoading,
    AtomButton,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    allowRemove: {
      type: Boolean,
      default: true,
    },
    imgSize: {
      type: Number,
      default: 84,
    },
    previewResolution: {
      type: Number,
      default: 200,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    onUploadImage: {
      type: Function as PropType<(file: File) => void>,
      required: true,
    },
    onDeleteImage: {
      type: Function as PropType<(e: Event) => void>,
      default: () => {},
    },
    onReplaceClick: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    onLogoClick: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    maxSize: {
      type: Number,
      default: 10000000,
    },
  },
  setup(props) {
    const inputFile = ref<HTMLInputElement | null>();
    const styles = computed(() => {
      const size = `${props.imgSize}px`;
      return {
        width: size,
        height: size,
        minWidth: size,
      };
    });

    const imgURL = computed(() =>
      imageURLBuilder(
        props.url,
        props.previewResolution,
        props.previewResolution
      )
    );
    const maxSizeMb = computed(() => props.maxSize / 1000000);

    const feedFile = (event: Event) => {
      const file: File | null = pathOr(null, ["target", "files", 0], event);
      if (isNil(file)) return;

      // @ts-ignore
      if (props.showErrors && file.size > props.maxSize) {
        generalErrorToast(
          tc("design_system.mol_upload_image.max_file_error", maxSizeMb)
        );
      } else {
        props.onUploadImage(file);
      }

      inputFile.value = null;
    };

    const handleClick = ({ isTrusted }: Event) => {
      if (isTrusted) {
        props.onLogoClick();
        return;
      }
      props.onReplaceClick();
    };

    const handleDelete = (e: Event) => {
      props.onDeleteImage(e);
    };

    return {
      t,
      tc,
      css,
      styles,
      imgURL,
      inputFile,
      maxSizeMb,
      feedFile,
      handleClick,
      handleDelete,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
