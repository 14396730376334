export enum OrderingSchedule {
  TODAY = "today",
  TOMORROW = "tomorrow",
}

export enum DeliverySchedule {
  SAME_DAY = "SAME_DAY",
  NEXT_DAY = "NEXT_DAY",
  BOTH = "BOTH",
}
