










import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomStatusMarkerColorEnum } from "./";

const css = bemBuilder("atom-status-marker");

export default defineComponent({
  name: "AtomMarker",
  props: {
    color: {
      type: String as PropType<AtomStatusMarkerColorEnum>,
      required: true,
    },
  },
  setup() {
    return { css };
  },
});
