import { CurrencyCodeEnum } from "@/v2/enum";
import { billingPlanPriceFormatter } from "./price-formatter";

export function billingMethodPlanCalculateFinalPrice(
  basePrice: number,
  extraOutlets: number,
  extraOutletPrice: number,
  currencyCode: CurrencyCodeEnum
): string {
  return billingPlanPriceFormatter(
    basePrice + extraOutlets * extraOutletPrice,
    currencyCode
  );
}
