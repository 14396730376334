<template>
  <div
    class="input-money"
    data-test="content"
    :class="{
      'input-money--loading': isLoading,
      'input-money--error': hasErrors,
    }"
  >
    <label
      v-if="showSymbol"
      class="input-money__currency"
      :for="`input-money-${_uid}`"
      data-test="currency-symbol"
    >
      {{ symbol }}
    </label>
    <input
      :id="`input-money-${_uid}`"
      ref="input"
      v-model="model"
      v-focus="onFocus"
      :required="required"
      type="text"
      :disabled="disabled"
      class="input-money__input"
      maxlength="13"
      @change="$emit('change', value)"
    />
  </div>
</template>

<script>
import toCurrency from "@/utils/filters/to-currency";
import Defaults from "@/utils/enums/defaults";
import { focus } from "@/utils/directives";
import { mapGetters } from "vuex";
import { find, pathOr, is } from "ramda";

/**
 * @deprecated
 * use `OrgFormMoneyInput` from `v2/new-design-system` instead
 */
export default {
  name: "InputMoney",
  directives: { focus },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    onFocus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showSymbol: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
    }),
    business() {
      return find((obj) => obj.id === this.businessId, this.businesses);
    },
    code() {
      return pathOr("USD", ["currency", "code"], this.business);
    },
    locale() {
      return this.business.language ?? Defaults.LANGUAGE;
    },
    symbol() {
      try {
        const currency = new Intl.NumberFormat(this.locale, {
          style: "currency",
          currency: this.code,
        })
          .formatToParts(1)
          .find((obj) => obj.type === "currency");

        return currency?.value ?? this.code;
      } catch (e) {
        return this.code;
      }
    },
    model: {
      get() {
        if (!is(Number, this.value)) return null;

        return toCurrency(this.value, {
          code: this.code,
          format: "0,0",
          locale: this.locale,
        });
      },
      set(val) {
        const rawNumber = this.rawNumber(val);

        if (Number.isNaN(rawNumber)) {
          this.$forceUpdate();
          return;
        }

        this.$emit("input", rawNumber);
        this.$forceUpdate();
      },
    },
  },
  methods: {
    rawNumber(value) {
      if (!value) return 0;

      const number = value.toString().replace(/[^0-9]/g, "");
      return parseFloat(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-money {
  position: relative;
  margin: 0;

  &__currency {
    position: absolute;
    margin: auto;
    top: 10px;
    left: 15px;
  }

  &__input {
    display: block;
    width: 100%;
    color: #444444;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 11px 16px 11px 50px;
    font-size: 14px;
    line-height: 18px;

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:disabled {
      cursor: no-drop;
      border-color: #e8e9ea;
      background-color: #f4f6f9;
    }
  }

  &--loading:after {
    content: "";
    position: absolute;
    top: 21px;
    right: 0;
    width: 20px;
    height: 20px;
    border: 3px solid #00a8f8;
    border-radius: 23px;
    clip: rect(0px 23px 46px 6px);
    mask-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(#00a8f8, 0)),
      to(#00a8f8)
    );
    animation: loading 0.6s linear infinite;
    transform: translate(-50%, -50%);
  }

  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  &:not(&--error) .input-money__input:focus {
    border-color: #00a8f8;
  }

  &--error {
    .input-money__currency,
    .input-money__input {
      color: #e42f46;
    }

    .input-money__input {
      border-color: #e42f46;
    }
  }
}
</style>
