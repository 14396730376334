




import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  PropType,
} from "@vue/composition-api";
import dayjs from "dayjs";
import dayjsAdvancedFormat from "dayjs/plugin/advancedFormat";
import dayjsCalendar from "dayjs/plugin/calendar";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import { AtomDateTypeEnum } from "./type.enum";
import { AtomDateTagEnum } from "./tag.enum";

dayjs.extend(dayjsAdvancedFormat);
dayjs.extend(dayjsCalendar);
dayjs.extend(dayjsRelativeTime);

const css = bemBuilder("atom-date");

export default defineComponent({
  name: "AtomDate",
  props: {
    datetime: {
      type: [String, Date],
      required: true,
    },
    type: {
      type: String as PropType<AtomDateTypeEnum>,
      default: AtomDateTypeEnum.CUSTOM,
    },
    format: {
      type: String,
      default: "MMM DD, YYYY - h:mm a",
    },
    tag: {
      type: String as PropType<AtomDateTagEnum>,
      default: "span",
    },
  },
  setup(props) {
    const date = ref<string>("");

    function reloadOutput() {
      switch (props.type) {
        case AtomDateTypeEnum.CALENDAR:
          date.value = calendarFormat(props.datetime);
          break;
        case AtomDateTypeEnum.FROM_NOW:
          date.value = fromNowFormat(props.datetime);
          break;
        default:
          date.value = customFormat(props.datetime);
      }
    }

    function createDate(datetime: string) {
      const date = dayjs(datetime);

      if (!date.isValid()) {
        throw new Error("AtomDate: invalid datetime string provided");
      }

      return date;
    }

    function fromNowFormat(datetime: string): string {
      return createDate(datetime).fromNow();
    }

    function calendarFormat(datetime: string): string {
      return createDate(datetime).calendar();
    }

    function customFormat(datetime: string): string {
      return createDate(datetime).format(props.format);
    }

    watch(
      () => props,
      () => reloadOutput(),
      { deep: true }
    );

    onMounted(() => {
      reloadOutput();
    });

    return {
      css,
      date,
    };
  },
});
