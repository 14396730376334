





















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system";

const css = bemBuilder("atom-button-toggle");

export default defineComponent({
  name: "AtomButtonToggle",
  components: {
    AtomIcon,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    onToggle: {
      type: Function as PropType<(value: boolean) => {}>,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
