import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";
import { AxiosResponse } from "axios";
import { http } from "../../http";

interface IUpdateBusinessPayoutPayeeRequest {
  businessId: string;
  payload: IUpdateBusinessPayoutPayee;
}

export interface IUpdateBusinessPayoutPayee {
  taxId: string;
  bankAccount: {
    accountHolderName: string;
    iban: string;
    swiftCode: string;
    accountNumber: string;
    bankCode: string;
    bankName: string;
  } | null;
}

interface IUpdateBusinessPayoutPayeePayload {
  tax_id: string;
  bank_account?: {
    account_holder_name: string;
    iban: string;
    swift_code: string;
    account_number: string;
    bank_code: string;
    bank_name: string;
  } | null;
}

export class UpdateBusinessPayoutPayeeError extends RepoBusinessSettingsError {}

export async function updateBusinessPayoutPayee(
  request: IUpdateBusinessPayoutPayeeRequest
): Promise<IUpdateBusinessPayoutPayee> {
  let response: AxiosResponse;

  let payload: IUpdateBusinessPayoutPayeePayload = {
    tax_id: request.payload.taxId,
  };

  const bankAccount = request.payload.bankAccount;

  if (bankAccount) {
    payload = {
      ...payload,
      bank_account: {
        account_holder_name: bankAccount.accountHolderName,
        iban: bankAccount.iban,
        swift_code: bankAccount.swiftCode,
        account_number: bankAccount.accountNumber,
        bank_code: bankAccount.bankCode,
        bank_name: bankAccount.bankName,
      },
    };
  }

  try {
    response = await http.put(
      `api/v3/businesses/${request.businessId}/payout-payee`,
      payload
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage ||
        "The request to updated the business payout payee failed.",
      response: response.data,
    };

    throw new UpdateBusinessPayoutPayeeError(error);
  }

  return {
    taxId: response.data.tax_id,
    bankAccount: response.data.bank_account
      ? {
          accountHolderName: response.data.bank_account.account_holder_name,
          iban: response.data.bank_account.iban,
          swiftCode: response.data.bank_account.swift_code,
          accountNumber: response.data.bank_account.account_number,
          bankCode: response.data.bank_account.bank_code,
          bankName: response.data.bank_account.bank_name,
        }
      : null,
  };
}
