import { VIEW_REVIEWS } from "@/utils/enums/permissions";

export default [
  {
    name: "reviews.index",
    path: "/business/:businessId/reviews",
    component: () =>
      import(
        /* webpackChunkName: "reviews-module" */ "@/v2/module/reviews/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Reviews",
      permission: VIEW_REVIEWS,
    },
  },
];
