import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";
import {
  ICreateVoidCategoryRequest,
  ICreateVoidCategoryBodyParams,
} from "./types";

export class CreateVoidCategoryError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CreateVoidCategoryError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/261b8672081d1-create-new-void-category API contract}
 */
export async function createVoidCategory(
  request: ICreateVoidCategoryRequest
): Promise<void> {
  try {
    const params: ICreateVoidCategoryBodyParams = {
      name: request.name,
      is_visible_to_customer: request.isVisibleToCustomer,
    };

    await http.post(
      `api/v3/businesses/${request.businessId}/tabs/void-categories`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateVoidCategoryError(error);
  }
}
