import Hotjar from "./hotjar";

/**
 * Vue Plugin to integrate Hotjar
 *
 * @param Vue
 * @param initConfig
 */
export default (Vue, initConfig = {}) => {
  const config = Object.assign(
    {
      id: null,
      debug: false,
    },
    initConfig
  );

  const hotjar = new Hotjar(config);

  Vue.prototype.$hotjar = Vue.hotjar = hotjar;
};
