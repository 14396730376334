import Vue from "vue";
import { router } from "@/router";

/**
 * Setup Gates plugin
 */
import Gates from "@/plugins/gates";
import { getAllPermissions } from "@/utils/enums/permissions";
Vue.use(Gates, {
  permissions: getAllPermissions(),
  router,
});

/**
 * Use Intercom
 */
import Intercom from "@/plugins/intercom";
Vue.use(Intercom, {
  appId: process.env.VUE_APP_INTERCOM_APP,
  debug: process.env.VUE_APP_INTERCOM_DEBUG === "true",
  hiddenUpTo: 767,
});

/**
 * Use Hotjar
 */
import Hotjar from "@/plugins/hotjar";
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  debug: process.env.VUE_APP_HOTJAR_DEBUG === "true",
});

/**
 * Use Analytics
 */
import Analytics from "@/plugins/analytics";
Vue.use(Analytics);

/**
 * Use VModal
 */
import VModal from "vue-js-modal";
Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  dynamicDefaults: {
    clickToClose: false,
  },
});

/**
 * Setup Vue Tooltip
 */
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
