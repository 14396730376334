import Base from "./Base";

/** Constructor to normalize outsider data */
class Partner extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} partner
   */
  constructor(partner = {}) {
    const keys = {
      id: ["id"],
      name: ["name"],
      slug: ["slug"],
    };

    super(keys, partner);
  }
}

export default Partner;
