import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchOnboardingSteps(
  businessId: string
): Promise<IFetchOnboardingStepsResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.onboardingSteps;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export type IFetchOnboardingStepsResponse = Array<{
  title: "create_first_outlet" | "customize_your_branding" | "build_your_menu";
  completedAt: string | null;
}>;
