<template>
  <div
    class="input-color"
    :class="{
      'input-color--focused': focused,
      'input-color--error': hasErrors,
    }"
  >
    <div class="input-color__picker">
      <verte
        class="input-color__presenter"
        picker="square"
        model="hex"
        :value="color"
        :enable-alpha="false"
        :draggable="false"
        :show-history="false"
        @input="pickColor"
      >
        <svg viewBox="0 0 24 24">
          <rect x="0" width="24" height="24" />
        </svg>
      </verte>
    </div>
    <input
      :value="color"
      type="text"
      class="input-color__input"
      :maxlength="7"
      @focus="focused = true"
      @blur="focused = false"
      @change="changeByInput"
    />
  </div>
</template>

<script>
import Verte from "verte";
import "verte/dist/verte.css";

export default {
  name: "InputColor",
  components: { Verte },
  props: {
    defaultHexColor: {
      type: String,
      required: true,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: this.defaultHexColor,
      focused: false,
    };
  },
  methods: {
    pickColor(color) {
      this.color = color;
      this.$emit("input", color);
    },
    changeByInput(e) {
      if (this.isValidHex(e.target.value)) {
        this.color = e.target.value;
        this.$emit("input", this.color);
      } else {
        this.color = this.defaultHexColor;
      }
    },
    isValidHex(color) {
      return /^#([0-9A-F]{3}){1,2}$/i.test(color);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-color {
  display: flex;
  padding: 8px;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 4px;

  &--focused:not(&--error) {
    border-color: #00a8f8;
  }

  &--error {
    border-color: #e42f46;
    color: #e42f46;
  }

  &__picker {
    position: relative;
  }

  &__presenter {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  &__input {
    border: none;
    font-size: 14px;
    line-height: 18px;
    color: #444444;
    width: 100%;
  }

  /deep/ .verte__controller {
    display: none;
  }

  /deep/ .verte__guide svg {
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
}
</style>
