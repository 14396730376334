import { VIEW_CAMPAIGNS } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { CampaignRouteEnum } from "./campaign-route.enum";
import { t } from "@/i18n";

export const campaignRoutes: Array<RouteConfig> = [
  {
    path: "/business/:businessId/campaigns",
    component: () =>
      import(/* webpackChunkName: "campaign-module" */ "../view/main").then(
        (r) => r.CampaignMain
      ),
    props: true,
    meta: {
      title: t("module.campaign.page_title"),
      permission: VIEW_CAMPAIGNS,
    },
    children: [
      {
        name: CampaignRouteEnum.MAIN,
        path: "",
        redirect: { name: CampaignRouteEnum.MAIN_SMS },
      },
      {
        name: CampaignRouteEnum.MAIN_SMS,
        path: "sms",
        component: () =>
          import(
            /* webpackChunkName: "campaign-module" */ "../view/sms-list"
          ).then((r) => r.CampaignSmsList),
        props: true,
        meta: {
          title: t("module.campaign.page_title"),
          permission: VIEW_CAMPAIGNS,
        },
      },
      {
        name: CampaignRouteEnum.MAIN_WHATSAPP,
        path: "whatsapp",
        component: () =>
          import(
            /* webpackChunkName: "campaign-module" */ "../view/whatsapp-list"
          ).then((r) => r.CampaignWhatsAppList),
        props: true,
        meta: {
          title: t("module.campaign.page_title"),
          permission: VIEW_CAMPAIGNS,
        },
      },
      {
        name: CampaignRouteEnum.MAIN_EMAIL,
        path: "email",
        component: () =>
          import(
            /* webpackChunkName: "campaign-module" */ "../view/email-list"
          ).then((r) => r.CampaignEmailList),
        props: true,
        meta: {
          title: t("module.campaign.page_title"),
          permission: VIEW_CAMPAIGNS,
        },
      },
    ],
  },
  {
    path: "/business/:businessId/campaigns/:campaignId",
    name: CampaignRouteEnum.SMS_MANAGER,
    component: () =>
      import(/* webpackChunkName: "campaign-module" */ "../view/manager").then(
        (r) => r.CampaignManager
      ),
    props: true,
    meta: {
      title: t("module.campaign.page_title"),
      permission: VIEW_CAMPAIGNS,
    },
  },
];
