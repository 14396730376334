// @ts-nocheck

import { isNil } from "ramda";

class Toast {
  create(options = {}): void {
    // get document body
    const body = document.querySelector("body");

    // append container to body
    const getContainer = document.getElementById("toast");
    if (isNil(getContainer)) {
      const container = document.createElement("div");
      container.id = "toast";
      container.classList = "toast__container";
      body.appendChild(container);
    }

    // create item
    const toast = document.createElement("div");
    toast.className = "toast__item";

    // type
    toast.className += ` toast--${options.type}`;

    // content
    const content = document.createElement("div");
    content.className = "toast__content";
    toast.appendChild(content);

    // title
    const title = document.createElement("h4");
    title.className = "toast__title";
    title.innerHTML = options.title || options.type;
    content.appendChild(title);

    // text
    if (options.text) {
      const p = document.createElement("p");
      p.className = "toast__text";
      p.innerHTML = options.text;
      content.appendChild(p);
    }

    // link
    if (options.link) {
      const link = document.createElement("span");
      link.className = "toast__link";
      link.innerHTML = options.link;
      toast.appendChild(link);
    }

    // close button
    const close = document.createElement("span");
    close.className = "toast__close";
    toast.appendChild(close);

    // click callback
    if (typeof options.callback === "function") {
      toast.addEventListener("click", options.callback);
    }

    // toast api
    toast.hide = (): void => {
      toast.className += " toast--hide";
      toast.addEventListener("animationend", removeToast, false);
    };

    // autohide
    if (!options.fixed) {
      setTimeout(toast.hide, options.timeout || 5000);
    }

    // hide
    toast.addEventListener("click", toast.hide);

    // remove
    const removeToast = (): void => {
      document.getElementById("toast").removeChild(toast);
    };

    document.getElementById("toast").appendChild(toast);
  }
}

export default Toast;
