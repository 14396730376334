import { createVoidCategory } from "./create-void-category";
import { createVoidReason } from "./create-void-reason";
import { fetchVoidCategories } from "./fetch-void-categories";
import { updateVoidCategory } from "./update-void-category";
import { updateVoidReason } from "./update-void-reason";
import { updateVoidReasonStatus } from "./update-void-reason-status";

export const voidReasonRepo = {
  createCategory: createVoidCategory,
  createReason: createVoidReason,
  getCategories: fetchVoidCategories,
  updateCategory: updateVoidCategory,
  updateReason: updateVoidReason,
  updateReasonStatus: updateVoidReasonStatus,
};
