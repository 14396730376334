






















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import { AtomGraphic } from "../../atom/graphic";
import { IMolInfoLockProps } from "./props";
import { VPopover } from "v-tooltip";

const css = bemBuilder("mol-info-lock");

export default defineComponent<IMolInfoLockProps>({
  name: "MolInfoLock",
  components: {
    AtomGraphic,
    VPopover,
  },
  props: {
    persistentOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showPopover = ref(false);

    function onEnter() {
      showPopover.value = true;
    }

    function onLeave() {
      if (!props.persistentOpen) {
        showPopover.value = false;
      }
    }

    return {
      onEnter,
      onLeave,
      showPopover,
      css,
    };
  },
});
