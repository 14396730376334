import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";
import {
  IFetchVoidCategoriesRequest,
  IFetchVoidCategoriesContract,
  IFetchVoidCategoriesBodyParams,
} from "./types";
import { ICategoryWithReasons } from "../../domain/category";

export class FetchVoidCategoriesError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchVoidCategoriesError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/branches/main/678c96011336e-get-void-categories API contract}
 */
export async function fetchVoidCategories(
  request: IFetchVoidCategoriesRequest
): Promise<Array<ICategoryWithReasons>> {
  try {
    const params: IFetchVoidCategoriesBodyParams = {
      filter_only_enabled_reasons: request.filterOnlyEnabledReasons ?? false,
    };

    const { data } = await http.get<IFetchVoidCategoriesContract>(
      `/api/v3/businesses/${request.businessId}/tabs/void-categories`,
      {
        params,
      }
    );

    return data.data
      .sort((a, b) =>
        a.name.localeCompare(b.name, "en", {
          numeric: true,
        })
      )
      .map((category) => ({
        id: category.id,
        name: category.name,
        isVisibleToCustomer: Boolean(category.is_visible_to_customer),
        reasons: category.reasons
          .sort((a, b) =>
            a.name.localeCompare(b.name, "en", {
              numeric: true,
            })
          )
          .map((reason) => ({
            id: reason.id,
            name: reason.name,
            isEnabled: Boolean(reason.is_enabled),
          })),
      }));
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchVoidCategoriesError(error);
  }
}
