<template>
  <div class="copy-code">
    <button class="copy-code__button" @click="copyCode">
      <Icon class="copy-code__icon" icon="copy" color="#00A8F8" :size="16" />
      {{ $t("label.copy_code") }}
    </button>
    <div class="copy-code__body">
      <p v-for="(line, i) in codeLines" :key="i" class="copy-code__line">
        <span class="copy-code__number">
          {{ i + 1 }}
        </span>
        {{ line }}
      </p>
    </div>
  </div>
</template>

<script>
import { report } from "@chatfood/bug-reporter";
import { Icon, Toast } from "@/design-system";

export default {
  name: "CopyCode",
  components: {
    Icon,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  computed: {
    codeLines() {
      return this.code.split("\n");
    },
  },
  methods: {
    copyCode() {
      try {
        window.navigator.clipboard.writeText(this.code);
        new Toast().create({
          type: "success",
          text: this.$t("message.code_copied"),
        });
      } catch (e) {
        report(e);
        new Toast().create({
          type: "error",
          text: this.$t("message.generic_failure"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-code {
  position: relative;
  overflow: auto;
  padding: 5px;
  border-radius: 4px;
  background: rgba(226, 234, 239, 0.2);

  &__body {
    min-height: 65px;
    tab-size: 4;
    padding-right: 140px;
    white-space: pre;
    overflow: auto;
  }

  &__line {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    line-height: 190%;
    font-family: "Cutive Mono", monospace;
    letter-spacing: -0.1em;
  }

  &__number {
    position: sticky;
    left: 0;
    width: 40px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    display: inline-block;
    user-select: none;
    background: #fff;
  }

  &__button {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    color: #00a8f8;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    padding: 0 12px;
    z-index: 9;
    border: none;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(39, 79, 155, 0.1);
  }

  &__icon {
    margin-right: 4px;
  }
}
</style>
