




/* global google */
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { injectStrict } from "@/v2/util/maps/type";
import { bemBuilder } from "@/v2/util/bem-builder";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("atom-map-tooltip");

export default defineComponent({
  name: "AtomMapTooltip",
  props: {
    title: {
      type: String,
      required: true,
    },
    position: {
      type: Object as PropType<google.maps.LatLng>,
      required: true,
    },
    maxWidth: {
      type: Number,
      default: 140,
    },
  },
  setup(props) {
    const map = injectStrict<Ref<google.maps.Map<Element>>>("map");
    const gMaps = injectStrict<Ref<typeof google.maps>>("gMaps");
    const errorHandler = injectStrict<(e: Error) => void>("errorHandler");
    const overlayView = ref();
    const tooltipRef = ref<HTMLElement>();

    const draw = () => {
      const overlayProjection = overlayView.value.getProjection();

      if (!props.position) return;

      const coordinates = overlayProjection?.fromLatLngToDivPixel(
        new gMaps.value.LatLng(props.position.lat(), props.position.lng())
      );

      if (tooltipRef.value && coordinates) {
        tooltipRef.value.style.left = `${coordinates.x}px`;
        tooltipRef.value.style.top = `${coordinates.y + 30}px`;
        tooltipRef.value.style.maxWidth = `${props.maxWidth}px`;
      }
    };

    watch(
      () => props.position,
      () => {
        draw();
      },
      { deep: true }
    );

    const onAdd = () => {
      if (!tooltipRef.value) return;

      const content = document.createElement("div");
      content.className += css("content");
      content.innerText = props.title;
      tooltipRef.value.appendChild(content);

      const arrow = document.createElement("div");
      arrow.className += css("arrow");
      tooltipRef.value.appendChild(arrow);

      const panes = overlayView.value.getPanes();
      panes?.overlayLayer?.appendChild(tooltipRef.value);
    };

    const onRemove = () => {
      if (tooltipRef.value) {
        const panes = overlayView.value.getPanes();
        panes?.overlayLayer?.removeChild(tooltipRef.value);
      }
    };

    const mountTooltip = () => {
      try {
        overlayView.value = new gMaps.value.OverlayView();

        overlayView.value.draw = draw;
        overlayView.value.onAdd = onAdd;
        overlayView.value.onRemove = onRemove;
        overlayView.value?.setMap(map.value);
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    const unmountTooltip = () => {
      try {
        overlayView.value.setMap(null);
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    onMounted(() => {
      mountTooltip();
    });

    onUnmounted(() => {
      unmountTooltip();
    });

    return { css, tooltipRef };
  },
});
