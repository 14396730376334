




import { defineComponent } from "@vue/composition-api";
import { number, string } from "@/v2/util/prop/type";

/**
 * @deprecated
 * use `AtomGraphic` from `v2/new-design-system` instead
 */

export default defineComponent({
  name: "AtomGraphic",
  props: {
    name: string().isRequired().use(),
    size: number().setDefault(32).use(),
  },
  computed: {
    graphicSize(): { width: string; height: string } {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    graphic() {
      // template literals doesn't work well with dynamic import on jest
      return () =>
        import(
          /* webpackChunkName: "icon-[request]" */ "./assets/" +
            this.name +
            ".svg"
        );
    },
  },
});
