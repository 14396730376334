import { VIEW_FACEBOOK_COMMERCE } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { FacebookCommerceRouteEnum } from "./facebook-commerce-route.enum";
import { t } from "@/i18n";

export const facebookCommerceRoutes: Array<RouteConfig> = [
  {
    name: FacebookCommerceRouteEnum.MAIN,
    path: "/business/:businessId/facebook-commerce",
    component: () =>
      import(
        /* webpackChunkName: "facebook-commerce-module" */ "../view/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.facebook_commerce.main.heading"),
      permission: VIEW_FACEBOOK_COMMERCE,
    },
  },
];
