

















































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
  Ref,
} from "@vue/composition-api";
import { MolCollapseIndicator } from "@/v2/new-design-system/mol/collapse-indicator";
import { AtomText } from "@/v2/new-design-system/atom/text";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { AtomCheckbox } from "@/v2/new-design-system/atom/checkbox";
import {
  IOrgCollapsableSelectListProps,
  IOrgCollapsableSelectListCategory,
} from "./props";
import { t } from "@/i18n";
import { debounce } from "@/v2/util/debounce";

const css = bemBuilder("org-collapsable-select-list");

export default defineComponent({
  name: "OrgCollapsableSelectList",
  components: {
    MolCollapseIndicator,
    AtomCheckbox,
    AtomText,
  },
  props: {
    data: {
      type: Array as PropType<IOrgCollapsableSelectListProps["data"]>,
      required: true,
    },
    filter: {
      type: String,
      default: "",
    },
    noItemsMessage: {
      type: String,
      default: "",
    },
    noItemsCategoryLabel: {
      type: String,
      default: "",
    },
    selectedItems: {
      type: Array as PropType<IOrgCollapsableSelectListProps["selectedItems"]>,
      default: () => [],
    },
    onChange: {
      type: Function as PropType<IOrgCollapsableSelectListProps["onChange"]>,
      required: true,
    },
    initialCollapse: {
      type: Boolean,
      default: false,
    },
    filterByCategory: {
      type: Boolean,
      default: false,
    },
    disableAll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Define state
    const categoryOpenState = ref<Array<Ref<boolean>>>([]);
    function initialCollapseOpen(open: boolean): void {
      const state = props.initialCollapse ? open : true;
      categoryOpenState.value = props.data.map(() => ref<boolean>(state));
    }

    const filteredData = ref(props.data);

    watch(
      () => props.data,
      () => {
        initialCollapseOpen(false);
        filteredData.value = props.data;
      },
      { immediate: true }
    );

    const selectedItemsSet = ref(new Set(props.selectedItems));

    const notFoundMessage = computed((): string => {
      return (
        props.noItemsMessage ||
        t("design_system.org_collapsable_select_list.no_items_message")
      );
    });

    function toggleOpenState(categoryIndex: number) {
      const categoryState = categoryOpenState.value[categoryIndex];
      categoryState.value = !categoryState.value;
    }

    function isCategoryOpen(categoryIndex: number) {
      return categoryOpenState.value[categoryIndex].value;
    }

    // Checks if a category has partially selected items
    function categoryCheckboxPartialStyle(
      category: IOrgCollapsableSelectListCategory
    ): boolean {
      return category.items.some(
        (value) => !selectedItemsSet.value.has(value.id)
      );
    }

    // Checks if a category has any items selected at all
    function categoryCheckboxChecked(
      category: IOrgCollapsableSelectListCategory
    ): boolean {
      return category.items.some((value) =>
        selectedItemsSet.value.has(value.id)
      );
    }

    // Check if all items are disabled
    function categoryCheckboxDisabled(
      category: IOrgCollapsableSelectListCategory
    ): boolean {
      return category.items.every((item) => item.isDisabled === true);
    }

    // Toggles the selection of an item
    function toggleSelectItem(itemId: string): void {
      if (selectedItemsSet.value.has(itemId)) {
        selectedItemsSet.value.delete(itemId);
      } else {
        selectedItemsSet.value.add(itemId);
      }

      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    function selectAllInCategory(category: IOrgCollapsableSelectListCategory) {
      category.items.forEach(
        (value) =>
          !value.isDisabled &&
          !value.disabledText &&
          selectedItemsSet.value.add(value.id)
      );
      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    function deselectAllInCategory(
      category: IOrgCollapsableSelectListCategory
    ) {
      category.items.forEach((value) =>
        selectedItemsSet.value.delete(value.id)
      );
      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    function onChangeCategory(
      checked: boolean,
      category: IOrgCollapsableSelectListCategory
    ) {
      checked ? selectAllInCategory(category) : deselectAllInCategory(category);
    }

    // Runs filteration on the data set in case a filter value is set

    const deboucedFilter = debounce(() => {
      if (props.filter) {
        initialCollapseOpen(true);

        if (props.filterByCategory) {
          filteredData.value = props.data.filter((item) =>
            item.name.toLowerCase().includes(props.filter.trim().toLowerCase())
          );
          return;
        }

        filteredData.value = props.data
          .map((category) => {
            const items = category.items.filter((item) =>
              item.name
                .toLowerCase()
                .includes(props.filter.trim().toLowerCase())
            );
            return { ...category, items }; // create a new object, making sure not to modify props
          })
          .filter((category) => category.items?.length > 0); // remove categories with no items

        return;
      }
      initialCollapseOpen(false);
      filteredData.value = props.data;
    }, 500);

    watch(
      () => props.filter,
      () => {
        deboucedFilter();
      },
      {
        immediate: true,
      }
    );

    const hasItems = computed((): boolean => {
      return filteredData.value.length > 0;
    });

    watch(
      () => props.selectedItems,
      (val, prevVal) => {
        const updatedItemSelection = new Set(val);
        if (updatedItemSelection.size != new Set(prevVal).size) {
          selectedItemsSet.value = updatedItemSelection;
        }
      }
    );

    watch(selectedItemsSet, () => {
      props.onChange(Array.from(selectedItemsSet.value));
    });

    return {
      t,
      css,
      notFoundMessage,
      selectedItemsSet,
      isCategoryOpen,
      hasItems,
      toggleOpenState,
      toggleSelectItem,
      categoryCheckboxPartialStyle,
      categoryCheckboxChecked,
      categoryCheckboxDisabled,
      filteredData,
      onChangeCategory,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
