import { EDIT_OUTLET } from "@/utils/enums/permissions";

const importer = (path) =>
  import(/* webpackChunkName: "mod-outlets" */ "@/modules/outlets/ui/" + path);

const outletPath = "/business/:businessId/outlets/:outletId";

export default [
  {
    path: outletPath,
    component: () => importer("views/outlet/Index.vue"),
    props: true,
    children: [
      {
        name: "outlets.create",
        path: "/business/:businessId/outlets/create",
        component: () => importer("views/outlet/Details.vue"),
        props: true,
        meta: {
          title: "General Information - Outlet",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.edit",
        path: "",
        component: () => importer("views/outlet/Details.vue"),
        props: true,
        meta: {
          title: "General Information - Outlet",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.address",
        path: "address",
        component: () => importer("views/outlet/Address.vue"),
        props: true,
        meta: {
          title: "Address - Outlets",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.ordering-services.delivery",
        path: "ordering-services/delivery",
        component: () => importer("views/outlet/OrderingService.vue"),
        props: (route) => ({
          businessId: route.params.businessId,
          outletId: route.params.outletId,
          orderingServiceSlug: "delivery",
        }),
        meta: {
          title: "Ordering Service - Outlet",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.ordering-services.pickup",
        path: "ordering-services/pickup",
        component: () => importer("views/outlet/OrderingService.vue"),
        props: (route) => ({
          businessId: route.params.businessId,
          outletId: route.params.outletId,
          orderingServiceSlug: "pickup",
        }),
        meta: {
          title: "Ordering Service - Outlet",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.ordering-services.dine_in",
        path: "ordering-services/dine-in",
        component: () => importer("views/outlet/OrderingService.vue"),
        props: (route) => ({
          businessId: route.params.businessId,
          outletId: route.params.outletId,
          orderingServiceSlug: "dine_in",
        }),
        meta: {
          title: "Ordering Service - Outlet",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.delivery-areas",
        path: "delivery-areas",
        component: () =>
          import(
            /* webpackChunkName: "mod-outlets" */ "@/v2/module/delivery-areas/main/Main.vue"
          ),
        props: true,
        meta: {
          title: "Delivery Areas - Outlet",
          permission: EDIT_OUTLET,
        },
      },
      {
        name: "outlets.dine-in-payments",
        path: "dine-in-payments",
        component: () =>
          import(
            /* webpackChunkName: "mod-outlets" */ "@/v2/module/outlet/dine-in-payments/DineInPayments.vue"
          ),
        props: true,
        meta: {
          title: "Dine in payments - Outlet",
          permission: EDIT_OUTLET,
        },
      },
    ],
  },
  {
    name: "outlets.delivery-areas.create",
    path: `${outletPath}/delivery-areas/create`,
    component: () =>
      import(
        /* webpackChunkName: "mod-outlets" */ "@/v2/module/delivery-areas/geo/map/Map.vue"
      ),
    props: true,
    meta: {
      title: "Delivery Areas - Outlet - Create",
      permission: EDIT_OUTLET,
    },
  },
  {
    name: "outlets.delivery-areas.edit",
    path: `${outletPath}/delivery-areas/:areaId/edit`,
    component: () =>
      import(
        /* webpackChunkName: "mod-outlets" */ "@/v2/module/delivery-areas/geo/map/Map.vue"
      ),
    props: true,
    meta: {
      title: "Delivery Areas - Outlet - Edit",
      permission: EDIT_OUTLET,
    },
  },
];
