







































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  getCurrentInstance,
  onUnmounted,
} from "@vue/composition-api";
import SidebarHeader from "./SidebarHeader.vue";
import SidebarMenu from "./SidebarMenu.vue";
import { setMainMenu, setBottomMenu } from "./method";
import { MolTypeformModal } from "@/v2/design-system";
import {
  sidebarIsCollapsed,
  sidebarMenuMobileIsOpen,
  sidebarSubmenuIsOpen,
  sidebarTypeformShowModal,
  sidebarTypeformLinkModal,
  sidebarPermissions,
  sidebarUserFullname,
  sidebarUserEmail,
} from "./state";
import { useHelpers } from "@/v2/composable/use-helpers";
import { IVuexBusiness } from "@/store/types";
import { ISidebarPropsMenuItem } from "@/v2/module/sidebar/props";
import { billingGetState, billingSetState } from "@/v2/module/billing/state";
import { useStore } from "@/store";
import { SidebarCreateBusiness } from "@/v2/module/sidebar/create-business";
import { EventEnum } from "@/v2/enum";
import { BUSINESS_CREATE } from "@/utils/enums/permissions";
import { createBusinessTrack } from "@/v2/util/analytics/createBusinessTrackEvent";
import { IFlags } from "@/v2/core/feature-flag/domain";
// @ts-ignore
import { activeBusiness } from "@/utils/helpers/functions";
import { unWatchFlag, watchFlag } from "@/v2/core/feature-flag";

const css = bemBuilder("sidebar");

export default defineComponent({
  name: "Sidebar",
  components: {
    SidebarHeader,
    SidebarMenu,
    MolTypeformModal,
    SidebarCreateBusiness,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { dispatch } = useStore();
    const vm = getCurrentInstance();
    const business = computed(() =>
      useHelpers().getCurrentBusiness(props.businessId)
    );
    const mainMenu = ref(
      setMainMenu({
        permissions: [],
        businessId: props.businessId,
        businessName: props.businessName,
        // @ts-ignore
        router: vm?.proxy.$router,
        flagsAvailable: [],
      })
    );

    const flagsAvailable = ref<Array<IFlags>>([]);

    const showCreateBusinessModal = ref(false);
    const setCreateBusinessModalState = (state: boolean = false) => {
      showCreateBusinessModal.value = state;
    };

    const onSaveBusiness = async (id: string, name: string) => {
      await dispatch("businesses/fetch");
      showCreateBusinessModal.value = false;

      createBusinessTrack(
        // @ts-ignore
        vm?.proxy.$analytics,
        EventEnum.CLICKED_ON_BUTTON,
        props.businessId,
        {
          name,
          label: "Clicked to submit new business name",
        }
      );

      await billingSetState("business", {
        id,
        name,
        logo: "",
      });

      activeBusiness.set(id);

      window.open(`/business/${id}`, "_self");
    };

    const bottomMenu = ref(() =>
      setBottomMenu(
        [],
        business.value,
        // @ts-ignore
        vm?.proxy.$router
      )
    );

    const typeFormLink = computed((): string => {
      const hasQueryParams = sidebarTypeformLinkModal.value.includes("?");

      const commonQueryParams = new URLSearchParams({
        business_name: props.businessName,
        business_id: props.businessId,
        tenant_name: sidebarUserFullname.value,
        tenant_email: sidebarUserEmail.value,
      });
      const stringQueryParams = commonQueryParams.toString();

      return hasQueryParams
        ? `${sidebarTypeformLinkModal.value}&${stringQueryParams}`
        : `${sidebarTypeformLinkModal.value}?${stringQueryParams}`;
    });

    const generateBrandSwitcher = (
      businesses: Array<IVuexBusiness>
    ): ISidebarPropsMenuItem => {
      const config = {
        key: "brand-switcher",
        label: business.value?.name,
        logo: business.value?.logo || "",
        visible: true,
        customSubmenu: true,
        action: {
          href: "#",
          func: setCreateBusinessModalState,
        },
      };

      if (canCreateBusiness.value || businesses.length > 1) {
        const items = businesses.map(({ id, logo, name }) => ({
          id,
          logo,
          label: name,
          visible: true,
          action: {},
        }));

        return { ...config, submenu: [{ items }] };
      }

      return config;
    };

    // function to display the menu item based on the Feature Flag
    // add `flagsAvailable.includes(flag-name)` in the `visible`
    // key in the `set-main-menu.ts` file to create the rule
    function updateFlagsAvailable(flag: IFlags, state: boolean) {
      if (state) {
        flagsAvailable.value = [...flagsAvailable.value, flag];
      } else {
        flagsAvailable.value = flagsAvailable.value.filter(
          (flagListed) => flagListed !== flag
        );
      }
    }

    const { getters } = useStore();
    const canCreateBusiness = computed(() => {
      const userData = getters["auth/getData"];
      return userData.permissions.includes(BUSINESS_CREATE);
    });

    const customerManagementFlag = "customers-management";
    const canSeeCustomerManagement = ref(false);
    function updateCanSeeCustomerManagement(val: boolean) {
      if (canSeeCustomerManagement.value === val) return;
      canSeeCustomerManagement.value = val;
      updateFlagsAvailable(customerManagementFlag, val);
    }

    const experimentalMetabaseReports = "experimental-metabase-reports";
    const canSeeExtendedReportsMenu = ref(false);
    function updateCanSeeExtendedReportsMenu(val: boolean) {
      if (canSeeExtendedReportsMenu.value === val) return;
      canSeeExtendedReportsMenu.value = val;
      updateFlagsAvailable(experimentalMetabaseReports, val);
    }

    onMounted(() => {
      if (
        window.innerWidth <= 992 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        )
      ) {
        sidebarIsCollapsed.value = true;
      }

      watchFlag(customerManagementFlag, updateCanSeeCustomerManagement);
      watchFlag(experimentalMetabaseReports, updateCanSeeExtendedReportsMenu);
    });

    onUnmounted(() => {
      unWatchFlag(customerManagementFlag, updateCanSeeCustomerManagement);
      unWatchFlag(experimentalMetabaseReports, updateCanSeeExtendedReportsMenu);
    });

    watch(
      () => flagsAvailable.value,
      () => buildMainMenu()
    );

    function buildMainMenu() {
      mainMenu.value = setMainMenu({
        permissions: sidebarPermissions.value,
        businessId: props.businessId,
        businessName: props.businessName,
        tenantName: "",
        tenantEmail: "",
        // @ts-ignore
        router: vm?.proxy.$router,
        flagsAvailable: flagsAvailable.value,
      });
    }

    watch(
      () => vm?.proxy.$store.getters["auth/getData"],
      (authData) => {
        sidebarPermissions.value = authData.permissions;
        sidebarUserFullname.value =
          vm?.proxy.$store.getters["auth/getFullName"];
        sidebarUserEmail.value = vm?.proxy.$store.getters["auth/getEmail"];

        buildMainMenu();

        const predefinedMenu = setBottomMenu(
          sidebarPermissions.value,
          business.value,
          // @ts-ignore
          vm?.proxy.$router
        );
        const brandSwitcher = generateBrandSwitcher(
          vm?.proxy.$store.getters["businesses/getData"]
        );
        // @ts-ignore
        bottomMenu.value = [...predefinedMenu, brandSwitcher];
      },
      { immediate: true }
    );

    watch(
      [
        () => vm?.proxy.$store.getters["businesses/getData"],
        () => vm?.proxy.$i18n.locale,
        () => billingGetState("showFlowStartCTA").value,
        () => billingGetState("flowSuccess").value,
        () => billingGetState("currentPlan").value,
      ],
      ([businesses]) => {
        buildMainMenu();

        const predefinedMenu = setBottomMenu(
          sidebarPermissions.value,
          business.value,
          // @ts-ignore
          vm?.proxy.$router
        );
        const brandSwitcher = generateBrandSwitcher(businesses);
        // @ts-ignore
        bottomMenu.value = [...predefinedMenu, brandSwitcher];
      },
      { immediate: true }
    );

    return {
      css,
      mainMenu,
      bottomMenu,
      sidebarPermissions,
      sidebarIsCollapsed,
      sidebarMenuMobileIsOpen,
      sidebarSubmenuIsOpen,
      sidebarTypeformShowModal,
      typeFormLink,
      showCreateBusinessModal,
      canCreateBusiness,
      setCreateBusinessModalState,
      onSaveBusiness,
    };
  },
});
