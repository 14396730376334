import { ref } from "@vue/composition-api";

export const sidebarUserFullname = ref<string>("");
export const sidebarUserEmail = ref<string>("");
export const sidebarPermissions = ref<Array<string>>([]);
export const sidebarIsCollapsed = ref<boolean>(false);
export const sidebarMenuMobileIsOpen = ref<boolean>(false);
export const sidebarSubmenuIsOpen = ref<boolean>(false);
export const sidebarTypeformShowModal = ref<boolean>(false);
export const sidebarTypeformLinkModal = ref<string>("");
