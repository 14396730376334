import i18n from "./index";
import deprecatedHttp from "@/api";
import { http } from "@/v2/repo/http.ts";
import Defaults from "@/utils/enums/defaults";

const loadedLanguages = [Defaults.LANGUAGE];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  deprecatedHttp.defaults.headers.common["Accept-Language"] = lang;
  http.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export default async (lang) => {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  return import(
    /* webpackChunkName: "lang-[request]" */ "@/i18n/languages/" +
      lang +
      ".json"
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
};
