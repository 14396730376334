








/* global google */
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { IAtomMapPolygonProps, IPositionCoordinates } from "./props";
import { addListeners, IListeners } from "@/v2/util/maps/google-maps";
import { injectStrict } from "@/v2/util/maps/type";
import { AtomMapTooltip } from "../map-tooltip";
import { report } from "@chatfood/bug-reporter";

export default defineComponent({
  name: "AtomMapPolygon",
  components: {
    AtomMapTooltip,
  },
  props: {
    coordinates: {
      type: Array as PropType<Array<[number, number]>>,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
    strokeOpacity: {
      type: Number,
      required: true,
    },
    strokeWeight: {
      type: Number,
      required: true,
    },
    fillColor: {
      type: String,
      required: true,
    },
    fillOpacity: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => {},
    },
    onDoubleClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => {},
    },
    onDrag: {
      type: Function as PropType<
        (coordinates: IAtomMapPolygonProps["coordinates"]) => void
      >,
      default: () => {},
    },
    onDragStart: {
      type: Function as PropType<
        (coordinates: IAtomMapPolygonProps["coordinates"]) => void
      >,
      default: () => {},
    },
    onDragEnd: {
      type: Function as PropType<
        (coordinates: IAtomMapPolygonProps["coordinates"]) => void
      >,
      default: () => {},
    },
    onInsertAt: {
      type: Function as PropType<
        (coordinates: IAtomMapPolygonProps["coordinates"]) => void
      >,
      default: () => {},
    },
    onSetAt: {
      type: Function as PropType<
        (coordinates: IAtomMapPolygonProps["coordinates"]) => void
      >,
      default: () => {},
    },
    onChange: {
      type: Function as PropType<
        (coordinates: IAtomMapPolygonProps["coordinates"]) => void
      >,
      default: () => {},
    },
    centered: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    hasHover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const map = injectStrict<Ref<google.maps.Map<Element>>>("map");
    const gMaps = injectStrict<Ref<typeof google.maps>>("gMaps");
    const errorHandler = injectStrict<(e: Error) => void>("errorHandler");
    const polygon = ref();
    const polyline = ref();
    const isDragging = ref(false);
    const tooltipPosition = ref<null | IPositionCoordinates>(null);

    const showTooltip = computed(() => props.title && tooltipPosition.value);

    const setTooltipPosition = (e: google.maps.MapMouseEvent) => {
      if (props.title) {
        const { lat, lng } = e.latLng;
        tooltipPosition.value = {
          lat,
          lng,
        };
      }
    };

    const removeTooltip = () => {
      tooltipPosition.value = null;
    };

    const setHover = (show: boolean) => {
      if (!props.hasHover || props.editable) return;

      if (!show) {
        polygon.value.setOptions({
          strokeOpacity: props.strokeOpacity,
          fillOpacity: props.fillOpacity,
        });

        unmountPolyline();

        return;
      }

      const opacity = {
        fillOpacity: 0,
        strokeOpacity: 0,
      };
      polygon.value.setOptions(opacity);

      polyline.value = new gMaps.value.Polyline({
        path: props.coordinates.map(([lat, lng]) => ({ lat, lng })),
        ...opacity,
        zIndex: 3,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 2,
              strokeColor: "#1258FF",
            },
            offset: "0",
            repeat: "10px",
          },
        ],
      });
      polyline.value.setMap(map.value);
    };

    const getFormattedCoordinates = (): Array<[number, number]> => {
      const coordinates = polygon.value?.getPath().getArray();
      return coordinates.map(({ lat, lng }: IPositionCoordinates) => [
        lat(),
        lng(),
      ]);
    };

    const getFormattedPath = (coordinates: Array<[number, number]>) =>
      coordinates.map(([lat, lng]) => new gMaps.value.LatLng(lat, lng));

    const addPolygonListeners = () => {
      const listeners: IListeners = {
        click: (e) => props.onClick(e),
        dblclick: (e) => props.onDoubleClick(e),
        drag: () => props.onDrag(getFormattedCoordinates()),
        dragstart: () => {
          isDragging.value = true;
          props.onDragStart(getFormattedCoordinates());
          removeTooltip();
        },
        dragend: () => {
          const coordinates = getFormattedCoordinates();
          props.onChange(coordinates);
          props.onDragEnd(coordinates);
          isDragging.value = false;
        },
        mousemove: (e?: google.maps.MapMouseEvent) => {
          if (e) {
            setTooltipPosition(e);
          }
        },
        mouseover: () => {
          setHover(true);
        },
        mouseout: () => {
          removeTooltip();
          setHover(false);
        },
      };

      const editListeners: IListeners = {
        insert_at: () => {
          if (isDragging.value) return;

          const coordinates = getFormattedCoordinates();
          props.onChange(coordinates);
          props.onInsertAt(coordinates);
        },
        set_at: () => {
          if (isDragging.value) return;

          const coordinates = getFormattedCoordinates();
          props.onChange(coordinates);
          props.onSetAt(coordinates);
        },
      };

      addListeners(listeners, polygon.value);
      addListeners(editListeners, polygon.value?.getPath());
    };

    const centerMap = (): void => {
      const bounds = new gMaps.value.LatLngBounds();
      polygon.value
        .getPaths()
        .forEach((path: google.maps.MVCArray<google.maps.LatLng>) =>
          path.forEach(
            (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
              bounds.extend(latLng);
              map.value.fitBounds(bounds);
            }
          )
        );
    };

    const mountPolygon = () => {
      try {
        polygon.value = new gMaps.value.Polygon({
          paths: getFormattedPath(props.coordinates),
          strokeColor: props.strokeColor,
          strokeOpacity: props.strokeOpacity,
          strokeWeight: props.strokeWeight,
          fillColor: props.fillColor,
          fillOpacity: props.fillOpacity,
          editable: props.editable,
          draggable: props.draggable,
          zIndex: props.editable ? 2 : 1,
        });
        polygon.value.setMap(map.value);

        if (props.centered) {
          centerMap();
        }

        addPolygonListeners();
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    const unmountPolygon = () => {
      try {
        // @ts-ignore
        gMaps.value?.event.clearInstanceListeners(polygon.value);
        polygon.value.setMap(null);
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    const unmountPolyline = () => {
      try {
        polyline.value?.setMap(null);
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    onMounted(() => mountPolygon());

    onUnmounted(() => {
      unmountPolyline();
      unmountPolygon();
    });

    watch(
      () => props.coordinates,
      (coordinates) => {
        polygon.value?.setPath(getFormattedPath(coordinates));
      },
      { deep: true }
    );

    watch(
      () => [
        props.strokeColor,
        props.strokeOpacity,
        props.strokeWeight,
        props.fillColor,
        props.fillOpacity,
      ],
      ([strokeColor, strokeOpacity, strokeWeight, fillColor, fillOpacity]) => {
        polygon.value?.setOptions({
          strokeColor,
          strokeOpacity,
          strokeWeight,
          fillColor,
          fillOpacity,
        });
      },
      { deep: true }
    );

    watch(
      () => props.editable,
      (editable) => {
        polygon.value?.setEditable(editable);
      },
      { deep: true }
    );

    watch(
      () => props.draggable,
      (draggable) => {
        polygon.value?.setDraggable(draggable);
      },
      { deep: true }
    );

    return { showTooltip, tooltipPosition };
  },
});
