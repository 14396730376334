























import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomText, AtomTextColorEnum } from "@/v2/new-design-system";

const css = bemBuilder("mol-linear-progress");

export default defineComponent({
  name: "MolLinearProgress",
  components: {
    AtomText,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 3,
    },
    backgroundColor: {
      type: String,
      default: "cloudy",
    },
    progressColor: {
      type: String,
      default: "chatfood",
    },
    label: {
      type: String,
      default: null,
    },
    labelColor: {
      type: String as PropType<AtomTextColorEnum>,
      default: AtomTextColorEnum.RAINY,
    },
  },
  setup(props) {
    const progress = ref(0);
    const score = computed(() =>
      props.value >= props.max ? props.max : props.value
    );
    const height = computed(() => `${props.size}px`);
    const radius = computed(() => props.size / 2);
    const params = computed(() => ({
      rx: radius.value,
      ry: radius.value,
    }));

    watch(
      () => [score.value, props.max],
      () => {
        setTimeout(() => {
          progress.value = (score.value / props.max) * 100 || 0;
        }, 250);
      },
      { immediate: true }
    );

    return { css, height, radius, progress, params };
  },
});
