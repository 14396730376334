import { VIEW_RESERVATIONS } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { optionalOutletFillerMiddleware } from "@/v2/components/outlet-switcher";
import { t } from "@/i18n";

export const reservationsRoutes: Array<RouteConfig> = [
  {
    path: "/business/:businessId/outlet/:outletId?/reservations",
    alias: "/business/:businessId/reservations",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "reservations-module" */ "@/v2/module/reservations/main/Main.vue"
      ),
    name: "reservations.index",
    props: true,
    meta: {
      title: t("module.reservations.main.title"),
      permission: VIEW_RESERVATIONS,
    },
  },
];
