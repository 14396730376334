/* global google */
import { inject } from "@vue/composition-api";

export interface PromiseReturn {
  resolve: Function;
  reject: Function;
}

export interface IUseGoogleMaps {
  loadLibrary: () => Promise<void>;
  getMaps: () => Promise<typeof google.maps>;
}

// eslint-disable-next-line
export function injectStrict<T>(key: string, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`);
  }

  return resolved;
}
