import Gates from "./gates";
import RouterHandler from "./router";
import { hideElement, validateConfig } from "./utils";
import { has } from "ramda";

/**
 * Installation procedure
 */
export default (Vue, config = {}) => {
  validateConfig(config);

  const gates = new Gates(config.permissions);

  if (has("router", config)) {
    RouterHandler(gates, config.router);
  }

  Vue.directive("can", {
    bind(el, binding, vnode) {
      if (!gates.can(binding.value)) {
        hideElement(el, vnode);
      }
    },
  });

  Vue.directive("hasRole", {
    bind(el, binding, vnode) {
      if (!gates.hasRole(binding.value)) {
        hideElement(el, vnode);
      }
    },
  });

  Vue.prototype.$gates = Vue.gates = gates;
  Vue.prototype.$can = (permission) => gates.can(permission);
};
