import { VIEW_ONBOARDING } from "@/utils/enums/permissions";

const route: any = [
  {
    name: "onboarding",
    path: "/business/:businessId/onboarding/:isSignup?",
    component: () =>
      import(
        /* webpackChunkName: "onboarding-module" */ "@/v2/module/onboarding/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Onboarding",
      permission: VIEW_ONBOARDING,
    },
  },
];

export default route;
