















































































import { t } from "@/i18n";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  MolGuideLink,
  MolLoadingZone,
  MolGuideLinkArticleEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  h,
  onMounted,
  ref,
} from "@vue/composition-api";
import { BillingPlanCard } from "../plan-card";
import {
  billingAction,
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
  IBillingPlan,
} from "../state";
import { fetchPaymentMethod } from "@/v2/repo/fetch-payment-method";
import { billingMethodPlanCalculateFinalPrice } from "../method/calculate-final-price";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { billingMethodTrack } from "../method/track";
import { billingPlanPriceFormatter } from "../method/price-formatter";
import { EventEnum } from "@/v2/enum";
import { report } from "@chatfood/bug-reporter";
import { createBusinessTrack } from "@/v2/util/analytics/createBusinessTrackEvent";
import { isCreateBusinessFlowStarted } from "@/v2/module/billing/utils/create-business";

const css = bemBuilder("billing-plan-modal");

export default defineComponent({
  name: "BillingPlanModal",
  components: {
    AtomModal,
    BillingPlanCard,
    AtomText,
    MolGuideLink,
    MolLoadingZone,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoadingNextStep = ref(false);
    const isLoadingPlans = ref(true);
    const vm = getCurrentInstance();
    // @ts-ignore
    const analytics = vm?.proxy.$analytics;

    onMounted(async () => {
      const businessId = billingGetState("business").value.id;
      try {
        await billingAction("loadPlans", [businessId]);
        billingMethodTrack(analytics, EventEnum.STARTED_SUBSCRIPTION_FLOW);
      } catch (error: any) {
        report(error.message, {
          context: {
            error: error,
          },
          tags: {
            "http.response_code": error.statusCode,
          },
        });

        billingSetState("stage", BillingStageStateEnum.DORMANT);
        generalErrorToast(t("c.billing.plan_load_error"));
      }
      isLoadingPlans.value = false;
    });

    const trackEvent = (plan: IBillingPlan): void => {
      if (isCreateBusinessFlowStarted(props.businessId)) {
        createBusinessTrack(
          analytics,
          EventEnum.CLICKED_ON_BUTTON,
          billingGetState("business").value.id,
          {
            label: "Clicked on choose plan",
          }
        );
        return;
      }

      billingMethodTrack(analytics, EventEnum.SELECTED_PLAN, {
        price: billingPlanPriceFormatter(plan?.priceRaw, plan.currency, "0"),
        is_recommended: plan.recommended,
        currency: plan.currency,
        plan: plan.name,
      });
    };

    async function onSelectPlan(plan: IBillingPlan) {
      trackEvent(plan);

      billingSetState("selectedPlan", plan);

      isLoadingNextStep.value = true;

      const existingPaymentMethod = await fetchPaymentMethod(
        billingGetState("business").value.id
      );

      isLoadingNextStep.value = false;

      if (existingPaymentMethod) {
        billingSetState("selectedCardInfo", existingPaymentMethod);
        billingSetState("stage", BillingStageStateEnum.REVIEW_ORDER);
      } else {
        billingSetState("stage", BillingStageStateEnum.PAYMENT_METHOD);
      }
    }

    return {
      t,
      h,
      css,
      billingMethodPlanCalculateFinalPrice,
      billingSetStageDormant: () =>
        billingSetState("stage", BillingStageStateEnum.DORMANT),
      currentOutletCount: billingGetState("currentOutletCount"),
      plans: billingGetState("plans"),
      isLoadingNextStep,
      isLoadingPlans,
      onSelectPlan,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
    };
  },
});
