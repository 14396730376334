














import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { RawLocation } from "vue-router";
import { AtomLinkSizeEnum } from "./size.enum";
import { AtomLinkTypeEnum } from "./type.enum";

const css = bemBuilder("atom-link");

export default defineComponent({
  name: "AtomLink",
  props: {
    type: {
      type: String as PropType<AtomLinkTypeEnum>,
      default: AtomLinkTypeEnum.PRIMARY,
    },
    size: {
      type: String as PropType<AtomLinkSizeEnum>,
      default: AtomLinkSizeEnum.BODY_BOLD,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    route: {
      type: [Object, String] as PropType<RawLocation | string>,
      required: true,
    },
  },
  setup(props) {
    const attrs = computed(() => {
      const attributes: Record<string, any> = {};

      if (props.openInNewTab) {
        attributes.target = "_blank";
        attributes.rel = "noopener noreferrer";
      }

      if (tag.value === "a") {
        attributes.href = props.route;
      } else {
        attributes.to = props.route;
      }

      return attributes;
    });

    const tag = computed(() => {
      return typeof props.route === "string" &&
        (props.route.startsWith("http") ||
          props.route.startsWith("mailto:") ||
          props.route.startsWith("tel:"))
        ? "a"
        : "router-link";
    });

    return {
      css,
      tag,
      attrs,
      AtomLinkTypeEnum,
    };
  },
});
