













import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { VTooltip } from "v-tooltip";
import { MolIconButtonTypeEnum } from "./type.enum";

const css = bemBuilder("mol-icon-button");

export default defineComponent({
  name: "MolIconButton",
  directives: {
    tooltip: VTooltip,
  },
  components: {
    AtomIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<MolIconButtonTypeEnum>,
      default: MolIconButtonTypeEnum.PRIMARY,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
