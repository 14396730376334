



























































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onUnmounted,
  PropType,
} from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system";
import SidebarSubmenu from "./SidebarSubmenu.vue";
import SidebarLiveOrdersBadge from "./SidebarLiveOrdersBadge.vue";
import SidebarBrandSwitcher from "./SidebarBrandSwitcher.vue";
import SidebarBrandSwitcherNEXT from "./next/SidebarBrandSwitcher.vue";
import { routeBuilder } from "./method";
import {
  sidebarIsCollapsed,
  sidebarMenuMobileIsOpen,
  sidebarSubmenuIsOpen,
} from "./state";
import { Submenu } from "./props";
import { InboxSidebarBadge } from "@/v2/module/inbox/sidebar-badge";
import SidebarBrandLogo from "./SidebarBrandLogo.vue";
import SidebarLabel from "./SidebarLabel.vue";
import { useStore } from "@/store";
import { BUSINESS_CREATE } from "@/utils/enums/permissions";

const css = bemBuilder("sidebar-menu-item");

export default defineComponent({
  name: "SidebarMenuItem",
  components: {
    AtomIcon,
    SidebarSubmenu,
    SidebarLiveOrdersBadge,
    InboxSidebarBadge,
    SidebarBrandSwitcher,
    SidebarBrandLogo,
    SidebarLabel,
    SidebarBrandSwitcherNEXT,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    action: {
      type: Object,
      default: null,
    },
    itemKey: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    submenu: {
      type: Array as PropType<Array<Submenu>>,
      default: () => [],
    },
    checkPosition: {
      type: Boolean,
      default: false,
    },
    customSubmenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const subMenuPosition = ref();
    const submenuRef = ref<HTMLElement>();
    const setPosition = ref(false);

    const isInViewport = (): void => {
      if (!props.checkPosition || !subMenuPosition.value) return;

      const validPosition =
        subMenuPosition.value.bottom <=
        (window.innerHeight || document.documentElement.clientHeight);

      setPosition.value = !validPosition;
    };

    onMounted(() => {
      subMenuPosition.value = submenuRef.value?.getBoundingClientRect();
      isInViewport();
      addEventListener("resize", isInViewport, true);
    });

    onUnmounted(() => {
      removeEventListener("resize", isInViewport, true);
    });

    const { getters } = useStore();
    const canCreateBusiness = computed(() => {
      const userData = getters["auth/getData"];
      return userData.permissions.includes(BUSINESS_CREATE);
    });

    const openSubmenu = ref<boolean>(false);

    const isActivePage = computed((): boolean => {
      return (
        props.action?.routerLink === context.root.$route.name &&
        context.root.$route.name !== "billing.draft"
      );
    });

    const hasSubmenu = computed((): boolean => {
      return Boolean(props.submenu && props.submenu.length > 0);
    });

    const untitled = computed((): boolean => {
      return !props.submenu[0]?.label;
    });

    const isLiveOrders = computed((): boolean => {
      return props.action?.routerLink === "orders.live";
    });

    const isInbox = computed((): boolean => {
      return props.action?.routerLink === "inbox";
    });

    function triggerLink({
      ctrlKey,
      metaKey,
    }: {
      ctrlKey: boolean;
      metaKey: boolean;
    }): void {
      if (
        hasSubmenu.value &&
        (window.innerWidth <= 768 || window.innerHeight <= 570)
      ) {
        document.getElementById("sidebar-menus")!.scroll(0, 0);
        toggleSubmenu(true);
        return;
      }

      const openInNewTab = ctrlKey || metaKey;
      routeBuilder(
        context,
        props.businessId || "",
        props.action,
        props.label,
        openInNewTab
      );
    }

    function toggleSubmenu(state: boolean): void {
      openSubmenu.value = state;
      sidebarSubmenuIsOpen.value = state;
    }

    return {
      css,
      submenuRef,
      setPosition,
      sidebarIsCollapsed,
      sidebarMenuMobileIsOpen,
      canCreateBusiness,
      triggerLink,
      untitled,
      isActivePage,
      isLiveOrders,
      isInbox,
      openSubmenu,
      hasSubmenu,
      toggleSubmenu,
    };
  },
});
