<template>
  <ServiceUnavailableView v-if="isUnderMaintenance" />
  <UnexpectedErrorView v-else />
</template>

<script>
import ServiceUnavailableView from "@/views/503.vue";
import UnexpectedErrorView from "@/views/500.vue";
import { mapGetters } from "vuex";

export default {
  name: "Error",
  components: {
    ServiceUnavailableView,
    UnexpectedErrorView,
  },
  computed: {
    ...mapGetters({
      isUnderMaintenance: "isUnderMaintenance",
    }),
  },
};
</script>
