
















































import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomTextColorEnum,
  MolSearchBox,
  MolSearchBoxEnum,
} from "@/v2/new-design-system";
import { ISidebarBrandSwitcher } from "@/v2/module/sidebar/props";
import { debounce } from "@/v2/util/debounce";
import SidebarBrandLogo from "./SidebarBrandLogo.vue";
// @ts-ignore
import { activeBusiness } from "@/utils/helpers/functions";

const css = bemBuilder("sidebar-brand-switcher");

export default defineComponent({
  name: "SidebarBrandSwitcher",
  components: {
    AtomText,
    MolSearchBox,
    SidebarBrandLogo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    brands: {
      type: Array as PropType<Array<ISidebarBrandSwitcher>>,
      required: true,
    },
  },
  setup(props) {
    const brandList = ref<Array<ISidebarBrandSwitcher>>(props.brands);
    const showFullFunctionality = computed(() => props.brands?.length >= 10);

    const isActive = (id: string): boolean => props.businessId === id;

    const handleClick = async (brand: ISidebarBrandSwitcher): Promise<void> => {
      if (isActive(brand.id)) return;

      activeBusiness.set(brand.id);
    };

    watch(
      () => props.brands,
      () => {
        brandList.value = props.brands;
      }
    );

    const handleSearch = debounce((key: string): void => {
      if (!key) {
        brandList.value = props.brands;
      } else {
        const searchKey = key.toLowerCase();
        brandList.value = props.brands.filter(({ label }) =>
          label.toLowerCase().includes(searchKey)
        );
      }
    }, 250);

    return {
      t,
      css,
      brandList,
      showFullFunctionality,
      isActive,
      handleClick,
      handleSearch,
      AtomTextColorEnum,
      MolSearchBoxEnum,
    };
  },
});
