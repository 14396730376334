import ValidationError from "./errors/ValidationError";
import ServerError from "./errors/ServerError";
import ServiceUnavailableError from "./errors/ServiceUnavailableError";
import UnauthorizedError from "./errors/UnauthorizedError";
import ForbiddenError from "@/api/errors/ForbiddenError";
import { path, gte } from "ramda";

export default (error) => {
  /*
   * The request was made and the server responded with a
   * status code that falls out of the range of 2xx
   */
  if (error?.response) {
    const errorResponse = path(["response"], error);
    const statusCode = path(["response", "status"], error);

    // Handle 401 errors
    if (statusCode === 401) {
      return Promise.reject(new UnauthorizedError(errorResponse));
    }

    // Handle 403 errors
    if (statusCode === 403) {
      return Promise.reject(new ForbiddenError(errorResponse));
    }

    // Handle 422 errors
    if (statusCode === 422) {
      return Promise.reject(new ValidationError(errorResponse));
    }

    // Handle 503 errors
    if (statusCode === 503) {
      return Promise.reject(new ServiceUnavailableError(errorResponse));
    }

    // Handle 5xx errors
    if (gte(statusCode, 500)) {
      return Promise.reject(new ServerError(errorResponse));
    }
  }

  return Promise.reject(error);
};
