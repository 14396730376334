export const ACCOUNT_ACTIVATION_INTENTION = "ACCOUNT_ACTIVATION_INTENTION";

export const APP_OPENED = "APP_OPENED";

export const BUTTON_CLICKED = "BUTTON_CLICKED";

export const DEMO_BOOKING_INTENTION = "DEMO_BOOKING_INTENTION";

export const ORDER_ACKNOWLEDGED = "ORDER_ACKNOWLEDGED";

export const ORDER_OPENED = "ORDER_OPENED";

export const ORDER_RECEIVED = "ORDER_RECEIVED";

export const PUSH_RECEIVED = "PUSH_RECEIVED";

export const SALES_CHANNEL_HELP_FEEDBACK = "SALES_CHANNEL_HELP_FEEDBACK";

export const SALES_CHANNEL_VERIFIED = "SALES_CHANNEL_VERIFIED";
