<template>
  <button
    v-bind="$attrs"
    class="button"
    :class="[
      `button--${kind}`,
      `button--${size}`,
      { 'button--loading': isLoading },
    ]"
    data-test="button"
    v-on="$listeners"
  >
    <slot />
    <div v-if="isLoading" data-test="loading" class="button__circle"></div>
  </button>
</template>

<script>
/**
 * @deprecated
 * use `AtomButton` from `@/v2/new-design-system` instead
 */

export default {
  name: "Button",
  props: {
    kind: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["primary", "primary-light", "secondary", "minimal"].includes(value),
    },
    size: {
      type: String,
      default: "md",
      validator: (value) => ["sm", "md"].includes(value),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #ffffff;
$grey: #444444;
$blue: #00a8f8;
$blue-light: #e5f6fe;

.button {
  position: relative;
  border: none;
  padding: 0;
  font-weight: 600;
  font-size: 13px;
  border-radius: 4px;

  &--sm {
    padding: 7px 14px;
  }

  &--md {
    padding: 12px 19px;
  }

  &--primary {
    color: $white;
    background-color: $blue;

    &:hover {
      background-color: #00a0ec;
    }

    &:active,
    &:focus {
      background-color: #0097df;
    }
  }

  &--primary-light,
  &--primary-light:hover,
  &--primary-light:focus {
    color: $blue;
    background-color: $blue-light;

    .button__circle {
      border: 3px solid $blue;
      mask-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(rgba(0, 151, 223, 0)),
        to($blue)
      );
    }
  }

  &--loading {
    cursor: default;
    pointer-events: none;
    color: transparent !important;
  }

  &__circle {
    width: 20px;
    height: 20px;
    border: 3px solid $white;
    border-radius: 23px;
    clip: rect(0px 23px 46px 6px);
    position: absolute;
    top: 50%;
    left: 50%;
    mask-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba($white, 0)),
      to($white)
    );
    animation: loading 0.6s linear infinite;
    transform: translate(-50%, -50%);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }

  &--secondary {
    color: $grey;
    background-color: #e2eaef;

    &:hover {
      background-color: #d7dee3;
    }

    &:active,
    &:focus {
      background-color: #cbd3d7;
    }

    .button__circle {
      border: 3px solid $grey;
      mask-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(rgba($grey, 0)),
        to($grey)
      );
    }
  }

  &--minimal {
    color: $blue;
    border: none;
    background: transparent;

    &:hover {
      color: #00a0ec;
    }

    &:active,
    &:focus {
      color: #0097df;
    }

    .button__circle {
      border: 3px solid #0097df;
      mask-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(rgba(0, 151, 223, 0)),
        to(#0097df)
      );
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
</style>
