




























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed, PropType } from "@vue/composition-api";
import {
  AtomInput,
  AtomDropdown,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomDatepickerRange,
  MolFormControl,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import dayjs from "dayjs";
import {
  IOrgFiltersConfigProps,
  IOrgFiltersConfigFieldsDateRange,
  IOrgFiltersConfigFields,
} from "./props";

const css = bemBuilder("org-filters");

type IRangePeriod = NonNullable<IOrgFiltersConfigFieldsDateRange["selected"]>;

type IState = Record<
  keyof IOrgFiltersConfigProps,
  IOrgFiltersConfigFields["selected"]
>;

export default defineComponent({
  name: "OrgFilters",
  components: {
    AtomDropdown,
    AtomButton,
    AtomDatepickerRange,
    AtomInput,
    MolFormControl,
  },
  props: {
    config: {
      type: Object as PropType<IOrgFiltersConfigProps>,
      required: true,
    },
    applyOnMounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    apply: (payload: IState) => true,
    clear: (payload: IState) => true,
    input: (payload: {
      name: keyof IOrgFiltersConfigProps;
      data: IOrgFiltersConfigFields["selected"];
    }) => true,
  },
  setup(props, { emit }) {
    const state = ref<IState>({});

    const calculateDateRange = (range: number): IRangePeriod => {
      if (range < 0) {
        return {
          start: dayjs().subtract(-range, "days").startOf("day").toDate(),
          end: dayjs().endOf("day").toDate(),
        };
      }

      return {
        start: dayjs().startOf("day").toDate(),
        end: dayjs().add(range, "days").endOf("day").toDate(),
      };
    };

    const entries = computed(() => Object.entries(props.config));

    const onClearFilters = () => {
      state.value = { ...initialState.value };
      emit("clear", state.value);
    };

    const onApplyFilters = () => {
      emit("apply", state.value);
    };

    function onInput(
      name: keyof IOrgFiltersConfigProps,
      data: IOrgFiltersConfigFields["selected"]
    ) {
      state.value = {
        ...state.value,
        [name]: data,
      };
      emit("input", { name, data });
    }

    const initialState = ref<IState>({});
    function preSelectOnLoad() {
      initialState.value = entries.value.reduce((prev: any, curr) => {
        const key = curr[0];
        const config = curr[1];
        let selected;

        if (config.selected) {
          selected = config.selected;
        } else if (
          config.type === "date-range" &&
          config.defaultRange !== undefined
        ) {
          selected = calculateDateRange(config.defaultRange);
        }

        prev[key] = selected;
        return prev;
      }, {});

      state.value = { ...initialState.value };
    }

    preSelectOnLoad();

    if (props.applyOnMounted) {
      onApplyFilters();
    }

    return {
      t,
      css,
      state,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      onClearFilters,
      onApplyFilters,
      entries,
      onInput,
    };
  },
});
