























import { defineComponent } from "@vue/composition-api";
// @ts-ignore
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { array, boolean, func, string } from "@/v2/util/prop/type";
import { debounce } from "@/v2/util/debounce";

/**
 * @deprecated
 * use `MolMultiselect` from `v2/new-design-system` instead
 */

export default defineComponent({
  name: "MolAutocompleteDropdown",
  components: {
    vSelect,
  },
  props: {
    options: array().use(),
    placeholder: string().use(),
    value: string().use(),
    loading: boolean().use(),
    disabled: boolean().use(),
    multiple: boolean().use(),
    searchable: boolean().setDefault(true).use(),
    clearable: boolean().setDefault(true).use(),
    onChangeQuery: func().use(),
    onChange: func().use(),
    error: boolean().use(),
  },
  setup(props) {
    const onKeyDownDebouncer = debounce(
      (map: any, vm: any) => props.onChangeQuery(vm.search),
      500
    );

    function onKeyDown(map: any, vm: any) {
      // @ts-ignore
      onKeyDownDebouncer(map, vm);
      return map;
    }

    return {
      onKeyDown,
    };
  },
});
