import { http } from "../http";

export async function fetchMenuIntegration(): Promise<
  Array<IFetchMenuIntegration>
> {
  const response = await http.get("/api/v3/integrations");

  return response.data.data.map((obj: any) => ({
    outletId: obj.outlet_id,
    businessId: obj.business_id,
  }));
}

export interface IFetchMenuIntegration {
  outletId: "";
  businessId: "";
}
