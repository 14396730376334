export enum RepoErrorEnum {
  NETWORK_ERROR = "NETWORK_ERROR",
  GRAPHQL_ERROR = "GRAPHQL_ERROR",
}

export enum RepoErrorValidationEnum {
  MUST_BE_GREATER_THAN_ZERO = "MUST_BE_GREATER_THAN_ZERO",
  FILE_TOO_LARGE = "FILE_TOO_LARGE",
  IMAGE_SIZE_WRONG = "IMAGE_SIZE_WRONG",
}

export enum RepoErrorDiscountEnum {
  DISCOUNT_CODE_DUPLICATE = "DISCOUNT_CODE_DUPLICATE",
}

export enum RepoErrorOutletEnum {
  ACTIVE_ORDER_EXIST = "ACTIVE_ORDER_EXIST",
  ALLOWED_OUTLETS_EXCEEDED = "max_allowed_active_outlets_reached",
  MISSING_VALID_ADDRESS = "outlet_is_missing_a_valid_address",
  MISSING_ACTIVE_ORDERING_MODE = "outlet_is_missing_active_delivery_pickup_options",
  SOME_OUTLETS_ARE_NOT_COVERED_WITH_POLYGON = "some_outlets_are_not_covered_with_polygon",
}

export enum RepoErrorCustomerEnum {
  NOT_FOUND = "customer_not_found",
  INVALID_PHONE_NUMBER = "invalid_phone_number",
  BUSINESS_NOT_ALLOWED = "business_not_allowed",
}

export enum RepoErrorReportEnum {
  NOT_FOUND = "report_not_found",
}

export enum RepoErrorAnalyticEnum {
  NOT_FOUND = "analytic_not_found",
  UNAUTHENTICATED = "analytic_unauthenticated",
}
