import { useStore } from "@/store";
import { IVuexBusiness } from "@/store/types";

export function canViewGetStarted(
  hasViewOnboardingPermission: boolean
): boolean {
  const businesses: Array<IVuexBusiness> =
    useStore().getters["businesses/getData"];

  const hasOnboardingBusiness = Boolean(
    businesses.find(function (business) {
      return (
        business.onboardingStatus === "Active Implementation" ||
        business.onboardingStatus === "New"
      );
    })
  );

  return hasOnboardingBusiness && hasViewOnboardingPermission;
}
