import Api from "./index";
import TenantParser from "./parsers/tenant-parser";

export default {
  getAuthToken({ email = null, password = null, hash = null }) {
    let credentials = {
      grant_type: "password",
      email,
      password,
    };

    if (hash) {
      credentials = {
        grant_type: "token",
        hash,
      };
    }

    return Api.post("/api/v3/auth/token", credentials);
  },
  me() {
    return Api.get(`/api/v3/me`, {
      params: {
        includes: "roles,permissions,organization",
      },
    }).then((data) => {
      const parser = new TenantParser(data.data);
      return { data: parser.fromApi() };
    });
  },

  /**
   * Create tenant account.
   *
   * @param {Object} payload
   */
  async createTenantAccount(payload) {
    const res = await Api.post("/restaurant/signup", payload);

    const data = res.data ?? {};

    return {
      hash: data.hash,
    };
  },
};
