import Base from "./Base";
import { pick } from "ramda";

class Customer extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} customer
   */
  constructor(customer = {}) {
    const keys = {
      name: ["name"],
      email: ["email"],
      phoneNumber: ["phoneNumber", "phone_number"],
      ordersPlaced: ["ordersPlaced", "orders_placed"],
      lastOrderedAt: ["lastOrderedAt", "last_ordered_at"],
      firstOrderedAt: ["firstOrderedAt", "first_ordered_at"],
      totalRevenue: ["totalRevenue", "total_revenue"],
    };

    super(keys, customer);
  }

  /** Filters the params and return the params as per API sepcs.display.
   *
   * @param {object} params
   * @return {Object}
   */
  static toFilterPayload(obj = {}) {
    obj = pick(["phoneNumber", "name", "email", "page"], obj);
    obj = super.toFilterPayload(obj);

    return obj;
  }
}

export default Customer;
