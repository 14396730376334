<template>
  <div class="FormList">
    <label v-if="label" class="FormList__label">{{ label }}</label>
    <ul class="FormList__list">
      <li
        v-for="(item, index) in $slots.default"
        :key="index"
        class="FormList__item"
      >
        <VNode :node="item" />
      </li>
    </ul>
    <span
      v-if="message"
      data-test="caption"
      class="FormList__caption"
      :class="{
        'FormList__caption--error': error,
        'FormList__caption--positive': positive,
      }"
    >
      {{ message }}
    </span>
  </div>
</template>

<script>
import VNode from "./VNode.vue";

export default {
  name: "FormList",
  components: {
    VNode,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    positive: {
      type: String,
      default: null,
    },
  },
  computed: {
    message() {
      return this.error || this.positive || this.caption || false;
    },
  },
};
</script>

<style lang="scss" scoped>
.FormList {
  &__label {
    display: block;
    color: #84a0b2;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 0.75rem;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    padding: 0.25rem 0;
    display: block;
  }

  &__caption {
    color: #444444;
    font-size: 13px;
    line-height: 22px;
    margin-top: 3px;

    &--error {
      color: #e42f46;
    }

    &--positive {
      color: #70cc49;
    }
  }
}
</style>
