import Api from "./index";
import PaginationConstructor from "@/constructors/Pagination";
import OrderConstructor from "@/constructors/Order";

export default {
  /**
   * Get list of orders.
   *
   * @param {Object} params
   * @returns {{data: Array, pagination: PaginationConstructor}}
   */
  async get(params = {}) {
    params = OrderConstructor.toFilterPayload(params);
    const { data, meta } = await Api.get(`/api/v3/orders`, { params });

    return {
      data: data,
      pagination: new PaginationConstructor(meta),
    };
  },

  /**
   * Find an orders by id.
   *
   * @param {String} orderId
   * @param {Object} params
   * @returns {Object}
   */
  find(orderId, params = {}) {
    return Api.get(`/api/v3/orders/${orderId}`, { params });
  },

  /**
   * Accept an order.
   *
   * @param {String} orderId
   * @param {Object} params
   * @returns {void}
   */
  accept(orderId, params) {
    return Api.put(`/api/v3/orders/${orderId}/accept`, params);
  },

  /**
   * Schedule an order.
   *
   * @param {String} orderId
   * @param {Object} params
   * @returns {void}
   */
  schedule(orderId, params) {
    return Api.put(`/api/v3/orders/${orderId}/schedule`, {
      preparation_minutes: params.preparationMinutes,
      estimated_completion_minutes: params.estimatedCompletionMinutes,
    });
  },

  /**
   * Reject an order.
   *
   * @param {String} orderId
   * @param {String} reason
   * @returns {void}
   */
  reject(orderId, reason) {
    return Api.put(`/api/v3/orders/${orderId}/reject`, { reason });
  },

  /**
   * Mark an order as ready.
   *
   * @param {String} orderId
   * @returns {void}
   */
  markReady(orderId) {
    return Api.put(`/api/v3/orders/${orderId}/mark-ready`);
  },

  /**
   * Request delivery service.
   *
   * @param {String} orderId
   * @param {String} deliveryService
   * @returns {void}
   */
  requestDelivery(orderId, deliveryService) {
    return Api.post(`/api/v3/orders/${orderId}/request-delivery`, {
      delivery_service: deliveryService,
    });
  },

  /**
   * Complete an order.
   *
   * @param {String} orderId
   * @returns {void}
   */
  complete(orderId) {
    return Api.put(`/api/v3/orders/${orderId}/complete`);
  },

  /**
   * Close an order.
   *
   * @param {String} orderId
   * @returns {void}
   */
  close(orderId) {
    return Api.put(`/api/v3/orders/${orderId}/close`);
  },

  /**
   * Cancel an order.
   *
   * @param {String} orderId
   * @returns {void}
   */
  cancel(orderId) {
    return Api.put(`/api/v3/orders/${orderId}/cancel`);
  },

  /**
   * Get the orders summary based on the given params.
   *
   * @param {Object} params
   * @returns {Object}
   */
  summaryReport(params = {}) {
    params = OrderConstructor.toFilterPayload(params);

    return Api.get(`/api/v3/orders/summary-report`, { params });
  },
};
