import { is } from "ramda";

export default class EventEmitter {
  constructor() {
    this.events = {};
    window.emitter = this.events;
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === "undefined") {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventsName, fn) {
    if (!is(Array, eventsName)) {
      eventsName = [eventsName];
    }

    eventsName.map((event) => this._getEventListByName(event).add(fn));
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = function (...args) {
      self.off(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach(
      function (fn) {
        fn.apply(this, args);
      }.bind(this)
    );
  }

  off(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}
