import Base from "./Base";

/** Constructor to normalize outsider data */
class Pagination extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} pagination
   */
  constructor(pagination = {}) {
    const keys = {
      currentPage: ["currentPage", "current_page"],
      from: ["from"],
      to: ["to"],
      perPage: ["perPage", "per_page"],
      lastPage: ["lastPage", "last_page"],
      total: ["total"],
    };

    super(keys, pagination);
  }
}

export default Pagination;
