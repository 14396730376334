import liveOrders from "./modules/live-orders";
import auth from "./modules/auth";
import businesses from "./modules/businesses";
import outlets from "./modules/outlets";

export default {
  auth,
  businesses,
  outlets,
  liveOrders,
};
