export const pushNotification = async (
  title: string,
  options?: NotificationOptions
): Promise<Notification | undefined> => {
  if (!("Notification" in window)) return undefined;

  if (Notification.permission === "granted") {
    return new Notification(title, options);
  }

  if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      return new Notification(title, options);
    }
  }

  return undefined;
};
