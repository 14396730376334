

























// @ts-ignore
import VueTimepicker from "vue2-timepicker";
import { defineComponent, computed, ref, watch } from "@vue/composition-api";

interface ISelectTimeProps {
  startTime: string;
  slotTime: string;
  isDisabled: boolean;
  hasErrors: boolean;
}

export default defineComponent<ISelectTimeProps>({
  name: "SelectTime",
  components: {
    VueTimepicker,
  },
  props: {
    startTime: {
      type: String,
      default: null,
    },
    slotTime: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const time = ref<string>("");

    const hourRange = computed((): Array<number> => {
      const [hours] = props.startTime ? props.startTime.split(":") : [0];
      return [Number(hours), 23]; // set max hour based on startTime
    });

    const minuteRange = computed((): Array<number> => {
      let startTime = 0; // define startTime
      const times = []; // times array
      const oneHour = 60;
      const interval = 5;
      const lastMinute = 59;

      for (let i = 0; startTime < oneHour; i++) {
        const minutes = startTime % oneHour; // getting minutes of the hour in 0-55 format
        times[i] = minutes; // transforms to date format
        startTime = startTime + interval;
      }

      times.push(lastMinute); // add the last minute
      return times;
    });

    watch(
      () => props.slotTime,
      async (val) => {
        if (props.slotTime) {
          time.value = val;
        }
      },
      {
        immediate: true,
      }
    );

    return {
      time,
      hourRange,
      minuteRange,
    };
  },
});
