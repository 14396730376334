import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export interface IFetchBusinessPayoutPayee {
  taxId: string;
  bankAccount: {
    accountHolderName: string;
    iban: string;
    swiftCode: string;
    accountNumber: string;
    bankCode: string;
    bankName: string;
  } | null;
}

export class FetchBusinessPayoutPayeeError extends RepoBusinessSettingsError {}

export async function fetchBusinessPayoutPayee(
  businessId: string
): Promise<IFetchBusinessPayoutPayee | undefined> {
  let response: AxiosResponse;

  try {
    response = await http.get(`api/v3/businesses/${businessId}/payout-payee`);
  } catch (e: any) {
    if (e.response.status === 404) {
      return undefined;
    }

    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage ||
        "The request to fetch the business payout payee failed.",
      response: response.data,
    };

    throw new FetchBusinessPayoutPayeeError(error);
  }

  return {
    taxId: response.data.tax_id || "",
    bankAccount: response.data.bank_account
      ? {
          accountHolderName:
            response.data.bank_account.account_holder_name || "",
          iban: response.data.bank_account.iban || "",
          swiftCode: response.data.bank_account.swift_code || "",
          accountNumber: response.data.bank_account.account_number || "",
          bankCode: response.data.bank_account.bank_code || "",
          bankName: response.data.bank_account.bank_name || "",
        }
      : null,
  };
}
