import Api from "./index";
import OutletsParser from "./parsers/outlets-parser";

export default {
  get(params = {}) {
    return Api.get(`/api/v3/outlets`, { params }).then((data) => {
      const parser = new OutletsParser(data.data);
      return { data: parser.fromApi() };
    });
  },
};
