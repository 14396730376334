<template>
  <div class="loading-zone" :style="loading ? `min-height: ${minHeight};` : ''">
    <div v-if="loading" class="loading-zone__loader" data-test="loading">
      <Icon icon="loading" :size="40" />
      <div v-if="message" class="loading-zone__message" data-test="message">
        {{ message }}
      </div>
    </div>
    <template v-if="domVisibility">
      <slot v-show="!loading" />
    </template>
    <template v-else>
      <slot v-if="!loading" />
    </template>
  </div>
</template>

<script>
import Icon from "./Icon/Index.vue";

/**
 * @deprecated
 * use `AtomLoading` from `@/v2/new-design-system` instead
 */

export default {
  name: "LoadingZone",
  components: {
    Icon,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: null,
    },
    domVisibility: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "300px",
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-zone {
  position: initial;
  z-index: 9;

  &__message {
    text-align: center;
    padding: 0 1.5rem;
    margin-top: 1rem;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      margin: auto;
    }
  }
}
</style>
