import axios from "axios";
import errorHandler from "@/api/errorHandler";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

client.interceptors.response.use(
  (response) => response.data,
  (error) => errorHandler(error)
);

export default client;
