import { LDClient, LDUser, initialize } from "launchdarkly-js-client-sdk";
import { globalConfig } from "..";
import { IFeatureFlagUser, IFlags } from "./domain";

let client: LDClient;

type IState = "off" | "disabled" | "running";
const hasLdKey: boolean = globalConfig.launchDarklyKey.length > 0;
let moduleState: IState = !hasLdKey ? "disabled" : "off";

const getClient = (): LDClient => {
  if (!client) {
    initFeatureFlag();
  }

  return client;
};

const initFeatureFlag = (): void => {
  if (moduleState === "disabled") return;

  moduleState = "running";

  client = initialize(
    globalConfig.launchDarklyKey,
    {
      anonymous: true,
    },
    {
      privateAttributeNames: ["email"],
    }
  );

  if (!client) return;

  client.on("error", () => {
    // do nothing
  });
};

if (moduleState === "off") {
  initFeatureFlag();
}

const identifyFeatureFlag = (user: IFeatureFlagUser): void => {
  if (moduleState === "disabled") return;

  const profile: LDUser = {
    firstName: user.firstName,
    lastName: user.lastName,
    name: `${user.firstName} ${user.lastName}`.trim(),
    key: `t_${user.id}-b_${user.businessId}`,
    email: user.email,
    custom: {
      tenantId: user.id,
      roles: Array.from(new Set(user.roles)).join(","),
      businessId: user.businessId,
      businessCountry: user.businessCountry,
      businessUrl: user.businessUrl,
      businessCreatedAt: user.businessCreatedAt.toJSON(),
    },
  };

  getClient().identify(profile);
};

const isFlagEnabled = (
  flag: IFlags,
  defaultValue: boolean = false
): boolean => {
  if (moduleState === "disabled") return defaultValue;
  return Boolean(getClient().variation(flag, defaultValue));
};

async function watchFlag(
  flag: IFlags,
  callback: (newVal: boolean, oldValue?: boolean) => void,
  defaultValue: boolean = false
): Promise<void> {
  if (moduleState !== "running") {
    callback(defaultValue);
    return;
  }

  const launchDarkly = getClient();
  await launchDarkly.waitUntilReady();

  const currentValue = isFlagEnabled(flag, defaultValue);
  callback(currentValue);

  launchDarkly.on(`change:${flag}`, callback);
}

async function unWatchFlag(
  flag: IFlags,
  callback: (newVal: boolean, oldValue?: boolean) => void
): Promise<void> {
  if (moduleState !== "running") return;

  const launchDarkly = getClient();
  await launchDarkly.waitUntilReady();

  launchDarkly.off(`change:${flag}`, callback);
}

export {
  initFeatureFlag,
  isFlagEnabled,
  watchFlag,
  unWatchFlag,
  identifyFeatureFlag,
};
