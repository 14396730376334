/* global google */
import { ref } from "@vue/composition-api";
import { IUseGoogleMaps, PromiseReturn } from "@/v2/util/maps/type";

//---- TODO: Remove on Vue 3 migrate [VUE_3_MIGRATE]
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";

Vue.use(VueCompositionApi);
// -----

export interface IListeners {
  [key: string]: (
    e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent
  ) => void;
}

const baseURL = "https://maps.googleapis.com/maps/api/js";

const apiKey = ref("");
const isLoading = ref(false);
const promises: Array<PromiseReturn> = [];

const generateURL = (
  key: string,
  params = [`key=${key}`, "libraries=places,geometry"]
): string => `${baseURL}?${params.join("&")}`;

const isGMapsAvailable = (): Boolean =>
  Boolean(window.google?.maps) && Boolean(window.google?.maps.geometry);

const loadLibrary = (): Promise<void> => {
  if (isGMapsAvailable() || !apiKey.value) {
    return Promise.resolve();
  }

  isLoading.value = true;

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.id = "google-maps-api";
    script.src = generateURL(apiKey.value);
    script.async = true;
    script.defer = true;
    script.onload = (): void => {
      isLoading.value = false;
      promises.forEach((promise) => promise.resolve(window.google.maps));
      resolve();
    };
    script.onerror = (e): void => {
      isLoading.value = false;
      promises.forEach((promise) => promise.reject(e));

      reject(new Error("Google Maps API could not be loaded"));
    };

    document.body.appendChild(script);
  });
};

const getMaps = (): Promise<typeof google.maps> => {
  if (isGMapsAvailable()) {
    return Promise.resolve(window.google.maps);
  }

  if (!isLoading.value) {
    loadLibrary();
  }

  return new Promise((resolve, reject) => promises.push({ resolve, reject }));
};

export const useGoogleMaps = (key: string = ""): IUseGoogleMaps => {
  apiKey.value = key;

  return {
    loadLibrary,
    getMaps,
  };
};

export const addListeners = (
  listeners: IListeners = {},
  map?: google.maps.Map
): void => {
  if (map) {
    Object.keys(listeners).forEach((key) =>
      map.addListener(key, listeners[key])
    );
  }
};
