
































































import { t, tc } from "@/i18n";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { MolRating } from "../rating";
import {
  getRelativeTimeFromNow,
  TimeLabelsTypeEnum,
} from "@chatfood/core-utils";
import { ProductTypeEnum } from "@/v2/repo/reviews";

const css = bemBuilder("mol-feedback-card");

export default defineComponent({
  name: "MolFeedbackCard",
  components: {
    AtomText,
    MolRating,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    rating: {
      type: Number,
      required: true,
    },
    createdAt: {
      type: Date,
      required: true,
    },
    comment: {
      type: String,
      default: null,
    },
    productType: {
      type: String as PropType<ProductTypeEnum>,
      required: true,
    },
    actionLabel: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<(id: string) => {}>,
      required: true,
    },
    onProductLabelClick: {
      type: Function as PropType<(e: Event) => {}>,
      default: () => {},
    },
    onRatingLabelClick: {
      type: Function as PropType<(e: Event) => {}>,
      default: () => {},
    },
  },
  setup(props) {
    const userName = computed(() => {
      if (props.name === "anonymous")
        return t("design_system.mol_feedback_card.anonymous");

      return props.name;
    });

    const ratingList = computed(() => {
      return [...Array(5).keys()].reduce(
        (res: Array<number>, i): Array<number> => [i + 1, ...res],
        []
      );
    });

    const relativeTime = computed(() => {
      const { count, type } = getRelativeTimeFromNow(new Date(props.createdAt));

      if (type === TimeLabelsTypeEnum.YEAR)
        return tc("design_system.mol_feedback_card.year", count);
      if (type === TimeLabelsTypeEnum.MOTH)
        return tc("design_system.mol_feedback_card.month", count);
      if (type === TimeLabelsTypeEnum.WEEK)
        return tc("design_system.mol_feedback_card.week", count);
      if (count > 1) return tc("design_system.mol_feedback_card.day", count);

      return count === 1
        ? t("design_system.mol_feedback_card.yesterday")
        : t("design_system.mol_feedback_card.today");
    });

    return {
      t,
      css,
      userName,
      relativeTime,
      ratingList,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
