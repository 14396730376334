





















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import dayjs from "dayjs";
// @ts-ignore
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

const css = bemBuilder("atom-datepicker-range");

interface IDatepickerRange {
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "AtomDatepickerRange",
  components: {
    VueDatePicker,
  },
  props: {
    value: {
      type: Object as PropType<IDatepickerRange>,
      required: true,
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    onInput: {
      type: Function as PropType<(inputRange?: IDatepickerRange) => void>,
      required: true,
    },
  },
  setup(props) {
    const model = ref<IDatepickerRange>({
      start: dayjs().startOf("day").toDate(),
      end: dayjs().endOf("day").toDate(),
    });

    const inputRange = computed(() => {
      if (!model.value) {
        return undefined;
      }

      return {
        start: dayjs(model.value.start).startOf("day").toDate(),
        end: dayjs(model.value.end).endOf("day").toDate(),
      };
    });

    const pickerEl = ref<HTMLDivElement>();

    function onChange() {
      props.onInput(inputRange.value);
    }

    watch(
      () => props.value,
      (val) => {
        if (val) {
          model.value = val;
        }
      },
      {
        immediate: true,
      }
    );

    return {
      css,
      model,
      onChange,
      pickerEl,
    };
  },
});
