import { prop, gt, propOr, length } from "ramda";
import { sortItBy } from "@/utils/helpers/collections";

/**
 * Get list of available outlets ordered by name
 *
 * @returns {Array}
 */
export const getData = (state) => sortItBy(propOr([], "data", state), "name");

/**
 * Returns if outlets are loaded based on numbers of items in the list
 *
 * @returns {Boolean}
 */
export const isLoaded = ({ data }) => gt(length(data), 0);

/**
 * Return if module is currently in loading state
 *
 * @returns {Boolean}
 */
export const isLoading = prop("loading");
