import { computed, ref } from "@vue/composition-api";

const isOnline = ref<boolean>(window.navigator.onLine);

const setState = (state: boolean): void => {
  isOnline.value = state;
};

window.addEventListener("offline", () => setState(false), {
  passive: true,
});

window.addEventListener("online", () => setState(true), {
  passive: true,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useConnectionState = () => {
  const isOffline = computed(() => !isOnline.value);

  return {
    isOnline,
    isOffline,
  };
};
