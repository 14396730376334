
























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { IAtomButtonLinkProps } from "./props";
import { AtomButtonLinkSizeEnum } from "./size.enum";
import { AtomButtonLinkTypeEnum } from "./type.enum";

const css = bemBuilder("atom-button-link");

export default defineComponent({
  name: "AtomButtonLink",
  props: {
    type: {
      type: String as PropType<AtomButtonLinkTypeEnum>,
      default: AtomButtonLinkTypeEnum.PRIMARY,
    },
    size: {
      type: String as PropType<AtomButtonLinkSizeEnum>,
      default: AtomButtonLinkSizeEnum.MEDIUM,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    route: {
      type: Object as PropType<IAtomButtonLinkProps["route"]>,
      required: true,
    },
  },
  setup(props) {
    function newTabAttrs() {
      return props.openInNewTab
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {};
    }
    return {
      css,
      newTabAttrs,
      AtomButtonLinkTypeEnum,
    };
  },
});
