




/* global google */
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import { IAtomMapMarkerProps } from "./props";
import { injectStrict } from "@/v2/util/maps/type";
import { addListeners, IListeners } from "@/v2/util/maps/google-maps";
import { PIN_ICON } from "./pin";
import { report } from "@chatfood/bug-reporter";

export default defineComponent<IAtomMapMarkerProps>({
  name: "AtomMapMarker",
  props: {
    position: {
      type: Object as PropType<{ lat: number; lng: number }>,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    onContextMenu: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => ({}),
    },
    onClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => ({}),
    },
    onDoubleClick: {
      type: Function as PropType<
        (e?: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => void
      >,
      default: () => ({}),
    },
    onDrag: {
      type: Function as PropType<
        (coordinates: IAtomMapMarkerProps["position"]) => void
      >,
      default: () => ({}),
    },
    onDragStart: {
      type: Function as PropType<
        (coordinates: IAtomMapMarkerProps["position"]) => void
      >,
      default: () => ({}),
    },
    onDragEnd: {
      type: Function as PropType<
        (coordinates: IAtomMapMarkerProps["position"]) => void
      >,
      default: () => ({}),
    },
  },
  setup(props) {
    const map = injectStrict<Ref<google.maps.Map<Element>>>("map");
    const gMaps = injectStrict<Ref<typeof google.maps>>("gMaps");
    const errorHandler = injectStrict<(e: Error) => void>("errorHandler");
    const marker = ref();

    const addMarkerListeners = () => {
      const listeners: IListeners = {
        click: (e) => props.onClick(e),
        dblclick: (e) => props.onDoubleClick(e),
        contextmenu: (e) => props.onContextMenu(e),
        drag: () => props.onDrag(marker.value.getPosition()),
        dragstart: () => props.onDragStart(marker.value.getPosition()),
        dragend: () => props.onDragEnd(marker.value.getPosition()),
      };

      addListeners(listeners, marker.value);
    };

    const mountMarker = () => {
      try {
        marker.value = new gMaps.value.Marker({
          position: props.position,
          draggable: props.draggable,
          icon: PIN_ICON,
        });
        marker.value.setMap(map.value);
        addMarkerListeners();
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    const unmountMarker = () => {
      try {
        // @ts-ignore
        gMaps.value?.event.clearInstanceListeners(marker.value);
        marker.value.setMap(null);
      } catch (e: any) {
        errorHandler(e);
        report(e);
      }
    };

    onMounted(() => {
      mountMarker();
    });

    onUnmounted(() => {
      unmountMarker();
    });

    watch(
      () => props.position,
      (position) => {
        marker.value?.setPosition(position);
      },
      { deep: true }
    );

    watch(
      () => props.draggable,
      (draggable) => {
        marker.value?.setDraggable(draggable);
      },
      { deep: true }
    );
  },
});
