











































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { IMolFormControlProps } from "./props";
import { boolean, string } from "@/v2/util/prop/type";
import { AtomHelpTooltip } from "@/v2/design-system/atom/help-tooltip";
import { AtomText, AtomTextSizeEnum } from "@/v2/design-system/atom/text";

const css = bemBuilder("mol-form-control");

/**
 * @deprecated
 * use `MolFormControl` from `v2/new-design-system` instead
 */

export default defineComponent<IMolFormControlProps>({
  name: "MolFormControl",
  components: {
    AtomHelpTooltip,
    AtomText,
  },
  props: {
    label: string().use(),
    caption: string().use(),
    errorMsg: string().use(),
    successMsg: string().use(),
    tooltip: string().use(),
    required: boolean().use(),
    hint: string().use(),
  },
  setup() {
    return {
      css,
      AtomTextSizeEnum,
    };
  },
  computed: {
    message() {
      return this.errorMsg || this.successMsg || false;
    },
  },
});
