var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('div',{class:_vm.css()},_vm._l((_vm.items),function(item,key){return _c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
      !item.tooltip
        ? false
        : {
            content: item.tooltip,
            trigger: 'click hover focus',
          }
    ),expression:"\n      !item.tooltip\n        ? false\n        : {\n            content: item.tooltip,\n            trigger: 'click hover focus',\n          }\n    "}],key:(key + "-" + (item.value)),class:_vm.css('item'),attrs:{"exact":"","to":{ name: item.routeName },"data-test":("tab-" + key),"active-class":_vm.css('item', 'selected')}},[_c('AtomText',{class:_vm.css('item-title'),attrs:{"data-title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")]),(_vm.showCounters && item.count !== undefined)?_c('AtomText',{class:_vm.css('item-count'),attrs:{"data-test":"counter","type":_vm.AtomTextTypeEnum.SMALL_BOLD,"color":_vm.AtomTextColorEnum.CHATFOOD}},[_vm._v(" "+_vm._s(item.count)+" ")]):_vm._e()],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }