// @ts-nocheck
/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import { prop, has } from "ramda";

Vue.use(Vuex);

/**
 * Check if module is registered.
 *
 * @param {String} namespace
 * @return {Boolean}
 */
if (!Vuex.Store.prototype.hasModule) {
  Vuex.Store.prototype.hasModule = function(namespace) {
    return has(namespace, this._modules.root._children);
  };
}

/**
 * Register module only once.
 *
 * @param {String} namesapce
 * @param {Object} module
 * @param {Object} opts
 * @return {Boolean}
 */
if (!Vuex.Store.prototype.registerModuleOnce) {
  Vuex.Store.prototype.registerModuleOnce = function(namesapce, module, opts) {
    if (this.hasModule(namesapce)) {
      return false;
    }

    return this.registerModule(namesapce, module, opts);
  };
}

const store = new Vuex.Store({
  state: {
    isSoundTested: false,
    globalError: "",
    isInboxConnected: false,
  },
  mutations: {
    SET_IS_SOUND_TESTED(state) {
      state.isSoundTested = true;
    },
    SET_IS_SOUND_UNTESTED(state) {
      state.isSoundTested = false;
    },
    SET_GLOBAL_ERROR(state, error) {
      state.globalError = error;
    },
    SET_IS_INBOX_CONNECTED(state, isConnected) {
      state.isInboxConnected = isConnected;
    }
  },
  getters: {
    isSoundTested: prop("isSoundTested"),
    hasGlobalError({ globalError }) {
      return ["under_maintenance", "unexpected_error"].includes(globalError);
    },
    isUnderMaintenance({ globalError }) {
      return globalError === "under_maintenance";
    },
    hasUnexpectedError({ globalError }) {
      return globalError === "unexpected_error";
    }
  },
  modules
});

export const useStore = () => {
  return {
    state: store.state,
    getters: store.getters,
    dispatch: store.dispatch,
    commit: store.commit
  }
}

export default store
