import {
  compose,
  pick,
  flatten,
  values,
  head,
  length,
  mapObjIndexed,
  isNil,
  includes,
  is,
  forEachObjIndexed,
  filter,
  has,
  pickAll,
  keys,
} from "ramda";
import { uniqAppend } from "ramda-extension";
import { keysToSnakeCase, camelToSnake } from "@/utils/helpers/collections";

class Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} keys
   */
  constructor(keys, entry) {
    // Base resource properties with its defaults.
    this._keys = keys;

    // Validate given.
    this._validateKeys();

    // Build default resource properties.
    this._buildBaseProperties();

    // Feed base properties with the given entry.
    this._feedBaseProperties(entry);

    // Build errors based on the available keys.
    this._errors = mapObjIndexed(() => [], this._keys);

    // Last raw errors
    this._rawErrors = {};
  }

  /**
   * Validate the given keys to avoid using reserved keywords.
   *
   * @returns {void}
   */
  _validateKeys() {
    const protectedKeywords = ["errors", "hasError", "firstError", "getErrors"];

    mapObjIndexed((value, key) => {
      if (includes(key, protectedKeywords)) {
        throw Error(`The keyword '${key}' is protected. Choose another one.`);
      }
    }, this._keys);
  }

  /**
   * Attach the default base properties to the constructor.
   *
   * @returns {void}
   */
  _buildBaseProperties() {
    mapObjIndexed((value, key) => {
      this[key] = null;
    }, this._keys);
  }

  /**
   * Feed base properties based on the given entity.
   *
   * @param {Object} data
   * @returns {void}
   */
  _feedBaseProperties(data) {
    mapObjIndexed((options, key) => {
      if (!is(Array, options)) {
        options = [options];
      }

      const value = head(values(pick(options, data)));
      this[key] = isNil(value) ? null : value;
    }, this._keys);
  }

  /**
   * Get list of errors grouped by key.
   *
   * @returns {Object}
   */
  get errors() {
    return this._errors;
  }

  /**
   * Set errors.
   *
   * @param {Object} errors
   * @returns {void}
   */
  set errors(errors) {
    Object.assign(this._rawErrors, errors);

    for (const [key, value] of Object.entries(this._keys)) {
      this._errors[key] = compose(flatten, values, pick(value))(errors);
    }
  }

  /**
   * Return if model has any errors or not.
   *
   * @returns {Boolean}
   */
  get hasErrors() {
    return length(flatten(values(this._errors))) > 0;
  }

  /**
   * Check if there is any error for the given key.
   *
   * @param {String} key
   * @returns {Boolean}
   */
  hasError(key) {
    return length(this._errors[key]) > 0;
  }

  /**
   * Get first item of a list of errors for the given key.
   *
   * @param {String} key
   * @returns {String}
   */
  firstError(key) {
    return head(this._errors[key]);
  }

  /**
   * Get the entire list of errors for the given key.
   *
   * @param {String} key
   * @returns {Array}
   */
  getErrors(key) {
    return this._errors[key];
  }

  /**
   * Push an error into the array of errors for the given key.
   *
   * @param {String} key
   * @param {String} errorMessage
   * @returns {void}
   */
  setError(key, errorMessage) {
    this._errors[key] = uniqAppend(errorMessage, this._errors[key]);
  }

  /**
   * Remove an error from the array of errors for the given key.
   *
   * @param {String} key
   * @param {String} errorMessage
   * @returns {void}
   */
  removeError(key, errorMessage) {
    this._errors[key] = filter(
      (msg) => msg !== errorMessage,
      this._errors[key]
    );
  }

  /**
   * Clear the array of errors for the given key.
   *
   * @param {String} key
   * @returns {void}
   */
  clearErrors(key) {
    this._errors[key] = [];
  }

  /**
   * Get a clone class instance.
   *
   * @returns {Object} Cloned class instance
   */
  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  /**
   * Return the plain object
   *
   * @returns {Object}
   */
  toObject() {
    return pickAll(keys(this._keys), this);
  }

  /**
   * Converts the given object to FormData.
   *
   * @param {Object} payload
   * @returns {FormData}
   */
  convertToFormData(payload) {
    const formData = new FormData();
    forEachObjIndexed((obj, i) => {
      if (isNil(obj)) return;
      if (is(Boolean, obj)) obj = obj === true ? 1 : 0;

      formData.append(i, obj);
    }, payload);

    return formData;
  }

  /**
   * Convert the given object from CamelCase to
   * SnakeCase and also treat the sort field.
   * Method meant to treat Filter payloads
   *
   * @return {Object}
   */
  static toFilterPayload(obj = {}) {
    obj = keysToSnakeCase(obj);

    if (has("sort", obj)) {
      obj.sort = camelToSnake(obj.sort);
    }

    return obj;
  }
}

export default Base;
