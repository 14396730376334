







































import VueJsModal from "vue-js-modal";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "@vue/composition-api";
import { AtomGraphic } from "@/v2/new-design-system/atom/graphic";

const css = bemBuilder("atom-modal");

export default defineComponent({
  name: "AtomModal",
  components: {
    AtomGraphic,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    positionY: {
      type: Number,
      default: 0.5,
    },
    width: {
      type: String,
      default: "600px",
    },
    height: {
      type: String,
      default: "300px",
    },
    maxHeight: {
      type: Number,
      default: Infinity,
    },
    maxWidth: {
      type: Number,
      default: Infinity,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: () => {},
    },
    overflowVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hoverCloseBtn = ref<boolean>(false);

    const vm = getCurrentInstance();

    onMounted(() => {
      vm?.proxy.$modal.show(props.name);
    });

    return {
      css,
      hoverCloseBtn,
    };
  },
});
