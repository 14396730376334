import { mutationType as fetchType } from "@/store/fetchBase";
import { mutationType as resetType } from "@/store/resetBase";

/** @type {Object} Needed mutations to fetch the resource */
export const FETCH = fetchType;

/** @type {String} Mutation type responsible for resetting the module state */
export const RESET = resetType;

/** @type {String} Mutation type responsible for saving/updating data in the list */
export const SAVE = "save";

/** @type {String} Mutation type responsible for destroing a record from the list */
export const DESTROY = "destroy";

/** @type {String} Mutation type responsible for marking order as acknowledged */
export const ACKNOWLEDGE_ORDER = "acknowledgeOrder";

/** @type {String} Mutation type responsible for adding order to be acknowledged */
export const ORDER_TO_ACKNOWLEDGE = "orderToAcknowledge";

/** @type {String} Mutation type responsible for updating order status */
export const UPDATE_STATUS = "updateStatus";
