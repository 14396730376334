






import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";

const css = bemBuilder("atom-card");

export default defineComponent({
  name: "AtomCard",
  setup() {
    return {
      css,
    };
  },
});
