





































import { t } from "@/i18n";
import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomModal,
} from "../../index";

const css = bemBuilder("mol-fail-modal");
export default defineComponent({
  name: "MolFailModal",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
    };
  },
});
