












































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { Icon } from "@/design-system"; // TODO: Use icon from new design system
import { pathOr } from "ramda"; // TODO: Get rid of this Ramda usage
import { boolean, func, string } from "@/v2/util/prop/type";
import { t } from "@/i18n";

const css = bemBuilder("org-image-drag-selector");

export default defineComponent({
  name: "OrgImageDragSelector",
  components: {
    Icon,
  },
  props: {
    previewImageUrl: string().use(),
    loading: boolean().use(),
    hasErrors: boolean().use(),
    instructionText: string().use(),
    onSelect: func().use(),
    onRemove: func().use(),
  },
  setup() {
    return {
      t,
      css,
    };
  },
  methods: {
    feedFile(event: any) {
      const image = pathOr(null, ["target", "files", 0], event);

      if (!image) return;

      // @ts-ignore
      this.$refs.inputImageDrag.value = "";
      this.onSelect(image);
    },
  },
});
