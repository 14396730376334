<template>
  <div
    class="input"
    data-test="input-box"
    :class="{ 'input--loading': isLoading }"
  >
    <input
      v-mask="mask"
      v-bind="$attrs"
      class="input__field"
      :class="{
        'input__field--error': hasErrors,
        'input__field--positive': isPositive,
      }"
      data-test="input"
      v-on="listeners"
    />
  </div>
</template>

<script>
import { VueMaskDirective } from "v-mask";

/**
 * @deprecated
 * use `AtomInput` from `@/v2/new-design-system` instead
 */
export default {
  name: "Input",
  directives: { mask: VueMaskDirective },
  inheritAttrs: false,
  props: {
    hasErrors: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPositive: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      // generate default mask for any symbols
      default: () => "X".repeat(255),
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  &--loading:after {
    content: "";
    position: absolute;
    top: 21px;
    right: 0;
    width: 20px;
    height: 20px;
    border: 3px solid #00a8f8;
    border-radius: 23px;
    clip: rect(0px 23px 46px 6px);
    mask-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(#00a8f8, 0)),
      to(#00a8f8)
    );
    animation: loading 0.6s linear infinite;
    transform: translate(-50%, -50%);
  }

  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  &__field {
    display: block;
    width: 100%;
    color: #444444;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 11px 16px;
    font-size: 14px;
    line-height: 18px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:not(&--error):focus {
      border-color: #00a8f8;
    }

    &--error {
      border-color: #e42f46;
      color: #e42f46;
    }
  }
}
</style>
