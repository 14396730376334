var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:_vm.css()},[(_vm.hasSummary)?_c('AtomText',{attrs:{"data-test":"summary"}},[_vm._v(_vm._s(_vm.t("design_system.mol_pagination.summary", { from: _vm.firstRecordIndex, to: _vm.lastRecordIndex, total: _vm.total, })))]):_vm._e(),_c('ul',{class:_vm.css('pagination')},[_c('li',{class:[
        _vm.css('page-item'),
        _vm.css('page-item', 'navigation'),
        ( _obj = {}, _obj[_vm.css('page-item', 'disabled')] = _vm.currentPage === 1, _obj ) ],attrs:{"data-test":"prev-page"}},[_c('a',{class:_vm.css('page-link'),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.nextPrev($event, _vm.currentPage - 1)}}},[_c('AtomIcon',{attrs:{"name":"arrow-left","size":10}})],1)]),_vm._l((_vm.pages),function(page,index){
      var _obj;
return _c('li',{key:index,class:[
        _vm.css('page-item'),
        ( _obj = {}, _obj[_vm.css('page-item', 'active')] = _vm.currentPage === page, _obj ) ],attrs:{"data-test":("page-" + page)}},[(page === '...')?_c('span',{class:[_vm.css('page-link'), _vm.css('page-link', 'dots')],attrs:{"data-test":"ellipsis"}},[_vm._v(" "+_vm._s(page)+" ")]):_c('a',{class:_vm.css('page-link'),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.navigate(page)}}},[_vm._v(" "+_vm._s(page)+" ")])])}),_c('li',{class:[
        _vm.css('page-item'),
        _vm.css('page-item', 'navigation'),
        ( _obj$1 = {}, _obj$1[_vm.css('page-item', 'disabled')] = _vm.currentPage === _vm.lastPage, _obj$1 ) ],attrs:{"data-test":"next-page"}},[_c('a',{class:_vm.css('page-link'),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.nextPrev($event, _vm.currentPage + 1)}}},[_c('AtomIcon',{attrs:{"name":"arrow-right","size":10}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }