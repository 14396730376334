/**
 * Returns an image url string with the correct domain host
 *
 * @param imageUrl
 *
 * @returns Returns an image url string with the correct domain host
 */

export function imageURLBuilder(
  imageUrl: string,
  width: number = 438,
  height: number = 220,
  fit: string = "crop",
  crop: string = "center"
): string {
  if (!imageUrl) return "";

  let dimensionParam = `w=${width}&h=${height}`;

  if (!width && !height) {
    dimensionParam = "";
  }

  const url = imageUrl.includes(`${process.env.VUE_APP_S3_BUCKET_PATH}`)
    ? imageUrl
    : `${process.env.VUE_APP_S3_BUCKET_PATH}${imageUrl}`;

  return encodeURI(`${url}?${dimensionParam}&fit=${fit}&crop=${crop}`);
}
