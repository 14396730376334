














































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system/atom/icon";
import { array, boolean, func, number, string } from "@/v2/util/prop/type";
import { t } from "@/i18n";

const css = bemBuilder("mol-input-email-tag");

export default defineComponent({
  name: "MolInputEmailTag",
  components: {
    AtomIcon,
  },
  props: {
    canRepeat: boolean().setDefault(false).use(),
    hasError: boolean().setDefault(false).use(),
    limitEmails: number().use(),
    onInput: func().use(),
    placeholder: string().use(),
    required: boolean().use(),
    value: array().use(),
  },
  setup(props) {
    const isFocused = ref<boolean>(false);
    const isValidInput = ref<boolean>(true);

    const isRequired = computed((): boolean => {
      return props.required && props.value.length === 0;
    });

    const isLimited = computed((): boolean => {
      if (props.limitEmails) {
        return props.value.length >= props.limitEmails;
      }

      return false;
    });

    const errorInput = computed((): boolean => {
      return Boolean(props.hasError || !isValidInput.value);
    });

    function addTag(event: any) {
      const newTag = event.target.value.trim();

      if (newTag.length === 0) return;

      // Prevent form submission only if there is value
      event.preventDefault();

      if (
        !isValidEmail(newTag) ||
        (!props.canRepeat && props.value.includes(newTag))
      ) {
        isValidInput.value = false;
        return;
      }

      const currentTags = props.value;
      currentTags.push(newTag);

      props.onInput(currentTags);
      event.target.value = "";
    }

    function removeTag(index: number) {
      const currentTags = props.value;
      currentTags.splice(index, 1);

      props.onInput(currentTags);
    }

    function removeLastTag(event: any) {
      if (event.target.value) return;
      removeTag(-1);
    }

    function onBlur(event: any) {
      addTag(event);
      isFocused.value = false;
    }

    function checkValidation(event: any) {
      const val = event.target.value.trim();

      if (val) {
        isValidInput.value = true;
      }
    }

    function isValidEmail(value: string): boolean {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(value);
    }

    return {
      css,
      t,
      isFocused,
      isRequired,
      addTag,
      isLimited,
      errorInput,
      checkValidation,
      onBlur,
      removeTag,
      removeLastTag,
    };
  },
});
