import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchActiveOutletCount(
  businessId: string
): Promise<number> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.subscriptionPlansWithRecommendation[0]
      .currentOutletsCount;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
