




import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  PropType,
} from "@vue/composition-api";
import { t } from "@/i18n";
import dayjs from "dayjs";

const css = bemBuilder("atom-live-time-ago");

export default defineComponent({
  name: "AtomLiveTimeAgo",
  props: {
    datetime: {
      type: String,
      required: true,
    },
    refresh: {
      type: Number,
      default: 30,
    },
    long: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String as PropType<"span" | "small">,
      default: "span",
    },
  },
  setup(props) {
    const timeago = ref<string>("");
    const intervalId = ref<number>();

    function reloadTime() {
      timeago.value = props.long
        ? longFormatter(props.datetime)
        : shortFormatter(props.datetime);
    }

    function shortFormatter(datetime: string): string {
      const date = dayjs(datetime);
      const diff = dayjs().diff(date, "second");
      const minute = 60;
      const hour = minute * 60;
      const day = hour * 24;
      const week = day * 7;

      let readDate;

      if (diff < minute) {
        readDate = t("design_system.atom_live_time_ago.now");
      } else if (diff < hour) {
        readDate =
          Math.round(diff / minute) +
          t("design_system.atom_live_time_ago.minute_shorter");
      } else if (diff < day) {
        readDate =
          Math.round(diff / hour) +
          t("design_system.atom_live_time_ago.hour_shorter");
      } else if (diff < week) {
        readDate =
          Math.round(diff / day) +
          t("design_system.atom_live_time_ago.day_shorter");
      } else {
        readDate =
          Math.round(diff / week) +
          t("design_system.atom_live_time_ago.week_shorter");
      }

      return readDate;
    }

    function longFormatter(datetime: string): string {
      return dayjs(datetime).fromNow();
    }

    watch([() => props.datetime, () => props.long], () => reloadTime());

    onMounted(() => {
      reloadTime();
      intervalId.value = window.setInterval(reloadTime, props.refresh * 1000);
    });

    onBeforeUnmount(() => {
      if (intervalId.value) clearInterval(intervalId.value);
    });

    return {
      css,
      timeago,
    };
  },
});
