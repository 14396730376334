import { IAnalytics } from "@/plugins/analytics/types";
import { EventCategoryEnum, EventEnum } from "@/v2/enum";
import { useHelpers } from "@/v2/composable/use-helpers";
import { IOutlet } from "@/v2/module/outlet/list/props";
import UIState from "@/modules/outlets/state/ui";
import { useStore } from "@/store";

export interface ITrackEvent {
  analytics: IAnalytics;
  eventName: EventEnum;
  additionalData?: Record<string, any>;
}

const commonData: Record<string, any> = {
  outlet: (outletId: string) => {
    const { getters } = useStore();
    const outlet = getters["outlets/getData"].find(
      (outlet: IOutlet) => outletId === outlet.id
    );

    return {
      outlet_id: outletId,
      outlet_name: outlet?.name || UIState?.outletName.value,
    };
  },
  business: (
    businessId: string
  ): { business_id: string; business_name: string } | {} => {
    const business = useHelpers().getCurrentBusiness(businessId);

    if (!business) return {};
    return { business_id: business.id, business_name: business.name };
  },
};

export const analyticsEventCommonData = (
  list: Array<[string, string]>
): {
  [key: string]: string;
} =>
  list.reduce((result: Record<string, any>, item: [string, string]) => {
    const [key, value] = item;
    const data = commonData[key];
    return data ? { ...result, ...data(value) } : result;
  }, {});

export const trackEvent = ({
  analytics,
  eventCategory,
  eventName,
  additionalData = {},
}: ITrackEvent & { eventCategory: EventCategoryEnum }): void => {
  if (!analytics || !analytics.trackEvent) return;

  // @ts-ignore
  analytics.trackEvent(eventName, {
    event_category: eventCategory,
    ...additionalData,
  });
};
