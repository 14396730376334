<template>
  <div
    class="selector-group"
    :class="[
      { 'selector-group--error': hasErrors },
      { 'selector-group--focus': hasFocus },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "InlineSelectorGroup",
  props: {
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hasFocus: false,
  }),
  mounted() {
    this.$on("hasFocus", () => {
      this.hasFocus = true;
    });
    this.$on("removeFocus", () => {
      this.hasFocus = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.selector-group {
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  display: flex;
  width: fit-content;
  align-items: center;

  &:not(&--error).selector-group--focus {
    border-color: #00a8f8;
  }

  &--error {
    border-color: #e42f46;
    color: #e42f46;
  }
}
</style>
