import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";

export class FetchPermissionsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPermissions");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPermissions(
  organizationId: string
): Promise<Array<string>> {
  let response: AxiosResponse;

  try {
    response = await http.get(`api/v3/me/permissions`, {
      params: { organization_id: organizationId },
    });
  } catch (err: any) {
    const isAxiosError = Boolean(err.isAxiosError);

    if (!isAxiosError) {
      throw err;
    }

    throw new FetchPermissionsError(err);
  }

  return response?.data;
}
