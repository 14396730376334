import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { CurrencyCodeEnum } from "@/v2/enum";

export async function fetchPlans(
  businessId: string
): Promise<IFetchPlansResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.subscriptionPlansWithRecommendation;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

interface Plan {
  estimatedCost: number;
  isRecommended: boolean;
  currentOutletsCount: number;
  plan: {
    additionalOrderPrice: number;
    additionalOutletPrice: number;
    currency: CurrencyCodeEnum;
    id: string;
    maximumActiveOutlets: number;
    maximumMonthlyOrders: number;
    name: string;
    price: number;
    priceId: string;
  };
}

export type IFetchPlansResponse = Array<Plan>;
