


















import { bemBuilder } from "@/v2/util/bem-builder";
import { boolean, func, string } from "@/v2/util/prop/type";
import { isOneOf } from "@/v2/util/prop/validator";
import { defineComponent } from "@vue/composition-api";
import { IAtomButtonProps } from "./props";
import { AtomButtonSizeEnum } from "./size.enum";
import { AtomButtonTypeEnum } from "./type.enum";

const css = bemBuilder("atom-button");

/**
 * @deprecated
 * use `AtomButton` from `v2/new-design-system`
 */
export default defineComponent<IAtomButtonProps>({
  name: "AtomButton",
  props: {
    type: string()
      .setDefault(AtomButtonTypeEnum.PRIMARY)
      .setValidator(isOneOf(Object.values(AtomButtonTypeEnum)))
      .use(),
    size: string()
      .setDefault(AtomButtonSizeEnum.MD)
      .setValidator(isOneOf(Object.values(AtomButtonSizeEnum)))
      .use(),
    isLoading: boolean().use(),
    onClick: func().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
