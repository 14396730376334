




















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import OpenIndicator from "./assets/OpenIndicator.vue";
import Deselect from "./assets/Deselect.vue";
import { IAtomDropdownProps } from "./props";

const css = bemBuilder("atom-dropdown");

type IOption = IAtomDropdownProps["options"][0];

export default defineComponent({
  name: "AtomDropdown",
  components: {
    vSelect,
  },
  props: {
    options: {
      type: Array as PropType<IAtomDropdownProps["options"]>,
      default: () => [],
    },
    value: {
      type: Object as PropType<IOption>,
      default: () => undefined,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    select: (payload: IOption) => true,
  },
  setup(props, { emit }) {
    const model = ref();

    const onSelected = (option: IOption) => {
      emit("select", option);
    };

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
      { immediate: true }
    );

    return {
      model,
      onSelected,
      css,
      OpenIndicator,
      Deselect,
    };
  },
});
