















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import AudioNotification from "@/components/helpers/AudioNotification.vue";
import { sidebarIsCollapsed } from "./state";
import { sidebarMethodUpdateFavicon } from "./method";
import { listenFromTenant } from "@/v2/core/broadcasting";
import { useLiveOrdersPulling } from "@/v2/composable/live-orders-pulling";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("sidebar-menu-item-badge");

export default defineComponent({
  name: "SidebarLiveOrdersBadge",
  components: {
    AudioNotification,
  },
  setup() {
    const incomingOrdersAmount = ref<number>(0);

    const vm = getCurrentInstance();
    const store = vm?.proxy.$store!;

    onMounted(async () => {
      await listenToLiveOrdersEvents();
    });

    watch(
      () => store.getters["liveOrders/getIncomingOrders"],
      (incomingOrders) => {
        incomingOrdersAmount.value = incomingOrders.length;
        sidebarMethodUpdateFavicon(incomingOrdersAmount.value);
      },
      { immediate: true }
    );

    const liveOrders = computed(() => {
      return store.getters["liveOrders/getData"];
    });

    const { start: startPulling } = useLiveOrdersPulling();

    async function listenToLiveOrdersEvents() {
      const userId = store.getters["auth/getId"];

      try {
        await listenFromTenant(
          userId,
          [
            "order.new",
            "order.accepted",
            "order.marked-as-ready",
            "order.complete",
            "order.rejected",
            "order.closed",
            "order.cancelled",
          ],
          eventHandler
        );
      } catch (e: any) {
        report(
          "Activate pulling after tenant channel subscription has failed",
          {
            context: {
              error: e,
            },
          }
        );

        startPulling();
      }
    }

    function logFailure(
      message: string,
      error: any,
      orderId: string,
      connectionState: boolean
    ) {
      report(message, {
        context: {
          error: error,
          "Connection State": connectionState ? "online" : "offline",
        },
        tags: {
          "order.id": orderId,
          "http.response_code": error?.statusCode,
        },
      });
    }

    async function retryFetch(orderId: string) {
      try {
        await store.dispatch("liveOrders/fetch");
      } catch (e: any) {
        logFailure(
          "Retry to fetch list of live order failed",
          e,
          orderId,
          window.navigator.onLine
        );
      }
    }

    async function eventHandler({ id }: { id: string }): Promise<void> {
      const order = liveOrders.value.find(
        (obj: Record<string, any>) => obj.id === id
      );

      if (!order) {
        store.dispatch("liveOrders/addOrderToAcknowledge", id);
      }

      const connectionState = window.navigator.onLine;

      try {
        await store.dispatch("liveOrders/fetch");
      } catch (e: any) {
        logFailure(
          "Could not fetch the list of live orders after receiving a push notification",
          e,
          id,
          connectionState
        );
        setTimeout(() => {
          retryFetch(id);
        }, 30000);
      }
    }

    return {
      css,
      sidebarIsCollapsed,
      incomingOrdersAmount,
    };
  },
});
