


























import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";

const css = bemBuilder("atom-input");

export default defineComponent({
  name: "AtomInput",
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<
        (val: string, selStart: number | null | undefined) => {}
      >,
      default: () => {},
    },
    onFocus: {
      type: Function as PropType<
        (val: string, selStart: number | null | undefined) => {}
      >,
      default: () => {},
    },
    cursorPosition: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
  },
  setup(props, ctx) {
    ctx.root.$listeners;

    watch(
      () => props.cursorPosition,
      (position) => {
        if (position && inputEl.value?.selectionStart) {
          inputEl.value.selectionStart = position;
          inputEl.value.selectionEnd = position;
        }
      }
    );

    const listeners = computed(() => {
      return {
        ...ctx.root.$listeners,
        input: (event: any) =>
          props.onChange(event.target.value, inputEl.value?.selectionStart),
        focus: (event: any) =>
          props.onFocus(event.target.value, inputEl.value?.selectionStart),
      };
    });

    const inputEl = ref<HTMLInputElement>();
    onMounted(() => {
      // @ts-ignore
      props.onChange(props.value, inputEl.value?.selectionStart);
    });

    return {
      listeners,
      inputEl,
      css,
    };
  },
});
