









import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon, AtomText, AtomTextColorEnum } from "@/v2/new-design-system";
import { usePreviousRoute } from "@/v2/composable/use-previous-route";
import { useRouter } from "@/router";
import { RawLocation } from "vue-router";

const css = bemBuilder("mol-back-nav");

export default defineComponent({
  name: "MolBackNav",
  components: {
    AtomIcon,
    AtomText,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: AtomTextColorEnum.RAINY,
    },
    route: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
    forceRoute: {
      type: Boolean,
      default: false,
    },
    avoidRouteName: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const iconColor = computed(() => {
      return `var(--color-${props.color})`;
    });

    const router = useRouter();
    const prevRoute = usePreviousRoute().getRoute();

    const useFallbackRoute = computed(
      () =>
        props.forceRoute ||
        !prevRoute ||
        props.avoidRouteName === prevRoute.name
    );

    const resolvedPath = computed(() => {
      let routeConfig: RawLocation | undefined;

      if (useFallbackRoute.value) {
        routeConfig = props.route;
      } else if (prevRoute) {
        routeConfig = {
          name: prevRoute.name,
          params: prevRoute.params,
          query: prevRoute.query,
          hash: prevRoute.hash,
        };
      } else {
        routeConfig = undefined;
      }

      if (!routeConfig) {
        throw new Error("MolBackNav: RouteConfig not found");
      }

      return router.resolve(routeConfig).href;
    });

    const onClick = (event: MouseEvent) => {
      if (event.metaKey || event.ctrlKey) {
        window.open(resolvedPath.value, "_target");
        return;
      }

      if (useFallbackRoute) {
        router.push(resolvedPath.value);
      } else {
        router.back();
      }
    };

    return {
      css,
      iconColor,
      resolvedPath,
      AtomTextColorEnum,
      onClick,
    };
  },
});
