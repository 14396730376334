export function isOneOf(itemArray: Array<any>): (value: any) => boolean {
  return (value: any): boolean => {
    if (!itemArray.includes(value)) {
      return false;
    }

    return true;
  };
}

export function isInteger(value: any): boolean {
  if (!Number.isInteger(value)) {
    return false;
  }

  return true;
}
