















import { boolean, func, string } from "@/v2/util/prop/type";
import { defineComponent } from "@vue/composition-api";
import { AtomModal, AtomIcon } from "@/v2/design-system";
import { IMolTypeformModalProps } from "./props";

export default defineComponent<IMolTypeformModalProps>({
  name: "MolTypeformModal",
  components: {
    AtomModal,
    AtomIcon,
  },
  props: {
    show: boolean().isRequired().setDefault(false).use(),
    typeFormLink: string().isRequired().use(),
    onClickClose: func().isRequired().use(),
  },
});
