




import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AtomGraphic",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    graphicSize(): { width: string; height: string } {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    graphic() {
      // template literals doesn't work well with dynamic import on jest
      return () =>
        import(
          /* webpackChunkName: "icon-[request]" */ "./assets/" +
            this.name +
            ".svg"
        );
    },
  },
});
