





















import { bemBuilder } from "@/v2/util/bem-builder";
import { boolean, func, string } from "@/v2/util/prop/type";
import { defineComponent } from "@vue/composition-api";
import { MolFormControl } from "@/v2/design-system/mol/form-control";
import { OrgImageDragSelector } from "@/v2/design-system/org/image-drag-selector";

const css = bemBuilder("org-form-image-drag-selector");

export default defineComponent({
  name: "OrgFormImageDragSelector",
  components: {
    OrgImageDragSelector,
    MolFormControl,
  },
  props: {
    label: string().use(),
    errorMsg: string().use(),
    successMsg: string().use(),
    tooltip: string().use(),
    caption: string().use(),
    required: boolean().use(),
    hint: string().use(),
    previewImageUrl: string().use(),
    loading: boolean().use(),
    instructionText: string().use(),
    onRemove: func().use(),
    onSelect: func().use(),
  },
  setup(props) {
    return {
      css,
    };
  },
});
