




















import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";

const css = bemBuilder("header-info");

export default defineComponent({
  name: "OrgHeaderInfo",
  components: {
    AtomText,
  },
  props: {
    heading: {
      type: String,
      default: "",
    },
    subHeading: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      css,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
