import VueApollo from "vue-apollo";
import {
  ApolloClient,
  ApolloLink,
  concat,
  InMemoryCache,
  gql,
} from "@apollo/client/core";
import { jwtToken } from "@/utils/helpers/functions";
import { createUploadLink } from "apollo-upload-client";

// HTTP connection to the API
const httpUploadLink = createUploadLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`,
});

// Auth middleware to add authorization header
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${jwtToken.get()}`,
    },
  });
  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpUploadLink),
  cache: new InMemoryCache(),
  defaultOptions,
  typeDefs: gql`
    enum DayOfWeek {
      SUNDAY
      MONDAY
      TUESDAY
      WEDNESDAY
      THURSDAY
      FRIDAY
      SATURDAY
    }
    enum SalesChannelEnum {
      WEBSITE
      FACEBOOK
      INSTAGRAM
    }
    enum OrderingServiceEnum {
      DELIVERY
      PICKUP
      DINE_IN
    }
    enum OrderingServiceStateEnum {
      ENABLED
      DISABLED
      PAUSED
    }
    enum OutletStateEnum {
      ACTIVE
      INACTIVE
      DRAFT
    }
    enum PaymentMethodEnum {
      CARD
      CASH
      PREPAID
    }
    enum DeliverySchedule {
      SAME_DAY
      NEXT_DAY
      BOTH
    }
    enum DiscountVisibility {
      AUTOMATIC
      MANUAL
      HIDDEN
    }
    enum DiscountType {
      PERCENTAGE
      FIXED_VALUE
    }
    enum DiscountSegmentation {
      ALL
      FIRST_ORDERS
    }
    enum SalesChannelEnum {
      WEBSITE
      FACEBOOK
      INSTAGRAM
      MESSENGER
      GOOGLEMYBUSINESS
      WHATSAPP
    }
  `,
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
