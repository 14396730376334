var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css('')},[(_vm.showFullFunctionality)?_c('div',{class:_vm.css('search-bar')},[_c('MolSearchBox',{attrs:{"placeholder":_vm.t('module.sidebar.brand_switcher.search'),"size":_vm.MolSearchBoxEnum.SMALL,"on-input":_vm.handleSearch}})],1):_vm._e(),_c('ul',{class:[
      _vm.css('brands'),
      ( _obj = {}, _obj[_vm.css('brands', 'scrollable')] = _vm.showFullFunctionality, _obj ) ]},[(_vm.brandList.length)?_vm._l((_vm.brandList),function(brand){
    var _obj, _obj$1;
return _c('li',{key:brand.id},[_c('a',{class:[
            _vm.css('brand'),
            ( _obj = {}, _obj[_vm.css('brand', 'active')] = _vm.isActive(brand.id), _obj ) ],attrs:{"href":("/business/" + (brand.id))},on:{"click":[function($event){if(!$event.metaKey){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey){ return null; }return (function () { return _vm.handleClick(brand); }).apply(null, arguments)},function($event){if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function () { return _vm.handleClick(brand); }).apply(null, arguments)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function () { return _vm.handleClick(brand); }).apply(null, arguments)}]}},[_c('SidebarBrandLogo',{class:_vm.css('icon'),attrs:{"logo":brand.logo}}),_c('AtomText',{class:[
              _vm.css('label'),
              ( _obj$1 = {}, _obj$1[_vm.css('label', 'active')] = _vm.isActive(brand.id), _obj$1 ) ]},[_vm._v(" "+_vm._s(brand.label)+" ")])],1)])}):(!_vm.brandList.length)?_c('li',{class:_vm.css('brand')},[_c('AtomText',{attrs:{"color":_vm.AtomTextColorEnum.RAINY}},[_vm._v(" "+_vm._s(_vm.t("module.sidebar.brand_switcher.no_results"))+" ")])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }