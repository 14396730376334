<template>
  <div
    class="select"
    data-test="select-box"
    :class="{ 'select--error': hasErrors }"
  >
    <select class="select__field" v-bind="$attrs" v-on="listeners">
      <slot />
      <option v-for="(item, index) in options" :key="index" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
</template>

<script>
/**
 * @deprecated
 * use `AtomSelect` from `@/v2/new-design-system` instead
 */

export default {
  name: "Select",
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    hasErrors: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    right: 15px;
    pointer-events: none;
    border: solid 5px transparent;
    border-top-color: #84a0b2;
  }

  &__field {
    display: block;
    width: 100%;
    color: #444444;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 11px 30px 11px 16px;
    font-size: 14px;
    background: #fff;
    line-height: 18px;

    &:not(&--error):focus {
      border-color: #00a8f8;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &--error {
    &:after {
      border-top-color: #e42f46;
    }

    .select__field,
    .select__field:focus {
      border-color: #e42f46;
      color: #e42f46;
    }
  }
}
</style>
