export enum OrderStatusEnum {
  CREATED = "created",
  ACCEPTED = "accepted",
  READY = "ready",
  PLACED = "placed",
  DELIVERED = "delivered",
  COMPLETED = "complete",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
}
