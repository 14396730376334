













import { AtomButton } from "@/v2/new-design-system/atom/button";
import { bemBuilder } from "@/v2/util/bem-builder";
import { boolean, func, string } from "@/v2/util/prop/type";
import { defineComponent } from "@vue/composition-api";
import { AtomButtonTypeEnum } from "../button/type.enum";
import { AtomButtonSizeEnum } from "../button/size.enum";

const css = bemBuilder("atom-button-cost");

export default defineComponent({
  name: "AtomButtonCost",
  components: {
    AtomButton,
  },
  props: {
    name: string().isRequired().use(),
    cost: string().isRequired().use(),
    onClick: func().isRequired().use(),
    disabled: boolean().use(),
  },
  setup() {
    return {
      css,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
