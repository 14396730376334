




import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { IMolCreditCardPreviewProps } from "./props";

const css = bemBuilder("mol-credit-card-preview");

export default defineComponent<IMolCreditCardPreviewProps>({
  name: "MolCreditCardPreview",
  setup() {
    return {
      css,
    };
  },
});
