import { useStore } from "@/store";

let pulling: number;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useLiveOrdersPulling = () => {
  const { dispatch } = useStore();

  const twoMinutes = 1000 * 60 * 2;

  const start = (timeout: number = twoMinutes): void => {
    if (pulling) {
      stop();
    }

    pulling = window.setInterval(() => {
      dispatch("liveOrders/fetch");
    }, timeout);
  };

  const stop = (): void => {
    window.clearInterval(pulling);
  };

  const get = (): number => {
    return pulling;
  };

  return {
    start,
    stop,
    get,
  };
};
