import Dinero from "dinero.js";
import { CurrencyPrecisionEnum } from "@/v2/enum/currency-precision.enum";
import { CurrencyCodeEnum } from "../enum/currency-code.enum";
import DineroFactory from "dinero.js";

/**
 * Format cents integer to locale aware currency format.
 *
 * @param {Integer} amount
 * @param {CurrencyCodeEnum} code
 * @param {String} format
 * @param {String} locale
 *
 * @returns {String}
 */
export function toLocaleCurrency(
  amountInCents: number,
  code: CurrencyCodeEnum = CurrencyCodeEnum.USD,
  format = "$0,0",
  locale = "en-US"
): string {
  if (!Number.isInteger(amountInCents)) {
    throw TypeError(
      `Amount (${amountInCents}) must be a integer. Given ${typeof amountInCents} instead.`
    );
  }

  const precision = CurrencyPrecisionEnum[code] ?? 2;
  let money = Dinero({
    amount: amountInCents,
    precision,
    currency: code as DineroFactory.Currency,
  });
  // Set Dinero locale for output formatted strings
  money = money.setLocale(locale);
  if (precision > 0) {
    format += "." + "0".repeat(precision);
  }

  return money.toFormat(format);
}
