import { http } from "../http";
import { IRepoErrors } from "../errors";

export class CreateBillingCustomerPortalSessionError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: createBillingCustomerPortalSession");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function createBillingCustomerPortalSession(
  businessId: string,
  subscriptionId: string,
  returnUrl: string
): Promise<{ sessionURL: string }> {
  try {
    const response = await http.post(
      `/api/v3/businesses/${businessId}/subscriptions/${subscriptionId}/billing-portal-session`,
      {
        return_url: returnUrl,
      }
    );
    return { sessionURL: response.data?.session_url };
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateBillingCustomerPortalSessionError(error);
  }
}
