<template>
  <component
    :is="component.tag"
    class="anchor"
    :class="{
      'anchor--button': button,
      'anchor--primary': primary,
      'anchor--outline-primary': outlinePrimary,
    }"
    :[component.attr]="link"
    data-test="anchor"
    :title="linkTitle"
    @click="openModal($event)"
  >
    <slot />
  </component>
</template>

<script>
import { ExternalContent } from "@/design-system";
import { pathOr } from "ramda";

/**
 * @deprecated
 * use `AtomAnchor` from `@/v2/new-design-system` instead
 */

export default {
  name: "Anchor",
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    outlinePrimary: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    isForModal() {
      return this.modal;
    },
    linkTitle() {
      return this.title ?? pathOr("", ["default", 0, "text"], this.$slots);
    },
    isPlainUrl() {
      return (
        typeof this.link === "string" && this.link.match(/^(http(s)?|mailto):/)
      );
    },
    component() {
      if (this.isForModal || this.isPlainUrl) {
        return {
          tag: "a",
          attr: "href",
        };
      }

      return {
        tag: "router-link",
        attr: "to",
      };
    },
  },
  methods: {
    openModal($event) {
      if (!this.isForModal) return;

      $event.preventDefault();

      this.$modal.show(
        ExternalContent,
        {
          title: this.linkTitle,
          link: this.link,
        },
        {
          name: "external_content",
          width: "80%",
          height: "80%",
          adaptive: true,
          resizable: true,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor {
  $anchor: &;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  user-select: none;
  background-color: transparent;
  color: var(--color-black);
  transition: all 0.15s ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  &--primary {
    color: var(--color-brand-primary);

    &:hover {
      color: #00a0ec;
    }

    &:focus {
      color: #0097df;
    }
  }

  &--button {
    text-align: center;
    vertical-align: middle;
    background-color: var(--color-brand-primary);
    color: var(--color-white);
    padding: 12px 19px;
    font-weight: 600;
    border-radius: 4px;

    &:hover {
      color: var(--color-white);
      text-decoration: none;
    }

    &:focus {
      color: var(--color-white);
      text-decoration: none;
      box-shadow: 0 0 0 0.2rem rgba(var(--color-black-rgb), 0.5);
    }

    &#{ $anchor }--primary {
      background-color: #00a8f8;
      color: var(--color-white);

      &:hover {
        background-color: #00a0ec;
      }

      &:active,
      &:focus {
        background-color: #0097df;
        box-shadow: 0 0 0 0.2rem rgba(var(--color-brand-primary-rgb), 0.5);
      }
    }
  }

  &--outline-primary {
    background-color: var(--color-white);
    color: #00a8f8;
    border: solid 1px #00a8f8;

    &:hover {
      color: var(--color-white);
      background-color: #00a0ec;
    }

    &:focus {
      border-color: #00a8f8;
      box-shadow: 0 0 0 0.2rem rgba(var(--color-brand-primary-rgb), 0.5);
    }
  }
}
</style>
