import {
  VIEW_CHANNELS,
  EDIT_CHANNEL,
  VIEW_WIDGET,
} from "@/utils/enums/permissions";

export default [
  {
    path: "/business/:businessId/sales-channels",
    component: () =>
      import(
        /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/Index.vue"
      ),
    props: true,
    children: [
      {
        name: "sales_channels.index",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/listing/Listing.vue"
          ),
        props: true,
        meta: {
          title: "Sales Channels",
          permission: VIEW_CHANNELS,
        },
      },
      {
        path: "facebook/profile",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/facebook/Index.vue"
          ),
        props: true,
        children: [
          {
            name: "sales_channels.facebook.index",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/facebook/Profile.vue"
              ),
            props: true,
            meta: {
              title: "Facebook - Profile",
              permission: EDIT_CHANNEL,
            },
          },
          {
            name: "sales_channels.facebook.post",
            path: "/business/:businessId/sales-channels/facebook/post",
            component: () =>
              import(
                /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/facebook/Post.vue"
              ),
            props: true,
            meta: {
              title: "Facebook - Post",
              permission: EDIT_CHANNEL,
            },
          },
        ],
      },
      {
        name: "sales_channels.messenger.index",
        path: "messenger",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/messenger/Messenger.vue"
          ),
        props: true,
        meta: {
          title: "Messenger",
          permission: EDIT_CHANNEL,
        },
      },
      {
        name: "sales_channels.instagram.index",
        path: "instagram",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/instagram/Instagram.vue"
          ),
        props: true,
        meta: {
          title: "Instagram",
          permission: EDIT_CHANNEL,
        },
      },
      {
        name: "sales_channels.instagram.food_button",
        path: "/business/:businessId/sales-channels/instagram/food-button",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/instagram/OrderFoodButton.vue"
          ),
        props: true,
        meta: {
          title: "Instagram - Order Food Button",
          permission: EDIT_CHANNEL,
        },
      },
      {
        name: "sales_channels.instagram.stories_sticker",
        path: "/business/:businessId/sales-channels/instagram/stories-sticker",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/instagram/StoriesSticker.vue"
          ),
        props: true,
        meta: {
          title: "Instagram - Stories ordering sticker",
          permission: EDIT_CHANNEL,
        },
      },
      {
        name: "sales_channels.google.index",
        path: "google-my-business",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/googlemybusiness/GoogleMyBusiness.vue"
          ),
        props: true,
        meta: {
          title: "Google My Business",
          permission: EDIT_CHANNEL,
        },
      },
      {
        name: "sales_channels.whatsapp.index",
        path: "whatsapp",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/whatsapp/WhatsApp.vue"
          ),
        props: true,
        meta: {
          title: "WhatsApp",
          permission: EDIT_CHANNEL,
        },
      },
      {
        name: "sales_channels.website.index",
        path: "website/install",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/sales-channels/ui/views/widget/Install.vue"
          ),
        props: true,
        meta: {
          title: "Widget - Install",
          permission: VIEW_WIDGET,
        },
      },
    ],
  },
];
