// @ts-nocheck
import Vue from "vue";
import store from "@/store";
import { isNil, pathOr } from "ramda";
import { jwtToken, bootstrap } from "@/utils/helpers/functions";
import ServiceUnavailableError from "@/api/errors/ServiceUnavailableError";
import { report } from "@chatfood/bug-reporter";
import { usePreviousRoute } from "@/v2/composable/use-previous-route";

/**
 * @param {Object} to VueRouter with details of the next route
 * @param {Object} from VueRouter with details of the previous route
 * @param {Function} next VueRouter function to call next middleware
 */
// eslint-disable-next-line
export default async (to, from, next) => {
  if (from.name) {
    const { updateRoute } = usePreviousRoute();
    updateRoute({
      name: from.name,
      query: from.query,
      params: from.params,
      hash: from.hash,
      path: from.path,
    });
  }

  const isAuthRequired = pathOr(true, ["meta", "isAuthRequired"], to);
  const isTenantLoaded = store.getters["auth/isLoaded"];

  // Skip bootstrap application if route doesn't require
  // authentication or tenant is already loaded or if it's a 500 error page
  if (!isAuthRequired || isTenantLoaded || to.name === "error.500") {
    return next();
  }

  const token = jwtToken.get();

  // Bootstrap application or redirect to login page in case of any exception
  try {
    // Throw an error in case token is not available
    if (isNil(token)) {
      // Except redirect to the login page if the user was logged out due to auth issue
      return next({
        name: "auth.login",
        query: to.fullPath !== "/" ? { goTo: to.fullPath } : null,
      });
    }

    // Call the method to bootstrap the aplication
    await bootstrap(token, store.dispatch, {
      gates: Vue.gates,
      intercom: Vue.intercom,
      hotjar: Vue.hotjar,
      analytics: Vue.analytics,
      urlParams: to.params,
    });
  } catch (e) {
    if (e instanceof ServiceUnavailableError) {
      store.commit("SET_GLOBAL_ERROR", "under_maintenance");
      return next();
    }

    // Show error page for all errors
    if (token) {
      store.commit("SET_GLOBAL_ERROR", "unexpected_error");
      report(e);
      return next();
    }
  }

  // Proceed to the next route
  return next();
};
