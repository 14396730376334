


















import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system/atom/text";
import { bemBuilder } from "@/v2/util/bem-builder";
import { func, number } from "@/v2/util/prop/type";
import { computed, defineComponent } from "@vue/composition-api";

const css = bemBuilder("atom-tally-counter");

export default defineComponent({
  name: "AtomTallyCounter",
  components: {
    AtomText,
  },
  props: {
    onCountUp: func().isRequired().use(),
    onCountDown: func().isRequired().use(),
    count: number().use(),
  },
  setup(props) {
    const countValue = computed(() =>
      isNaN(props.count) || props.count <= 0 ? 0 : props.count
    );
    const handleCountDown = () => {
      const value = countValue.value > 0 ? countValue.value - 1 : 0;
      props.onCountDown(value);
    };
    const handleCountUp = () => {
      const value = countValue.value + 1;
      props.onCountUp(value);
    };

    return {
      countValue,
      handleCountDown,
      handleCountUp,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      css,
    };
  },
});
