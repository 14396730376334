




































































import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
} from "@vue/composition-api";
import { AtomCollapseIndicator } from "@/v2/design-system/atom/collapse-indicator";
import { AtomText, AtomTextSizeEnum } from "@/v2/design-system/atom/text";
import { AtomCheckbox } from "@/v2/design-system/atom/checkbox";
import { AtomMoney } from "@/v2/design-system/atom/money";
import {
  IOrgCollapsableSelectListCategory,
  IOrgCollapsableSelectListProps,
} from "./props";

const css = (element: string = "", modifier: string = ""): string =>
  `org-collapsable-select-list${element ? `__${element}` : ""}${
    modifier ? `--${modifier}` : ""
  }`;

/**
 * @deprecated
 * use `OrgCollapsableSelectList` from `v2/new-design-system` instead
 */

export default defineComponent({
  name: "OrgCollapsableSelectList",
  components: {
    AtomCollapseIndicator,
    AtomCheckbox,
    AtomText,
    AtomMoney,
  },
  props: {
    data: {
      type: Array as PropType<IOrgCollapsableSelectListProps["data"]>,
      required: true,
    },
    filter: {
      type: String,
      default: "",
    },
    noItemsMessage: {
      type: String,
      default: "No items",
    },
    currencyCode: {
      type: String,
      default: "AED",
    },
    selectedItems: {
      type: Array as PropType<IOrgCollapsableSelectListProps["selectedItems"]>,
      default: () => [],
    },
    onChange: {
      type: Function as PropType<IOrgCollapsableSelectListProps["onChange"]>,
      required: true,
    },
  },
  setup(props) {
    // Define state
    const categoryOpenState = props.data.map(() => ref(true));
    const selectedItemsSet = ref(new Set(props.selectedItems));

    watch(
      () => props.selectedItems,
      (val, prevVal) => {
        const updatedItemSelection = new Set(val);
        if (updatedItemSelection.size != new Set(prevVal).size) {
          selectedItemsSet.value = updatedItemSelection;
        }
      }
    );

    function toggleOpenState(categoryIndex: number) {
      const categoryState = categoryOpenState[categoryIndex];
      categoryState.value = !categoryState.value;
    }

    function isCategoryOpen(categoryIndex: number) {
      return categoryOpenState[categoryIndex].value;
    }

    // Checks if a category has partially selected items
    function categoryCheckboxPartialStyle(
      category: IOrgCollapsableSelectListCategory
    ): boolean {
      return category.items.some(
        (value) => !selectedItemsSet.value.has(value.id)
      );
    }

    // Checks if a category has any items selected at all
    function categoryCheckboxChecked(
      category: IOrgCollapsableSelectListCategory
    ): boolean {
      return category.items.some((value) =>
        selectedItemsSet.value.has(value.id)
      );
    }

    // Toggles the selection of an item
    function toggleSelectItem(itemId: string): void {
      if (selectedItemsSet.value.has(itemId)) {
        selectedItemsSet.value.delete(itemId);
      } else {
        selectedItemsSet.value.add(itemId);
      }

      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    function selectAllInCategory(category: IOrgCollapsableSelectListCategory) {
      category.items.forEach((value) => selectedItemsSet.value.add(value.id));
      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    function deselectAllInCategory(
      category: IOrgCollapsableSelectListCategory
    ) {
      category.items.forEach((value) =>
        selectedItemsSet.value.delete(value.id)
      );
      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    function countCategoryItems(category: IOrgCollapsableSelectListCategory) {
      return category.items.length;
    }

    function onChangeCategory(
      checked: boolean,
      category: IOrgCollapsableSelectListCategory
    ) {
      checked ? selectAllInCategory(category) : deselectAllInCategory(category);
    }

    // Runs filteration on the data set in case a filter value is set

    const filteredData = computed(() => {
      if (props.filter) {
        return props.data
          .map((category: IOrgCollapsableSelectListCategory) => {
            const items = category.items.filter((item) =>
              item.name.toLowerCase().includes(props.filter.toLowerCase())
            );
            return { ...category, items }; // create a new object, making sure not to modify props
          })
          .filter(
            (category: IOrgCollapsableSelectListCategory) =>
              category.items?.length > 0
          ); // remove categories with no items
      }

      return props.data;
    });

    const hasItems = computed(() => filteredData.value.length > 0);

    watch(selectedItemsSet, () => {
      props.onChange(Array.from(selectedItemsSet.value));
    });

    return {
      categoryOpenState,
      AtomTextSizeEnum,
      css,
      selectedItemsSet,
      isCategoryOpen,
      hasItems,
      countCategoryItems,
      toggleOpenState,
      toggleSelectItem,
      categoryCheckboxPartialStyle,
      categoryCheckboxChecked,
      selectAllInCategory,
      deselectAllInCategory,
      filteredData,
      onChangeCategory,
    };
  },
});
