<template>
  <Error v-if="hasGlobalError" />
  <div v-else class="pageLogin">
    <slot />
  </div>
</template>

<script>
import Error from "./partials/Error.vue";
import { mapGetters } from "vuex";

export default {
  name: "RawLayout",
  components: {
    Error,
  },
  computed: {
    ...mapGetters({
      hasGlobalError: "hasGlobalError",
    }),
  },
};
</script>
