import { http } from "../../http";
import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";
import { BusinessTypeEnum } from "../business_type.enum";

export interface IFetchBusinessCategory {
  name: string;
  slug: BusinessTypeEnum;
}

export class FetchBusinessCategoriesError extends RepoBusinessSettingsError {}

const sortBusinessCategories = (
  list: Array<IFetchBusinessCategory>
): Array<IFetchBusinessCategory> =>
  list.sort((a, b): number => {
    if (a.slug === BusinessTypeEnum.OTHERS) return 1;
    if (b.slug === BusinessTypeEnum.OTHERS) return -1;

    if (a.slug > b.slug) return 1;
    if (b.slug > a.slug) return -1;
    return 0;
  });

export async function fetchBusinessCategories(): Promise<
  Array<IFetchBusinessCategory>
> {
  try {
    const businessCategories = await http.get("api/v3/business-categories");
    return Array.isArray(businessCategories?.data)
      ? sortBusinessCategories(businessCategories?.data)
      : [];
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage || "The request to fetch a business categories failed.",
      response: response.data,
    };

    throw new FetchBusinessCategoriesError(error);
  }
}
