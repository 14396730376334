export enum MenuManagementSourceByIdEnum {
  CHATFOOD = 0,
  DELIVERY = 1,
  GRUBTECH = 2,
  URBAN_PIPER = 3,
  FOODICS = 4,
  OTTER = 5,
  OMNIVORE = 6,
  REVEL = 7,
  TEVALIS = 8,
}
