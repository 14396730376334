






























import { Toast } from "@/design-system";
import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { VTooltip } from "v-tooltip";
const css = bemBuilder("mol-clipboard-copy");

export default defineComponent({
  name: "MolClipboardCopy",
  components: {
    AtomText,
    AtomIcon,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    inputStyles: {
      type: Boolean,
      default: false,
    },
    successMsg: {
      type: String,
      default: t("design_system.mol_clipboard_copy.success"),
    },
    title: {
      type: String,
      default: null,
    },
    onCopy: {
      type: Function as PropType<(link: string) => void>,
      default: () => {},
    },
  },
  setup(props) {
    function copy() {
      try {
        window.navigator.clipboard.writeText(props.link);
        props.onCopy(props.link);

        new Toast().create({
          type: "success",
          title: props.successMsg,
        });
      } catch {
        generalErrorToast(
          t("design_system.mol_clipboard_copy.feature_not_supported")
        );
      }
    }
    return {
      css,
      copy,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
