import Vue from "vue";
import i18n from "@/i18n";
import { isEmpty, isNil, pathOr } from "ramda";

const options = () => ({
  pickup: {
    placed: i18n.t("pickup_status.placed"),
    accepted: i18n.t("pickup_status.accepted"),
    scheduled: i18n.t("pickup_status.scheduled"),
    ready: i18n.t("pickup_status.ready"),
    complete: i18n.t("pickup_status.complete"),
    rejected: i18n.t("pickup_status.rejected"),
    cancelled: i18n.t("pickup_status.cancelled"),
  },
  delivery: {
    placed: i18n.t("delivery_status.placed"),
    accepted: i18n.t("delivery_status.accepted"),
    scheduled: i18n.t("delivery_status.scheduled"),
    ready: i18n.t("delivery_status.ready"),
    complete: i18n.t("delivery_status.complete"),
    rejected: i18n.t("delivery_status.rejected"),
    cancelled: i18n.t("delivery_status.cancelled"),
  },
  dineIn: {
    placed: i18n.t("dine_in_status.placed"),
    accepted: i18n.t("dine_in_status.accepted"),
    ready: i18n.t("dine_in_status.ready"),
    complete: i18n.t("dine_in_status.complete"),
    rejected: i18n.t("dine_in_status.rejected"),
    cancelled: i18n.t("dine_in_status.cancelled"),
  },
});

Vue.filter("cfStatusBeautify", function (value, orderingService) {
  if (orderingService === "dine-in") {
    orderingService = "dineIn";
  }

  let ref = pathOr("", [orderingService, value], options());
  if (isEmpty(ref) || isNil(ref)) return value;

  ref = ref.split("_").join(" ");
  return ref.replace(/\b[a-z]/g, (f) => f.toUpperCase());
});
