<template>
  <div class="Paginator row">
    <div class="col-md text-center text-md-left my-2">
      {{
        $tc("message.ds_pagination_showing", source.total, {
          count: count,
          total: sourceTotal,
        })
      }}
    </div>
    <div class="col-md text-center text-md-right">
      <nav class="d-inline-block">
        <ul class="pagination justify-content-center mb-0">
          <li
            class="Paginator__page-item"
            :class="{ disabled: source.currentPage === 1 }"
          >
            <a
              class="Paginator__page-link"
              href="#"
              @click.prevent="nextPrev($event, source.currentPage - 1)"
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li
            v-for="page in pages"
            :key="page"
            class="Paginator__page-item"
            track-by="$index"
            :class="{ active: source.currentPage === page }"
          >
            <span v-if="page === '...'" class="Paginator__page-link">{{
              page
            }}</span>
            <a
              v-if="page !== '...'"
              class="Paginator__page-link"
              href="#"
              @click.prevent="navigate(page)"
              >{{ page }}
            </a>
          </li>
          <li
            class="Paginator__page-item"
            :class="{ disabled: source.currentPage === source.lastPage }"
          >
            <a
              class="Paginator__page-link"
              href="#"
              @click.prevent="nextPrev($event, source.currentPage + 1)"
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { propOr } from "ramda";

/**
 * @deprecated
 * use `MolPagination` from `@/v2/new-design-system` instead
 */

export default {
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pages: [],
    };
  },
  computed: {
    count() {
      return propOr(0, "count", this.source);
    },
    sourceTotal() {
      return propOr(0, "total", this.source);
    },
  },
  watch: {
    source() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    navigate(page) {
      this.$emit("navigate", page);
    },
    nextPrev(e, page) {
      if (page === 0 || page === this.source.lastPage + 1) return;

      this.navigate(page);
    },
    generatePagesArray(
      currentPage,
      collectionLength,
      rowsPerPage,
      paginationRange
    ) {
      const pages = [];
      const totalPages = Math.ceil(collectionLength / rowsPerPage);
      const halfWay = Math.ceil(paginationRange / 2);
      const ellipsesNeeded = paginationRange < totalPages;

      let position = "middle";

      if (currentPage <= halfWay) position = "start";
      else if (totalPages - halfWay < currentPage) position = "end";

      let i = 1;

      while (i <= totalPages && i <= paginationRange) {
        const pageNumber = this.calculatePageNumber(
          i,
          currentPage,
          paginationRange,
          totalPages
        );
        const openingEllipsesNeeded =
          i === 2 && (position === "middle" || position === "end");
        const closingEllipsesNeeded =
          i === paginationRange - 1 &&
          (position === "middle" || position === "start");

        if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded))
          pages.push("...");
        else pages.push(pageNumber);

        i++;
      }
      return pages;
    },
    calculatePageNumber(i, currentPage, paginationRange, totalPages) {
      const halfWay = Math.ceil(paginationRange / 2);

      if (i === paginationRange) return totalPages;
      else if (i === 1) return i;
      else if (paginationRange < totalPages)
        if (totalPages - halfWay < currentPage)
          return totalPages - paginationRange + i;
        else if (halfWay < currentPage) return currentPage - halfWay + i;
        else return i;
      else return i;
    },
    init() {
      const s = this.source;

      this.pages = this.generatePagesArray(
        s.currentPage,
        s.total,
        s.perPage,
        8
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.Paginator {
  align-items: center;
}

.Paginator__page-item {
  height: 36px;
  line-height: 36px;
  margin-left: 4px;

  &.active .Paginator__page-link,
  &:not(.disabled) .Paginator__page-link:hover {
    color: #ffffff;
    background: #00a8f8;
    border-color: #00a8f8;
  }

  &.disabled .Paginator__page-link {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.Paginator__page-link {
  padding: 8px 12px;
  background: #e2eaef;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 4px;
  color: #84a0b2;
  text-decoration: none;
}
</style>
