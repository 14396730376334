

























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system/atom/icon";
import { IMolDropdownProps, IMolDropdownPropsItem } from "./props";
import { array, func, string } from "@/v2/util/prop/type";

const css = bemBuilder("atom-dropdown");

export default defineComponent<IMolDropdownProps>({
  name: "MolDropdown",
  components: {
    AtomIcon,
  },
  props: {
    label: string().isRequired().use(),
    items: array().isRequired().use(),
    onChange: func().isRequired().use(),
  },
  setup(props) {
    const isFocused = ref<boolean>(false);
    const openDropdown = ref<boolean>(false);

    function selectItem(itemId: string) {
      props.onChange(itemId);
      openDropdown.value = false;
    }

    return {
      css,
      isFocused,
      openDropdown,
      selectItem,
    };
  },
});
