


















































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { AtomLoading } from "../../atom/loading";
import { AtomCheckbox } from "../../atom/checkbox";
import { AtomText, AtomTextColorEnum, AtomTextTypeEnum } from "../../atom/text";
import { IOrgTableProps, ISortArgument, ISortDirection } from "./props";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("org-table");

export default defineComponent({
  name: "OrgTable",
  components: {
    AtomIcon,
    AtomText,
    AtomLoading,
    AtomCheckbox,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    columns: {
      type: Object as PropType<IOrgTableProps["columns"]>,
      required: true,
    },
    data: {
      type: Array as PropType<IOrgTableProps["data"]>,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    hasActions: {
      type: Boolean,
      default: false,
    },
    hasBulkSelection: {
      type: Boolean,
      default: false,
    },
    onBulkSelectionChange: {
      type: Function as PropType<(selectedIds: Array<string>) => void>,
      default: () => {},
    },
    bulkSelectionIds: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    noRecordsMsg: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowClick: {
      type: Function as PropType<
        (row: IOrgTableProps["data"][0], event: MouseEvent) => void
      >,
      default: undefined,
    },
    onSort: {
      type: Function as PropType<
        (key: string, sortDirection: ISortDirection) => void
      >,
      default: () => {},
    },
  },
  setup(props) {
    const columnsState = ref(props.columns);
    const dataState = ref(props.data);
    const selectionSet = ref<Set<string>>(new Set());
    const columnStateKeys = computed(() => Object.keys(columnsState.value));

    watch(
      () => props.bulkSelectionIds,
      (ids) => {
        selectionSet.value = new Set(ids);
      },
      { immediate: true }
    );

    watch(
      () => props.columns,
      (updatedColumns) => {
        // Clone the columns to local state so they can be modified
        columnsState.value = setColumns(updatedColumns);
      },
      { immediate: true }
    );

    watch(
      () => props.data,
      (updatedData) => {
        // Clone the data to local state so they can be modified
        dataState.value = updatedData.map((row) => ({ ...row }));
        // Check and apply an existing sort if available
        sort(...getPreAppliedSort());
      },
      { immediate: true }
    );

    function getPreAppliedSort(): [string, ISortDirection] {
      let key = "";
      let sortDirection: ISortDirection = "NONE";

      let result = Object.entries(columnsState.value).find(
        ([_, columns]) => columns.sortDirection !== "NONE"
      );

      if (result) {
        [key, { sortDirection }] = result;
      }

      return [key, sortDirection];
    }

    function setColumns(
      columns: IOrgTableProps["columns"],
      modifyProps: Record<string, any> = {}
    ) {
      return Object.entries(columns).reduce(
        (total: IOrgTableProps["columns"], [key, value]) => {
          total[key] = { ...value, ...modifyProps };
          return total;
        },
        {}
      );
    }

    const isAllBulkSelection = computed(
      () => selectionSet.value.size === props.totalRecords
    );

    function reportBulkSelectionChange(modifiedSelection: Array<string>) {
      props.onBulkSelectionChange(modifiedSelection);
    }

    function deselectAll() {
      reportBulkSelectionChange([]);
    }

    function selectAll() {
      reportBulkSelectionChange(
        dataState.value.map((row) => row.uniqueId) as Array<string>
      );
    }

    function select(uniqueId: string) {
      reportBulkSelectionChange([...props.bulkSelectionIds, uniqueId]);
    }

    function deselect(uniqueId: string) {
      reportBulkSelectionChange(
        props.bulkSelectionIds.filter((ids) => ids !== uniqueId)
      );
    }

    function sort(key: string, sortDirection: ISortDirection): void {
      props.onSort(key, sortDirection);

      const sortMethod =
        (isAscending: boolean) =>
        (a: ISortArgument, b: ISortArgument): number => {
          const left =
            typeof a[key] === "string" ? String(a[key]).toLowerCase() : a[key];

          const right =
            typeof b[key] === "string" ? String(b[key]).toLowerCase() : b[key];

          if (left > right) return isAscending ? 1 : -1;
          if (left < right) return isAscending ? -1 : 1;
          return 0;
        };

      if (sortDirection === "ASC") {
        columnsState.value = {
          ...setColumns(columnsState.value, { sortDirection: "NONE" }),
          [key]: {
            ...columnsState.value[key],
            sortDirection: "ASC",
          },
        };

        dataState.value = dataState.value
          .map((row) => ({ ...row }))
          .sort(sortMethod(true));
        return;
      }

      if (sortDirection === "DESC") {
        columnsState.value = {
          ...setColumns(columnsState.value, { sortDirection: "NONE" }),
          [key]: {
            ...columnsState.value[key],
            sortDirection: "DESC",
          },
        };
        dataState.value = dataState.value
          .map((row) => ({ ...row }))
          .sort(sortMethod(false));
      }
    }

    const hasRowClick = computed(() => props.rowClick !== undefined);

    function onRowClick(
      row: IOrgTableProps["data"][0],
      event: MouseEvent
    ): void {
      if (!hasRowClick.value) return;

      props.rowClick(row, event);
    }

    return {
      css,
      sort,
      columnsState,
      columnStateKeys,
      dataState,
      selectAll,
      deselectAll,
      select,
      deselect,
      selectionSet,
      isAllBulkSelection,
      onRowClick,
      hasRowClick,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
