




import { AtomIcon } from "@/v2/new-design-system";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Deselect",
  components: {
    AtomIcon,
  },
});
