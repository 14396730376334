import {
  prop,
  propOr,
  pathOr,
  isNil,
  compose,
  toUpper,
  join,
  map,
  head,
  values,
  pick,
} from "ramda";

/**
 * Returns if tenant is loaded based on the present of ID property
 *
 * @returns {Boolean}
 */
export const isLoaded = ({ data }) => !isNil(propOr(null, "id", data));

/**
 * Return if module is currently in loading state
 *
 * @returns {Boolean}
 */
export const isLoading = prop("loading");

/**
 * Get the authenticated tenant
 *
 * @returns {Object}
 */
export const getData = propOr({}, "data");

/**
 * Get the tenant ID
 *
 * @returns {String}
 */
export const getId = pathOr(null, ["data", "id"]);

/**
 * Get the list of permissions granted to the tenant
 *
 * @returns {Array}
 */
export const getPermissions = pathOr([], ["data", "permissions"]);

/**
 * Get the organization details that the tenant belongs to
 *
 * @returns {Object}
 */
export const getOrganization = pathOr({}, ["data", "organization"]);

/**
 * Get the organization id that the tenant belongs to
 *
 * @returns {String}
 */
export const getOrganizationId = pathOr(null, ["data", "organization", "id"]);

/**
 * Get the 2 initials of the tenant name
 *
 * @returns {String}
 */
export const getInitials = compose(
  toUpper,
  join(""),
  map(head),
  values,
  pick(["firstName", "lastName"]),
  propOr({}, "data")
);

/**
 * Get tenant's full name
 *
 * @returns {String}
 */
export const getFullName = compose(
  join(" "),
  values,
  pick(["firstName", "lastName"]),
  propOr({}, "data")
);

/**
 * Get the list of tenant's roles
 *
 * @returns {Array}
 */
export const getRoles = pathOr([], ["data", "roles"]);

/**
 * Get the tenant email
 *
 * @returns {String}
 */
export const getEmail = pathOr(null, ["data", "email"]);
