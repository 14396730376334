import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";
import { IUpdateVoidReasonRequest, IUpdateVoidReasonBodyParams } from "./types";

export class UpdateVoidReasonError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateVoidReasonError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 *{@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/0e2364f16dbec-update-void-reason API contract}
 */
export async function updateVoidReason(
  request: IUpdateVoidReasonRequest
): Promise<void> {
  try {
    const params: IUpdateVoidReasonBodyParams = {
      id: request.id,
      reason: request.reason,
    };

    await http.put(
      `api/v3/businesses/${request.businessId}/tabs/void-categories/${request.voidCategoryId}/reasons`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateVoidReasonError(error);
  }
}
