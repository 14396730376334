export enum AtomTextTypeEnum {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  SUBTITLE = "SUBTITLE",
  BODY = "BODY",
  BODY_BOLD = "BODY_BOLD",
  P = "P",
  SMALL = "SMALL",
  SMALL_BOLD = "SMALL_BOLD",
  LINK = "LINK",
  LINK_SMALL = "LINK_SMALL",
  LINK_BOLD = "LINK_BOLD",
}
