import { propOr } from "ramda";
import { isNilOrEmpty } from "ramda-extension";

/**
 * Hotjar class management.
 *
 * To find more info about Hotjar:
 * https://help.hotjar.com/hc/en-us
 */
class Hotjar {
  /**
   * Hotjar Class Constructor.
   *
   * @param {{ id: String, debug: Boolean }} config
   */
  constructor(config) {
    this.id = propOr(null, "id", config);
    this.debug = propOr(false, "debug", config);
    this.enabled = !isNilOrEmpty(this.id);

    if (!this.enabled) {
      this.logger("disabled");
      return;
    }

    this.logger("mounted");
    this.loadScript();
  }

  /**
   * Verify if Hotjar script has been already loaded.
   *
   * @returns {Boolean}
   */
  hasScript() {
    const hasScript = Array.from(document.getElementsByTagName("script")).some(
      (script) => script.src.includes("hotjar")
    );

    this.logger(
      hasScript ? "check script [loaded]" : "check script [not loaded]"
    );

    return hasScript;
  }

  /**
   * If Hotjar script has not been loaded yet
   * it will run the default snipet to load it.
   *
   * @returns {Promise}
   */
  loadScript() {
    if (this.hasScript()) return Promise.resolve();

    return new Promise((resolve, reject) => {
      window.hj =
        window.hj ||
        function () {
          (window.hj.q = window.hj.q || []).push(arguments);
        };

      window._hjSettings = {
        hjid: this.id,
        hjsv: 6,
      };

      const scriptTag = document.createElement("script");
      scriptTag.async = true;
      scriptTag.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;

      const headTag = document.getElementsByTagName("head")[0];
      headTag.appendChild(scriptTag);

      scriptTag.onload = () => {
        this.logger("script loaded");
        return resolve;
      };
      scriptTag.onerror = () => {
        this.logger("script loading failed");
        return reject;
      };
    });
  }

  /**
   * Logs the action in the browser console.
   *
   * @param {String} label
   * @param {Any} extra
   */
  logger(label, extra = "") {
    if (this.debug && window) {
      window.console.log(`Hotjar: ${label}`, extra);
    }
  }

  /**
   * Call window.hj method.
   *
   * @param {String} event
   * @param {Any} params
   * @returns {void}
   */
  trigger(...params) {
    if (!this.enabled) {
      this.logger("triggered while disabled");
      return;
    }

    if (!window || !window.hj) {
      this.logger("triggered but window.hj unavailable");
      return;
    }

    window.hj(...params);
  }

  /**
   * Identify the tenant
   * Docs: https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference
   *
   * @param {Object} tenant
   * @returns {void}
   */
  identify(tenant) {
    this.trigger("identify", tenant.id, {
      name: `${tenant.firstName} ${tenant.lastName}`,
    });

    this.logger("user identified", tenant.id);
  }
}

export default Hotjar;
