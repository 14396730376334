import Vue from "vue";
import VueI18n from "vue-i18n";
import defaultLang from "@/i18n/languages/en-US.json";
import Defaults from "@/utils/enums/defaults";

/**
 * Added a wrapper to silence the _t undefined error
 * https://github.com/kazupon/vue-i18n/issues/184
 */
const I18nPlugin = {
  install(Vue) {
    const _$t = Vue.prototype.$t;
    Vue.prototype._$t = _$t;

    Vue.prototype.$t = function () {
      if (this.$i18n) {
        return _$t.apply(this, arguments);
      }
      return _$t.apply(this.$root, arguments);
    };
  },
};

Vue.use(VueI18n);
Vue.use(I18nPlugin);

const i18n = new VueI18n({
  locale: Defaults.LANGUAGE,
  fallbackLocale: Defaults.LANGUAGE,
  preserveDirectiveContent: true,
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages: {
    [Defaults.LANGUAGE]: defaultLang,
  },
});

export const translate = (key) => {
  return !key ? "" : i18n.t(key);
};

export const t = (key, values) => {
  return !key ? "" : i18n.t(key, values);
};

export const tc = (key, number, values) => {
  return !key ? "" : i18n.tc(key, number, values);
};

export default i18n;
