












import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { MolSocialMediaTagTypeEnum } from "./type.enum";
import { t } from "@/i18n";
import { AtomGraphic } from "../../atom/graphic";

const css = bemBuilder("mol-social-media-tag");

export default defineComponent({
  name: "MolSocialMediaTag",
  components: {
    AtomGraphic,
  },
  props: {
    type: {
      type: String as PropType<MolSocialMediaTagTypeEnum>,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const contentMap = {
      [MolSocialMediaTagTypeEnum.FACEBOOK]: t(
        "design_system.mol_social_media_tag.facebook"
      ),
      [MolSocialMediaTagTypeEnum.INSTAGRAM]: t(
        "design_system.mol_social_media_tag.instagram"
      ),
      [MolSocialMediaTagTypeEnum.WHATSAPP]: t(
        "design_system.mol_social_media_tag.whatsapp"
      ),
      [MolSocialMediaTagTypeEnum.GOOGLE_SEARCH]: t(
        "design_system.mol_social_media_tag.google_search"
      ),
      [MolSocialMediaTagTypeEnum.DELIVERECT]: t(
        "design_system.mol_social_media_tag.deliverect"
      ),
      [MolSocialMediaTagTypeEnum.STRIPE]: t(
        "design_system.mol_social_media_tag.stripe"
      ),
    };
    const content = computed(() => {
      if (props.label !== "") {
        return props.label;
      }

      return contentMap[props.type];
    });

    const graphicMap = {
      [MolSocialMediaTagTypeEnum.FACEBOOK]: "facebook-v2",
      [MolSocialMediaTagTypeEnum.INSTAGRAM]: "instagram-v2",
      [MolSocialMediaTagTypeEnum.WHATSAPP]: "whatsapp-v2",
      [MolSocialMediaTagTypeEnum.GOOGLE_SEARCH]: "google-v2",
      [MolSocialMediaTagTypeEnum.DELIVERECT]: "deliverect",
      [MolSocialMediaTagTypeEnum.STRIPE]: "stripe",
    };
    const graphicName = computed(() => graphicMap[props.type]);

    return {
      css,
      graphicName,
      content,
    };
  },
});
