




































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed, PropType } from "@vue/composition-api";

const css = bemBuilder("atom-checkbox");

export default defineComponent({
  name: "AtomCheckbox",
  inheritAttrs: false,
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    partial: {
      type: Boolean,
      default: false,
    },
    rightContent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<"danger" | "success" | "neutral">,
      default: undefined,
    },
    onChange: {
      type: Function as PropType<(state: boolean, value: string) => void>,
      required: true,
    },
  },
  setup(props, context) {
    const hasContent = computed((): boolean => {
      return Boolean(context.root.$slots.default);
    });

    const handleChange = (
      state: boolean,
      value: string,
      event: Event
    ): void => {
      event.stopPropagation();
      if (props.disabled) return;

      props.onChange(state, value);
    };

    return {
      css,
      hasContent,
      handleChange,
    };
  },
});
