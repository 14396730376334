import VueRouter from "vue-router";
import { pathOr, isNil } from "ramda";

export default (gates, router, accessDeniedPath = "/access-denied") => {
  if (!(router instanceof VueRouter)) {
    throw new Error("Router must be an instance of VueRouter");
  }

  router.beforeEach((to, from, next) => {
    const routePermission = pathOr(null, ["meta", "permission"], to);

    if (!isNil(routePermission) && gates.ready && !gates.can(routePermission)) {
      next(accessDeniedPath);
      return;
    }

    next();
  });
};
