




import { defineComponent, computed } from "@vue/composition-api";
import { toLocaleCurrency } from "@/v2/util/to-locale-currency";
import { string, number, boolean } from "@/v2/util/prop/type";

export default defineComponent({
  name: "AtomMoney",
  props: {
    tag: string().setDefault("span").use(),
    cents: number().isRequired().use(),
    showSymbol: boolean().setDefault(true).use(),
    currencyCode: string().setDefault("USD").use(),
    locale: string().setDefault("en-US").use(),
  },
  setup(props) {
    return {
      formattedMoney: computed(() => {
        const amount = Math.round(props.cents) || 0;
        return toLocaleCurrency(
          amount,
          props.currencyCode,
          props.showSymbol ? "$0,0" : "0,0",
          props.locale
        );
      }),
    };
  },
});
