import { VIEW_REPORTS } from "@/utils/enums/permissions";
import { optionalOutletFillerMiddleware } from "@/v2/components/outlet-switcher";
import { RouteConfig } from "vue-router";
import { t } from "@/i18n";
import { ReportMetabaseDashboardEnum } from "@/v2/module/report/domain/metabase-dashboard.enum";
import dayjs from "dayjs";

const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
const weekAgo = dayjs().subtract(1, "week").format("YYYY-MM-DD");

export const reportsRoutes: Array<RouteConfig> = [
  {
    name: "reports.index",
    path: "/business/:businessId/reports",
    component: () =>
      import(
        /* webpackChunkName: "report-module" */ "@/v2/module/report/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.report.heading"),
      permission: VIEW_REPORTS,
    },
  },
  {
    name: "reports.sales-summary",
    path: "/business/:businessId/outlet/:outletId?/reports/sales-summary",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "report-module" */ "@/v2/module/report/metabase-outlet-dashboard/MetabaseOutletDashboard.vue"
      ),
    props: (route) => ({
      ...route.params,
      dashboardId: ReportMetabaseDashboardEnum.SALES_SUMMARY,
      title: t("module.report.sales_summary_title"),
      params: { end_date: yesterday, start_date: weekAgo },
    }),
    meta: {
      title: t("module.report.sales_summary_title"),
      permission: VIEW_REPORTS,
    },
  },
  {
    name: "reports.analytics",
    path: "/business/:businessId/outlet/:outletId?/reports/analytics",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "report-module" */ "@/v2/module/report/metabase-outlet-dashboard/MetabaseOutletDashboard.vue"
      ),
    props: (route) => ({
      ...route.params,
      dashboardId: ReportMetabaseDashboardEnum.ANALYTICS_REPORT,
      title: t("module.report.analytics_report_title"),
      params: { end_date: yesterday, date: weekAgo },
    }),
    meta: {
      title: t("module.report.analytics_report_title"),
      permission: VIEW_REPORTS,
    },
  },
];
