

















import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { GuideLinkConfig } from "./config";
import { MolGuideLinkTypeEnum } from "./type.enum";
import { MolGuideLinkArticleEnum } from "./article.enum";

const css = bemBuilder("mol-guide-link");

export default defineComponent({
  name: "MolGuideLink",
  components: {
    AtomText,
    AtomIcon,
  },
  props: {
    article: {
      type: String as PropType<MolGuideLinkArticleEnum>,
      required: true,
    },
    iconName: {
      type: String,
      default: "",
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    label: {
      type: Function as PropType<() => string>,
      default: () => undefined,
    },
    target: {
      type: String as PropType<"_blank" | "_self">,
      default: "_blank",
    },
    type: {
      type: String as PropType<MolGuideLinkTypeEnum>,
      default: MolGuideLinkTypeEnum.PRIMARY,
    },
  },
  setup(props) {
    const link = computed(() => GuideLinkConfig[props.article].link);

    const textColor = computed(() => {
      if (props.type === MolGuideLinkTypeEnum.PRIMARY) {
        return AtomTextColorEnum.CHATFOOD;
      }

      return AtomTextColorEnum.RAINY;
    });

    const textType = computed(() => {
      if (props.type === MolGuideLinkTypeEnum.PRIMARY) {
        return AtomTextTypeEnum.BODY;
      }

      return AtomTextTypeEnum.BODY_BOLD;
    });

    const handledLabel = computed(() => {
      if (!props.label()) {
        return GuideLinkConfig[props.article].label();
      }

      return props.label();
    });

    const handledIconName = computed(() => {
      if (props.iconName) {
        return props.iconName;
      }

      return GuideLinkConfig[props.article].iconName;
    });

    return {
      css,
      link,
      textType,
      textColor,
      handledIconName,
      handledLabel,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
