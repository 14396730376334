export enum OrderingModes {
  DELIVERY = "delivery",
  PICKUP = "pickup",
  DINE_IN = "dine-in",
}

export enum OrderingModeStates {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  PAUSED = "PAUSED",
}

export enum OutletStates {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DRAFT = "DRAFT",
}
