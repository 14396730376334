//---- TODO: Remove on Vue 3 migrate [VUE_3_MIGRATE]
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);
// -----
import { IFetchPaymentMethodSetupCompletionResponse } from "@/v2/repo/fetch-payment-method-setup-completion";
import { IFetchCurrentSubscription } from "@/v2/repo/fetch-current-subscription";
import { stateBuilder } from "@/v2/util/state/state-builder";
import { billingMethodLoadPlans } from "./method/load-plans";
import { CurrencyCodeEnum } from "@/v2/enum";

export enum BillingStageStateEnum {
  DORMANT = 0,
  CHOOSE_PLAN = 1,
  CHECKING_PAYMENT_METHOD = 2,
  PAYMENT_METHOD = 3,
  VALIDATING_PAYMENT_METHOD = 4,
  REVIEW_ORDER = 5,
  PLACING_ORDER = 6,
  TRANSACTION_FAIL = 7,
  TRANSACTION_SUCCESS = 8,
  WARNING_NO_PLAN = 9,
}

export interface IBillingPlan {
  recommended: boolean;
  id: string;
  priceId: string;
  price: string;
  priceRaw: number;
  extraOutletPriceRaw: number;
  currency: CurrencyCodeEnum;
  name: string;
  maxOutlets: number;
  maxOrders: number;
  extraOutlets: number;
  extraOrderPrice: string;
  extraOutletPrice: string;
}

const BillingState = {
  moduleName: "Billing",
  state: {
    business: {
      id: "",
      name: "",
      logo: "",
    },
    user: {
      name: "",
      email: "",
    },
    currentPlan: null as IFetchCurrentSubscription | null,
    showFlowStartCTA: false,
    trialDays: 0,
    flowSuccess: false,
    currentOutletCount: 0,
    stage: BillingStageStateEnum.DORMANT,
    plans: [] as Array<IBillingPlan>,
    selectedPlan: null as IBillingPlan | null,
    selectedCardInfo: null as IFetchPaymentMethodSetupCompletionResponse | null,
  },
  action: {
    loadPlans: billingMethodLoadPlans,
  },
};

const {
  initState: billingInitState,
  setState: billingSetState,
  getState: billingGetState,
  action: billingAction,
} = stateBuilder<typeof BillingState>(BillingState);

billingInitState();

export { billingInitState, billingSetState, billingGetState, billingAction };
