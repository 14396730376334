import { VIEW_MENUS } from "@/utils/enums/permissions";

export default [
  {
    name: "menus.index",
    path: "/business/:businessId/menus",
    component: () =>
      import(
        /* webpackChunkName: "menu-module" */ "@/v2/module/menu/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Menus",
      permission: VIEW_MENUS,
    },
  },
];
