/**
 * Format a number based on the given precision
 * NOTE: Any negative precision, will trucante the result accordingly.
 *
 * @param {Number} value
 * @param {Number} precision
 */
const customFixed = (value, precision) => {
  value = value.toFixed(Math.abs(precision));

  if (precision >= 0) {
    return value;
  }

  return Number(value);
};

/**
 * Format number to percentage.
 * Ex.
 *    toPercentage(1.1, 2) // 1.10
 *    toPercentage(1.10, -2) // 1.1
 *    toPercentage(1.13, -2) // 1.13
 *    toPercentage(1.132, 2, true) // +1.13
 *
 * @param {Number} value
 * @param {Number} precision
 * @param {Boolean} forcePositiveSign enforce to response to be followed by '+' sign when positive
 *
 * @returns {String}
 */
const toPercentage = (value, precision = 2, forcePositiveSign = false) => {
  let percentage = parseFloat(value);

  if (Number.isNaN(percentage)) return value;

  percentage = customFixed(percentage, precision);

  if (forcePositiveSign && percentage >= 0) {
    return `+${percentage}%`;
  }

  return `${percentage}%`;
};

export default toPercentage;
