// @ts-nocheck

import Vue from "vue";
import App from "./App.vue";
import { router, useVueRouter } from "./router";
import store from "./store";
import i18n from "@/i18n";
import { VNode } from "vue/types/umd";
import { reportInit } from "@chatfood/bug-reporter";
import { globalConfig } from "./v2/core";

/**
 * Setup Vue Composition API
 */
import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);

reportInit({
  app: Vue,
  dsn: globalConfig.sentryDsn,
  environment: globalConfig.env,
  debug: false,
  enabled: globalConfig.sentryEnabled,
  release: globalConfig.appVersion,
  jsFramework: "Vue 2",
  browserTracing: {
    router,
    origins: [/^\//],
  },
});

/**
 * Setup Apollo
 */
import VueApollo from "vue-apollo";

import { apolloProvider } from "./graphql";
Vue.use(VueApollo);

/**
 * Require basic setup
 */
require("./bootstrap");

/**
 * Require global components
 */
require("./components");

/**
 * Require global filters
 */
require("./utils/filters/install");

/**
 * Vue basic settings
 */
Vue.config.productionTip = false;

/**
 * Use VueRouter
 */
useVueRouter(Vue);

/**
 * Instantiate root Vue Component
 */
new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: (h): VNode => h(App),
}).$mount("#app");

window.version = process.env.VUE_APP_VERSION;
