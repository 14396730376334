import { reactive, computed, Ref } from "@vue/composition-api";
import { OrderingModeStates, OutletStates } from "../enums";
//---- TODO: Remove on Vue 3 migrate [VUE_3_MIGRATE]
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import { fetchCurrentSubscriptionMaximumOutlets } from "@/v2/repo/fetch-current-subscription-maximum-outlets";
import { fetchActiveOutletCount } from "@/v2/repo/fetch-active-outlet-count";
Vue.use(VueCompositionApi);
// -----
const state = reactive({
  outletId: "",
  addressId: "",
  outletState: OutletStates.DRAFT,
  deliveryModeState: OrderingModeStates.DISABLED,
  pickupModeState: OrderingModeStates.DISABLED,
  dineInModeState: OrderingModeStates.DISABLED,
  attachedAreasAmount: 0,
  outletName: "",
  isLocked: true,
});

const resetState = (): void => {
  state.outletId = "";
  state.addressId = "";
  state.outletState = OutletStates.DRAFT;
  state.deliveryModeState = OrderingModeStates.DISABLED;
  state.pickupModeState = OrderingModeStates.DISABLED;
  state.dineInModeState = OrderingModeStates.DISABLED;
  state.attachedAreasAmount = 0;
  state.outletName = "";
  state.isLocked = true;
};

const setAttachedAreasAmount = (value: number): void => {
  state.attachedAreasAmount = value;
};

const setAddressId = (addressId: string): void => {
  state.addressId = addressId;
};

const setOutletId = (outletId: string): void => {
  state.outletId = outletId;
};

const setOutletState = (outletState: OutletStates): void => {
  state.outletState = outletState;
};

const setDeliveryModeState = (deliveryModeState: OrderingModeStates): void => {
  state.deliveryModeState = deliveryModeState;
};

const setPickupModeState = (pickupModeState: OrderingModeStates): void => {
  state.pickupModeState = pickupModeState;
};

const setOutletName = (outletName: string): void => {
  state.outletName = outletName;
};

const setDineInModeState = (dineInModeState: OrderingModeStates): void => {
  state.dineInModeState = dineInModeState;
};

const isOutletInDraft: Ref<boolean> = computed(
  () => state.outletState === OutletStates.DRAFT
);

const isOutletActive: Ref<boolean> = computed(
  () => state.outletState === OutletStates.ACTIVE
);

const isOutletInactive: Ref<boolean> = computed(
  () => state.outletState === OutletStates.INACTIVE
);

const isAddressCreated: Ref<boolean> = computed(() => state.addressId !== "");

const isCreatingOutlet: Ref<boolean> = computed(() => state.outletId === "");

const isDeliveryEnabled: Ref<boolean> = computed(
  () =>
    state.deliveryModeState === OrderingModeStates.ENABLED ||
    state.deliveryModeState === OrderingModeStates.PAUSED
);

const isPickupEnabled: Ref<boolean> = computed(
  () => state.pickupModeState === OrderingModeStates.ENABLED
);

const isDineInEnabled: Ref<boolean> = computed(
  () => state.dineInModeState === OrderingModeStates.ENABLED
);

const canBePublished: Ref<boolean> = computed(() => {
  const isOpenForDelivery: boolean =
    isDeliveryEnabled.value && state.attachedAreasAmount > 0;

  return (
    isOutletInDraft.value &&
    isAddressCreated.value &&
    (isPickupEnabled.value || isOpenForDelivery || isDineInEnabled.value)
  );
});

const attachedAreasAmount: Ref<number> = computed(
  () => state.attachedAreasAmount
);

const outletName = computed(() => state.outletName);

const isLocked = computed(() => state.isLocked);
const setIsLocked = (locked: boolean): void => {
  state.isLocked = locked;
};

const updateLockState = async (businessId: string): Promise<void> => {
  const [maxActiveOutlets, activeOutlets] = await Promise.all([
    fetchCurrentSubscriptionMaximumOutlets(businessId),
    fetchActiveOutletCount(businessId),
  ]);

  setIsLocked(maxActiveOutlets <= activeOutlets);
};

export default {
  // Getters
  isOutletInDraft,
  isOutletActive,
  isOutletInactive,
  isAddressCreated,
  isCreatingOutlet,
  isDeliveryEnabled,
  isPickupEnabled,
  isDineInEnabled,
  canBePublished,
  outletName,
  attachedAreasAmount,
  isLocked,

  // Actions
  setAttachedAreasAmount,
  setAddressId,
  setOutletId,
  setOutletState,
  setOutletName,
  setDeliveryModeState,
  setPickupModeState,
  setDineInModeState,
  setIsLocked,
  updateLockState,
  resetState,
};
