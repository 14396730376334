/**
 * Returns a function bound to the component name to help build css
 * classes based on element and modifiers
 *
 * @param componentName
 *
 * @returns Returns a function bound to the component name to help build css
 * classes based on element and modifiers
 */
export function bemBuilder(
  componentName: string = ""
): (element?: string, modifer?: string) => string {
  return (element: string = "", modifier: string = ""): string =>
    `${componentName}${element ? `__${element}` : ""}${
      modifier ? `--${modifier}` : ""
    }`;
}
