var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('table',{class:_vm.css('table')},[(_vm.showHeader)?_c('thead',[_c('tr',{class:_vm.css('header-row')},[_vm._l((Object.entries(_vm.columnsState)),function(ref,index){
var _obj, _obj$1, _obj$2;

var key = ref[0];
var column = ref[1];return [(index === 0 && _vm.hasBulkSelection)?_c('th',{key:("header-status-" + key),class:_vm.css('header')},[_c('AtomCheckbox',{attrs:{"checked":_vm.selectionSet.size > 0,"partial":!_vm.isAllBulkSelection,"on-change":function (value) { return (value ? _vm.selectAll() : _vm.deselectAll()); }}})],1):_vm._e(),(column.show)?_c('th',{key:("header-" + key),class:[
              _vm.css('header'),
              ( _obj = {}, _obj[_vm.css(("align-" + (column.align)))] = column.align, _obj ) ],style:(column.width ? ("min-width: " + (column.width) + "px") : ''),attrs:{"data-test":("header-" + key)}},[_c('span',{class:( _obj$1 = {}, _obj$1[_vm.css('sortable-header')] = column.sortable, _obj$1 ),on:{"click":function($event){$event.preventDefault();return (function () { return column.sortable &&
                  _vm.sort(key, column.sortDirection === 'ASC' ? 'DESC' : 'ASC'); }).apply(null, arguments)}}},[(column.sortable)?_c('a',{class:_vm.css('table-head-cell-link'),attrs:{"href":"","data-test":"table-head-cell-link"}},[_c('AtomIcon',{key:("header-" + key + "-sort-" + (column.sortDirection.toLowerCase())),attrs:{"name":("sort-" + (column.sortDirection.toLowerCase())),"size":20,"color":column.sortDirection != 'NONE'
                      ? 'var(--color-chatfood)'
                      : 'var(--color-stormy)'}})],1):_vm._e(),(column.header)?_c('AtomText',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: column.helpText,
                  classes: 'new-tooltip',
                  placement: 'top',
                }),expression:"{\n                  content: column.helpText,\n                  classes: 'new-tooltip',\n                  placement: 'top',\n                }"}],class:( _obj$2 = {}, _obj$2[_vm.css('has-help-text')] = Boolean(column.helpText), _obj$2 ),attrs:{"color":_vm.AtomTextColorEnum.STORMY}},[_vm._v(" "+_vm._s(column.header())+" ")]):_vm._e()],1)]):_vm._e(),(_vm.hasActions && index === _vm.columnStateKeys.length - 1)?_c('th',{key:("header-" + index + "-actions")}):_vm._e()]})],2)]):_vm._e(),(!_vm.loading)?[_c('tbody',_vm._l((_vm.dataState),function(row){
                var _obj;
return _c('tr',{key:row.uniqueId,class:( _obj = {}, _obj[_vm.css('row')] = true, _obj[_vm.css('row', 'with-click')] = _vm.hasRowClick, _obj ),attrs:{"data-test":("row-" + (row.uniqueId))},on:{"click":function($event){$event.preventDefault();return _vm.onRowClick(row, $event)}}},[_vm._l((_vm.columnStateKeys),function(columnKey,index){
          var _obj;
return [(index === 0 && _vm.hasBulkSelection)?_c('td',{key:((row.uniqueId) + "-" + index + "-bulk-select"),class:_vm.css('cell')},[_c('AtomCheckbox',{attrs:{"checked":_vm.selectionSet.has(row.uniqueId),"on-change":function (value) { return value ? _vm.select(row.uniqueId) : _vm.deselect(row.uniqueId); }}})],1):_vm._e(),(_vm.columnsState[columnKey].show)?_c('td',{key:((row.uniqueId) + "-" + columnKey),class:( _obj = {}, _obj[_vm.css('cell')] = true, _obj[_vm.css('cell', 'sortable')] = _vm.columnsState[columnKey].sortable, _obj ),style:(_vm.columnsState[columnKey].width
                  ? ("min-width: " + (_vm.columnsState[columnKey].width) + "px")
                  : ''),attrs:{"data-test":((row.uniqueId) + "-" + columnKey)}},[_vm._t(columnKey,null,{"cell":row[columnKey],"row":row})],2):_vm._e(),(_vm.hasActions && index === _vm.columnStateKeys.length - 1)?_c('td',{key:((row.uniqueId) + "-" + index + "-actions")},[_c('div',{class:_vm.css('row__actions'),attrs:{"data-test":"row__actions"}},[_vm._t("actions",null,{"row":row})],2)]):_vm._e()]})],2)}),0)]:_vm._e()],2),(!_vm.dataState.length && !_vm.loading)?_c('div',{class:_vm.css('empty-text'),attrs:{"data-test":"no-records-msg"}},[_c('AtomText',{attrs:{"color":_vm.AtomTextColorEnum.RAINY}},[_vm._v(_vm._s(_vm.noRecordsMsg))])],1):_vm._e(),(_vm.loading)?_c('AtomLoading',{class:_vm.css('loading'),attrs:{"inline":"","data-test":"loading"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }