import * as events from "../events";
import { applySpec, type, prop, propOr } from "ramda";

const getProfile = applySpec({
  firstName: prop("firstName"),
  lastName: prop("lastName"),
  email: prop("email"),
  phone: prop("phone"),
  group: propOr("Tenant", "group"),
  roles: prop("roles"),
});

const getGroup = applySpec({
  name: prop("name"),
  country: prop("country"),
  createdAt: prop("createdAt"),
});

export default class Segmentio {
  constructor(key) {
    this.key = key;

    this.loadAnalyticsJs();
  }

  loadAnalyticsJs() {
    const analytics = (window.analytics = window.analytics || []);

    if (analytics.initialize) {
      return;
    }

    if (analytics.invoked) {
      window.console.error("Segment snippet included twice.");
      return;
    }

    analytics.invoked = !0;

    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
    ];

    analytics.factory = function (t) {
      return function () {
        const e = Array.prototype.slice.call(arguments);
        e.unshift(t);
        analytics.push(e);
        return analytics;
      };
    };

    for (let t = 0; t < analytics.methods.length; t++) {
      const e = analytics.methods[t];
      analytics[e] = analytics.factory(e);
    }

    analytics.load = (t, e) => {
      const n = document.createElement("script");
      n.type = "text/javascript";
      n.async = !0;
      n.src =
        "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
      const a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(n, a);
      analytics._loadOptions = e;
    };

    analytics.SNIPPET_VERSION = "4.1.0";
    analytics.load(this.key);
  }

  /**
   * Docs: https://segment.com/docs/connections/spec/page/
   */
  trackPageView({ name, ...opts }) {
    this.analytics().page(name, opts);
  }

  analytics() {
    if (window.analytics) {
      return window.analytics;
    }

    return {
      identify: () => {},
      track: () => {},
      page: () => {},
      group: () => {},
    };
  }

  identify(id, metadata = {}) {
    const profileData = getProfile(metadata);
    profileData.roles = Array.from(new Set(profileData.roles));

    this.analytics().identify(id, {
      ...profileData,
      createdAt: metadata.organization?.createdAt || null,
    });

    const { id: orgId, ...data } = propOr({}, "organization", metadata);
    this.group(orgId, data);
  }

  group(id, metadata = {}) {
    this.analytics().group(id, getGroup(metadata));
  }

  trackEvent(event, payload) {
    if (type(this[event]) === "Function") {
      // This is the legacy way to handle events
      this[event](payload);
    } else {
      /**
       * Put this here as the new way to handle events
       * there is no need for the double registration and
       * is a premature optimization of events if the only
       * service we are sending to is Segment, if in the
       * future we start sending to some other
       * service, we can change this around.
       **/
      this.fireTrack(event, payload);
    }
  }

  fireTrack(event, payload) {
    this.analytics().track(event, payload);
  }

  [events.ACCOUNT_ACTIVATION_INTENTION](payload) {
    this.fireTrack("Clicked on account activation", payload);
  }

  [events.APP_OPENED](payload) {
    this.fireTrack("App Opened", payload);
  }

  [events.BUTTON_CLICKED](payload) {
    this.fireTrack("Clicked on Button", payload);
  }

  [events.DEMO_BOOKING_INTENTION](payload) {
    this.fireTrack("Clicked on booking a demo", payload);
  }

  [events.ORDER_ACKNOWLEDGED](payload) {
    this.fireTrack("Order Acknowledged", payload);
  }

  [events.ORDER_OPENED](payload) {
    this.fireTrack("Order Opened", payload);
  }

  [events.ORDER_RECEIVED](payload) {
    this.fireTrack("Order Received", payload);
  }

  [events.PUSH_RECEIVED](payload) {
    this.fireTrack("Push Received", payload);
  }

  [events.SALES_CHANNEL_HELP_FEEDBACK](payload) {
    this.fireTrack("Sales channel help feedback", payload);
  }

  [events.SALES_CHANNEL_VERIFIED](payload) {
    this.fireTrack("Sales channel verified", payload);
  }
}
