import * as types from "./mutation_types";
import Businesses from "@/api/businesses";
import { action as fetchAction } from "@/store/fetchBase";
import { action as resetAction } from "@/store/resetBase";
import { pathOr, isNil, length } from "ramda";
import setLanguageAsync from "@/i18n/setLanguageAsync";
import { Toast } from "@/design-system";
import { t } from "@/i18n";

/**
 * Set the language callback based on the first business of the
 * given list
 *
 * @param {Array} businesses
 * @returns {void}
 */
const setDefaultLanguage = (businesses) => {
  const language = pathOr(null, ["data", "0", "language"], businesses);

  if (!isNil(language)) setLanguageAsync(language);
};

/**
 * Fetch a list of businesses visible by the authenticated tenant
 *
 * @returns {Array}
 */
export const fetch = async ({ commit }) => {
  const businesses = await fetchAction(commit, types.FETCH, Businesses.get, {
    include: "currency",
  });

  setDefaultLanguage(businesses);

  if (!length(businesses.data)) {
    new Toast().create({
      type: "error",
      text: t("message.businesses_not_available"),
    });
  }

  return businesses;
};

/**
 * Reset the module to the initial state
 *
 * @returns {Void}
 */
export const reset = async ({ commit }) => resetAction(commit, types.RESET);
