import { http } from "../../http";
import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";

export class UploadBusinessLogoError extends RepoBusinessSettingsError {}

export async function uploadBusinessLogo(
  businessId: string,
  file: File
): Promise<string> {
  const data = new FormData();
  data.append("logo", file);

  try {
    const response = await http.post(
      `/api/v3/businesses/${businessId}/update-logo`,
      data
    );

    return response.data.logo_url;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage || "The request to upload a business logo failed.",
      response: response.data,
    };

    throw new UploadBusinessLogoError(error);
  }
}
