


























import { boolean, func, string } from "@/v2/util/prop/type";
import { defineComponent } from "@vue/composition-api";
import { IAtomRadioProps } from "./props";

/**
 * @deprecated
 * use `AtomRadio` from `v2/new-design-system` instead
 */

export default defineComponent<IAtomRadioProps>({
  name: "AtomRadio",
  inheritAttrs: false,
  model: {
    prop: "modelValue",
  },
  props: {
    name: string().isRequired().use(),
    value: string().use(),
    modelValue: string().use(),
    disabled: boolean().use(),
    onChange: func().use(),
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
});
