import { http } from "@/v2/repo/http";
import {
  IAxiosErrorResponse,
  IRepoBusinessSettingsErrorResponse,
  RepoBusinessSettingsError,
} from "@/v2/repo/business-settings/error-response";

export class CreateBusinessError extends RepoBusinessSettingsError {}

export async function createBusiness(name: string): Promise<string> {
  try {
    const response = await http.post("/api/v3/businesses", { name });
    return response.data?.id;
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error: IRepoBusinessSettingsErrorResponse = {
      status: response.status,
      message: rawErrorMessage || "The request to create a business failed.",
      response: response.data,
    };

    if (error.status === 422) {
      const { code } = response.data?.error;

      if (code === "business_name_already_used") {
        error.validationError = {
          businessName: code,
        };
      }
    }

    throw new CreateBusinessError(error);
  }
}
