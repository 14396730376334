/**
 * Debounce function
 *
 * @param {Function} callback
 * @param {Number} wait Time in miliseconds to hold exec
 *
 * @returns {Function}
 */

export const debounce = (
  callback: (...args: any) => void,
  wait: number
): (() => void) => {
  let timeout: NodeJS.Timeout;

  return function (this: any, ...args: any): void {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};
