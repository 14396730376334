







































































import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextColorEnum,
  AtomTextTagEnum,
} from "@/v2/new-design-system";
import { useStore } from "@/store";
import { VIEW_BILLING } from "@/utils/enums/permissions";
import { billingGetState } from "@/v2/module/billing/state";
import { useStripeCustomerPortal } from "@/v2/module/billing/use-stripe-customer-portal";

const css = bemBuilder("pending-payment-banner");

export default defineComponent({
  name: "PendingPaymentBanner",
  components: {
    AtomButton,
    AtomText,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    subscriptionId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { getters } = useStore();

    const canSeeBillingPage = computed(() => {
      const permissions = getters["auth/getPermissions"];
      return permissions.includes(VIEW_BILLING);
    });

    const isUnpaidStatus = computed(() => {
      return billingGetState("currentPlan").value?.state === "UNPAID";
    });

    const { openBillingCustomerPortal, isStripeCustomerPortalLoading } =
      useStripeCustomerPortal();

    const onGoToStripePortal = () =>
      openBillingCustomerPortal(props.businessId, props.subscriptionId);

    onMounted(() => window.scrollTo(0, 0));

    return {
      t,
      css,
      isStripeCustomerPortalLoading,
      canSeeBillingPage,
      isUnpaidStatus,
      onGoToStripePortal,
      AtomButtonSizeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
    };
  },
});
