<template>
  <div class="upload-icon" :class="[{ 'upload-icon--error': hasErrors }]">
    <div v-if="loading" class="upload-icon__loading">
      <div class="upload-icon__circle"></div>
      <div class="upload-icon__text" data-test="loading">
        {{ $t("message.uploading_image") }}
      </div>
    </div>
    <div v-else class="upload-icon__content">
      <template v-if="url">
        <img class="upload-icon__image" :src="url" data-test="content" />
      </template>
      <template v-else>
        <Icon
          class="upload-icon__icon"
          :icon="iconName"
          :size="iconSize"
          color="#F2F2F2"
        />
        <!-- eslint-disable -->
        <div
          class="upload-icon__text"
          v-html="$t('message.drag_drop_widget_icon')"
        />
        <!-- eslint-enable -->
      </template>
    </div>
    <input
      type="file"
      class="upload-icon__input"
      accept="image/x-png, image/jpeg"
      data-test="input"
      @change.prevent="feedFile"
    />
    <a
      v-if="url"
      class="upload-icon__remove"
      data-test="remove"
      @click="removeImage"
    >
      <Icon icon="times-circle" color="#A2B8C6" />
    </a>
  </div>
</template>

<script>
import { Icon } from "@/design-system";
import { pathOr, isNil } from "ramda";

export default {
  name: "UploadIcon",
  components: {
    Icon,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 40,
    },
    iconName: {
      type: String,
      default: "shop",
    },
  },
  computed: {
    imageUrl() {
      if (isNil(this.url)) return "";

      return `${this.url}?w=48&h=48&fit=crop&crop=center`;
    },
  },
  methods: {
    feedFile(event) {
      const image = pathOr(null, ["target", "files", 0], event);

      if (isNil(image)) return;

      this.$emit("uploadImage", image);
      event.target.value = null;
    },
    removeImage() {
      this.$emit("removeImage");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-icon {
  position: relative;
  display: block;
  cursor: pointer;
  margin: 0;
  height: 48px;

  &__image {
    height: 48px;
    max-width: 48px;
  }

  &__icon {
    border: 1px dashed #dee2e6;
    padding: 9px 11px;
    border-radius: 4px;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__text {
    text-align: left;
    max-width: 211px;
    margin-left: 12px;
    margin-top: 0;
    color: #84a0b2;

    /deep/ > span {
      color: #00a8f8;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
  }

  &__circle {
    width: 24px;
    height: 24px;
    margin: 12px;
    border: 3px solid #00a8f8;
    border-radius: 23px;
    clip: rect(0px 23px 46px 6px);
    mask-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(0, 168, 248, 0)),
      to(#00a8f8)
    );
    animation: loading 0.6s linear infinite;
  }

  &__remove {
    position: absolute;
    left: 57px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 1;
    top: 12px;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
