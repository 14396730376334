import { VIEW_CUSTOMERS } from "@/utils/enums/permissions";
import { ICustomerManagementRouteEnum } from "@/v2/module/customer-management/customer-mangement-route.enum";
import { RouteConfig } from "vue-router";
import { t } from "@/i18n";

export const crmRoutes: Array<RouteConfig> = [
  {
    name: "customer-management.index",
    path: "/business/:businessId/customer-management",
    component: () =>
      import(
        /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.customer_management.titles.main"),
      permission: VIEW_CUSTOMERS,
    },
    redirect: { name: ICustomerManagementRouteEnum.ALL },
    children: [
      {
        path: "",
        name: ICustomerManagementRouteEnum.ALL,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/info/Info.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.all"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "recent",
        name: ICustomerManagementRouteEnum.RECENT,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/info/Info.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.recent"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "inactive",
        name: ICustomerManagementRouteEnum.INACTIVE,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/info/Info.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.inactive"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "best",
        name: ICustomerManagementRouteEnum.BEST,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/info/Info.vue"
          ),
        meta: {
          title: "Customer Management - Best",
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "critical",
        name: ICustomerManagementRouteEnum.CRITICAL,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/info/Info.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.critical"),
          permission: VIEW_CUSTOMERS,
        },
      },
    ],
  },
  {
    path: "/business/:businessId/customer-management/:customerId",
    component: () =>
      import(
        /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/details/Details.vue"
      ),
    props: true,
    meta: {
      title: t("module.customer_management.titles.details"),
      permission: VIEW_CUSTOMERS,
    },
    children: [
      {
        name: ICustomerManagementRouteEnum.DETAILS,
        path: "",
        redirect: {
          name: ICustomerManagementRouteEnum.DINE_IN_TRANSACTIONS,
        },
      },
      {
        path: "transactions/dine-in",
        name: ICustomerManagementRouteEnum.DINE_IN_TRANSACTIONS,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/transactions/Transactions.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.details_dine_in"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "transactions/delivery",
        name: ICustomerManagementRouteEnum.DELIVERY_TRANSACTIONS,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/transactions/Transactions.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.details_delivery"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "transactions/pickup",
        name: ICustomerManagementRouteEnum.PICKUP_TRANSACTIONS,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/transactions/Transactions.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.details_pickup"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "transactions/payment-link",
        name: ICustomerManagementRouteEnum.PAYMENT_LINK_TRANSACTIONS,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/transactions/Transactions.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.details_payment_links"),
          permission: VIEW_CUSTOMERS,
        },
      },
      {
        path: "transactions/pay-at-table",
        name: ICustomerManagementRouteEnum.PAT_TRANSACTIONS,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "customer-management-module" */ "@/v2/module/customer-management/transactions/Transactions.vue"
          ),
        meta: {
          title: t("module.customer_management.titles.details_pat"),
          permission: VIEW_CUSTOMERS,
        },
      },
    ],
  },
];
