import AnalyticsPlugin from "./AnalyticsPlugin";

/**
 * Installation procedure
 *
 * @param Vue
 * @param initConf
 */
const install = function (Vue) {
  Vue.prototype.$analytics = Vue.analytics = new AnalyticsPlugin();
};

export default { install };
