import { RouteConfig } from "vue-router";
import Index from "@/views/Index.vue";
import {
  VIEW_ORDERS,
  VIEW_LIVE_ORDERS,
  VIEW_MENUS,
  VIEW_MODIFIER_LISTS,
  VIEW_ITEMS,
  VIEW_MARKETING,
} from "@/utils/enums/permissions";

// @ts-expect-error route file needed to be adapted to TS
import OutletsModule from "@/modules/outlets/routes";
// @ts-expect-error route file needed to be adapted to TS
import DiscountsModule from "@/modules/discounts/routes";
// @ts-expect-error route file needed to be adapted to TS
import AuthModule from "@/modules/auth/routes";
// @ts-expect-error route file needed to be adapted to TS
import ChannelsModule from "@/modules/sales-channels/routes";
// @ts-expect-error route file needed to be adapted to TS
import BrandingModule from "@/modules/branding/routes";

import MenusModule from "@/v2/module/menu/routes";
import PauseOrdersModule from "@/v2/module/pause-orders/routes";
import TipsModule from "@/v2/module/tips/routes";
import DashboardModule from "@/v2/module/dashboard/routes";
import OutletsV2Module from "@/v2/module/outlet/routes";
import ReactivationModule from "@/v2/module/reactivation/email/routes";
import UserManagementModule from "@/v2/module/user-management/routes";
import BillingModule from "@/v2/module/billing/routes";
import OnboardingModule from "@/v2/module/onboarding/routes";
import InboxModule from "@/v2/module/inbox/routes";
import QRCodeModule from "@/v2/module/qr-code/routes";
import PaymentLinkModule from "@/v2/module/payment-link/routes";
import PayoutModule from "@/v2/module/payout/routes";
import BusinessSettingsModule from "@/v2/module/business-settings/routes";
import LoyaltyModule from "@/v2/module/loyalty/routes";
import PATModule from "@/v2/module/pat/routes";
import ReviewsModule from "@/v2/module/reviews/routes";
import SpotsManagementModule from "@/v2/module/spots-management/routes";
import TabsModule from "@/v2/module/tabs/routes";
import { reportsRoutes } from "@/v2/module/report/routes";
import { spotsOccupancyRoutes } from "@/v2/module/spots-occupancy/routes";
import { reservationsRoutes } from "@/v2/module/reservations/routes";
import { crmRoutes } from "@/v2/module/customer-management/routes";
import { backOfficeSettingsRoutes } from "@/module/back-office-settings";
import { inventoryRoutes } from "@/module/inventory";
import { facebookCommerceRoutes } from "@/module/facebook-commerce";
import { campaignRoutes } from "@/module/campaign";

export const routes: Array<RouteConfig> = [
  {
    name: "auth.login",
    path: "/login",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/Login.vue"),
    props: true,
    meta: {
      isAuthRequired: false,
      title: "Login",
      layout: "raw",
    },
  },
  {
    name: "auth.magic-link",
    path: "/:hash/signin",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/Login.vue"),
    props: true,
    meta: {
      isAuthRequired: false,
      title: "Signin",
      layout: "raw",
    },
  },

  ...AuthModule,

  ...PauseOrdersModule,

  ...TipsModule,

  /**
   * Marketing routes
   */
  {
    name: "marketing.index",
    path: "/business/:businessId/marketing",
    component: () =>
      import(/* webpackChunkName: "general" */ "@/views/Marketing.vue"),
    props: true,
    meta: {
      title: "Marketing",
      permission: VIEW_MARKETING,
    },
  },

  /**
   * Order routes
   */
  {
    name: "orders.live",
    path: "/business/:businessId/orders/live",
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/LiveOrders.vue"),
    props: true,
    meta: {
      title: "Live Orders",
      permission: VIEW_LIVE_ORDERS,
    },
    children: [
      {
        name: "orders.live.view",
        path: ":orderId",
        props: true,
        component: () =>
          import(/* webpackChunkName: "orders" */ "@/views/LiveOrdersView.vue"),
        meta: {
          title: "Live Orders",
          permission: VIEW_LIVE_ORDERS,
        },
      },
    ],
  },
  {
    name: "orders.index",
    path: "/business/:businessId/orders",
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/OrderHistory.vue"),
    props: true,
    meta: {
      title: "Order History",
      permission: VIEW_ORDERS,
    },
  },

  /**
   * Item routes
   */
  {
    name: "items.index",
    path: "/business/:businessId/items",
    component: () =>
      import(/* webpackChunkName: "menu-builder" */ "@/views/Items.vue"),
    props: true,
    meta: {
      title: "Items & Modifiers",
      permission: VIEW_ITEMS,
    },
  },
  {
    name: "items.create",
    path: "/business/:businessId/items/create",
    component: () =>
      import(/* webpackChunkName: "menu-builder" */ "@/views/ItemsCreate.vue"),
    props: true,
    meta: {
      title: "Create - Items & Modifiers",
      permission: VIEW_ITEMS,
    },
  },
  {
    name: "items.edit",
    path: "/business/:businessId/items/:itemId/edit",
    component: () =>
      import(/* webpackChunkName: "menu-builder" */ "@/views/ItemsEdit.vue"),
    props: true,
    meta: {
      title: "View - Items & Modifiers",
      permission: VIEW_ITEMS,
    },
  },

  /**
   * Menu routes
   */
  ...MenusModule,
  {
    name: "menus.edit",
    path: "/business/:businessId/menus/:menuId/edit",
    component: () =>
      import(/* webpackChunkName: "menu-builder" */ "@/views/MenusEdit.vue"),
    props: true,
    meta: {
      title: "View - Menus",
      permission: VIEW_MENUS,
    },
  },

  /**
   * Modifier List routes
   */
  {
    name: "modifier-lists.index",
    path: "/business/:businessId/modifier-lists",
    component: () =>
      import(
        /* webpackChunkName: "menu-builder" */ "@/views/ModifierLists.vue"
      ),
    props: true,
    meta: {
      title: "Modifier Lists",
      permission: VIEW_MODIFIER_LISTS,
    },
  },
  {
    name: "modifier-lists.create",
    path: "/business/:businessId/modifier-lists/create",
    component: () =>
      import(
        /* webpackChunkName: "menu-builder" */ "@/views/ModifierListsCreate.vue"
      ),
    props: true,
    meta: {
      title: "Create - Modifier Lists",
      permission: VIEW_MODIFIER_LISTS,
    },
  },
  {
    name: "modifier-lists.edit",
    path: "/business/:businessId/modifier-lists/:modifierListId/edit",
    component: () =>
      import(
        /* webpackChunkName: "menu-builder" */ "@/views/ModifierListsEdit.vue"
      ),
    props: true,
    meta: {
      title: "View - Modifier Lists",
      permission: VIEW_MODIFIER_LISTS,
    },
  },

  /**
   * Register modules routes
   */
  ...DashboardModule,
  ...OutletsV2Module,
  ...OutletsModule,
  ...DiscountsModule,
  ...ChannelsModule,
  ...ReactivationModule,
  ...BillingModule,
  ...OnboardingModule,
  ...BrandingModule,
  ...UserManagementModule,
  ...InboxModule,
  ...QRCodeModule,
  ...reportsRoutes,
  ...PaymentLinkModule,
  ...PayoutModule,
  ...BusinessSettingsModule,
  ...LoyaltyModule,
  ...PATModule,
  ...ReviewsModule,
  ...SpotsManagementModule,
  ...TabsModule,
  ...spotsOccupancyRoutes,
  ...crmRoutes,
  ...reservationsRoutes,
  ...backOfficeSettingsRoutes,
  ...inventoryRoutes,
  ...facebookCommerceRoutes,
  ...campaignRoutes,

  /**
   * Error routes
   */
  {
    name: "error.403",
    path: "/access-denied",
    component: () => import(/* webpackChunkName: "errors" */ "@/views/403.vue"),
    meta: {
      title: "Access Denied",
      layout: "master",
    },
  },
  {
    name: "error.500",
    path: "/technical-issue",
    component: () => import(/* webpackChunkName: "errors" */ "@/views/500.vue"),
    meta: {
      title: "Technical Issue",
      layout: "empty",
    },
  },
  {
    path: "/business/:businessId",
    component: Index,
    meta: {
      title: "Dashboard",
      layout: "master",
    },
  },
  {
    name: "app.index",
    path: "/:isSignup?",
    props: true,
    component: Index,
    meta: {
      title: "Dashboard",
      layout: "master",
    },
  },
  {
    name: "error.404",
    path: "*",
    component: () => import(/* webpackChunkName: "errors" */ "@/views/404.vue"),
    meta: {
      title: "Page Not Found",
      layout: "master",
    },
  },
];
