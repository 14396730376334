












import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { AtomIcon } from "../../atom/icon";
import { IMolInfoBulbProps } from "./props";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("mol-info-bulb");

export default defineComponent<IMolInfoBulbProps>({
  name: "MolInfoBulb",
  directives: {
    tooltip: VTooltip,
  },
  components: {
    AtomIcon,
  },
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
