

























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system/atom/text";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("mol-form-control");

export default defineComponent({
  name: "MolFormControl",
  components: {
    AtomText,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    successMsg: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const message = computed(() => {
      return props.errorMsg || props.successMsg || false;
    });

    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      message,
    };
  },
});
