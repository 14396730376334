














import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
} from "@vue/composition-api";
import { AtomModal } from "../../atom/modal";

const css = bemBuilder("mol-iframe-modal");

export default defineComponent({
  name: "MolIframeModal",
  components: {
    AtomModal,
  },
  props: {
    iframeSrc: {
      type: String,
      required: true,
    },
    onClickClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onFormSubmit: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup(props) {
    const isTypeForm = computed(() => props.iframeSrc.includes("typeform.com"));

    function isEventFromIframe(eventOrigin: string) {
      const iframeSrcOrigin = props.iframeSrc
        .replace(/https?:\/\//, "")
        .split("/")[0];

      return eventOrigin.includes(iframeSrcOrigin);
    }

    function handleTypeFormEvents(event: MessageEvent) {
      if (!isEventFromIframe(event.origin)) return;

      if (event.data.type === "embed-auto-close-popup") {
        props.onFormSubmit();
      }
    }

    onMounted(() => {
      if (isTypeForm.value) {
        window.addEventListener("message", handleTypeFormEvents);
      }
    });

    onBeforeUnmount(() => {
      if (isTypeForm.value) {
        window.addEventListener("message", handleTypeFormEvents);
      }
    });

    return {
      css,
    };
  },
});
