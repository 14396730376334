import { VIEW_LOYALTY } from "@/utils/enums/permissions";

export default [
  {
    name: "loyalty.index",
    path: "/business/:businessId/loyalty",
    component: () =>
      import(
        /* webpackChunkName: "loyalty-module" */ "@/v2/module/loyalty/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Loyalty",
      permission: VIEW_LOYALTY,
    },
  },
];
