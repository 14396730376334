import { AxiosResponse } from "axios";

export enum RepoInboxErrorEnum {
  UNKNOWN = "unknown",
  WHATSAPP_START_ONBOARDING_FAILED = "whatsapp_start_onboarding_failed",
  GREETING_MESSAGE_NOT_FOUND = "greeting_message_not_found",
  COUNTRY_NOT_AVAILABLE = "country_not_available",
}

export interface IAxiosErrorResponse extends AxiosResponse {}

export interface IRepoInboxErrorResponse {
  code: RepoInboxErrorEnum;
  message: string;
  request?: Record<string, any>;
  response?: Record<string, any>;
}

export class RepoInboxError extends Error {
  error: IRepoInboxErrorResponse;

  constructor(error: IRepoInboxErrorResponse) {
    super(error.message);
    this.error = error;
    this.name = this.constructor.name;
  }
}
