








































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";
import { AtomGraphic } from "@/v2/new-design-system/atom/graphic";
import { AtomText } from "@/v2/new-design-system/atom/text";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { t } from "@/i18n";

const css = bemBuilder("org-tips-slider");

export default defineComponent({
  name: "OrgTipsSlider",
  components: {
    AtomGraphic,
    AtomText,
  },
  setup(_, context) {
    const currentTip = ref<number>(1);
    const animation = ref(null);

    function labelTip(item: number): string {
      const map = {
        [1]: t("design_system.org_tips_slider.tip_01"),
        [2]: t("design_system.org_tips_slider.tip_02"),
        [3]: t("design_system.org_tips_slider.tip_03"),
      };
      // @ts-ignore
      return map[item];
    }

    onMounted(() => {
      // @ts-ignore
      animation.value = setInterval(startAnimation, 5000);
    });

    onUnmounted(() => {
      // @ts-ignore
      if (animation.value) clearInterval(animation.value);
    });

    function startAnimation() {
      if (currentTip.value === 3) {
        currentTip.value = 1;
        return;
      }

      currentTip.value = currentTip.value + 1;
    }

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      currentTip,
      labelTip,
      animation,
    };
  },
});
