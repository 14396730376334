/**
 * Debounce function
 *
 * @param {Function} callback
 * @param {Number} wait Time in miliseconds to hold exec
 *
 * @returns {Function}
 */
export const debounce = (callback, wait) => {
  let timeout;

  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};
