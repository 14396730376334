





















import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, onMounted } from "@vue/composition-api";
import { IAtomInputProps } from "./props";
import { boolean, func, string } from "@/v2/util/prop/type";

const css = bemBuilder("atom-input");

/**
 * @deprecated
 * use `AtomInput` from `v2/new-design-system` instead
 */

export default defineComponent<IAtomInputProps>({
  name: "AtomInput",
  inheritAttrs: false,
  props: {
    value: string().isRequired().use(),
    error: boolean().use(),
    loading: boolean().use(),
    onChange: func().use(),
  },
  setup(props, ctx) {
    ctx.root.$listeners;

    const listeners = computed(() => {
      return {
        ...ctx.root.$listeners,
        input: (event: any) => props.onChange(event.target.value),
      };
    });

    onMounted(() => props.onChange(props.value));

    return {
      listeners,
      css,
    };
  },
});
