import * as types from "./mutation_types";
import Outlets from "@/api/outlets";
import { action as fetchAction } from "@/store/fetchBase";
import { action as resetAction } from "@/store/resetBase";
import { fetchDeliverectChannelLinks } from "@/v2/repo/fetch-deliverect-channel-links";
import { fetchMenuIntegration } from "@/v2/repo/fetch-menu-integration";

/**
 * Fetch a list of outlets visible by the authenticated tenant
 *
 * @returns {Array}
 */
export const fetch = async ({ commit, dispatch }) => {
  const outlets = await fetchAction(commit, types.FETCH, Outlets.get, {
    include: [
      "orderingServices.settings",
      "deliveryIntegrations",
      "address.area",
    ].join(","),
  });

  Promise.all([
    await dispatch("attachDeliverectState"),
    await dispatch("attachMenuIntegrationState"),
  ]);

  return outlets;
};

export const attachDeliverectState = async ({ state, commit }) => {
  const deliverect = await fetchDeliverectChannelLinks();
  await commit(types.ATTACH_DELIVERECT_STATE, deliverect);
};

export const attachMenuIntegrationState = async ({ state, commit }) => {
  const menuIntegration = await fetchMenuIntegration();
  await commit(types.ATTACH_MENU_INTEGRATION_STATE, menuIntegration);
};

/**
 * Reset the module to the initial state
 *
 * @returns {Void}
 */
export const reset = async ({ commit }) => resetAction(commit, types.RESET);
