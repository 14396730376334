





















































import { t } from "@/i18n";
import {
  AtomModal,
  AtomTextTypeEnum,
  MolFormControl,
  OrgFormInput,
  AtomText,
  AtomButton,
  AtomTextColorEnum,
  AtomButtonTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
} from "@vue/composition-api";
import {
  loadStripe,
  Stripe,
  PaymentMethod,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { wait } from "@/v2/util/wait";
import { fetchStripeClientSecret } from "@/v2/repo/fetch-stripe-client-secret";
import { fetchCurrentSubscriptionId } from "@/v2/repo/fetch-current-subscription-id";
import { fetchPaymentMethodSetupCompletion } from "@/v2/repo/fetch-payment-method-setup-completion";
import {
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
} from "../state";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { billingMethodTrack } from "../method/track";
import { EventEnum } from "@/v2/enum";
import { isCreateBusinessFlowStarted } from "@/v2/module/billing/utils/create-business";
import { createBusinessTrack } from "@/v2/util/analytics/createBusinessTrackEvent";

const css = bemBuilder("billing-payment");
let stripeInstance: Stripe | null;
let cardNumberElm: StripeCardNumberElement;
let cardExpiryElm: StripeCardExpiryElement;
let cardCVCElm: StripeCardCvcElement;

const name = ref("");
const cardNumberValid = ref(false);
const cardExpiryValid = ref(false);
const cardCVCValid = ref(false);
const hasError = ref(false);

export default defineComponent({
  name: "BillingPayment",
  components: {
    AtomModal,
    OrgFormInput,
    MolFormControl,
    AtomText,
    AtomButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const vm = getCurrentInstance();

    async function checkTheCard() {
      disablePaymentInputs(true);

      let subscriptionId: string;
      let stripeClientSecret: string;

      // @ts-ignore
      const analytics = vm?.proxy.$analytics;
      if (isCreateBusinessFlowStarted(props.businessId)) {
        createBusinessTrack(
          analytics,
          EventEnum.CLICKED_ON_BUTTON,
          billingGetState("business").value.id,
          {
            label: "Clicked to submit payment method",
          }
        );
      }

      try {
        subscriptionId = await fetchCurrentSubscriptionId(
          billingGetState("business").value.id
        );
        stripeClientSecret = await fetchStripeClientSecret(subscriptionId);
      } catch {
        disablePaymentInputs(false);
        return;
      }

      if (!stripeInstance) return;

      const confirmationResult = await stripeInstance.confirmCardSetup(
        stripeClientSecret,
        {
          payment_method: {
            card: cardNumberElm,
            billing_details: {
              name: name.value,
            },
          },
        }
      );

      if (!confirmationResult.error) {
        await waitForSetupCompletion(
          subscriptionId,
          confirmationResult.setupIntent?.payment_method || ""
        );

        billingMethodTrack(analytics, EventEnum.ADDED_PAYMENT_METHOD, {
          method: "credit_card",
        });
      } else {
        hasError.value = true;
      }

      disablePaymentInputs(false);
    }

    function disablePaymentInputs(disabled: boolean): void {
      isLoading.value = disabled;
      cardNumberElm.update({ disabled });
      cardExpiryElm.update({ disabled });
      cardCVCElm.update({ disabled });
    }

    async function waitForSetupCompletion(
      subscriptionId: string,
      paymentMethodId: string | PaymentMethod
    ): Promise<void> {
      return new Promise<void>((res) => {
        const interval = setInterval(async () => {
          let paymentMethod = await fetchPaymentMethodSetupCompletion(
            subscriptionId,
            paymentMethodId
          );

          if (paymentMethod) {
            res();
            billingSetState("selectedCardInfo", paymentMethod);
            window.clearInterval(interval);
            billingSetState("stage", BillingStageStateEnum.REVIEW_ORDER);
          }
        }, 3000);
      });
    }

    const isFormValid = computed(
      () =>
        name.value &&
        cardNumberValid.value &&
        cardCVCValid.value &&
        cardExpiryValid.value
    );

    return {
      t,
      css,
      checkTheCard,
      name,
      isLoading,
      isFormValid,
      hasError,
      billingSetStageDormant: () =>
        billingSetState("stage", BillingStageStateEnum.DORMANT),
      BillingStageStateEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
    };
  },
  destroyed() {
    name.value = "";
    cardNumberValid.value = false;
    cardExpiryValid.value = false;
    cardCVCValid.value = false;
    hasError.value = false;
  },
  async mounted() {
    await wait(100);
    const elementSlots = this.$refs;

    // eslint-disable-next-line
    // @ts-ignore
    stripeInstance = await loadStripe(process.env.VUE_APP_STRIPE_KEY);

    if (stripeInstance) {
      const elementsInstance = stripeInstance?.elements();

      // @ts-ignore
      cardNumberElm = elementsInstance.create("cardNumber", {
        showIcon: true,
        style: {
          base: {
            border: "1px solid black",
          },
        },
      });
      cardExpiryElm = elementsInstance.create("cardExpiry");
      cardCVCElm = elementsInstance.create("cardCvc");

      cardNumberElm.mount(elementSlots.sleCardNumber as HTMLElement);
      cardExpiryElm.mount(elementSlots.sleCardExpiry as HTMLElement);
      cardCVCElm.mount(elementSlots.sleCardCVC as HTMLElement);

      cardNumberElm.on("change", function (event) {
        hasError.value = false;

        if (event.complete) {
          cardNumberValid.value = true;
        } else if (event.error || event.empty) {
          cardNumberValid.value = false;
        }
      });

      cardExpiryElm.on("change", function (event) {
        hasError.value = false;
        if (event.complete) {
          cardExpiryValid.value = true;
        } else if (event.error || event.empty) {
          cardExpiryValid.value = false;
        }
      });

      cardCVCElm.on("change", function (event) {
        hasError.value = false;
        if (event.complete) {
          cardCVCValid.value = true;
        } else if (event.error || event.empty) {
          cardCVCValid.value = false;
        }
      });
    } else {
      billingSetState("stage", BillingStageStateEnum.DORMANT);
      generalErrorToast();
    }
  },
});
