import { map, isNil, reject, propOr } from "ramda";
import {
  USE_BETA_FEATURES,
  VIEW_REPORTS,
  VIEW_INBOX,
  VIEW_PAYMENT_LINKS,
  VIEW_PAYOUTS,
  VIEW_TABLE_PAYMENTS,
  VIEW_BUSINESS_SETTINGS,
  VIEW_PAYOUT_PAYEE,
  VIEW_WHATS_NEW,
  BUSINESS_CREATE,
  VIEW_LOYALTY,
  VIEW_TABLE_PAYMENT_HISTORY,
  VIEW_REVIEWS,
  VIEW_SPOTS_MANAGEMENT,
  VIEW_LIVE_TABS,
  VIEW_TABS_HISTORY,
  VIEW_SPOTS_OCCUPANCY,
  VIEW_BACK_OFFICE_SETTINGS,
  VIEW_OUTLET_DINE_IN_PAYMENTS,
  VIEW_TIPS,
  VIEW_RESERVATIONS,
  VIEW_CUSTOM_PAYMENT_METHOD,
  EDIT_CUSTOM_PAYMENT_METHOD,
  VIEW_VOID_REASONS,
  VIEW_FACEBOOK_COMMERCE,
  VIEW_CAMPAIGNS,
} from "@/utils/enums/permissions";

/** @type {Object} API x App permissions map */
const mappedPermissions = {
  "orders-accept": "ACCEPT_ORDER",
  "orders-reject": "REJECT_ORDER",
  "orders-print": "PRINT_ORDER",
  "orders-close": "CLOSE_ORDER",
  "orders-cancel": "CANCEL_ORDER",
  "orders-mark_as_ready": "MARK_ORDER_AS_READY",
  "orders-complete": "COMPLETE_ORDER",
  "orders-live-view": "VIEW_LIVE_ORDERS",
  "orders-schedule": "SCHEDULE_ORDER",
  "orders-view": "VIEW_ORDERS",
  "orders-view-placed": "VIEW_PLACED_ORDERS",
  "orders-view-accepted": "VIEW_ACCEPTED_ORDERS",
  "orders-view-ready": "VIEW_READY_ORDERS",
  "orders-view-complete": "VIEW_COMPLETE_ORDERS",
  "orders-view-rejected": "VIEW_REJECTED_ORDERS",
  "orders-view-scheduled": "VIEW_SCHEDULED_ORDERS",
  "orders-view-cancelled": "VIEW_CANCELLED_ORDERS",
  "dashboard-view": "VIEW_DASHBOARD",
  "billing-view": "VIEW_BILLING",
  "onboarding-view": "VIEW_ONBOARDING",
  "customers-view": "VIEW_CUSTOMERS",
  "inventory-view": "VIEW_INVENTORY",
  "settings-view": "VIEW_SETTINGS",
  "marketing-view": "VIEW_MARKETING",
  "items-view": "VIEW_ITEMS",
  "items-import": "IMPORT_ITEMS",
  "modifier-lists-view": "VIEW_MODIFIER_LISTS",
  "menus-view": "VIEW_MENUS",
  "menus-edit-notes": "EDIT_MENU_NOTES",
  "menus-edit-images": "EDIT_MENU_IMAGES",
  "outlets-view": "VIEW_OUTLETS",
  "outlets-edit": "EDIT_OUTLET",
  "widget-view": "VIEW_WIDGET",
  "widget-edit": "EDIT_WIDGET",
  "discounts-view": "VIEW_DISCOUNTS",
  "discounts-edit": "EDIT_DISCOUNT",
  "channels-view": "VIEW_CHANNELS",
  "channels-edit": "EDIT_CHANNEL",
  "reactivation-edit": "EDIT_REACTIVATION",
  "users-view": "VIEW_USERS",
  "users-edit": "EDIT_USERS",
  "super-use-beta-features": USE_BETA_FEATURES,
  "inbox-view": VIEW_INBOX,
  "reports-view": VIEW_REPORTS,
  "payment-links-view": VIEW_PAYMENT_LINKS,
  "table-payments-view": VIEW_TABLE_PAYMENTS,
  "table-payments-history-view": VIEW_TABLE_PAYMENT_HISTORY,
  "statement-of-accounts-view": VIEW_PAYOUTS,
  "business-settings-view": VIEW_BUSINESS_SETTINGS,
  "payout-payees-view": VIEW_PAYOUT_PAYEE,
  "whats-new-view": VIEW_WHATS_NEW,
  "businesses-create": BUSINESS_CREATE,
  "reward-view": VIEW_LOYALTY,
  "feedbacks-view": VIEW_REVIEWS,
  "tables-view": VIEW_SPOTS_MANAGEMENT,
  "tab-management-view": VIEW_LIVE_TABS,
  "tab-history-view": VIEW_TABS_HISTORY,
  "table-occupancy-view": VIEW_SPOTS_OCCUPANCY,
  "outlet-dine-in-payments-view": VIEW_OUTLET_DINE_IN_PAYMENTS,
  "tip-transactions-view": VIEW_TIPS,
  "reservations-view": VIEW_RESERVATIONS,
  "custom-payment-methods-view": VIEW_CUSTOM_PAYMENT_METHOD,
  "custom-payment-methods-edit": EDIT_CUSTOM_PAYMENT_METHOD,
  "void-category-reason-view": VIEW_VOID_REASONS,
  "fbe-integration-view": VIEW_FACEBOOK_COMMERCE,
  "campaigns-view": VIEW_CAMPAIGNS,
};

/** Parser to normalize data coming from the API */
export default class TenantParser {
  /**
   * Tenant Parser Constuctor.
   *
   * @param {Object} response Tenant profile coming from API
   */
  constructor(response) {
    this.response = response;
  }

  /**
   * Convert permissions nomenclature to app format
   *
   * @returns {Object}
   */
  normalizedPermissions() {
    const mapped = map((val) => {
      const permission = mappedPermissions[val];
      if (!isNil(permission)) return permission;
    }, this.response.permissions);

    const canSeeBackOffice = mapped.some((e) =>
      [VIEW_CUSTOM_PAYMENT_METHOD, VIEW_VOID_REASONS].includes(e)
    );

    if (canSeeBackOffice) {
      mapped.push(VIEW_BACK_OFFICE_SETTINGS);
    }

    return reject(isNil, mapped);
  }

  /**
   * Convert organization nomenclature to app format
   *
   * @param {Object} organization
   * @returns {Object}
   */
  normalizedOrganization(organization) {
    return {
      id: organization.id,
      name: organization.name,
      country: organization.country,
      createdAt: organization.created_at,
    };
  }

  /**
   * Parses the tenant profile coming from the API to a valid format
   *
   * @returns {Object}
   */
  fromApi() {
    const tenant = {
      id: this.response.id,
      firstName: this.response.fname,
      lastName: this.response.lname,
      email: this.response.email,
      phone: this.response.phone,
      roles: this.response.roles,
      permissions: [],

      outletId: parseInt(this.response.outlet_id),
    };

    const organization = propOr(null, "organization", this.response);
    if (organization) {
      tenant.organization = this.normalizedOrganization(organization);
    }

    return tenant;
  }
}
