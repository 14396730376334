
















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { IMolInputPasswordProps } from "./props";
import { AtomInput } from "@/v2/new-design-system/atom/input";
import { AtomIcon } from "@/v2/new-design-system/atom/icon";
import { string, boolean, func } from "@/v2/util/prop/type";

const css = bemBuilder("mol-input-password");

export default defineComponent<IMolInputPasswordProps>({
  name: "MolInputPassword",
  components: {
    AtomIcon,
    AtomInput,
  },
  props: {
    value: string().isRequired().use(),
    placeholder: string().use(),
    errorMsg: boolean().use(),
    onChange: func().use(),
  },
  setup() {
    const type = ref<string>("password");

    const hidePassword = computed((): boolean => {
      return type.value === "password";
    });

    const iconVisibility = computed((): string => {
      return hidePassword.value ? "eye" : "eye-slash";
    });

    function toggleVisibility() {
      type.value = hidePassword.value ? "text" : "password";
    }

    return {
      css,
      type,
      iconVisibility,
      toggleVisibility,
    };
  },
});
