import * as actions from "./actions";
import * as getters from "./getters";
import * as types from "./mutation_types";
import { clone } from "ramda";
import {
  mutation as fetchMutation,
  state as fetchState,
} from "@/store/fetchBase";
import { mutation as resetMutation } from "@/store/resetBase";

/** @type {Object} Initial state for the module */
export const initialState = {
  ...fetchState(),
};

export default {
  // Clone of the initialState to set to the module
  state: clone(initialState),

  // List of available module mutations
  mutations: {
    ...fetchMutation(types.FETCH),
    ...resetMutation(types.RESET, initialState),
    [types.SET_PERMISSIONS](state, permissions) {
      state.data = { ...state.data, permissions };
    },
    [types.UPDATE_USER_PROFILE](state, userInfo) {
      state.data = {
        ...state.data,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      };
    },
  },

  // List of available module actions
  actions,

  // List of available module getters
  getters,

  // Add namespace into module
  namespaced: true,
};
