
































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  getCurrentInstance,
} from "@vue/composition-api";
import { AtomGraphic, AtomIcon } from "@/v2/design-system";
import { sidebarIsCollapsed, sidebarMenuMobileIsOpen } from "./state";
import { sidebarMethodToggleMenuMobile } from "./method";
import { string, array } from "@/v2/util/prop/type";
import { useHelpers } from "@/v2/composable/use-helpers";
import SidebarBrandLogo from "./SidebarBrandLogo.vue";

const css = bemBuilder("sidebar-header");

export default defineComponent({
  name: "SidebarHeader",
  components: {
    AtomGraphic,
    AtomIcon,
    SidebarBrandLogo,
  },
  props: {
    businessId: string().isRequired().use(),
    permissions: array().isRequired().use(),
  },
  setup(props) {
    const currentInstance = getCurrentInstance();
    const brandLogo = computed(() => {
      const business = useHelpers().getCurrentBusiness(props.businessId) || {};
      return business.logo;
    });

    function homeDefault() {
      document.body.style.overflow = "";
      sidebarMenuMobileIsOpen.value = false;

      if (props.permissions.includes("VIEW_ONBOARDING")) {
        currentInstance?.proxy.$router.push({
          name: "onboarding",
          params: {
            businessId: props.businessId,
          },
        });
        return;
      }

      if (props.permissions.includes("VIEW_LIVE_TABS")) {
        currentInstance?.proxy.$router.push({
          name: "tabs.live",
          params: {
            businessId: props.businessId,
          },
        });
        return;
      }

      if (props.permissions.includes("VIEW_LIVE_ORDERS")) {
        currentInstance?.proxy.$router.push({
          name: "orders.live",
          params: {
            businessId: props.businessId,
          },
        });
      }
    }

    return {
      css,
      brandLogo,
      homeDefault,
      sidebarMethodToggleMenuMobile,
      sidebarIsCollapsed,
      sidebarMenuMobileIsOpen,
    };
  },
});
