











import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system/atom/icon";

const css = bemBuilder("mol-collapse-indicator");

export default defineComponent({
  name: "MolCollapseIndicator",
  components: {
    AtomIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const nameIcon = computed((): string => {
      return props.open ? "arrow-down" : "arrow-right";
    });

    return {
      css,
      nameIcon,
    };
  },
});
