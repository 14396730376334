import { VIEW_SETTINGS } from "@/utils/enums/permissions";

export default [
  {
    name: "settings.index",
    path: "/business/:businessId/settings",
    component: () =>
      import(
        /* webpackChunkName: "pause-orders-module" */ "@/v2/module/pause-orders/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Pause Orders",
      permission: VIEW_SETTINGS,
    },
  },
];
