import { VIEW_DASHBOARD } from "@/utils/enums/permissions";

export default [
  {
    name: "dashboard.index",
    path: "/business/:businessId/analytics/:isSignup?",
    component: () =>
      import(
        /* webpackChunkName: "dashboard-module" */ "@/v2/module/dashboard/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Dashboard",
      permission: VIEW_DASHBOARD,
    },
  },
];
