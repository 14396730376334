import { useStore } from "@/store";
import { IVuexBusiness, IVuexOutlet, IVuexTenant } from "@/store/types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useHelpers = () => {
  const { getters } = useStore();

  const getTenant = (): IVuexTenant & { name: string } => {
    const tenant = getters["auth/getData"] as IVuexTenant;

    return {
      ...tenant,
      ...{
        name: `${tenant.firstName} ${tenant.lastName}`.trim(),
      },
    };
  };

  const getCurrentBusiness = (businessId: string): IVuexBusiness => {
    const businesses = getters["businesses/getData"] as Array<IVuexBusiness>;
    const business = businesses.find((business) => business.id === businessId);

    if (!business) {
      throw new Error("useHelpers[currentBusiness]: business not found");
    }

    return business;
  };

  const hasDeliverect = (businessId: string): Boolean => {
    const outlets = getters["outlets/getData"] as Array<IVuexOutlet>;

    return Boolean(
      outlets.find(
        (outlet) =>
          outlet.businessId === businessId && outlet.isDeliverectEnabled
      )
    );
  };

  const outletHasDeliverect = (outletId: string): Boolean => {
    const outlets = getters["outlets/getData"] as Array<IVuexOutlet>;
    const outlet = outlets.find((outlet) => outlet.id === outletId);

    return Boolean(outlet?.isDeliverectEnabled);
  };

  const hasMenuIntegration = (businessId: string): Boolean => {
    const outlets = getters["outlets/getData"] as Array<IVuexOutlet>;

    return Boolean(
      outlets.find(
        (outlet) => outlet.businessId === businessId && outlet.isMenuIntegrated
      )
    );
  };

  const getOutletsFromBusiness = (businessId: string): Array<IVuexOutlet> => {
    const outlets = getters["outlets/getData"] as Array<IVuexOutlet>;

    return outlets.filter((outlet) => outlet.businessId === businessId);
  };

  return {
    getCurrentBusiness,
    hasDeliverect,
    outletHasDeliverect,
    hasMenuIntegration,
    getTenant,
    getOutletsFromBusiness,
  };
};
