<template>
  <component :is="iconLoader" :style="iconStyle" :fill="color" />
</template>

<script>
/**
 * @deprecated
 * use `AtomIcon` from `@/v2/new-design-system` instead
 */
export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "currentColor",
    },
    size: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
    iconLoader() {
      // template literals doesn't work well with dynamic import on jest
      return () =>
        import(
          /* webpackChunkName: "icon-[request]" */ "./svgs/" +
            this.icon +
            ".svg"
        );
    },
  },
};
</script>
