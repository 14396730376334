











































































import {
  AtomButton,
  AtomCard,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButtonTypeEnum,
  OrgInfoLockButton,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { boolean, func, number, string } from "@/v2/util/prop/type";
import { defineComponent } from "@vue/composition-api";
import { IBillingPlanCardProps } from "./props";
import { t, tc } from "@/i18n";
import { VTooltip } from "v-tooltip";
import { AtomButtonSizeEnum } from "@/v2/new-design-system";

const css = bemBuilder("billing-plan-card");

export default defineComponent<IBillingPlanCardProps>({
  name: "BillingPlanCard",
  components: {
    AtomCard,
    AtomText,
    AtomButton,
    OrgInfoLockButton,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    heading: string().isRequired().use(),
    price: string().isRequired().use(),
    pricePerExtraOrder: string().isRequired().use(),
    includedOutletCount: number().isRequired().use(),
    includedOrderCount: number().isRequired().use(),
    ctaLabel: string().use(),
    showBadge: boolean().use(),
    extraOutletCount: number().use(),
    onAddOutlet: func().use(),
    onSubtractOutlet: func().use(),
    onClickCta: func().use(),
    decor: number().use(),
    disabled: boolean().use(),
    isLoadingNextStep: boolean().use(),
  },
  setup() {
    return {
      t,
      tc,
      css,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
