<template>
  <div class="form-field">
    <div
      class="form-field__label-wrapper"
      :class="{ 'justify-content-between': !tooltip }"
    >
      <label
        v-if="label"
        :for="_uid"
        class="form-field__label"
        :class="{ 'form-field__label--required': required }"
        data-test="label"
      >
        {{ label }}
      </label>
      <HelpTooltip v-if="tooltip" :content="tooltip" />
      <span v-if="hint" class="form-field__hint" data-test="hint">
        {{ hint }}
      </span>
    </div>
    <slot :id="_uid" />
    <span v-if="caption" class="form-field__caption" data-test="caption">
      {{ caption }}
    </span>
    <span
      v-if="message"
      class="form-field__caption"
      data-test="message"
      :class="{
        'form-field__caption--error': error,
        'form-field__caption--positive': positive,
      }"
    >
      {{ message }}
    </span>
  </div>
</template>

<script>
import HelpTooltip from "./HelpTooltipDepricated.vue";

/**
 * @deprecated
 * use `MolFormControl` from `@/v2/new-design-system` instead
 */

export default {
  name: "FormControl",
  components: {
    HelpTooltip,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    positive: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
  },
  computed: {
    message() {
      return this.error || this.positive || false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field {
  &__label-wrapper {
    display: flex;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }

  &__label {
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    color: #444444;
    margin-bottom: 0;

    &--required:after {
      content: "*";
      display: inline-block;
    }
  }

  &__caption {
    display: block;
    color: #444444;
    font-size: 13px;
    margin-top: 3px;

    &--error {
      color: #e42f46;
      margin-bottom: 5px;
    }
  }

  &__hint {
    font-size: 0.8125rem;
    color: #84a0b2;
  }
}
</style>
