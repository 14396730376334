








































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import Multiselect from "vue-multiselect";
import { AtomIcon } from "../../atom/icon";

const css = bemBuilder("mol-multiselect");

interface IList {
  name: string;
  id: string | number;
}

export default defineComponent({
  name: "MolMultiselect",
  components: {
    Multiselect,
    AtomIcon,
  },
  props: {
    list: {
      type: Array as PropType<IList[]>,
      required: true,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    onInput: {
      type: Function as PropType<(model: IList) => void>,
      required: true,
    },
    trackBy: {
      type: String,
      default: "id",
    },
    defaultValue: {
      type: Array as PropType<Array<IList>>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const model = ref<IList[]>();
    const label = computed(() => {
      if (!model.value) return null;

      return model.value
        .map((item: IList) => item.name)
        .toString()
        .replace(/,/g, ", ");
    });
    watch(
      () => props.defaultValue,
      (value) => {
        if (value?.[0] === null) {
          model.value = undefined;
        } else {
          model.value = value?.[0] === undefined ? undefined : value;
        }
      },
      { immediate: true }
    );
    return {
      t,
      css,
      model,
      label,
    };
  },
});
