






import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomAttentionBadgeTypeEnum } from "./type.enum";

const css = bemBuilder("atom-attention-badge");

export default defineComponent({
  name: "AtomAttentionBadge",
  props: {
    type: {
      type: String as PropType<AtomAttentionBadgeTypeEnum>,
      default: AtomAttentionBadgeTypeEnum.WARN,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
