/* eslint-disable */

import { isInteger } from "./validator";

/**
 * @deprecated
 * Configure the number type
 *
 * Default Value: 0
 */
export const number = typeFactory<number>(Number, 0);

/**
 * Configure the integer type
 *
 * Default Value: 0
 */
export const integer = typeFactory<number>(Number, 0, isInteger);

/**
 * @deprecated
 * Configure the string type
 *
 * Default Value: ""
 */
export const string = typeFactory<string>(String, "");

/**
 * @deprecated
 * Configure the boolean type
 *
 * Default Value: false
 */
export const boolean = typeFactory<boolean>(Boolean, false);

/**
 * @deprecated
 * Configure the object type
 *
 * Default Value: {}
 */
export const object = typeFactory<object>(Object, () => ({}));

/**
 * @deprecated
 * Configure the array type
 *
 * Default Value: []
 */
// @ts-ignore
export const array = typeFactory<[]>(Array, () => []);

/**
 * @deprecated
 * Configure the function type
 *
 * Default Value: () => {}
 */
export const func = typeFactory<() => void>(Function, () => {});

interface ITypeReturn<defaultType> {
  isRequired: () => ITypeReturn<defaultType>;
  setDefault: (value: defaultType) => ITypeReturn<defaultType>;
  setValidator: (
    validator: (value: any) => boolean
  ) => ITypeReturn<defaultType>;
  use: () => {
    type: any;
    default: defaultType;
    required: boolean;
    validator: (value: any) => boolean;
  };
}

function typeFactory<defaultType>(
  type: any,
  initialDefault: defaultType,
  defaultValidator?: (value: any) => boolean
) {
  return function(): ITypeReturn<defaultType> {
    let defaultValue = initialDefault;
    let required = false;
    let validator: any;

    const publicAPI = {
      isRequired: () => {
        required = true;
        return publicAPI;
      },
      setDefault: (value: any) => {
        defaultValue = value;
        return publicAPI;
      },
      setValidator: (someValidator: any) => {
        validator = someValidator;
        return publicAPI;
      },
      use: () => {
        return {
          type,
          required,
          default: defaultValue,
          validator: (value: any): boolean => {
            if (defaultValidator) return defaultValidator(value);
            if (validator) return validator(value);

            return true;
          }
        };
      }
    };

    return publicAPI;
  };
}

interface IPublicApi {
  isRequired: () => any;
  setDefault: (value: any) => any;
  setValidator: (someValidator: any) => any;
  use: () => {
    type: any;
    required: boolean;
    default: any;
    validator: (value: any) => boolean;
  };
}
