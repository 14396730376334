import { VIEW_CUSTOM_PAYMENT_METHOD } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { CustomPaymentMethodRouteEnum } from "./custom-payment-method-route.enum";
import { t } from "@/i18n";

export const customPaymentMethodRoutes: Array<RouteConfig> = [
  {
    name: CustomPaymentMethodRouteEnum.MAIN,
    path: "custom-payment-method",
    component: () =>
      import(
        /* webpackChunkName: "custom-payment-method-module" */ "../view/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.custom_payment_method.title_page"),
      permission: VIEW_CUSTOM_PAYMENT_METHOD,
    },
  },
];
