




































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import { AtomButton } from "@/v2/new-design-system/atom/button";
import { AtomModal } from "@/v2/new-design-system/atom/modal";
import { AtomText } from "@/v2/new-design-system/atom/text";
import { AtomTextTypeEnum } from "@/v2/new-design-system/atom/text/type.enum";
import { AtomTextColorEnum } from "@/v2/new-design-system/atom/text/color.enum";
import { AtomButtonSizeEnum } from "@/v2/new-design-system/atom/button/size.enum";
import { AtomButtonTypeEnum } from "@/v2/new-design-system/atom/button/type.enum";
import { MolDeleteButton } from "@/v2/new-design-system/mol/delete-button";

const css = bemBuilder("mol-modal-confirm");

export default defineComponent({
  name: "MolModalConfirm",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    MolDeleteButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    cancelButtonLabel: {
      type: String,
      default: "",
    },
    confirmButtonLabel: {
      type: String,
      default: "",
    },
    displayConfirmIcon: {
      type: Boolean,
      default: true,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    onClickConfirm: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const isDeleting = ref(false);

    async function onDelete() {
      isDeleting.value = true;
      await props.onClickConfirm();
      isDeleting.value = false;
    }

    return {
      css,
      t,
      isDeleting,
      onDelete,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
