import { VIEW_BACK_OFFICE_SETTINGS } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { BackOfficeSettingsRouteEnum } from "./back-office-settings-route.enum";
import { customPaymentMethodRoutes } from "@/module/custom-payment-method";
import { voidReasonRoutes } from "@/module/void-reason";
import { t } from "@/i18n";

export const backOfficeSettingsRoutes: Array<RouteConfig> = [
  {
    name: BackOfficeSettingsRouteEnum.MAIN,
    path: "/business/:businessId/back-office-settings",
    component: () =>
      import(
        /* webpackChunkName: "back-office-settings-module" */ "../view/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.back_office_settings.header"),
      permission: VIEW_BACK_OFFICE_SETTINGS,
    },
    children: [...customPaymentMethodRoutes, ...voidReasonRoutes],
  },
];
