import { billingGetState } from "../state";
import {
  analyticsEventCommonData,
  trackEvent,
} from "@/v2/util/analytics/trackEvent";
import { EventCategoryEnum, EventEnum } from "@/v2/enum";
import { IAnalytics } from "@/plugins/analytics/types";

export function billingMethodTrack(
  analytics: IAnalytics,
  eventName: EventEnum,
  additionalData: Record<string, any> = {}
): void {
  trackEvent({
    // @ts-ignore
    analytics,
    eventCategory: EventCategoryEnum.BILLING,
    eventName,
    additionalData: {
      ...analyticsEventCommonData([
        ["business", billingGetState("business").value.id],
      ]),
      ...additionalData,
    },
  });
}
