import { render, staticRenderFns } from "./StatusMarker.vue?vue&type=template&id=526f6ae4&scoped=true&"
import script from "./StatusMarker.vue?vue&type=script&lang=ts&"
export * from "./StatusMarker.vue?vue&type=script&lang=ts&"
import style0 from "./StatusMarker.vue?vue&type=style&index=0&id=526f6ae4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526f6ae4",
  null
  
)

export default component.exports