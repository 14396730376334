import { t } from "@/i18n";
import { OrderingServiceOutletEnum, OrderStatusEnum } from "../enum";

export const getTranslatedOrderStatus = (
  mode: OrderingServiceOutletEnum,
  status: OrderStatusEnum
): string => {
  if (mode === OrderingServiceOutletEnum.DINE_IN) {
    return getForDinein(status);
  }
  if (mode === OrderingServiceOutletEnum.PICKUP) {
    return getForPickup(status);
  }

  return getForDelivery(status);
};

const getForDinein = (status: OrderStatusEnum): string => {
  const map = {
    [OrderStatusEnum.ACCEPTED]: t("terms_dine_in.order_status.accepted"),
    [OrderStatusEnum.READY]: t("terms_dine_in.order_status.ready"),
    [OrderStatusEnum.COMPLETED]: t("terms_dine_in.order_status.completed"),
    [OrderStatusEnum.REJECTED]: t("terms_dine_in.order_status.rejected"),
    [OrderStatusEnum.CANCELLED]: t("terms_dine_in.order_status.cancelled"),
    [OrderStatusEnum.CREATED]: t("terms_dine_in.order_status.created"),
    [OrderStatusEnum.DELIVERED]: t("terms_dine_in.order_status.delivered"),
    [OrderStatusEnum.PLACED]: t("terms_dine_in.order_status.placed"),
  };

  return map[status];
};

const getForDelivery = (status: OrderStatusEnum): string => {
  const map = {
    [OrderStatusEnum.ACCEPTED]: t("terms_delivery.order_status.accepted"),
    [OrderStatusEnum.READY]: t("terms_delivery.order_status.ready"),
    [OrderStatusEnum.COMPLETED]: t("terms_delivery.order_status.completed"),
    [OrderStatusEnum.REJECTED]: t("terms_delivery.order_status.rejected"),
    [OrderStatusEnum.CANCELLED]: t("terms_delivery.order_status.cancelled"),
    [OrderStatusEnum.CREATED]: t("terms_delivery.order_status.created"),
    [OrderStatusEnum.DELIVERED]: t("terms_delivery.order_status.delivered"),
    [OrderStatusEnum.PLACED]: t("terms_delivery.order_status.placed"),
  };

  return map[status];
};

const getForPickup = (status: OrderStatusEnum): string => {
  const map = {
    [OrderStatusEnum.ACCEPTED]: t("terms_pickup.order_status.accepted"),
    [OrderStatusEnum.READY]: t("terms_pickup.order_status.ready"),
    [OrderStatusEnum.COMPLETED]: t("terms_pickup.order_status.completed"),
    [OrderStatusEnum.REJECTED]: t("terms_pickup.order_status.rejected"),
    [OrderStatusEnum.CANCELLED]: t("terms_pickup.order_status.cancelled"),
    [OrderStatusEnum.CREATED]: t("terms_pickup.order_status.created"),
    [OrderStatusEnum.DELIVERED]: t("terms_pickup.order_status.delivered"),
    [OrderStatusEnum.PLACED]: t("terms_pickup.order_status.placed"),
  };

  return map[status];
};
