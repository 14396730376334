





















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { IOrgFormInputProps } from "./props";
import { boolean, func, string } from "@/v2/util/prop/type";
import { AtomInput } from "@/v2/design-system/atom/input";
import { MolFormControl } from "@/v2/design-system/mol/form-control";

const css = bemBuilder("org-form-input");

/**
 * @deprecated
 * use `OrgFormInput` from `v2/new-design-system` instead
 */

export default defineComponent<IOrgFormInputProps>({
  name: "OrgFormInput",
  components: {
    MolFormControl,
    AtomInput,
  },
  props: {
    value: string().isRequired().use(),
    label: string().use(),
    errorMsg: string().use(),
    successMsg: string().use(),
    tooltip: string().use(),
    onChange: func().use(),
    loading: boolean().use(),
    caption: string().use(),
    required: boolean().use(),
    hint: string().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
