import Vue from "vue";
import { globalConfig } from "@/v2/core";

const apiKey = globalConfig.googleMapsApiKey;

const state = Vue.observable({
  isReady: false,
  isLoading: false,
  hasFailed: false,
});

export const loadLibrary = () => {
  if (state.isReady || state.isLoading) {
    return Promise.resolve();
  }

  state.isLoading = true;

  const params = [`key=${apiKey}`, "libraries=places"];

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.id = "google-maps-api";
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?${params.join("&")}`;
    document.body.appendChild(script);

    script.onload = () => {
      state.isLoading = false;
      state.isReady = true;
      state.hasFailed = false;
      resolve();
    };
    script.onerror = () => {
      state.isLoading = false;
      state.isReady = false;
      state.hasFailed = true;
      reject();
    };
  });
};

export const useGoogleMaps = () => {
  return {
    state,
    loadLibrary,
  };
};
