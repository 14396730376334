






import { string } from "@/v2/util/prop/type";
import { defineComponent } from "@vue/composition-api";
import { IAtomHighlightProps } from "./props";

export default defineComponent<IAtomHighlightProps>({
  name: "AtomHighlight",
  props: {
    maxWidth: string().setDefault("520px").use(),
  },
});
