import { socket } from "./io";
import { emitEvent } from "./pub";
import {
  offEvent,
  onEvent,
  wsContactAdapter,
  wsConversationAdapter,
  wsConversationWithMessagesAdapter,
  wsIncomingMessageAdapter,
  wsMessageAdapter,
} from "./sub";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSocket = () => {
  const connectSocket = (): void => {
    if (socket.connected) {
      return;
    }

    socket.connect();
  };

  const disconnectSocket = (): void => {
    socket.disconnect();
  };

  const isSocketConnected = (): boolean => {
    return socket.connected;
  };

  const isSocketDisconnected = (): boolean => {
    return socket.disconnected;
  };

  const joinRoom = (businessId: string): void => {
    const fn = (): void => {
      socket.emit("join_room", { roomId: businessId });
    };

    socket.io.on("reconnect", fn);
    fn();
  };

  const leaveRoom = (businessId: string): void => {
    socket.io.off("reconnect");
    socket.emit("leave_room", { roomId: businessId });
  };

  return {
    isSocketConnected,
    isSocketDisconnected,
    connectSocket,
    disconnectSocket,
    joinRoom,
    leaveRoom,
    emitEvent,
    offEvent,
    onEvent,
    wsContactAdapter,
    wsConversationAdapter,
    wsConversationWithMessagesAdapter,
    wsIncomingMessageAdapter,
    wsMessageAdapter,
  };
};
