































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { VTooltip } from "v-tooltip";
import { t } from "@/i18n";
import { IntegrationDisabledButtonEnum } from "./type.enum";

const css = bemBuilder("atom-integration-disabled-button");

export default defineComponent({
  name: "AtomIntegrationDisabledButton",
  directives: {
    VTooltip,
  },
  props: {
    hasMenuIntegration: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<IntegrationDisabledButtonEnum>,
      default: IntegrationDisabledButtonEnum.DELIVERECT,
      required: false,
    },
    kind: {
      type: String as PropType<
        | "delete"
        | "publish"
        | "custom"
        | "add-items"
        | "red-label"
        | "red-label-sm"
        | "black-label"
        | "blue-label"
        | "default"
        | "default-no-radius-left"
      >,
      required: true,
    },
  },
  setup(props) {
    const displayCustom = computed(
      () => props.kind === "custom" && props.enabled
    );
    const tooltipLabel = props.hasMenuIntegration
      ? IntegrationDisabledButtonEnum.INTEGRATION
      : IntegrationDisabledButtonEnum.DELIVERECT;
    const tooltipSettings = {
      autoHide: false,
      trigger: "click hover",
      content: t(tooltipLabel),
    };
    const displayDefault = computed(() => {
      return (
        [
          "default",
          "default-no-radius-left",
          "add-items",
          "black-label",
          "blue-label",
          "red-label",
          "red-label-sm",
        ].includes(props.kind) && props.enabled
      );
    });

    return {
      css,
      tooltipSettings,
      displayDefault,
      displayCustom,
    };
  },
});
