<template>
  <audio
    id="audio-notification"
    ref="player"
    tabindex="0"
    controls
    preload="auto"
    loop
    style="display: none"
  >
    <source src="@/assets/audio/order_ringtone.mp3" type="audio/mpeg" />
    <source src="@/assets/audio/order_ringtone.ogg" />
  </audio>
</template>

<script>
import { mapMutations } from "vuex";
import bus from "@/utils/events/bus";

export default {
  props: {
    play: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    play: {
      handler(val) {
        this.toggle(val);
      },
      immediate: true,
    },
  },
  created() {
    bus.$on("testSound", this.testSound);
  },
  beforeDestroy() {
    bus.$off("testSound", this.testSound);
  },
  methods: {
    ...mapMutations({
      setSoundAsTested: "SET_IS_SOUND_TESTED",
    }),
    toggle(playSound) {
      if (playSound) {
        return this.playNotification();
      }

      return this.pauseNotification();
    },
    async testSound() {
      try {
        await this.playNotification();

        this.setSoundAsTested();

        setTimeout(() => {
          if (!this.play) this.pauseNotification();
        }, 2000);
      } catch (e) {
        //
      }
    },
    async pauseNotification() {
      try {
        await this.$refs.player.pause();
        this.$refs.player.currentTime = 0;
      } catch (e) {
        //
      }
    },
    async playNotification() {
      try {
        await this.$refs.player.play();
      } catch (e) {
        //
      }
    },
  },
};
</script>
