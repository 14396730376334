import Dinero from "dinero.js";
import Defaults from "@/utils/enums/defaults";
import { is } from "ramda";
import { allPrecisions } from "@/utils/enums/currencies";

/**
 * Format integer to currency.
 *
 * @param {Integer} amount
 * @param {{ code, format, locale }} options
 *
 * @returns {String}
 */
const toCurrency = (
  amount,
  { code = "USD", format = "$0,0", locale = Defaults.LANGUAGE }
) => {
  if (!is(Number, amount)) {
    throw TypeError(
      `Amount (${amount}) must be an integer. Given ${typeof amount} instead.`
    );
  }

  const precision = allPrecisions[code] ?? 2;

  let money = Dinero({
    amount,
    precision,
    currency: code,
  });

  // Set Dinero locale for output formatted strings
  money = money.setLocale(locale);

  if (precision > 0) {
    format += "." + "0".repeat(precision);
  }

  return money.toFormat(format);
};

export default toCurrency;
