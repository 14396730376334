import { VIEW_SPOTS_OCCUPANCY } from "@/utils/enums/permissions";
import { optionalOutletFillerMiddleware } from "@/v2/components/outlet-switcher";
import { RouteConfig } from "vue-router";
import { t } from "@/i18n";

export const spotsOccupancyRoutes: Array<RouteConfig> = [
  {
    name: "spots-occupancy.index",
    path: "/business/:businessId/outlet/:outletId?/floor-plan",
    alias: "/business/:businessId/spots-occupancy/:outletId?",
    beforeEnter: optionalOutletFillerMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "spots-occupancy-module" */ "@/v2/module/spots-occupancy/main/Main.vue"
      ),
    props: true,
    meta: {
      title: t("module.spots_occupancy.main.heading"),
      permission: VIEW_SPOTS_OCCUPANCY,
    },
  },
];
