export enum BusinessTypeEnum {
  RESTAURANT = "restaurant",
  BAKERY = "bakery",
  BUTCHER_SHOP = "butcher-shop",
  DESSERT_SHOP = "dessert-shop",
  GROCERY = "grocery",
  MEAL_PLANS = "meal-plans",
  FLORIST = "florist",
  RETAIL = "retail",
  CINEMA = "cinema",
  ROOM = "room",
  CATERING = "catering",
  BAR = "bar",
  BEACH_CLUB = "beach-club",
  HOTEL = "hotel",
  OTHERS = "others",
}
