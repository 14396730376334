import {
  prop,
  gt,
  propOr,
  length,
  reduceBy,
  compose,
  filter,
  sortWith,
  descend,
} from "ramda";

/**
 * Get list of available orders ordered by creation date
 *
 * @returns {Array<Object>}
 */
export const getData = (state) => {
  const orders = propOr([], "data", state);
  const toAcknowledge = propOr([], "toAcknowledge", state);

  orders.map((order) => {
    order.acknowledged = toAcknowledge.indexOf(order.id) === -1;
  });

  return sortWith([descend(prop("created_at"))], orders);
};

/**
 * Returns if orders are loaded based on numbers of items in the list
 *
 * @returns {Boolean}
 */
export const isLoaded = ({ data }) => gt(length(data), 0);

/**
 * Returns if module is currently in loading state
 *
 * @returns {Boolean}
 */
export const isLoading = prop("loading");

/**
 * Returns the amount of orders grouped by status
 *
 * @returns {Object}
 */
export const getOrdersByStatusCount = compose(
  reduceBy(
    (acc) => acc + 1,
    0,
    (order) => order.status
  ),
  propOr([], "data")
);

/**
 * Returns the amount of orders grouped by ordering service
 *
 * @returns {Object}
 */
export const getOrdersByServiceCount = compose(
  reduceBy(
    (acc) => acc + 1,
    0,
    (order) => order.ordering_service
  ),
  propOr([], "data")
);

/**
 * Returns the list of orders to be accepeted AND acknowledged
 *
 * @returns {Array<Object>}
 */
export const getIncomingOrders = (state) => {
  return filter((obj) => obj.status === "placed", getData(state));
};

/**
 * Get orders to be acknowledged.
 *
 * @returns {Array<String>}
 */
export const getOrdersToAcknowledge = propOr([], "toAcknowledge");
