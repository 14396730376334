import { globalConfig } from "../core";

export type IChannel =
  | "instagram"
  | "sms"
  | "messenger"
  | "web"
  | "google"
  | "whatsapp"
  | "facebook";

const channelMap = {
  instagram: "i",
  sms: "s",
  messenger: "m",
  web: "w",
  google: "g",
  whatsapp: "w",
  facebook: "f",
};

export function formatLinkForChannel(link: string, channel: IChannel): string {
  const isCustomDomain = !globalConfig.chatfoodDomains.some((domain) =>
    link.includes(domain)
  );
  const protocollessLink = link.replace(/https?:\/\//g, "");
  const firstLinkSegment = protocollessLink.split("/")?.[1];
  const isSubdomain = Boolean(!firstLinkSegment);

  const channelSegment = channelMap[channel];

  if (isCustomDomain || isSubdomain) {
    return `${link}/${channelSegment}`;
  }

  return link.replace(/https?:\/\/[^/]+\//g, `$&${channelSegment}/`);
}
