import { Route } from "vue-router";
import { QRCodeRouteEnum, QRCodeType } from "@/v2/module/qr-code/domain";
import { VIEW_MARKETING } from "@/utils/enums/permissions";

export default [
  {
    name: QRCodeRouteEnum.DIGITAL_MENU,
    path: "/business/:businessId/qr-code/digital-menu",
    component: () =>
      import(
        /* webpackChunkName: "qr-code-module" */ "@/v2/module/qr-code/main/Main.vue"
      ),
    props: (route: Route) => ({
      businessId: route.params.businessId,
      page: QRCodeType.DIGITAL_MENU,
    }),
    meta: {
      title: "QR Code / Digital Menu",
      permission: VIEW_MARKETING,
    },
  },
  {
    name: QRCodeRouteEnum.DELIVERY_N_PICKUP,
    path: "/business/:businessId/qr-code/delivery-and-pickup",
    component: () =>
      import(
        /* webpackChunkName: "qr-code-module" */ "@/v2/module/qr-code/main/Main.vue"
      ),
    props: (route: Route) => ({
      businessId: route.params.businessId,
      page: QRCodeType.DELIVERY_N_PICKUP,
    }),
    meta: {
      title: "QR Code / Delivery and Pickup",
      permission: VIEW_MARKETING,
    },
  },
  {
    path: "/business/:businessId/qr-code",
    redirect: { name: QRCodeRouteEnum.DIGITAL_MENU },
  },
];
