import { ISortDirection } from "./props";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOrgTable = () => {
  function convertDirToString<T>(key: string, direction: ISortDirection): T {
    const prefix = direction === "ASC" ? "" : "-";
    // @ts-expect-error needs to improve ts inference in the OrgTable
    return `${prefix}${key}`;
  }

  return {
    convertDirToString,
  };
};
