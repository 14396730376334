import { sortAscBy } from "@/v2/util";
import { IInboxContact } from "../domain/contact";
import {
  IInboxConversation,
  IInboxConversationWithMessages,
} from "../domain/conversation";
import { IInboxIncomingMessage, IInboxMessage } from "../domain/message";
import { IInboxMessageType } from "../domain/message-type";
import { socket } from "./io";

enum WsSubEventEnum {
  UNREAD_CONVERSATIONS_COUNT = "unread_conversations_count",
  CONVERSATIONS_LOADED = "conversations_loaded",
  CONVERSATION_LOADED = "conversation_loaded",
  MESSAGE_DELETED = "message_deleted",
  MESSAGE_RECEIVED = "message_received",
  MESSAGES_LOADED = "messages_loaded",
}

interface IWsSubCallback {
  UNREAD_CONVERSATIONS_COUNT: (amount: number) => void;
  CONVERSATIONS_LOADED: (conversations: Array<Record<string, any>>) => void;
  CONVERSATION_LOADED: (conversation: Record<string, any>) => void;
  MESSAGE_DELETED: (data: {
    messageId: string;
    conversationId: string;
  }) => void;
  MESSAGE_RECEIVED: (data: Record<string, any>) => void;
  MESSAGES_LOADED: (messages: Array<Record<string, any>>) => void;
}

export function onEvent<T extends keyof IWsSubCallback>(
  event: T,
  callback: IWsSubCallback[T]
): void {
  socket.on(WsSubEventEnum[event], callback as any);
}

export function offEvent<T extends keyof IWsSubCallback>(
  event: T,
  callback: IWsSubCallback[T]
): void {
  socket.off(WsSubEventEnum[event], callback as any);
}

const normalizeType = (type: string): IInboxMessageType => {
  if (type.startsWith("video")) {
    return IInboxMessageType.VIDEO;
  }

  if (type.startsWith("image")) {
    return IInboxMessageType.IMAGE;
  }

  if (type.startsWith("audio")) {
    return IInboxMessageType.AUDIO;
  }

  if (type.startsWith("document")) {
    return IInboxMessageType.DOCUMENT;
  }

  if (type.startsWith("location")) {
    return IInboxMessageType.LOCATION;
  }

  return IInboxMessageType.TEXT;
};

export const wsContactAdapter = (data: Record<string, any>): IInboxContact => ({
  id: data.id,
  name: data.name,
  phoneNumber: data.phoneNumber,
  instagramHandle: data.handle,
  email: data.email,
  photo: data.photoUrl,
  createdAt: new Date(data.createdAt),
});

export const wsConversationAdapter = (
  data: Record<string, any>
): IInboxConversation => ({
  id: data.id,
  businessId: data.businessId,
  contact: wsContactAdapter(data.contact),
  channel: data.channel,
  hasUnreadMessages: data.hasUnreadMessages === true,
  createdAt: new Date(data.createdAt),
  lastMessageAt: new Date(data.lastMessageAt),
  lastMessageReceivedAt: new Date(data.lastMessageReceivedAt),
});

export const wsConversationWithMessagesAdapter = (
  data: Record<string, any>
): IInboxConversationWithMessages => ({
  id: data.id,
  businessId: data.businessId,
  contact: wsContactAdapter(data.contact),
  channel: data.channel,
  hasUnreadMessages: data.hasUnreadMessages === true,
  messages: sortAscBy(
    data.messages.map((m: Record<string, any>) => wsMessageAdapter(m)),
    "createdAt"
  ),
  createdAt: new Date(data.createdAt),
  lastMessageAt: new Date(data.lastMessageAt),
  lastMessageReceivedAt: new Date(data.lastMessageReceivedAt),
});

export const wsMessageAdapter = (data: Record<string, any>): IInboxMessage => {
  const messageType = normalizeType(data.type);
  let content: string = "";

  if (messageType === IInboxMessageType.TEXT) {
    content = data.content;
  }

  if (
    [
      IInboxMessageType.AUDIO,
      IInboxMessageType.IMAGE,
      IInboxMessageType.VIDEO,
      IInboxMessageType.DOCUMENT,
    ].includes(messageType)
  ) {
    content = data.url;
  }

  return {
    id: data.id,
    content: content,
    authorType: data.authorType,
    createdAt: new Date(data.createdAt),
    type: messageType,
    filename: data?.filename,
    coordinates: !data?.coordinates
      ? undefined
      : { lat: data.coordinates.latitude, lng: data.coordinates.longitude },
  };
};

export const wsIncomingMessageAdapter = (
  data: Record<string, any>
): IInboxIncomingMessage => ({
  ...wsMessageAdapter(data),
  ...{ conversation: wsConversationAdapter(data.conversation) },
});
