




















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { IOrgFormInputProps } from "./props";
import { AtomInput } from "@/v2/new-design-system/atom/input";
import { MolFormControl } from "@/v2/new-design-system/mol/form-control";

const css = bemBuilder("org-form-input");

export default defineComponent<IOrgFormInputProps>({
  name: "OrgFormInput",
  components: {
    MolFormControl,
    AtomInput,
  },
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    helpText: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    successMsg: {
      type: String,
      default: "",
    },
    onChange: {
      type: Function,
      required: true,
    },
    onTrack: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
