

















import { AtomIcon } from "@/v2/new-design-system/atom/icon";
import { defineComponent } from "@vue/composition-api";
import { IMolLoadingZoneProps } from "./props";

/**
 * @deprecated
 * use `AtomLoading` instead
 */
export default defineComponent<IMolLoadingZoneProps>({
  name: "MolLoadingZone",
  components: {
    AtomIcon,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: null,
    },
    domVisibility: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "300px",
    },
  },
});
