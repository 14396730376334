






















import { defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomModal } from "@/v2/new-design-system";

const css = bemBuilder("mol-video-modal");

export default defineComponent({
  name: "MolVideoModal",
  components: { AtomModal },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
