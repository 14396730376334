



































import { defineComponent, PropType, ref } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomIcon,
  AtomButton,
  AtomButtonSizeEnum,
} from "@/v2/new-design-system";
import { IMultiButtonItem } from "@/v2/new-design-system/mol/multi-button/props";

const css = bemBuilder("mol-multi-button");

export default defineComponent({
  name: "MolMultiButton",
  components: {
    AtomIcon,
    AtomButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<Array<IMultiButtonItem>>,
      required: true,
    },
  },
  setup() {
    const openDropdown = ref(false);
    const toggleDropDown = (state: boolean) => {
      openDropdown.value = state;
    };

    const handleClick = (item: IMultiButtonItem) => {
      item.onClick && item.onClick();
      toggleDropDown(false);
    };

    return {
      css,
      openDropdown,
      toggleDropDown,
      handleClick,
      AtomButtonSizeEnum,
    };
  },
});
