import { map, propOr, isNil } from "ramda";

/** Parser to normalize data coming from the API */
export default class OutletsParser {
  /**
   * Outlets Parser Constuctor.
   *
   * @param {Array} response List of items coming from API
   */
  constructor(response) {
    this.response = response;
  }

  /**
   * Parse the outlet address from API to local format.
   *
   * @param  {Object} address
   * @return {Object}
   */
  parseAddress(address) {
    const parsed = {
      id: address.id,
      lat: address.lat,
      lng: address.lng,
      location: address.location,
      nickname: address.nickname,
      instructions: address.instructions,
    };

    if (!isNil(address.area)) {
      const area = propOr({}, "area", address);
      parsed.area = this.parseArea(area);
    }

    return parsed;
  }

  /**
   * Parse ordering services from API to local format.
   *
   * @param  {Object} orderingServices
   * @return {Object}
   */
  parseOrderingServices(orderingServices) {
    return map((obj) => {
      const parsed = {
        name: obj.name,
        slug: obj.slug,
        isEnabled: obj.is_enabled,
        operationStatus: obj.operation_status,
        sort: obj.sort,
      };

      if (!isNil(obj.settings)) {
        const settings = propOr({}, "settings", obj);
        parsed.settings = map(
          (item) => ({
            operationStatus: item.operation_status,
            preparationMinutes: item.preparation_minutes,
            estimatedCompletionMinutes: item.estimated_completion_minutes,
          }),
          settings
        );
      }

      return parsed;
    }, orderingServices);
  }

  /**
   * Parse the area from API to local format.
   *
   * @param  {Object} area
   * @return {Object}
   */
  parseArea(area) {
    return {
      id: area.id,
      name: area.name,
    };
  }

  /**
   * Parses the list of outlets coming from the API to a valid format
   *
   * @returns {Array}
   */
  fromApi() {
    return map((obj) => {
      const parsed = {
        id: obj.id,
        name: obj.name,
        url: obj.url,
        countryCode: obj.country_code,
        deliveryFee: obj.delivery_fee,
        phone: obj.phone,
        taxRate: obj.tax_rate,
        taxIncluded: obj.tax_included,
        minBasket: obj.min_basket,
        timezone: obj.timezone,
        businessId: obj.business_id,
      };

      if (!isNil(obj.printer_settings)) {
        const printerSettings = propOr({}, "printer_settings", obj);
        parsed.printerSettings = {
          id: printerSettings.id,
          apiKey: printerSettings.api_key,
          params: printerSettings.params,
        };
      }

      if (!isNil(obj.address)) {
        const address = propOr({}, "address", obj);
        parsed.address = this.parseAddress(address);
      }

      if (!isNil(obj.ordering_services)) {
        const orderingServices = propOr({}, "ordering_services", obj);
        parsed.orderingServices = this.parseOrderingServices(orderingServices);
      }

      if (!isNil(obj.delivery_integrations)) {
        const deliveryServices = propOr({}, "delivery_integrations", obj);
        parsed.deliveryServices = map((obj) => obj.slug, deliveryServices);
      }

      return parsed;
    }, this.response);
  }
}
