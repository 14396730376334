import { http } from "../../http";
import {
  IAxiosErrorResponse,
  IRepoBusinessSettingsErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";

interface ISaveBusinessSettings {
  name: string;
  language: string;
  category: string;
  website: string;
  cuisineIds: Array<string>;
}

export class SaveBusinessSettingsError extends RepoBusinessSettingsError {}

export async function saveBusinessSettings(
  businessId: string,
  params: ISaveBusinessSettings
): Promise<void> {
  const { name, website, category, language, cuisineIds } = params;

  try {
    await http.put(`/api/v3/businesses/${businessId}/update`, {
      name,
      category,
      language,
      domain_name: website,
      cuisine_ids: cuisineIds,
    });
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }
    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error: IRepoBusinessSettingsErrorResponse = {
      status: response?.status,
      message:
        rawErrorMessage || "The request to update a business settings failed.",
      request: { ...params, businessId },
      response: response.data,
    };

    if (error.status === 422) {
      const { errors } = response.data;

      if (errors.domain_name) {
        error.validationError = {
          website: errors.domain_name[0],
        };
      }
    }

    throw new SaveBusinessSettingsError(error);
  }
}
