import ApiServiceError from "./ApiServiceError";

class ForbiddenError extends ApiServiceError {
  constructor(...params) {
    // Pass remaining arguments to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }

    this.name = "ForbiddenError";
  }
}

export default ForbiddenError;
