import { sidebarPermissions } from "../state";
import {
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
} from "@/v2/module/billing/state";
import { t } from "@/i18n";
import { generateURL } from "@/v2/module/sidebar/method/generate-url";
import VueRouter from "vue-router";
import { ISidebarPropsMenuItem } from "@/v2/module/sidebar/props";
import { IVuexBusiness } from "@/store/types";
import { BackOfficeSettingsRouteEnum } from "@/module/back-office-settings";
import { VIEW_BACK_OFFICE_SETTINGS } from "@/utils/enums/permissions";

export const setBottomMenu = (
  permissions: Array<string>,
  business: IVuexBusiness,
  router: VueRouter
): Array<ISidebarPropsMenuItem | {}> | Array<ISidebarPropsMenuItem> => {
  const routes = [
    {
      key: "upgrade",
      label: t("c.billing.upgrade"),
      icon: "sparkle",
      visible:
        !billingGetState("flowSuccess").value &&
        billingGetState("showFlowStartCTA").value,
      action: {
        func: (): void => {
          billingSetState("business", {
            id: business.id,
            name: business.name,
            logo: business.logo || "",
          });

          billingSetState("stage", BillingStageStateEnum.CHOOSE_PLAN);
        },
      },
    },
    {
      key: "help",
      label: t("menu.help"),
      icon: "help-menu",
      visible: true,
      submenu: [
        {
          label: "",
          items: [
            {
              label: t("menu.whats_new"),
              visible: permissions.includes("VIEW_WHATS_NEW"),
              action: {
                routerLink: "",
                useTracking: true,
                httpLink:
                  "https://docs.chatfood.io/en/articles/5583203-what-s-new-in-chatfood",
              },
            },
            {
              label: t("menu.search_help_center"),
              visible: true,
              action: {
                httpLink: "http://docs.chatfood.io",
              },
            },
            {
              label: t("module.sidebar.help.contact_support"),
              visible: true,
              action: {
                httpLink: "mailto:support@chatfood.io",
              },
            },
          ],
        },
      ],
    },
    {
      key: "users",
      label: t("menu.users"),
      icon: "users-menu",
      visible: sidebarPermissions.value.includes("VIEW_USERS"),
      action: {
        routerLink: "user-management.index",
      },
    },
    {
      key: "settings",
      label: t("menu.settings"),
      icon: "settings",
      visible: true,
      submenu: [
        {
          label: "",
          items: [
            {
              label: t("menu.ordering_page"),
              icon: "preview",
              visible: true,
              action: {
                httpLink: business?.orderingLink,
                useTracking: true,
              },
            },
            {
              key: "outlets",
              label: t("menu.outlets"),
              icon: "outlets-menu",
              visible: permissions.includes("VIEW_OUTLETS"),
              action: {
                routerLink: "outlets.index",
                func: (): void => {},
              },
            },
            {
              label: t("menu.branding"),
              icon: "branding",
              visible: permissions.includes("EDIT_WIDGET"),
              action: {
                routerLink: "branding",
              },
            },
            {
              key: "billing",
              label: t("menu.billing"),
              icon: "billing",
              visible:
                sidebarPermissions.value.includes("VIEW_BILLING") &&
                Boolean(
                  billingGetState("currentPlan").value &&
                    billingGetState("currentPlan").value?.state !== "CREATED"
                ),
              action: {
                routerLink: "billing",
              },
            },
            {
              label: t("menu.business_settings"),
              icon: "settings",
              visible: permissions.includes("VIEW_BUSINESS_SETTINGS"),
              action: {
                routerLink: "business-settings",
              },
            },
            {
              key: "spots-management",
              label: t("menu.spots_management"),
              icon: "dashboard-menu",
              visible: permissions.includes("VIEW_SPOTS_MANAGEMENT"),
              action: {
                routerLink: "spots-management",
              },
            },
            {
              key: "backoffice-settings",
              label: t("menu.back_office_settings"),
              icon: "preferences",
              visible: permissions.includes(VIEW_BACK_OFFICE_SETTINGS),
              action: {
                routerLink: BackOfficeSettingsRouteEnum.MAIN,
              },
            },
          ],
        },
        {
          label: "",
          items: [
            {
              label: t("menu.my_profile"),
              icon: "profile-menu",
              visible: true,
              action: {
                routerLink: "user-management.profile",
              },
            },
            {
              label: t("menu.logout"),
              icon: "logout-menu",
              visible: true,
              action: {
                routerLink: "auth.login",
              },
            },
          ],
        },
      ],
    },
  ];

  return generateURL(routes, router, { businessId: business.id });
};
