























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomButtonSizeEnum } from "./size.enum";
import { AtomButtonTypeEnum } from "./type.enum";

const css = bemBuilder("atom-button");

export default defineComponent({
  name: "AtomButton",
  props: {
    type: {
      type: String as PropType<AtomButtonTypeEnum>,
      default: AtomButtonTypeEnum.PRIMARY,
    },
    size: {
      type: String as PropType<AtomButtonSizeEnum>,
      default: AtomButtonSizeEnum.MEDIUM,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      css,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});
