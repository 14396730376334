import Vue from "vue";

const getHeader = (headers, header) => {
  const key = Object.keys(headers).find(
    (key) => key.toLowerCase() === header.toLowerCase()
  );

  return headers?.[key];
};

class ApiServiceError extends Error {
  constructor(response, ...params) {
    // Pass remaining arguments to parent constructor
    super(...params);

    const requestHeaders = response?.config?.headers ?? {};
    const responseHeaders = response?.headers ?? {};

    this.name = "ApiServiceError";
    this.message = response?.data?.message;
    this.statusCode = response?.status;
    this.requestId = getHeader(responseHeaders, "X-Request-ID");
  }
}

export default ApiServiceError;
