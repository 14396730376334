




import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { imageURLBuilder } from "@/v2/util/image-url-builder";

const css = bemBuilder("sidebar-brand-logo");
const defaultLogo = require("./assets/avatar.png");

export default defineComponent({
  name: "SidebarBrandLogo",
  props: {
    logo: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const brandLogo = computed(() => {
      return imageURLBuilder(props.logo, 100, 100) || defaultLogo;
    });
    return {
      css,
      brandLogo,
    };
  },
});
