
















import { string, boolean } from "@/v2/util/prop/type";
import { isOneOf } from "@/v2/util/prop/validator";
import { AtomTextSizeEnum } from "./size.enum";
import { defineComponent } from "@vue/composition-api";

/**
 * @deprecated
 * use `AtomText` from `v2/new-design-system` instead
 */

export default defineComponent({
  name: "AtomText",
  props: {
    tag: string().setDefault("span").use(),
    size: string()
      .setDefault(AtomTextSizeEnum.NR)
      .setValidator(
        isOneOf([
          AtomTextSizeEnum.XLG,
          AtomTextSizeEnum.LG,
          AtomTextSizeEnum.MD,
          AtomTextSizeEnum.NR,
          AtomTextSizeEnum.SM,
          AtomTextSizeEnum.XS,
          AtomTextSizeEnum.XXS,
        ])
      )
      .use(),
    bold: boolean().setDefault(false).use(),
    mute: boolean().use(),
  },
});
