<template>
  <label class="selector">
    <input
      class="selector__input"
      type="radio"
      v-bind="$attrs"
      :checked="isChecked"
      :value="value"
      data-test="input"
      @change="updateInput"
      @focus="hasFocus"
      @blur="removeFocus"
    />
    <span class="selector__value"><slot /></span>
  </label>
</template>

<script>
export default {
  name: "InlineSelector",
  inheritAttrs: false,
  model: {
    prop: "modelValue",
  },
  props: {
    value: {
      type: [String, Boolean],
      default: null,
    },
    modelValue: {
      type: [String, Boolean, Number],
      default: "",
    },
  },
  computed: {
    isChecked() {
      return this.modelValue == this.value;
    },
  },
  methods: {
    updateInput() {
      this.$emit("input", this.value);
    },
    hasFocus() {
      this.$parent.$emit("hasFocus");
    },
    removeFocus() {
      this.$parent.$emit("removeFocus");
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  $self: &;
  display: inline-block;
  cursor: pointer;
  margin: 0;

  &__input {
    opacity: 0;
    appearance: none;
  }

  &__value {
    display: inline-block;
    height: 30px;
    padding: 5px 24px;
    border-radius: 2px;
    color: #84a0b2;
    font-size: 14px;
    font-weight: normal;

    &:hover {
      background: #e5f6fe;
      color: #00a8f8;
    }
  }

  &__input:checked ~ #{ $self }__value {
    background: #00a8f8;
    color: white;
  }

  &:focus #{ $self }__input:not(:checked) ~ #{ $self }__value {
    background: #e5f6fe;
    color: #00a8f8;
  }
}
</style>
