

































import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system/atom/text";
import { AtomIcon } from "@/v2/new-design-system/atom/icon";
import { MolIframeModal } from "@/v2/new-design-system/mol/iframe-modal";
import { MolModalGuideLinkVideoEnum } from "./video.enum";
import { ModalGuideLinkConfig } from "./configs";
import { IMolModalGuideLinkProps } from "./props";
import { MolVideoModal } from "../video-modal";

const css = bemBuilder("mol-modal-guide-link");

export default defineComponent<IMolModalGuideLinkProps>({
  name: "MolModalGuideLink",
  components: {
    AtomText,
    AtomIcon,
    MolIframeModal,
    MolVideoModal,
  },
  props: {
    video: {
      type: String as PropType<MolModalGuideLinkVideoEnum>,
      required: true,
    },
    iconName: {
      type: String,
      default() {
        return ModalGuideLinkConfig[this.video]?.iconName;
      },
    },
    label: {
      type: Function as PropType<() => string>,
      default() {
        return ModalGuideLinkConfig[this.video]?.label();
      },
    },
    linkKey: {
      type: String,
      default: null,
    },
    useIframeModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showModal = ref(false);
    const onShowModal = () => (showModal.value = true);
    const onCloseModal = () => (showModal.value = false);

    const link = computed(() => {
      const el = ModalGuideLinkConfig[props.video]?.link;
      return typeof el === "object" && props.linkKey ? el[props.linkKey] : el;
    });

    return {
      css,
      link,
      showModal,
      onShowModal,
      onCloseModal,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
