














































//@ts-nocheck
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { IOrgImageCropperProps } from "./props";
import { AtomModal } from "@/v2/design-system/atom/modal";
import { AtomButton, AtomButtonTypeEnum } from "@/v2/design-system/atom/button";
import { AtomIcon } from "../../atom/icon";

const css = bemBuilder("org-image-cropper");

export default defineComponent<IOrgImageCropperProps>({
  name: "OrgImageCropper",
  components: {
    VueCropper,
    AtomModal,
    AtomButton,
    AtomIcon,
  },
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    ratio: {
      type: Number,
      required: true,
    },
    minHeight: {
      type: Number,
      required: true,
    },
    minWidth: {
      type: Number,
      required: true,
    },
    onCrop: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      css,
      t,
      AtomButtonTypeEnum,
    };
  },
  data: () => ({
    zoomRatio: 1,
  }),
  mounted() {
    this.wheelEvent = document.createEvent("MouseEvents");
    this.wheelEvent.initEvent("wheel", true, true);
  },
  methods: {
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob(this.onCrop, "image/jpeg");
    },
    zoomIn() {
      this.wheelEvent.deltaY = -120;
      document
        .querySelector(".cropper-container")
        .dispatchEvent(this.wheelEvent);
      this.customZoomInitiation = true;
    },
    zoomOut() {
      this.wheelEvent.deltaY = +120;
      document
        .querySelector(".cropper-container")
        .dispatchEvent(this.wheelEvent);
      this.customZoomInitiation = true;
    },
    zoom(e) {
      const data = this.$refs.cropper.getData();

      if (
        (data.height < this.minHeight * 1.15 ||
          data.width < this.minWidth * 1.15) &&
        e.detail.oldRatio < e.detail.ratio
      ) {
        e.preventDefault();
        return false;
      }

      this.zoomRatio = e.detail.ratio;
      if (this.customZoomInitiation) {
        this.customZoomInitiation = false;
        this.$refs.cropper.zoomTo(this.zoomRatio);
      }
    },
  },
});
