













import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("atom-tooltip");

export default defineComponent({
  name: "AtomTooltip",
  directives: {
    tooltip: VTooltip,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    trigger: {
      type: Array as PropType<Array<"hover" | "click" | "focus">>,
      default: () => ["hover", "click"],
    },
    position: {
      type: String as PropType<
        | "auto"
        | "auto-start"
        | "auto-end"
        | "top"
        | "top-start"
        | "top-end"
        | "right"
        | "right-start"
        | "right-end"
        | "bottom"
        | "bottom-start"
        | "bottom-end"
        | "left"
        | "left-start"
        | "left-end"
      >,
      default: "top-start",
    },
  },
  setup(props) {
    const setTrigger = computed(() => props.trigger.join(" "));

    return {
      css,
      setTrigger,
    };
  },
});
