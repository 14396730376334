


















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";

const css = bemBuilder("atom-auto-size-textarea");

export default defineComponent({
  name: "AtomAutoSizeTextarea",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    breakLineOnShiftEnter: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    onEnter: {
      type: Function as PropType<(event: KeyboardEvent) => void>,
      default: () => {},
    },
    onShiftEnter: {
      type: Function as PropType<(event: KeyboardEvent) => void>,
      default: () => {},
    },
  },
  setup(props) {
    const el = ref<HTMLDivElement>();
    const inputEl = ref<HTMLTextAreaElement>();
    const content = ref<string>("");

    async function autoResize() {
      await getCurrentInstance()?.proxy.$nextTick();

      if (!inputEl.value || !el.value) return;

      inputEl.value.style.height = "auto";
      inputEl.value.style.height = `${inputEl.value?.scrollHeight}px`;
      el.value.style.height = `${inputEl.value?.scrollHeight}px`;
    }

    function onNativeInput() {
      if (!inputEl.value) return;
      props.onInput(inputEl.value.value);
    }

    watch(
      () => props.value,
      async (newVal, oldVal) => {
        if (newVal === oldVal) return;

        content.value = newVal;
        autoResize();
        focusOnInput();
      }
    );

    function focusOnInput() {
      if (!inputEl.value) return;
      inputEl.value.focus();
    }

    onMounted(() => {
      content.value = props.value;
      autoResize();

      if (props.autofocus) {
        focusOnInput();
      }
    });

    function onKeyDownEnter(event: KeyboardEvent) {
      if (event.shiftKey) {
        props.onShiftEnter(event);
        return;
      }

      props.onEnter(event);
    }

    return {
      css,
      onKeyDownEnter,
      autoResize,
      onNativeInput,
      el,
      inputEl,
      content,
    };
  },
});
