<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";
import { STAFF } from "@/utils/enums/roles";
import { getActiveBusiness } from "@/utils/mixins";

export default {
  mixins: [getActiveBusiness],
  props: {
    isSignup: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      roles: "auth/getRoles",
    }),
  },
  created() {
    const isBusinessOnboarding =
      this.activeBusiness.onboardingStatus === "New" ||
      this.activeBusiness.onboardingStatus === "Active Implementation";

    if (this.$gates.can("VIEW_ONBOARDING") && isBusinessOnboarding) {
      this.redirectTo("onboarding", {
        isSignup: this.isSignup ? "welcome" : null,
      });
      return;
    }

    if (this.$gates.can("VIEW_DASHBOARD")) {
      this.redirectTo("dashboard.index");
      return;
    }

    if (this.$gates.can("VIEW_LIVE_TABS")) {
      this.redirectTo("tabs.live");
      return;
    }

    if (this.$gates.can("VIEW_LIVE_ORDERS")) {
      this.redirectTo("orders.live");
      return;
    }

    this.redirectTo("orders.index");
  },
  methods: {
    redirectTo(routeName, otherParams = {}) {
      this.$router.replace({
        name: routeName,
        params: {
          businessId: this.activeBusiness.id,
          ...otherParams,
        },
      });
    },
  },
};
</script>
