import { EDIT_REACTIVATION } from "@/utils/enums/permissions";

export default [
  {
    name: "marketing.reactivation.email",
    path: "/business/:businessId/reactivation/email",
    component: () =>
      import(
        /* webpackChunkName: "reactivation-setting-form" */ "@/v2/module/reactivation/email/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Reactivation - Email",
      permission: EDIT_REACTIVATION,
    },
  },
];
