



















import { defineComponent } from "@vue/composition-api";
import { AtomIcon } from "@/v2/design-system/atom/icon";
import { IMolSeachBoxProps } from "./props";
import { string, func } from "@/v2/util/prop/type";

/**
 * @deprecated
 * use `MolSearchBox` from `v2/new-design-system` instead
 */

export default defineComponent<IMolSeachBoxProps>({
  name: "MolSearchBox",
  components: {
    AtomIcon,
  },
  props: {
    placeholder: string().use(),
    onChangeInput: func().use(),
    onChangeEnter: func().use(),
  },
});
