interface IGlobalConfig {
  env: string;
  appVersion: string;
  apiBaseUrl: string;
  widgetUrl: string;
  sentryDsn: string;
  sentryEnabled: boolean;
  sentryTracingSampleRate: number;
  googleMapsApiKey: string;
  facebookId: string;
  inboxWebsocketAddress: string;
  pusherKey: string;
  pusherCluster: string;
  chatfoodDomains: Array<string>;
  launchDarklyKey: string;
  prosperstackClientId: string;
  prosperstackFlowId: string;
  defaultPaginationSize: number;
  inboxMetaAppId: string;
  inboxWhatsAppSignupConfigId: string;
}

export const globalConfig: IGlobalConfig = {
  appVersion: process.env.VUE_APP_VERSION!,
  apiBaseUrl: process.env.VUE_APP_API_URL!,
  widgetUrl: process.env.VUE_APP_WIDGET_URL ?? "",
  env: process.env.VUE_APP_ENV!,
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY!,
  sentryDsn: process.env.VUE_APP_SENTRY_DSN ?? "",
  sentryEnabled: process.env.VUE_APP_SENTRY_ENABLED === "true",
  sentryTracingSampleRate: parseFloat(
    process.env.VUE_APP_SENTRY_TRACING_SAMPLE_RATE ?? "0"
  ),
  facebookId: process.env.VUE_APP_FACEBOOK_ID ?? "",
  inboxWebsocketAddress: process.env.VUE_APP_INBOX_SOCKET_ADDRESS!,
  pusherKey: process.env.VUE_APP_PUSHER_KEY!,
  pusherCluster: process.env.VUE_APP_PUSHER_CLUSTER!,
  chatfoodDomains: process.env.VUE_APP_CHATFOOD_DOMAINS!.split(","),
  launchDarklyKey: process.env.VUE_APP_LAUNCH_DARKLY_KEY ?? "",
  prosperstackClientId: process.env.VUE_APP_PROSPERSTACK_CLIENT_ID ?? "",
  prosperstackFlowId: process.env.VUE_APP_PROSPERSTACK_FLOW_ID ?? "",
  defaultPaginationSize: parseInt(
    process.env.VUE_APP_DEFAULT_PAGINATION_SIZE ?? "10",
    10
  ),
  inboxMetaAppId: process.env.VUE_APP_INBOX_META_APP_ID!,
  inboxWhatsAppSignupConfigId:
    process.env.VUE_APP_INBOX_WHATSAPP_SIGNUP_CONFIG_ID!,
};
