import { EventCategoryEnum, EventEnum } from "@/v2/enum/events";
import { IAnalytics } from "@/plugins/analytics/types";
import {
  analyticsEventCommonData,
  trackEvent,
} from "@/v2/util/analytics/trackEvent";

export function sidebarTrack(
  analytics: IAnalytics,
  eventName: EventEnum,
  businessId: string,
  additionalData: Record<string, any> = {}
): void {
  trackEvent({
    eventCategory: EventCategoryEnum.SIDEBAR,
    analytics,
    eventName,
    additionalData: {
      ...analyticsEventCommonData([["business", businessId]]),
      ...additionalData,
    },
  });
}
