import { ref } from "@vue/composition-api";
import { Location } from "vue-router";

const previousRoute = ref<Location>();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function usePreviousRoute() {
  const getRoute = (): Location | undefined => {
    return previousRoute.value;
  };

  const updateRoute = (route: Location): void => {
    previousRoute.value = route;
  };

  return {
    getRoute,
    updateRoute,
  };
}
