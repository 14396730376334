import { DeepReadonly, isRef, readonly, Ref, ref } from "@vue/composition-api";

//---- TODO: Remove on Vue 3 migrate [VUE_3_MIGRATE]
import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);
// -----

interface IStateStructureInternal {
  moduleName: string;
  state: any;
  action?: any;
}

// eslint-disable-next-line
export function stateBuilder<T extends IStateStructureInternal>(
  stateStructure: T
) {
  const defaultState = JSON.parse(JSON.stringify(stateStructure.state));
  let initalized = false;

  if (!stateStructure.moduleName) {
    throw new Error(`[STATE BUILDER ERROR]: Module name is required`);
  }

  if (stateStructure.state && !Object.keys(stateStructure.state).length) {
    throw new Error(
      `[STATE BUILDER ERROR]: Atleast one state item is required`
    );
  }

  return {
    setState: <
      T extends keyof (typeof stateStructure)["state"],
      U extends (typeof stateStructure)["state"][T]
    >(
      state: T,
      value: U
    ): void => {
      if (!initalized) {
        throw new Error(
          `[STATE BUILDER ERROR]: Attempted to set state of uninitialized module: ${stateStructure.moduleName}`
        );
      }

      stateStructure.state[state].value = value;
    },
    getState: <T extends keyof (typeof stateStructure)["state"]>(
      state: T
    ): DeepReadonly<Ref<(typeof stateStructure)["state"][T]>> => {
      if (!initalized) {
        throw new Error(
          `[STATE BUILDER ERROR]: Attempted to get state of uninitialized module: ${stateStructure.moduleName}`
        );
      }

      return readonly(stateStructure.state[state]);
    },
    action: async <
      T extends keyof (typeof stateStructure)["action"],
      U extends (typeof stateStructure)["action"][T]
    >(
      actionName: T,
      payload: Parameters<U>
    ): Promise<void> => {
      if (!initalized) {
        throw new Error(
          `[STATE BUILDER ERROR]: Attempted to call action of uninitialized module: ${stateStructure.moduleName}`
        );
      }

      return stateStructure.action[actionName](...payload);
    },
    initState: (): void => {
      Object.entries(defaultState).forEach(([key, value]) => {
        if (isRef(stateStructure.state[key])) {
          stateStructure.state[key].value = value;
          return;
        }

        stateStructure.state[key] = ref(value);
      });

      initalized = true;
    },
  };
}
