const promiseTimeout = (ms, promise) => {
  let timer;
  const timeout = new Promise((resolve) => {
    timer = setTimeout(() => {
      resolve(ms);
    }, ms);
  });

  return Promise.race([promise, timeout])
    .then((res) => {
      clearTimeout(timer);
      return res;
    })
    .catch((err) => {
      clearTimeout(timer);
      throw err;
    });
};

export default class AnalyticsPlugin {
  constructor() {
    this.observers = [];
  }

  countObservers() {
    return this.observers.length;
  }

  registerObserver(...plugins) {
    this.observers = this.observers.concat(plugins);
  }

  identify(id, metadata) {
    return promiseTimeout(1000, this.notifyAll("identify", id, metadata));
  }

  trackEvent(evt, properties) {
    return promiseTimeout(1000, this.notifyAll("trackEvent", evt, properties));
  }

  trackPageView(route) {
    return Promise.all(
      this.observers.map((observer) => observer.trackPageView(route))
    );
  }

  async notifyAll(method, evt, properties) {
    return Promise.all(
      this.observers.map((observer) => observer[method](evt, properties))
    );
  }
}
