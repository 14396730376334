export enum EventEnum {
  CLICKED_ON_BUTTON = "Clicked on Button",
  EMAIL_REACTIVATION_START = "Email reactivation start",
  EMAIL_REACTIVATION_ACTIVATE = "Email reactivation activate",
  EMAIL_REACTIVATION_DEACTIVATE = "Email reactivation deactivate",
  DELIVERY_AREAS_NOT_FOUND = "Delivery area not found",
  DELIVERY_AREAS_START = "Add delivery area started",
  DELIVERY_AREA_CREATED = "Delivery area created",
  DELIVERY_AREA_UPDATED = "Updated delivery area",
  DELIVERY_AREA_BULK_EDIT = "Area coverage bulk edit ",
  DELIVERY_AREA_DELETE = "Deleted delivery area",
  DELIVERY_AREA_BULK_DELETE = "Area coverage bulk delete",
  DELIVERY_AREA_CHANGED_STATE = "Changed delivery area state",
  OUTLET_START = "Create outlet started",
  OUTLET_STATE_UPDATE = "Updated outlet state",
  OUTLET_PUBLISHED = "Published outlet",
  ONBOARDING_FLOW_STARTED = "Onboarding flow started",
  ONBOARDING_FLOW_COMPLETED = "Onboarding flow completed",
  CHATFOOD_INTRO_VIDEO_STARTED = "ChatFood Intro Video Started",
  CHATFOOD_INTRO_VIDEO_FINISHED = "ChatFood Intro Video Finished",
  DOWNLOAD_QR_CODE = "Download QR Code",
  CREATE_FIRST_OUTLET_STARTER = "Create First Outlet Started",
  OUTLET_DONE_LINK = "Outlet Done Link",
  BUILD_MENU_STARTED = "Build Menu Started",
  MENU_DONE_LINK = "Menu Done Link",
  CUSTOMIZE_BRANDING_STARTED = "Customize Branding Started",
  BRANDING_DONE_LINK = "Branding Done Link",
  ROAD_TO_SUCCESS_CLICKED = "Road to Success Clicked",
  ADDED_PAYMENT_METHOD = "Added payment method",
  STARTED_SUBSCRIPTION_FLOW = "Started subscription flow",
  SELECTED_PLAN = "Selected plan",
  ACTIVATED_SUBSCRIPTION = "Activated subscription",
  PROMOTE_BANNER_LOADED = "Banner loaded",
  PROMOTE_BANNER_CLICKED = "Banner Clicked",
  PROMOTE_BANNER_MODAL_LOADED = "Modal Loaded",
  PROMOTE_BANNER_MODAL_CLICKED = "Modal Clicked",
  OBOARDING_MODAL_ABANDONMENT_STARTED = "Onboarding Modal Abandonment Started",
  OBOARDING_MODAL_ABANDONMENT_EXPLORE = "Onboarding Modal Abandonment Explore",
  OBOARDING_MODAL_ABANDONMENT_CONTINUE = "Onboarding Modal Abandonment Continue",
  OBOARDING_MODAL_ABANDONMENT_GET_STARTED = "Onboarding Modal Abandonment Get Started Guide",
  OBOARDING_MODAL_ABANDONMENT_CLOSED = "Onboarding Modal Abandonment Closed",
  QR_CODE_DOWNLOAD = "QR Code Download",
  INBOX_STARTED_INSTAGRAM_CONNECTION = "Started Instagram connection",
  INBOX_CONNECTED_WITH_FACEBOOK = "Connected with Facebook",
  INBOX_CONNECTED_INSTAGRAM = "Connected Instagram to Inbox",
  INBOX_STARTED_WHATSAPP_CONNECTION = "Started WhatsApp connection",
  INBOX_CONNECTED_WHATSAPP = "Connected WhatsApp to Inbox",
  PAYMENT_LINK_AGREEMENT_VIEW = "paylink_startpage_view",
  PAYMENT_LINK_AGREEMENT_LEARN_MORE = "paylink_startpage_learnmore",
  PAYMENT_LINK_SHARE_COPY_LINK = "paylink_actions_sharing_copy_link",
  PAYMENT_LINK_SHARE_WHATSAPP = "paylink_actions_sharing_whatsapp",
  PAYMENT_LINK_TERMS_AND_CONDITIONS = "paylink_click_terms_conditions",
  PAYMENT_LINK_CREATE = "paylink_create_payment_link",
  PAYMENT_LINK_CREATED = "paylink_click_create_success",
  DASHBOARD_VIEWED = "dashboard_sales_performance_view",
  DASHBOARD_FILTERED = "dashboard_sales_filter_outl_use",
  BRAND_SETTING_LOGO_CLICK = "Brand setting logo action click",
  BRAND_SETTING_LOGO_REPLACE = "Brand setting logo action replace",
  BRAND_SETTING_LOGO_REMOVE = "Brand setting logo action remove",
  BRAND_SETTING_BUSINESS_NAME = "Brand setting business name",
  BRAND_SETTING_BUSINESS_WEBSITE = "Brand setting brand website",
  BRAND_SETTING_COPY_LINK = "Brand setting copy link",
  BRAND_SETTING_PAGE_LANGUAGE = "Brand setting page language",
  BRAND_SETTING_TYPE = "Brand setting business type choose",
  BRAND_SETTING_CUISINE = "Brand setting cuisine type choose",
  BRAND_SETTING_SAVE = "Brand setting save the settings",
  SHARE_ADDRESS = "Share address",
  PAY_AT_TABLE_TABLE_PAID = "Business clicks to close the table",
  PAY_AT_TABLE_TABLE_ROW = "Business takes any action on the table row",
  PAY_AT_TABLE_ORDER_ITEMS = "Business either add or removes items to an order",
  REVIEWS_VIEW_PAGE = "Reviews - Viewed Page",
  REVIEWS_VIEW_DETAILS = "Reviews - Viewed Details",
  CUSTOMER_MANAGEMENT_VIEW_PAGE = "Customer Management - Viewed Main Page",
  CUSTOMER_MANAGEMENT_VIEW_DETAILS = "Customer Management - Viewed Details Page",
}

export enum EventCategoryEnum {
  DELIVERY_AREAS = "delivery_areas",
  OUTLET = "outlet",
  BILLING = "billing",
  SALES_CHANNEL = "sales_channel",
  REVIEWS = "reviews",
  BANNER_PERFORMANCE = "banner_performances",
  INBOX = "commerce-inbox",
  ONBOARDING = "onboarding",
  MARKETING = "marketing",
  PAYMENT_LINK = "payment_link",
  DASHBOARD = "dashboard",
  SIDEBAR = "sidebar",
  BUSINESS_SETTINGS = "business_settings",
  LIVE_ORDERS = "live_orders",
  CREATE_BUSINESS = "create_business",
  PAY_AT_TABLE = "pay_at_table",
  CUSTOMER_MANAGEMENT = "customer-management",
}
