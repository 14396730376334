import ApiServiceError from "./ApiServiceError";

class ServiceUnavailableError extends ApiServiceError {
  constructor(...params) {
    // Pass remaining arguments to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServiceUnavailableError);
    }

    this.name = "ServiceUnavailableError";
    this.message = "The service is not available, please try again later.";
  }
}

export default ServiceUnavailableError;
