















import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  computed,
  onMounted,
  PropType,
} from "@vue/composition-api";
import { array, boolean, func } from "@/v2/util/prop/type";

const css = bemBuilder("atom-select");

export default defineComponent({
  name: "AtomSelect",
  inheritAttrs: false,
  props: {
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function as PropType<(selectedOptions: any) => void>,
      default: () => {},
    },
  },
  setup() {
    return {
      css,
    };
  },
});
