import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";
import {
  IUpdateVoidReasonStatusRequest,
  IUpdateVoidReasonStatusBodyParams,
} from "./types";

export class UpdateVoidReasonStatusError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateVoidReasonStatusError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 *{@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/b67e3a7a426da-update-void-reason-status API contract}
 */
export async function updateVoidReasonStatus(
  request: IUpdateVoidReasonStatusRequest
): Promise<void> {
  try {
    const params: IUpdateVoidReasonStatusBodyParams = {
      id: request.id,
      is_enabled: request.isEnabled,
    };

    await http.patch(
      `api/v3/businesses/${request.businessId}/tabs/void-categories/${request.voidCategoryId}/reasons`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateVoidReasonStatusError(error);
  }
}
