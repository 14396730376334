











import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { AtomAvatarSizeEnum } from "./size.enum";
import PlaceholderSvg from "./assets/placeholder.svg";

const css = bemBuilder("atom-avatar");

export default defineComponent({
  name: "AtomAvatar",
  components: {
    PlaceholderSvg,
  },
  props: {
    size: {
      type: String as PropType<AtomAvatarSizeEnum>,
      default: AtomAvatarSizeEnum.REGULAR,
    },
    imageLink: {
      type: String,
      default: "",
    },
    altText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const hasImage = computed(() => Boolean(props.imageLink.length));

    return {
      css,
      hasImage,
    };
  },
});
