<template>
  <Error v-if="hasGlobalError" />
  <div v-else class="main">
    <div class="main-wrapper">
      <Sidebar
        :business-id="activeBusiness.id"
        :business-name="activeBusiness.name"
      />
      <div class="main-content">
        <BillingMain
          :key="activeBusiness.id"
          :business-id="activeBusiness.id"
        />
        <PendingPaymentBanner
          v-if="shouldDisplayPendingPaymentBanner"
          :business-id="activeBusiness.id"
          :subscription-id="subscriptionId"
        />
        <slot />
      </div>
    </div>
    <NetworkStateMain />
  </div>
</template>

<script>
import { Sidebar } from "@/v2/module/sidebar";
import PendingPaymentBanner from "./partials/PendingPaymentBanner.vue";
import Error from "./partials/Error.vue";
import { getActiveBusiness } from "@/utils/mixins";
import { mapGetters } from "vuex";
import { BillingMain } from "@/v2/module/billing/main";
import { billingGetState } from "@/v2/module/billing/state";
import { computed } from "@vue/composition-api";
import { NetworkStateMain } from "@/v2/module/network-state/main";

export default {
  name: "TheMaster",
  components: {
    Sidebar,
    BillingMain,
    PendingPaymentBanner,
    Error,
    NetworkStateMain,
  },
  mixins: [getActiveBusiness],
  setup() {
    const subscriptionId = computed(() => {
      return billingGetState("currentPlan").value?.id;
    });

    const shouldDisplayPendingPaymentBanner = computed(() => {
      let subscriptionState = billingGetState("currentPlan").value?.state;

      return (
        subscriptionState === "PENDING_PAYMENT" ||
        subscriptionState === "UNPAID"
      );
    });

    return {
      subscriptionId,
      shouldDisplayPendingPaymentBanner,
    };
  },
  computed: {
    ...mapGetters({
      hasGlobalError: "hasGlobalError",
    }),
  },
};
</script>
