export enum OrderingServiceEnum {
  DELIVERY = "DELIVERY",
  PICKUP = "PICKUP",
  DINE_IN = "DINE_IN",
}

export enum OrderingServiceOutletEnum {
  DELIVERY = "delivery",
  PICKUP = "pickup",
  DINE_IN = "dine-in",
}
