import { map, isNil, propOr } from "ramda";

/** Parser to normalize data coming from the API */
export default class BusinessesParser {
  /**
   * Businesses Parser Constuctor.
   *
   * @param {Array} response List of items coming from API
   */
  constructor(response) {
    this.response = response;
  }

  /**
   * Parse the business currency from API to local format.
   *
   * @param  {Object} currency Raw currency in the API response format
   * @return {Object}
   */
  parseCurrency(currency) {
    return {
      code: currency.code,
      symbol: currency.symbol,
      thousand: currency.thousand,
      decimal: currency.decimal,
      precision: currency.precision,
      symbolFirst: currency.symbol_first,
      symbolSeparator: currency.symbol_separator,
    };
  }

  /**
   * Parses the list of businesses coming from the API to a valid format
   *
   * @returns {Array}
   */
  fromApi() {
    return map((obj) => {
      const transformed = {
        id: obj.id,
        name: obj.name,
        email: obj.email,
        url: obj.url,
        logo: obj.logo,
        country: obj.country,
        category: obj.category,
        language: obj.language,
        features: Array.isArray(obj.features) ? obj.features : [],
        analyticsLink: obj.analytics_link,
        onboardingStatus: obj.onboarding_status,
        hasInbox: Boolean(obj.has_inbox_service),
        timezone: obj.timezone,
        trialEndsAt: obj.trial_ends_at,
        colorset: obj.colorset,
        orderingLink: obj.ordering_link,
        organizationId: obj.organization_id,
        createdAt: obj.created_at,
      };

      if (!isNil(obj.currency)) {
        const currency = propOr({}, "currency", obj);
        transformed.currency = this.parseCurrency(currency);
      }

      return transformed;
    }, this.response);
  }
}
