<template>
  <div class="ExternalContent clearfix">
    <div class="d-flex justify-content-between align-items-top">
      <h4 class="pl-4 pt-4 m-0 bold" data-test="title">{{ title }}</h4>
      <a href="#" class="p-4" data-test="close" @click.prevent="$emit('close')">
        <TimesSVG class="w-10" />
      </a>
    </div>
    <iframe
      class="ExternalContent__iframe"
      data-test="iframe"
      :src="link"
    ></iframe>
  </div>
</template>

<script>
import TimesSVG from "@/assets/svg/icons/times.svg";

export default {
  name: "ExternalContent",
  components: {
    TimesSVG,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ExternalContent {
  display: inline-block;
  width: 100%;
  height: 100%;

  &__iframe {
    width: 100%;
    height: calc(100% - 75px);
    border: 0;
  }
}
</style>
