




















import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomIcon } from "../../atom/icon";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
} from "../../atom/button";

const css = bemBuilder("mol-delete-button");

export default defineComponent({
  name: "MolDeleteButton",
  components: {
    AtomButton,
    AtomIcon,
  },
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    textOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { css, AtomButtonTypeEnum, AtomButtonSizeEnum };
  },
});
