export enum TimezonesEnum {
  Africa_Abidjan = "Africa/Abidjan",
  Africa_Accra = "Africa/Accra",
  Africa_Algiers = "Africa/Algiers",
  Africa_Bissau = "Africa/Bissau",
  Africa_Cairo = "Africa/Cairo",
  Africa_Casablanca = "Africa/Casablanca",
  Africa_Ceuta = "Africa/Ceuta",
  Africa_El_Aaiun = "Africa/El_Aaiun",
  Africa_Johannesburg = "Africa/Johannesburg",
  Africa_Khartoum = "Africa/Khartoum",
  Africa_Lagos = "Africa/Lagos",
  Africa_Maputo = "Africa/Maputo",
  Africa_Monrovia = "Africa/Monrovia",
  Africa_Nairobi = "Africa/Nairobi",
  Africa_Ndjamena = "Africa/Ndjamena",
  Africa_Tripoli = "Africa/Tripoli",
  Africa_Tunis = "Africa/Tunis",
  Africa_Windhoek = "Africa/Windhoek",
  America_Anchorage = "America/Anchorage",
  America_Araguaina = "America/Araguaina",
  America_Argentina_Buenos_Aires = "America/Argentina/Buenos_Aires",
  America_Asuncion = "America/Asuncion",
  America_Bahia = "America/Bahia",
  America_Barbados = "America/Barbados",
  America_Belem = "America/Belem",
  America_Belize = "America/Belize",
  America_Boa_Vista = "America/Boa_Vista",
  America_Bogota = "America/Bogota",
  America_Campo_Grande = "America/Campo_Grande",
  America_Cancun = "America/Cancun",
  America_Caracas = "America/Caracas",
  America_Cayenne = "America/Cayenne",
  America_Chicago = "America/Chicago",
  America_Costa_Rica = "America/Costa_Rica",
  America_Cuiaba = "America/Cuiaba",
  America_Curacao = "America/Curacao",
  America_Danmarkshavn = "America/Danmarkshavn",
  America_Dawson_Creek = "America/Dawson_Creek",
  America_Denver = "America/Denver",
  America_Edmonton = "America/Edmonton",
  America_El_Salvador = "America/El_Salvador",
  America_Fortaleza = "America/Fortaleza",
  America_Godthab = "America/Godthab",
  America_Grand_Turk = "America/Grand_Turk",
  America_Guatemala = "America/Guatemala",
  America_Guayaquil = "America/Guayaquil",
  America_Guyana = "America/Guyana",
  America_Halifax = "America/Halifax",
  America_Havana = "America/Havana",
  America_Hermosillo = "America/Hermosillo",
  America_Iqaluit = "America/Iqaluit",
  America_Jamaica = "America/Jamaica",
  America_La_Paz = "America/La_Paz",
  America_Lima = "America/Lima",
  America_Los_Angeles = "America/Los_Angeles",
  America_Maceio = "America/Maceio",
  America_Managua = "America/Managua",
  America_Manaus = "America/Manaus",
  America_Martinique = "America/Martinique",
  America_Mazatlan = "America/Mazatlan",
  America_Mexico_City = "America/Mexico_City",
  America_Miquelon = "America/Miquelon",
  America_Montevideo = "America/Montevideo",
  America_Nassau = "America/Nassau",
  America_New_York = "America/New_York",
  America_Noronha = "America/Noronha",
  America_Panama = "America/Panama",
  America_Paramaribo = "America/Paramaribo",
  America_Phoenix = "America/Phoenix",
  America_Port_au_Prince = "America/Port-au-Prince",
  America_Port_of_Spain = "America/Port_of_Spain",
  America_Porto_Velho = "America/Porto_Velho",
  America_Puerto_Rico = "America/Puerto_Rico",
  America_Punta_Arenas = "America/Punta_Arenas",
  America_Recife = "America/Recife",
  America_Regina = "America/Regina",
  America_Rio_Branco = "America/Rio_Branco",
  America_Santiago = "America/Santiago",
  America_Santo_Domingo = "America/Santo_Domingo",
  America_Sao_Paulo = "America/Sao_Paulo",
  America_Scoresbysund = "America/Scoresbysund",
  America_St_Johns = "America/St_Johns",
  America_Tegucigalpa = "America/Tegucigalpa",
  America_Thule = "America/Thule",
  America_Tijuana = "America/Tijuana",
  America_Toronto = "America/Toronto",
  America_Vancouver = "America/Vancouver",
  America_Whitehorse = "America/Whitehorse",
  America_Winnipeg = "America/Winnipeg",
  America_Yellowknife = "America/Yellowknife",
  Antarctica_Casey = "Antarctica/Casey",
  Antarctica_Davis = "Antarctica/Davis",
  Antarctica_DumontDUrville = "Antarctica/DumontDUrville",
  Antarctica_Mawson = "Antarctica/Mawson",
  Antarctica_Palmer = "Antarctica/Palmer",
  Antarctica_Rothera = "Antarctica/Rothera",
  Antarctica_Syowa = "Antarctica/Syowa",
  Antarctica_Vostok = "Antarctica/Vostok",
  Asia_Almaty = "Asia/Almaty",
  Asia_Amman = "Asia/Amman",
  Asia_Aqtau = "Asia/Aqtau",
  Asia_Aqtobe = "Asia/Aqtobe",
  Asia_Ashgabat = "Asia/Ashgabat",
  Asia_Baghdad = "Asia/Baghdad",
  Asia_Bahrain = "Asia/Bahrain",
  Asia_Baku = "Asia/Baku",
  Asia_Bangkok = "Asia/Bangkok",
  Asia_Beirut = "Asia/Beirut",
  Asia_Bishkek = "Asia/Bishkek",
  Asia_Brunei = "Asia/Brunei",
  Asia_Choibalsan = "Asia/Choibalsan",
  Asia_Colombo = "Asia/Colombo",
  Asia_Damascus = "Asia/Damascus",
  Asia_Dhaka = "Asia/Dhaka",
  Asia_Dili = "Asia/Dili",
  Asia_Dubai = "Asia/Dubai",
  Asia_Dushanbe = "Asia/Dushanbe",
  Asia_Gaza = "Asia/Gaza",
  Asia_Hong_Kong = "Asia/Hong_Kong",
  Asia_Hovd = "Asia/Hovd",
  Asia_Irkutsk = "Asia/Irkutsk",
  Asia_Jakarta = "Asia/Jakarta",
  Asia_Jayapura = "Asia/Jayapura",
  Asia_Jerusalem = "Asia/Jerusalem",
  Asia_Kabul = "Asia/Kabul",
  Asia_Kamchatka = "Asia/Kamchatka",
  Asia_Karachi = "Asia/Karachi",
  Asia_Kathmandu = "Asia/Kathmandu",
  Asia_Kolkata = "Asia/Kolkata",
  Asia_Krasnoyarsk = "Asia/Krasnoyarsk",
  Asia_Kuala_Lumpur = "Asia/Kuala_Lumpur",
  Asia_Macau = "Asia/Macau",
  Asia_Magadan = "Asia/Magadan",
  Asia_Makassar = "Asia/Makassar",
  Asia_Manila = "Asia/Manila",
  Asia_Nicosia = "Asia/Nicosia",
  Asia_Omsk = "Asia/Omsk",
  Asia_Pyongyang = "Asia/Pyongyang",
  Asia_Qatar = "Asia/Qatar",
  Asia_Riyadh = "Asia/Riyadh",
  Asia_Saigon = "Asia/Saigon",
  Asia_Seoul = "Asia/Seoul",
  Asia_Shanghai = "Asia/Shanghai",
  Asia_Singapore = "Asia/Singapore",
  Asia_Taipei = "Asia/Taipei",
  Asia_Tashkent = "Asia/Tashkent",
  Asia_Tbilisi = "Asia/Tbilisi",
  Asia_Tehran = "Asia/Tehran",
  Asia_Thimphu = "Asia/Thimphu",
  Asia_Tokyo = "Asia/Tokyo",
  Asia_Ulaanbaatar = "Asia/Ulaanbaatar",
  Asia_Vladivostok = "Asia/Vladivostok",
  Asia_Yakutsk = "Asia/Yakutsk",
  Asia_Yangon = "Asia/Yangon",
  Asia_Yekaterinburg = "Asia/Yekaterinburg",
  Asia_Yerevan = "Asia/Yerevan",
  Atlantic_Azores = "Atlantic/Azores",
  Atlantic_Bermuda = "Atlantic/Bermuda",
  Atlantic_Canary = "Atlantic/Canary",
  Atlantic_Cape_Verde = "Atlantic/Cape_Verde",
  Atlantic_Faroe = "Atlantic/Faroe",
  Atlantic_Reykjavik = "Atlantic/Reykjavik",
  Atlantic_South_Georgia = "Atlantic/South_Georgia",
  Atlantic_Stanley = "Atlantic/Stanley",
  Australia_Adelaide = "Australia/Adelaide",
  Australia_Brisbane = "Australia/Brisbane",
  Australia_Darwin = "Australia/Darwin",
  Australia_Hobart = "Australia/Hobart",
  Australia_Perth = "Australia/Perth",
  Australia_Sydney = "Australia/Sydney",
  Etc_GMT = "Etc/GMT",
  Europe_Amsterdam = "Europe/Amsterdam",
  Europe_Andorra = "Europe/Andorra",
  Europe_Athens = "Europe/Athens",
  Europe_Belgrade = "Europe/Belgrade",
  Europe_Berlin = "Europe/Berlin",
  Europe_Brussels = "Europe/Brussels",
  Europe_Bucharest = "Europe/Bucharest",
  Europe_Budapest = "Europe/Budapest",
  Europe_Chisinau = "Europe/Chisinau",
  Europe_Copenhagen = "Europe/Copenhagen",
  Europe_Dublin = "Europe/Dublin",
  Europe_Gibraltar = "Europe/Gibraltar",
  Europe_Helsinki = "Europe/Helsinki",
  Europe_Istanbul = "Europe/Istanbul",
  Europe_Kaliningrad = "Europe/Kaliningrad",
  Europe_Kiev = "Europe/Kiev",
  Europe_Lisbon = "Europe/Lisbon",
  Europe_London = "Europe/London",
  Europe_Luxembourg = "Europe/Luxembourg",
  Europe_Madrid = "Europe/Madrid",
  Europe_Malta = "Europe/Malta",
  Europe_Minsk = "Europe/Minsk",
  Europe_Monaco = "Europe/Monaco",
  Europe_Moscow = "Europe/Moscow",
  Europe_Oslo = "Europe/Oslo",
  Europe_Paris = "Europe/Paris",
  Europe_Prague = "Europe/Prague",
  Europe_Riga = "Europe/Riga",
  Europe_Rome = "Europe/Rome",
  Europe_Samara = "Europe/Samara",
  Europe_Sofia = "Europe/Sofia",
  Europe_Stockholm = "Europe/Stockholm",
  Europe_Tallinn = "Europe/Tallinn",
  Europe_Tirane = "Europe/Tirane",
  Europe_Vienna = "Europe/Vienna",
  Europe_Vilnius = "Europe/Vilnius",
  Europe_Warsaw = "Europe/Warsaw",
  Europe_Zurich = "Europe/Zurich",
  Indian_Chagos = "Indian/Chagos",
  Indian_Christmas = "Indian/Christmas",
  Indian_Cocos = "Indian/Cocos",
  Indian_Kerguelen = "Indian/Kerguelen",
  Indian_Mahe = "Indian/Mahe",
  Indian_Maldives = "Indian/Maldives",
  Indian_Mauritius = "Indian/Mauritius",
  Indian_Reunion = "Indian/Reunion",
  Pacific_Apia = "Pacific/Apia",
  Pacific_Auckland = "Pacific/Auckland",
  Pacific_Chuuk = "Pacific/Chuuk",
  Pacific_Easter = "Pacific/Easter",
  Pacific_Efate = "Pacific/Efate",
  Pacific_Enderbury = "Pacific/Enderbury",
  Pacific_Fakaofo = "Pacific/Fakaofo",
  Pacific_Fiji = "Pacific/Fiji",
  Pacific_Funafuti = "Pacific/Funafuti",
  Pacific_Galapagos = "Pacific/Galapagos",
  Pacific_Gambier = "Pacific/Gambier",
  Pacific_Guadalcanal = "Pacific/Guadalcanal",
  Pacific_Guam = "Pacific/Guam",
  Pacific_Honolulu = "Pacific/Honolulu",
  Pacific_Kiritimati = "Pacific/Kiritimati",
  Pacific_Kosrae = "Pacific/Kosrae",
  Pacific_Kwajalein = "Pacific/Kwajalein",
  Pacific_Majuro = "Pacific/Majuro",
  Pacific_Marquesas = "Pacific/Marquesas",
  Pacific_Nauru = "Pacific/Nauru",
  Pacific_Niue = "Pacific/Niue",
  Pacific_Norfolk = "Pacific/Norfolk",
  Pacific_Noumea = "Pacific/Noumea",
  Pacific_Pago_Pago = "Pacific/Pago_Pago",
  Pacific_Palau = "Pacific/Palau",
  Pacific_Pitcairn = "Pacific/Pitcairn",
  Pacific_Pohnpei = "Pacific/Pohnpei",
  Pacific_Port_Moresby = "Pacific/Port_Moresby",
  Pacific_Rarotonga = "Pacific/Rarotonga",
  Pacific_Tahiti = "Pacific/Tahiti",
  Pacific_Tarawa = "Pacific/Tarawa",
  Pacific_Tongatapu = "Pacific/Tongatapu",
  Pacific_Wake = "Pacific/Wake",
  Pacific_Wallis = "Pacific/Wallis",
}
