import Base from "./Base";
import { has, is, forEach } from "ramda";

/** Constructor to normalize outsider data */
class Order extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} order
   */
  constructor(order = {}) {
    const keys = {};

    super(keys, order);
  }

  /** @inheritdoc */
  static toFilterPayload(obj = {}) {
    forEach(
      (key) => {
        if (has(key, obj) && !is(Array, obj[key])) {
          obj[key] = [obj[key]];
        }
      },
      ["status", "outlets", "orderingServices", "channels"]
    );

    obj = super.toFilterPayload(obj);

    return obj;
  }
}

export default Order;
