import { http } from "@/v2/repo/http";
import { IRepoErrors } from "@/v2/repo/errors";
import {
  IUpdateVoidCategoryRequest,
  IUpdateVoidCategoryBodyParams,
} from "./types";

export class UpdateVoidCategoryError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateVoidCategoryError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

/**
 * {@link https://chatfood.stoplight.io/docs/chatfood-api-dashboard/69c1a5774d086-update-void-category API contract}
 */
export async function updateVoidCategory(
  request: IUpdateVoidCategoryRequest
): Promise<void> {
  try {
    const params: IUpdateVoidCategoryBodyParams = {
      name: request.name,
      is_visible_to_customer: request.isVisibleToCustomer,
    };

    await http.put(
      `api/v3/businesses/${request.businessId}/tabs/void-categories/${request.voidCategoryId}`,
      params
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateVoidCategoryError(error);
  }
}
