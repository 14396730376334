




















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { boolean, func, string } from "@/v2/util/prop/type";
import { AtomTextarea } from "@/v2/design-system/atom/textarea";
import { MolFormControl } from "@/v2/design-system/mol/form-control";

const css = bemBuilder("org-form-textarea");

export default defineComponent({
  name: "OrgFormTextarea",
  components: {
    MolFormControl,
    AtomTextarea,
  },
  props: {
    value: string().isRequired().use(),
    label: string().use(),
    errorMsg: string().use(),
    successMsg: string().use(),
    tooltip: string().use(),
    onChange: func().use(),
    loading: boolean().use(),
    caption: string().use(),
    required: boolean().use(),
    hint: string().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
