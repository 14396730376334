


































import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";
import { ITabNavigationItem } from "@/v2/new-design-system/mol/tab-navigation/props";
import { VTooltip } from "v-tooltip";

const css = bemBuilder("mol-tab-navigation");

export default defineComponent({
  name: "MolTabNavigation",
  components: {
    AtomText,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    items: {
      type: Array as PropType<Array<ITabNavigationItem>>,
      required: true,
    },
    showCounters: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
