import { VIEW_USERS, EDIT_USERS } from "@/utils/enums/permissions";

export default [
  {
    name: "user-management.index",
    path: "/business/:businessId/user-management",
    component: () =>
      import(
        /* webpackChunkName: "user-management-list" */ "@/v2/module/user-management/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "User Management",
      permission: VIEW_USERS,
    },
  },
  {
    name: "user-management.invite",
    path: "/business/:businessId/user-management/invite",
    component: () =>
      import(
        /* webpackChunkName: "user-management-list" */ "@/v2/module/user-management/invite/Invite.vue"
      ),
    props: true,
    meta: {
      title: "Invite users",
      permission: EDIT_USERS,
    },
  },
  {
    name: "auth.user-management.accept",
    path: "/invite/:token",
    component: () =>
      import(
        /* webpackChunkName: "user-management-list" */ "@/v2/module/user-management/invite/accept/Accept.vue"
      ),
    props: true,
    meta: {
      isAuthRequired: false,
      title: "Invite",
      layout: "raw",
    },
  },
  {
    name: "user-management.edit",
    path: "/business/:businessId/user-management/:userType/:userId",
    component: () =>
      import(
        /* webpackChunkName: "user-management-list" */ "@/v2/module/user-management/edit/Edit.vue"
      ),
    props: true,
    meta: {
      title: "Edit user - User Management",
      permission: EDIT_USERS,
    },
  },
  {
    name: "user-management.profile",
    path: "/business/:businessId/profile",
    component: () =>
      import(
        /* webpackChunkName: "user-management-list" */ "@/v2/module/user-management/profile/Profile.vue"
      ),
    props: true,
    meta: {
      title: "Profile",
    },
  },
];
