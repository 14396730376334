<template>
  <div class="align-items-center d-flex flex-column p-5">
    <h1 class="display-5">Unexpected error</h1>
    <p class="text-center text-black-50">
      Sorry, something went wrong while processing your request. We are
      currently working to fix the problem. Please try again in a few minutes
    </p>
    <p class="w-100 d-flex justify-content-center align-items-center mt-3">
      <a href="#" class="btn btn-primary mr-3" @click.prevent="refresh">
        Try again
      </a>
      or
      <router-link :to="{ name: 'app.index' }" class="btn btn-primary ml-3">
        Go to homepage
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "503",
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>
