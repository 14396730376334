import { VIEW_SPOTS_MANAGEMENT } from "@/utils/enums/permissions";

export default [
  {
    name: "spots-management",
    path: "/business/:businessId/spots-management/:outletId?",
    component: () =>
      import(
        /* webpackChunkName: "spots-management-module" */ "@/v2/module/spots-management/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Tables Management",
      permission: VIEW_SPOTS_MANAGEMENT,
    },
  },
];
