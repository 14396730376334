





































import { defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { boolean, func, string } from "@/v2/util/prop/type";
import { AtomIcon } from "@/v2/design-system/atom/icon";
import { AtomHighlight } from "@/v2/design-system/atom/highlight";
import { AtomText, AtomTextSizeEnum } from "@/v2/design-system/atom/text";

const css = bemBuilder("mol-info-box");

export default defineComponent({
  name: "MolInfoBox",
  components: {
    AtomIcon,
    AtomHighlight,
    AtomText,
  },
  props: {
    onClickCta: func()
      .setDefault(() => undefined)
      .use(),
    onClickClose: func()
      .setDefault(() => undefined)
      .use(),
    heading: string().isRequired().use(),
    cta: string().use(),
    maxWidth: string().setDefault("520px").use(),
    showCloseButton: boolean().use(),
  },
  setup(props) {
    return {
      css,
      AtomTextSizeEnum,
    };
  },
});
