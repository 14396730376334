import { createBillingCustomerPortalSession } from "@/v2/repo/create-billiling-customer-portal-session/create-billing-customer-portal-session";
import { Toast } from "@/design-system";
import { report } from "@chatfood/bug-reporter";
import { ref } from "@vue/composition-api";
import { t } from "@/i18n";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useStripeCustomerPortal = () => {
  const isStripeCustomerPortalLoading = ref(false);

  async function openBillingCustomerPortal(
    businessId: string,
    subscriptionId: string
  ): Promise<void> {
    isStripeCustomerPortalLoading.value = true;

    try {
      const sessionObj = await createBillingCustomerPortalSession(
        businessId,
        subscriptionId,
        window.location.href
      );

      window.open(sessionObj.sessionURL, "_self");
    } catch (e) {
      isStripeCustomerPortalLoading.value = false;

      new Toast().create({
        type: "error",
        title: t("module.billing.stripe_failure_title"),
        text: t("module.billing.stripe_failure_subtitle"),
      });

      report(e);
    }
  }

  return {
    isStripeCustomerPortalLoading,
    openBillingCustomerPortal,
  };
};
