




import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { AtomMoneyTagEnum } from "./tag.enum";
import DineroFactory from "dinero.js";
import Dinero from "dinero.js";
import { CurrencyCodeEnum, CurrencyPrecisionEnum } from "@/v2/enum";

const css = bemBuilder("atom-money");

export default defineComponent({
  name: "AtomMoney",
  props: {
    tag: {
      type: String as PropType<AtomMoneyTagEnum>,
      default: AtomMoneyTagEnum.SPAN,
    },
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    locale: {
      type: String,
      default: "",
    },
    hideCents: {
      type: Boolean,
      default: false,
    },
    hideCurrencyCode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const precision = computed(() => {
      return CurrencyPrecisionEnum[props.currency] ?? 2;
    });

    const locale = computed(() => {
      if (!props.locale) {
        return window.navigator.language ?? "en-US";
      }

      return props.locale;
    });

    const format = computed(() => {
      let result = "0,0";

      if (!props.hideCurrencyCode) {
        result = `$${result}`;
      }

      if (!props.hideCents && precision.value > 0) {
        result = result + "." + `0`.repeat(precision.value);
      }

      return result;
    });

    const formattedAmount = computed(() => {
      let money = Dinero({
        amount: props.amount,
        precision: precision.value,
        currency: props.currency as DineroFactory.Currency,
      });

      money = money.setLocale(locale.value);

      return money.toFormat(format.value);
    });

    return {
      formattedAmount,
      css,
    };
  },
});
