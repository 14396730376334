import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

// By a product decision, the parameter 'numberAdditionalOutlets' will
// no longer be used for checkout, but it is still mandatory at the endpoint,
// so we need to wait for removal on the API side to remove here.
export async function createSubscriptionCheckout(
  businessId: string,
  priceId: string
): Promise<ICreateSubscriptionCheckoutResponse> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        businessId,
        subscriptionCheckoutAttributes: {
          priceId: priceId,
          numberAdditionalOutlets: 0,
        },
      },
    });

    return response.data.subscriptionCheckout;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface ICreateSubscriptionCheckoutResponse {
  paymentInfo: {
    invoiceId: string;
    status:
      | "PAID"
      | "REQUIRES_PAYMENT_METHOD"
      | "REQUIRES_CUSTOMER_AUTHENTICATION";
    paymentIntentClientSecret: string;
  } | null;
  status: "SUCCESSFUL" | "FAILED" | "UNMODIFIED";
  subscriptionId: string;
}
