





















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { useConnectionState } from "@/v2/composable/use-connection-state";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTagEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";
import NetworkIcon from "./assets/network-icon.svg";

const css = bemBuilder("network-state-main");

export default defineComponent({
  name: "NetworkStateMain",
  components: {
    AtomText,
    NetworkIcon,
  },
  setup() {
    const { isOnline, isOffline } = useConnectionState();

    return {
      css,
      t,
      AtomTextColorEnum,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      isOnline,
      isOffline,
    };
  },
});
