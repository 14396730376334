







































































import { bemBuilder } from "@/v2/util/bem-builder";
import { t } from "@/i18n";
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  computed,
  PropType,
} from "@vue/composition-api";
import { AtomIcon, AtomText } from "@/v2/new-design-system";

const css = bemBuilder("mol-pagination");

export default defineComponent({
  name: "MolPagination",
  components: {
    AtomIcon,
    AtomText,
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    onNavigate: {
      type: Function as PropType<(page: number) => void>,
      default: () => {},
    },
    hasSummary: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const pages = ref([]);
    const count = 8;

    onMounted(async () => {
      init();
    });

    watch(
      () => [props.currentPage, props.total],
      async () => {
        await init();
      },
      {
        immediate: true,
      }
    );

    function navigate(page: number) {
      if (props.currentPage === page) return;
      props.onNavigate(page);
    }

    function nextPrev(event: any, page: number) {
      if (page === 0 || page === props.lastPage + 1) return;

      navigate(page);
    }

    function generatePagesArray(
      currentPage: number,
      collectionLength: number,
      rowsPerPage: number
    ) {
      const pages = [];
      const totalPages = Math.ceil(collectionLength / rowsPerPage);
      const halfWay = Math.ceil(count / 2);
      const ellipsesNeeded = count < totalPages;

      let position = "middle";

      if (currentPage <= halfWay) {
        position = "start";
      } else if (totalPages - halfWay < currentPage) {
        position = "end";
      }

      let i = 1;

      while (i <= totalPages && i <= count) {
        const pageNumber = calculatePageNumber(i, currentPage, totalPages);
        const openingEllipsesNeeded =
          i === 2 && (position === "middle" || position === "end");
        const closingEllipsesNeeded =
          i === count - 1 && (position === "middle" || position === "start");

        if (
          ellipsesNeeded &&
          (openingEllipsesNeeded || closingEllipsesNeeded)
        ) {
          pages.push("...");
        } else {
          pages.push(pageNumber);
        }

        i++;
      }
      return pages;
    }

    function calculatePageNumber(
      i: number,
      currentPage: number,
      totalPages: number
    ) {
      const halfWay = Math.ceil(count / 2);

      if (i === count) {
        return totalPages;
      } else if (i === 1) {
        return i;
      } else if (count < totalPages) {
        if (totalPages - halfWay < currentPage) {
          return totalPages - count + i;
        } else if (halfWay < currentPage) {
          return currentPage - halfWay + i;
        }
      }
      return i;
    }

    function init() {
      // @ts-ignore
      pages.value = generatePagesArray(
        props.currentPage,
        props.total,
        props.perPage
      );
    }

    const firstRecordIndex = computed(
      () => props.currentPage * props.perPage - props.perPage + 1
    );

    const lastRecordIndex = computed(() => {
      const last = props.currentPage * props.perPage;

      return last > props.total ? props.total : last;
    });
    return {
      css,
      t,
      pages,
      firstRecordIndex,
      lastRecordIndex,
      navigate,
      nextPrev,
    };
  },
});
