











import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { AtomDropdown } from "@/v2/new-design-system";
import { useRoute, useRouter } from "@/router";
import { useHelpers } from "@/v2/composable/use-helpers";
import { setActiveOutlet } from "./utils";

const css = bemBuilder("outlet-switcher");

interface IOutlet {
  id: string;
  name: string;
}

export default defineComponent({
  name: "OutletSwitcher",
  components: {
    AtomDropdown,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const outlets = computed(() =>
      useHelpers()
        .getOutletsFromBusiness(props.businessId)
        .map((o) => ({
          id: o.id,
          name: o.name,
        }))
    );

    const outletSelected = computed(() =>
      outlets.value.find((o) => o.id === props.outletId)
    );

    const router = useRouter();
    const route = useRoute();

    function onSelect(outlet: IOutlet) {
      setActiveOutlet(outlet.id);
      router.push({
        name: route.name ?? undefined,
        params: {
          ...route.params,
          outletId: outlet.id,
        },
        query: route.query,
        hash: route.hash,
      });
    }

    return {
      css,
      outlets,
      onSelect,
      outletSelected,
    };
  },
});
