














import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomButton, AtomButtonSizeEnum } from "../../atom/button";
import { MolInfoLock } from "../../mol/info-lock";
import { IOrgInfoLockButtonProps } from "./props";

const css = bemBuilder("org-info-lock-button");

export default defineComponent<IOrgInfoLockButtonProps>({
  name: "OrgInfoLockButton",
  components: {
    AtomButton,
    MolInfoLock,
  },
  props: {
    size: {
      type: String as PropType<AtomButtonSizeEnum>,
      default: AtomButtonSizeEnum.MEDIUM,
    },
    persistentOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
