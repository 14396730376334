import { values } from "ramda";

export default class Enumeration {
  constructor(obj) {
    for (const key in obj) {
      this[key] = obj[key];
    }
    return Object.freeze(this);
  }

  has(key) {
    // eslint-disable-next-line
    return this.hasOwnProperty(key);
  }

  values() {
    return values(this);
  }

  includes(value) {
    return this.values().includes(value);
  }
}
