/**
 * @deprecated
 * use from v2/new-design-system
 */

export enum AtomButtonTypeEnum {
  PRIMARY = "primary",
  PRIMARY_LIGHT = "primary-light",
  SECONDARY = "secondary",
  MINIMAL = "minimal",
  MINIMAL_OUTLINE = "minimal-outline",
}
