import { VIEW_TIPS } from "@/utils/enums/permissions";

export default [
  {
    name: "tips.index",
    path: "/business/:businessId/tips",
    component: () =>
      import(
        /* webpackChunkName: "tips-module" */ "@/v2/module/tips/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Tips",
      permission: VIEW_TIPS,
    },
  },
];
