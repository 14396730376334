const LS_KEY = "active_outlet";

const setActiveOutlet = (outletId: string): void => {
  window.localStorage.setItem(LS_KEY, outletId);
};

const getActiveOutlet = (): string | undefined => {
  try {
    return window.localStorage.getItem(LS_KEY) ?? undefined;
  } catch (e) {
    return undefined;
  }
};

export { getActiveOutlet, setActiveOutlet };
