import { socket } from "./io";

enum WsPubEventEnum {
  LOAD_CONVERSATIONS_BY_RANGE = "load_conversations_offset",
  LOAD_CONVERSATIONS = "load_conversations",
  LOAD_CONVERSATION = "load_conversation",
  LOAD_MESSAGES = "load_messages",
  SEND_MESSAGE = "send_message",
  SEND_MESSAGE_TEMPLATE = "send_template_message",
  MARK_CONVERSATION_AS_READ = "mark_conversation_as_read",
}

interface IWsPubPayload {
  LOAD_CONVERSATIONS_BY_RANGE: {
    businessId: string;
    rangeStart: number;
    rangeEnd: number;
  };
  LOAD_CONVERSATIONS: {
    businessId: string;
    conversationsCount: number;
  };
  LOAD_CONVERSATION: {
    businessId: string;
    conversationId: string;
    messagesCount: number;
  };
  SEND_MESSAGE: {
    businessId: string;
    conversationId: string;
    content: string;
    createdAt: Date;
  };
  SEND_MESSAGE_TEMPLATE: {
    businessId: string;
    conversationId: string;
    templateId: string;
    createdAt: Date;
  };
  LOAD_MESSAGES: {
    businessId: string;
    conversationId: string;
    rangeStart: number;
    rangeEnd: number;
  };
  MARK_CONVERSATION_AS_READ: {
    conversationId: string;
  };
}

export function emitEvent<T extends keyof IWsPubPayload>(
  event: T,
  params: IWsPubPayload[T]
): void {
  socket.emit(WsPubEventEnum[event], params);
}
