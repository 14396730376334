











































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system";
import { t } from "@/i18n";
import { MolSearchBoxEnum } from "./size.enum";

const css = bemBuilder("mol-search-box");

export default defineComponent({
  name: "MolSearchBox",
  components: {
    AtomIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    size: {
      type: String as PropType<MolSearchBoxEnum>,
      default: MolSearchBoxEnum.NORMAL,
    },
    value: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    hasCta: {
      type: Boolean,
      default: false,
    },
    labelCta: {
      type: String,
      default: "",
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      default: () => {},
    },
    onInputFocus: {
      type: Function as PropType<(value: string) => void>,
      default: () => {},
    },
    onInputBlur: {
      type: Function as PropType<(value: string) => void>,
      default: () => {},
    },
    onSearch: {
      type: Function as PropType<(value: string) => void>,
      default: () => {},
    },
  },
  setup(props) {
    const query = ref<string>("");

    watch(
      () => props.value,
      (value) => {
        query.value = value;
      },
      { immediate: true }
    );

    const cleanQuery = () => {
      query.value = "";
      inputChange();

      if (props.hasCta) {
        onButtonClick();
      }
    };

    const inputChange = () => {
      props.onInput(query.value);
    };

    const onButtonClick = () => {
      props.onSearch(query.value);
    };

    return {
      css,
      t,
      query,
      cleanQuery,
      inputChange,
      onButtonClick,
    };
  },
});
