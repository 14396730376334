import { VIEW_INBOX } from "@/utils/enums/permissions";
import { RouteConfig } from "vue-router";
import { IInboxRouteEnum } from "./inbox-route.enum";

export default [
  {
    name: IInboxRouteEnum.SETTINGS,
    path: "/business/:businessId/inbox/settings",
    component: () =>
      import(
        /* webpackChunkName: "inbox-module" */ "@/v2/module/inbox/settings/Settings.vue"
      ),
    props: true,
    meta: {
      title: "Inbox",
      permission: VIEW_INBOX,
    },
  },
  {
    path: "/business/:businessId/inbox",
    component: () =>
      import(
        /* webpackChunkName: "inbox-module" */ "@/v2/module/inbox/main/Main.vue"
      ),
    props: true,
    children: [
      {
        name: IInboxRouteEnum.INBOX,
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "inbox-module" */ "@/v2/module/inbox/empty-chat/EmptyChat.vue"
          ),
        props: true,
        meta: {
          title: "Inbox",
          permission: VIEW_INBOX,
        },
      },
      {
        name: IInboxRouteEnum.CHAT,
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "inbox-module" */ "@/v2/module/inbox/conversation-view/ConversationView.vue"
          ),
        props: true,
        meta: {
          title: "Inbox",
          permission: VIEW_INBOX,
        },
      },
    ],
  },
] as Array<RouteConfig>;
