export enum MolGuideLinkArticleEnum {
  FAQ_SUBSCRIPTION = "FAQ_SUBSCRIPTION",
  PRICING = "PRICING",
  HOW_TO_CREATE_QR_CODES = "HOW_TO_CREATE_QR_CODES",
  HOW_TO_MANAGE_DELIVERY_AREAS = "HOW_TO_MANAGE_DELIVERY_AREAS",
  HOW_TO_MANAGE_LIVE_ORDERS = "HOW_TO_MANAGE_LIVE_ORDERS",
  HOW_TO_MANAGE_OUTLETS = "HOW_TO_MANAGE_OUTLETS",
  HOW_TO_MANAGE_USER = "HOW_TO_MANAGE_USER",
  LEARN_MORE_PAYMENT_LINKS = "LEARN_MORE_PAYMENT_LINKS",
  LEARN_HOW_MANAGE_MENUS = "LEARN_HOW_MANAGE_MENUS",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  LEARN_MORE_INBOX = "LEARN_MORE_INBOX",
  HOW_TO_CONNECT_INSTAGRAM_INBOX = "HOW_TO_CONNECT_INSTAGRAM_INBOX",
  UNDERSTAND_INBOX_MESSAGING_WINDOW = "UNDERSTAND_INBOX_MESSAGING_WINDOW",
  LEARN_BUSINESS_PROFILE = "LEARN_BUSINESS_PROFILE",
  LEARN_LOYALTY = "LEARN_LOYALTY",
  BOOK_DEMO = "BOOK_DEMO",
}
