




































import { t } from "@/i18n";
import { EventEnum } from "@/v2/enum";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomModal,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
} from "@vue/composition-api";
import { billingPlanPriceFormatter } from "../method/price-formatter";
import { billingMethodTrack } from "../method/track";
import {
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
} from "../state";
import { isCreateBusinessFlowStarted } from "@/v2/module/billing/utils/create-business";
import { createBusinessTrack } from "@/v2/util/analytics/createBusinessTrackEvent";

const css = bemBuilder("billing-success-modal");

export default defineComponent({
  name: "BillingSuccessModal",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
  },
  props: {
    logo: {
      type: String,
      default: "",
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance();

    onMounted(async () => {
      const selectedPlan = billingGetState("selectedPlan").value;

      if (selectedPlan) {
        billingMethodTrack(
          // @ts-ignore
          vm?.proxy.$analytics,
          EventEnum.ACTIVATED_SUBSCRIPTION,
          {
            price: billingPlanPriceFormatter(
              selectedPlan.priceRaw,
              selectedPlan.currency,
              "0"
            ),
            is_recommended: selectedPlan.recommended,
            is_on_trial: billingGetState("trialDays").value > 0,
            currency: selectedPlan.currency,
            plan: selectedPlan.name,
          }
        );

        const currentPlan = billingGetState("currentPlan").value;

        if (currentPlan) {
          billingSetState("currentPlan", {
            ...currentPlan,
            plan: {
              ...currentPlan.plan,
              type: "REGULAR",
              maximumActiveOutlets: selectedPlan.maxOutlets || 0,
            },
          });
        }
      }
    });

    const billingSetStageDormant = () => {
      const business = billingGetState("business").value;

      if (isCreateBusinessFlowStarted(props.businessId)) {
        const selectedPlan = billingGetState("selectedPlan").value;
        createBusinessTrack(
          // @ts-ignore
          vm?.proxy.$analytics,
          EventEnum.CLICKED_ON_BUTTON,
          billingGetState("business").value.id,
          {
            plan_name: selectedPlan?.name,
            label: "Clicked on Done",
          }
        );
        location.replace(`/business/${business.id}`);
        return;
      }
      billingSetState("stage", BillingStageStateEnum.DORMANT);
    };

    return {
      css,
      t,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      billingSetStageDormant,
      selectedPlan: billingGetState("selectedPlan"),
    };
  },
});
