import { VIEW_BILLING } from "@/utils/enums/permissions";

export default [
  {
    name: "billing",
    path: "/business/:businessId/billing",
    component: () =>
      import(
        /* webpackChunkName: "billing-module" */ "@/v2/module/billing/page/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Billing",
      permission: VIEW_BILLING,
    },
  },
  {
    name: "billing.draft",
    path: "/business/:businessId/draft",
    component: () =>
      import(
        /* webpackChunkName: "billing-module" */ "@/v2/module/billing/page/draft/Draft.vue"
      ),
    props: true,
    meta: {
      title: "Billing - Draft",
      permission: VIEW_BILLING,
    },
  },
];
