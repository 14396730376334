











import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomIcon } from "@/v2/new-design-system";

const css = bemBuilder("atom-close-button");

export default defineComponent({
  name: "AtomCloseButton",
  components: {
    AtomIcon,
  },
  props: {
    onClick: {
      type: Function as PropType<(e: Event) => void>,
      default: () => {},
    },
  },
  setup() {
    return {
      css,
    };
  },
});
