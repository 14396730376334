
































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { string, boolean, func } from "@/v2/util/prop/type";

const css = bemBuilder("atom-switcher");

/**
 * @deprecated
 * use `AtomSwitcher` from `v2/new-design-system` instead
 */

export default defineComponent({
  name: "AtomSwitcher",
  props: {
    label: string().use(),
    onLabel: string().setDefault("on").use(),
    offLabel: string().setDefault("off").use(),
    checked: boolean().use(),
    disabled: boolean().use(),
    onChange: func().isRequired().use(),
  },
  setup() {
    return {
      css,
    };
  },
});
