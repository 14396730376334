export interface ICountry {
  name: string;
  country: string;
  dialCode: string;
  flagEmoji: string;
}

export const countries: Array<ICountry> = [
  {
    name: "Afghanistan (‫افغانستان‬‎)",
    country: "AF",
    dialCode: "93",
    flagEmoji: "🇦🇫",
  },
  {
    name: "Albania (Shqipëri)",
    country: "AL",
    dialCode: "355",
    flagEmoji: "🇦🇱",
  },
  {
    name: "Algeria (‫الجزائر‬‎)",
    country: "DZ",
    dialCode: "213",
    flagEmoji: "🇩🇿",
  },
  {
    name: "American Samoa",
    country: "AS",
    dialCode: "1684",
    flagEmoji: "🇦🇸",
  },
  { name: "Andorra", country: "AD", dialCode: "376", flagEmoji: "🇦🇩" },
  {
    name: "Angola",
    country: "AO",
    dialCode: "244",
    flagEmoji: "🇦🇴",
  },
  { name: "Anguilla", country: "AI", dialCode: "1264", flagEmoji: "🇦🇮" },
  {
    name: "Antigua and Barbuda",
    country: "AG",
    dialCode: "1268",
    flagEmoji: "🇦🇬",
  },
  { name: "Argentina", country: "AR", dialCode: "54", flagEmoji: "🇦🇷" },
  {
    name: "Armenia (Հայաստան)",
    country: "AM",
    dialCode: "374",
    flagEmoji: "🇦🇲",
  },
  { name: "Aruba", country: "AW", dialCode: "297", flagEmoji: "🇦🇼" },
  {
    name: "Australia",
    country: "AU",
    dialCode: "61",
    flagEmoji: "🇦🇺",
  },
  {
    name: "Austria (Österreich)",
    country: "AT",
    dialCode: "43",
    flagEmoji: "🇦🇹",
  },
  {
    name: "Azerbaijan (Azərbaycan)",
    country: "AZ",
    dialCode: "994",
    flagEmoji: "🇦🇿",
  },
  {
    name: "Bahamas",
    country: "BS",
    dialCode: "1242",
    flagEmoji: "🇧🇸",
  },
  {
    name: "Bahrain (‫البحرين‬‎)",
    country: "BH",
    dialCode: "973",
    flagEmoji: "🇧🇭",
  },
  {
    name: "Bangladesh (বাংলাদেশ)",
    country: "BD",
    dialCode: "880",
    flagEmoji: "🇧🇩",
  },
  {
    name: "Barbados",
    country: "BB",
    dialCode: "1246",
    flagEmoji: "🇧🇧",
  },
  {
    name: "Belarus (Беларусь)",
    country: "BY",
    dialCode: "375",
    flagEmoji: "🇧🇾",
  },
  {
    name: "Belgium (België)",
    country: "BE",
    dialCode: "32",
    flagEmoji: "🇧🇪",
  },
  { name: "Belize", country: "BZ", dialCode: "501", flagEmoji: "🇧🇿" },
  {
    name: "Benin (Bénin)",
    country: "BJ",
    dialCode: "229",
    flagEmoji: "🇧🇯",
  },
  { name: "Bermuda", country: "BM", dialCode: "1441", flagEmoji: "🇧🇲" },
  {
    name: "Bhutan (འབྲུག)",
    country: "BT",
    dialCode: "975",
    flagEmoji: "🇧🇹",
  },
  {
    name: "Bolivia",
    country: "BO",
    dialCode: "591",
    flagEmoji: "🇧🇴",
  },
  {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    country: "BA",
    dialCode: "387",
    flagEmoji: "🇧🇦",
  },
  { name: "Botswana", country: "BW", dialCode: "267", flagEmoji: "🇧🇼" },
  {
    name: "Brazil (Brasil)",
    country: "BR",
    dialCode: "55",
    flagEmoji: "🇧🇷",
  },
  {
    name: "British Indian Ocean Territory",
    country: "IO",
    dialCode: "246",
    flagEmoji: "🇮🇴",
  },
  {
    name: "British Virgin Islands",
    country: "VG",
    dialCode: "1284",
    flagEmoji: "🇻🇬",
  },
  {
    name: "Brunei",
    country: "BN",
    dialCode: "673",
    flagEmoji: "🇧🇳",
  },
  {
    name: "Bulgaria (България)",
    country: "BG",
    dialCode: "359",
    flagEmoji: "🇧🇬",
  },
  {
    name: "Burkina Faso",
    country: "BF",
    dialCode: "226",
    flagEmoji: "🇧🇫",
  },
  {
    name: "Burundi (Uburundi)",
    country: "BI",
    dialCode: "257",
    flagEmoji: "🇧🇮",
  },
  {
    name: "Cambodia (កម្ពុជា)",
    country: "KH",
    dialCode: "855",
    flagEmoji: "🇰🇭",
  },
  {
    name: "Cameroon (Cameroun)",
    country: "CM",
    dialCode: "237",
    flagEmoji: "🇨🇲",
  },
  {
    name: "Canada",
    country: "CA",
    dialCode: "1",
    flagEmoji: "🇨🇦",
  },
  {
    name: "Cape Verde (Kabu Verdi)",
    country: "CV",
    dialCode: "238",
    flagEmoji: "🇨🇻",
  },
  {
    name: "Caribbean Netherlands",
    country: "BQ",
    dialCode: "599",
    flagEmoji: "🇧🇶",
  },
  {
    name: "Cayman Islands",
    country: "KY",
    dialCode: "1345",
    flagEmoji: "🇰🇾",
  },
  {
    name: "Central African Republic (République centrafricaine)",
    country: "CF",
    dialCode: "236",
    flagEmoji: "🇨🇫",
  },
  { name: "Chad (Tchad)", country: "TD", dialCode: "235", flagEmoji: "🇹🇩" },
  {
    name: "Chile",
    country: "CL",
    dialCode: "56",
    flagEmoji: "🇨🇱",
  },
  { name: "China (中国)", country: "CN", dialCode: "86", flagEmoji: "🇨🇳" },
  {
    name: "Christmas Island",
    country: "CX",
    dialCode: "61",
    flagEmoji: "🇨🇽",
  },
  {
    name: "Cocos (Keeling) Islands",
    country: "CC",
    dialCode: "61",
    flagEmoji: "🇨🇨",
  },
  {
    name: "Colombia",
    country: "CO",
    dialCode: "57",
    flagEmoji: "🇨🇴",
  },
  {
    name: "Comoros (‫جزر القمر‬‎)",
    country: "KM",
    dialCode: "269",
    flagEmoji: "🇰🇲",
  },
  {
    name: "Congo (DRC) (Jamhuri ya Kcountryemokrasia ya Kongo)",
    country: "CD",
    dialCode: "243",
    flagEmoji: "🇨🇩",
  },
  {
    name: "Congo (Republic) (Congo-Brazzaville)",
    country: "CG",
    dialCode: "242",
    flagEmoji: "🇨🇬",
  },
  { name: "Cook Islands", country: "CK", dialCode: "682", flagEmoji: "🇨🇰" },
  {
    name: "Costa Rica",
    country: "CR",
    dialCode: "506",
    flagEmoji: "🇨🇷",
  },
  {
    name: "Croatia (Hrvatska)",
    country: "HR",
    dialCode: "385",
    flagEmoji: "🇭🇷",
  },
  {
    name: "Cuba",
    country: "CU",
    dialCode: "53",
    flagEmoji: "🇨🇺",
  },
  { name: "Curaçao", country: "CW", dialCode: "599", flagEmoji: "🇨🇼" },
  {
    name: "Cyprus (Κύπρος)",
    country: "CY",
    dialCode: "357",
    flagEmoji: "🇨🇾",
  },
  {
    name: "Czech Republic (Česká republika)",
    country: "CZ",
    dialCode: "420",
    flagEmoji: "🇨🇿",
  },
  { name: "Côte d’Ivoire", country: "CI", dialCode: "225", flagEmoji: "🇨🇮" },
  {
    name: "Denmark (Danmark)",
    country: "DK",
    dialCode: "45",
    flagEmoji: "🇩🇰",
  },
  { name: "Djibouti", country: "DJ", dialCode: "253", flagEmoji: "🇩🇯" },
  {
    name: "Dominica",
    country: "DM",
    dialCode: "1767",
    flagEmoji: "🇩🇲",
  },
  {
    name: "Dominican Republic (República Dominicana)",
    country: "DO",
    dialCode: "1",
    flagEmoji: "🇩🇴",
  },
  { name: "Ecuador", country: "EC", dialCode: "593", flagEmoji: "🇪🇨" },
  {
    name: "Egypt (‫مصر‬‎)",
    country: "EG",
    dialCode: "20",
    flagEmoji: "🇪🇬",
  },
  {
    name: "El Salvador",
    country: "SV",
    dialCode: "503",
    flagEmoji: "🇸🇻",
  },
  {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    country: "GQ",
    dialCode: "240",
    flagEmoji: "🇬🇶",
  },
  { name: "Eritrea", country: "ER", dialCode: "291", flagEmoji: "🇪🇷" },
  {
    name: "Estonia (Eesti)",
    country: "EE",
    dialCode: "372",
    flagEmoji: "🇪🇪",
  },
  {
    name: "Ethiopia",
    country: "ET",
    dialCode: "251",
    flagEmoji: "🇪🇹",
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    country: "FK",
    dialCode: "500",
    flagEmoji: "🇫🇰",
  },
  {
    name: "Faroe Islands (Føroyar)",
    country: "FO",
    dialCode: "298",
    flagEmoji: "🇫🇴",
  },
  {
    name: "Fiji",
    country: "FJ",
    dialCode: "679",
    flagEmoji: "🇫🇯",
  },
  { name: "Finland (Suomi)", country: "FI", dialCode: "358", flagEmoji: "🇫🇮" },
  {
    name: "France",
    country: "FR",
    dialCode: "33",
    flagEmoji: "🇫🇷",
  },
  {
    name: "French Guiana (Guyane française)",
    country: "GF",
    dialCode: "594",
    flagEmoji: "🇬🇫",
  },
  {
    name: "French Polynesia (Polynésie française)",
    country: "PF",
    dialCode: "689",
    flagEmoji: "🇵🇫",
  },
  { name: "Gabon", country: "GA", dialCode: "241", flagEmoji: "🇬🇦" },
  {
    name: "Gambia",
    country: "GM",
    dialCode: "220",
    flagEmoji: "🇬🇲",
  },
  {
    name: "Georgia (საქართველო)",
    country: "GE",
    dialCode: "995",
    flagEmoji: "🇬🇪",
  },
  {
    name: "Germany (Deutschland)",
    country: "DE",
    dialCode: "49",
    flagEmoji: "🇩🇪",
  },
  {
    name: "Ghana (Gaana)",
    country: "GH",
    dialCode: "233",
    flagEmoji: "🇬🇭",
  },
  { name: "Gibraltar", country: "GI", dialCode: "350", flagEmoji: "🇬🇮" },
  {
    name: "Greece (Ελλάδα)",
    country: "GR",
    dialCode: "30",
    flagEmoji: "🇬🇷",
  },
  {
    name: "Greenland (Kalaallit Nunaat)",
    country: "GL",
    dialCode: "299",
    flagEmoji: "🇬🇱",
  },
  { name: "Grenada", country: "GD", dialCode: "1473", flagEmoji: "🇬🇩" },
  {
    name: "Guadeloupe",
    country: "GP",
    dialCode: "590",
    flagEmoji: "🇬🇵",
  },
  { name: "Guam", country: "GU", dialCode: "1671", flagEmoji: "🇬🇺" },
  {
    name: "Guatemala",
    country: "GT",
    dialCode: "502",
    flagEmoji: "🇬🇹",
  },
  { name: "Guernsey", country: "GG", dialCode: "44", flagEmoji: "🇬🇬" },
  {
    name: "Guinea (Guinée)",
    country: "GN",
    dialCode: "224",
    flagEmoji: "🇬🇳",
  },
  {
    name: "Guinea-Bissau (Guiné Bissau)",
    country: "GW",
    dialCode: "245",
    flagEmoji: "🇬🇼",
  },
  {
    name: "Guyana",
    country: "GY",
    dialCode: "592",
    flagEmoji: "🇬🇾",
  },
  { name: "Haiti", country: "HT", dialCode: "509", flagEmoji: "🇭🇹" },
  {
    name: "Honduras",
    country: "HN",
    dialCode: "504",
    flagEmoji: "🇭🇳",
  },
  {
    name: "Hong Kong (香港)",
    country: "HK",
    dialCode: "852",
    flagEmoji: "🇭🇰",
  },
  {
    name: "Hungary (Magyarország)",
    country: "HU",
    dialCode: "36",
    flagEmoji: "🇭🇺",
  },
  { name: "Iceland (Ísland)", country: "IS", dialCode: "354", flagEmoji: "🇮🇸" },
  {
    name: "India (भारत)",
    country: "IN",
    dialCode: "91",
    flagEmoji: "🇮🇳",
  },
  { name: "Indonesia", country: "country", dialCode: "62", flagEmoji: "🇮🇩" },
  {
    name: "Iran (‫ایران‬‎)",
    country: "IR",
    dialCode: "98",
    flagEmoji: "🇮🇷",
  },
  { name: "Iraq (‫العراق‬‎)", country: "IQ", dialCode: "964", flagEmoji: "🇮🇶" },
  {
    name: "Ireland",
    country: "IE",
    dialCode: "353",
    flagEmoji: "🇮🇪",
  },
  { name: "Isle of Man", country: "IM", dialCode: "44", flagEmoji: "🇮🇲" },
  {
    name: "Israel (‫ישראל‬‎)",
    country: "IL",
    dialCode: "972",
    flagEmoji: "🇮🇱",
  },
  { name: "Italy (Italia)", country: "IT", dialCode: "39", flagEmoji: "🇮🇹" },
  {
    name: "Jamaica",
    country: "JM",
    dialCode: "1876",
    flagEmoji: "🇯🇲",
  },
  { name: "Japan (日本)", country: "JP", dialCode: "81", flagEmoji: "🇯🇵" },
  {
    name: "Jersey",
    country: "JE",
    dialCode: "44",
    flagEmoji: "🇯🇪",
  },
  {
    name: "Jordan (‫الأردن‬‎)",
    country: "JO",
    dialCode: "962",
    flagEmoji: "🇯🇴",
  },
  {
    name: "Kazakhstan (Казахстан)",
    country: "KZ",
    dialCode: "7",
    flagEmoji: "🇰🇿",
  },
  {
    name: "Kenya",
    country: "KE",
    dialCode: "254",
    flagEmoji: "🇰🇪",
  },
  { name: "Kiribati", country: "KI", dialCode: "686", flagEmoji: "🇰🇮" },
  {
    name: "Kosovo",
    country: "XK",
    dialCode: "383",
    flagEmoji: "🇽🇰",
  },
  {
    name: "Kuwait (‫الكويت‬‎)",
    country: "KW",
    dialCode: "965",
    flagEmoji: "🇰🇼",
  },
  {
    name: "Kyrgyzstan (Кыргызстан)",
    country: "KG",
    dialCode: "996",
    flagEmoji: "🇰🇬",
  },
  {
    name: "Laos (ລາວ)",
    country: "LA",
    dialCode: "856",
    flagEmoji: "🇱🇦",
  },
  { name: "Latvia (Latvija)", country: "LV", dialCode: "371", flagEmoji: "🇱🇻" },
  {
    name: "Lebanon (‫لبنان‬‎)",
    country: "LB",
    dialCode: "961",
    flagEmoji: "🇱🇧",
  },
  { name: "Lesotho", country: "LS", dialCode: "266", flagEmoji: "🇱🇸" },
  {
    name: "Liberia",
    country: "LR",
    dialCode: "231",
    flagEmoji: "🇱🇷",
  },
  { name: "Libya (‫ليبيا‬‎)", country: "LY", dialCode: "218", flagEmoji: "🇱🇾" },
  {
    name: "Liechtenstein",
    country: "LI",
    dialCode: "423",
    flagEmoji: "🇱🇮",
  },
  {
    name: "Lithuania (Lietuva)",
    country: "LT",
    dialCode: "370",
    flagEmoji: "🇱🇹",
  },
  {
    name: "Luxembourg",
    country: "LU",
    dialCode: "352",
    flagEmoji: "🇱🇺",
  },
  {
    name: "Macau (澳門)",
    country: "MO",
    dialCode: "853",
    flagEmoji: "🇲🇴",
  },
  {
    name: "Macedonia (FYROM) (Македонија)",
    country: "MK",
    dialCode: "389",
    flagEmoji: "🇲🇰",
  },
  {
    name: "Madagascar (Madagasikara)",
    country: "MG",
    dialCode: "261",
    flagEmoji: "🇲🇬",
  },
  {
    name: "Malawi",
    country: "MW",
    dialCode: "265",
    flagEmoji: "🇲🇼",
  },
  { name: "Malaysia", country: "MY", dialCode: "60", flagEmoji: "🇲🇾" },
  {
    name: "Maldives",
    country: "MV",
    dialCode: "960",
    flagEmoji: "🇲🇻",
  },
  { name: "Mali", country: "ML", dialCode: "223", flagEmoji: "🇲🇱" },
  {
    name: "Malta",
    country: "MT",
    dialCode: "356",
    flagEmoji: "🇲🇹",
  },
  { name: "Marshall Islands", country: "MH", dialCode: "692", flagEmoji: "🇲🇭" },
  {
    name: "Martinique",
    country: "MQ",
    dialCode: "596",
    flagEmoji: "🇲🇶",
  },
  {
    name: "Mauritania (‫موريتانيا‬‎)",
    country: "MR",
    dialCode: "222",
    flagEmoji: "🇲🇷",
  },
  {
    name: "Mauritius (Moris)",
    country: "MU",
    dialCode: "230",
    flagEmoji: "🇲🇺",
  },
  {
    name: "Mayotte",
    country: "YT",
    dialCode: "262",
    flagEmoji: "🇾🇹",
  },
  { name: "Mexico (México)", country: "MX", dialCode: "52", flagEmoji: "🇲🇽" },
  {
    name: "Micronesia",
    country: "FM",
    dialCode: "691",
    flagEmoji: "🇫🇲",
  },
  {
    name: "Moldova (Republica Moldova)",
    country: "MD",
    dialCode: "373",
    flagEmoji: "🇲🇩",
  },
  {
    name: "Monaco",
    country: "MC",
    dialCode: "377",
    flagEmoji: "🇲🇨",
  },
  {
    name: "Mongolia (Монгол)",
    country: "MN",
    dialCode: "976",
    flagEmoji: "🇲🇳",
  },
  {
    name: "Montenegro (Crna Gora)",
    country: "ME",
    dialCode: "382",
    flagEmoji: "🇲🇪",
  },
  {
    name: "Montserrat",
    country: "MS",
    dialCode: "1664",
    flagEmoji: "🇲🇸",
  },
  {
    name: "Morocco (‫المغرب‬‎)",
    country: "MA",
    dialCode: "212",
    flagEmoji: "🇲🇦",
  },
  {
    name: "Mozambique (Moçambique)",
    country: "MZ",
    dialCode: "258",
    flagEmoji: "🇲🇿",
  },
  {
    name: "Myanmar (Burma) (မြန်မာ)",
    country: "MM",
    dialCode: "95",
    flagEmoji: "🇲🇲",
  },
  {
    name: "Namibia (Namibië)",
    country: "NA",
    dialCode: "264",
    flagEmoji: "🇳🇦",
  },
  {
    name: "Nauru",
    country: "NR",
    dialCode: "674",
    flagEmoji: "🇳🇷",
  },
  {
    name: "Nepal (नेपाल)",
    country: "NP",
    dialCode: "977",
    flagEmoji: "🇳🇵",
  },
  {
    name: "Netherlands (Nederland)",
    country: "NL",
    dialCode: "31",
    flagEmoji: "🇳🇱",
  },
  {
    name: "New Caledonia (Nouvelle-Calédonie)",
    country: "NC",
    dialCode: "687",
    flagEmoji: "🇳🇨",
  },
  { name: "New Zealand", country: "NZ", dialCode: "64", flagEmoji: "🇳🇿" },
  {
    name: "Nicaragua",
    country: "NI",
    dialCode: "505",
    flagEmoji: "🇳🇮",
  },
  { name: "Niger (Nijar)", country: "NE", dialCode: "227", flagEmoji: "🇳🇪" },
  {
    name: "Nigeria",
    country: "NG",
    dialCode: "234",
    flagEmoji: "🇳🇬",
  },
  { name: "Niue", country: "NU", dialCode: "683", flagEmoji: "🇳🇺" },
  {
    name: "Norfolk Island",
    country: "NF",
    dialCode: "672",
    flagEmoji: "🇳🇫",
  },
  {
    name: "North Korea (조선 민주주의 인민 공화국)",
    country: "KP",
    dialCode: "850",
    flagEmoji: "🇰🇵",
  },
  {
    name: "Northern Mariana Islands",
    country: "MP",
    dialCode: "1670",
    flagEmoji: "🇲🇵",
  },
  { name: "Norway (Norge)", country: "NO", dialCode: "47", flagEmoji: "🇳🇴" },
  {
    name: "Oman (‫عُمان‬‎)",
    country: "OM",
    dialCode: "968",
    flagEmoji: "🇴🇲",
  },
  {
    name: "Pakistan (‫پاکستان‬‎)",
    country: "PK",
    dialCode: "92",
    flagEmoji: "🇵🇰",
  },
  {
    name: "Palau",
    country: "PW",
    dialCode: "680",
    flagEmoji: "🇵🇼",
  },
  {
    name: "Palestine (‫فلسطين‬‎)",
    country: "PS",
    dialCode: "970",
    flagEmoji: "🇵🇸",
  },
  { name: "Panama (Panamá)", country: "PA", dialCode: "507", flagEmoji: "🇵🇦" },
  {
    name: "Papua New Guinea",
    country: "PG",
    dialCode: "675",
    flagEmoji: "🇵🇬",
  },
  { name: "Paraguay", country: "PY", dialCode: "595", flagEmoji: "🇵🇾" },
  {
    name: "Peru (Perú)",
    country: "PE",
    dialCode: "51",
    flagEmoji: "🇵🇪",
  },
  { name: "Philippines", country: "PH", dialCode: "63", flagEmoji: "🇵🇭" },
  {
    name: "Poland (Polska)",
    country: "PL",
    dialCode: "48",
    flagEmoji: "🇵🇱",
  },
  { name: "Portugal", country: "PT", dialCode: "351", flagEmoji: "🇵🇹" },
  {
    name: "Puerto Rico",
    country: "PR",
    dialCode: "1",
    flagEmoji: "🇵🇷",
  },
  { name: "Qatar (‫قطر‬‎)", country: "QA", dialCode: "974", flagEmoji: "🇶🇦" },
  {
    name: "Romania (România)",
    country: "RO",
    dialCode: "40",
    flagEmoji: "🇷🇴",
  },
  { name: "Russia (Россия)", country: "RU", dialCode: "7", flagEmoji: "🇷🇺" },
  {
    name: "Rwanda",
    country: "RW",
    dialCode: "250",
    flagEmoji: "🇷🇼",
  },
  {
    name: "Réunion (La Réunion)",
    country: "RE",
    dialCode: "262",
    flagEmoji: "🇷🇪",
  },
  { name: "Saint Barthélemy", country: "BL", dialCode: "590", flagEmoji: "🇧🇱" },
  {
    name: "Saint Helena",
    country: "SH",
    dialCode: "290",
    flagEmoji: "🇸🇭",
  },
  {
    name: "Saint Kitts and Nevis",
    country: "KN",
    dialCode: "1869",
    flagEmoji: "🇰🇳",
  },
  {
    name: "Saint Lucia",
    country: "LC",
    dialCode: "1758",
    flagEmoji: "🇱🇨",
  },
  {
    name: "Saint Martin (Saint-Martin (partie française))",
    country: "MF",
    dialCode: "590",
    flagEmoji: "🇲🇫",
  },
  {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    country: "PM",
    dialCode: "508",
    flagEmoji: "🇵🇲",
  },
  {
    name: "Saint Vincent and the Grenadines",
    country: "VC",
    dialCode: "1784",
    flagEmoji: "🇻🇨",
  },
  { name: "Samoa", country: "WS", dialCode: "685", flagEmoji: "🇼🇸" },
  {
    name: "San Marino",
    country: "SM",
    dialCode: "378",
    flagEmoji: "🇸🇲",
  },
  {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    country: "SA",
    dialCode: "966",
    flagEmoji: "🇸🇦",
  },
  {
    name: "Senegal (Sénégal)",
    country: "SN",
    dialCode: "221",
    flagEmoji: "🇸🇳",
  },
  {
    name: "Serbia (Србија)",
    country: "RS",
    dialCode: "381",
    flagEmoji: "🇷🇸",
  },
  { name: "Seychelles", country: "SC", dialCode: "248", flagEmoji: "🇸🇨" },
  {
    name: "Sierra Leone",
    country: "SL",
    dialCode: "232",
    flagEmoji: "🇸🇱",
  },
  { name: "Singapore", country: "SG", dialCode: "65", flagEmoji: "🇸🇬" },
  {
    name: "Sint Maarten",
    country: "SX",
    dialCode: "1721",
    flagEmoji: "🇸🇽",
  },
  {
    name: "Slovakia (Slovensko)",
    country: "SK",
    dialCode: "421",
    flagEmoji: "🇸🇰",
  },
  {
    name: "Slovenia (Slovenija)",
    country: "SI",
    dialCode: "386",
    flagEmoji: "🇸🇮",
  },
  {
    name: "Solomon Islands",
    country: "SB",
    dialCode: "677",
    flagEmoji: "🇸🇧",
  },
  {
    name: "Somalia (Soomaaliya)",
    country: "SO",
    dialCode: "252",
    flagEmoji: "🇸🇴",
  },
  {
    name: "South Africa",
    country: "ZA",
    dialCode: "27",
    flagEmoji: "🇿🇦",
  },
  {
    name: "South Korea (대한민국)",
    country: "KR",
    dialCode: "82",
    flagEmoji: "🇰🇷",
  },
  {
    name: "South Sudan (‫جنوب السودان‬‎)",
    country: "SS",
    dialCode: "211",
    flagEmoji: "🇸🇸",
  },
  {
    name: "Spain (España)",
    country: "ES",
    dialCode: "34",
    flagEmoji: "🇪🇸",
  },
  {
    name: "Sri Lanka (ශ්‍රී ලංකාව)",
    country: "LK",
    dialCode: "94",
    flagEmoji: "🇱🇰",
  },
  {
    name: "Sudan (‫السودان‬‎)",
    country: "SD",
    dialCode: "249",
    flagEmoji: "🇸🇩",
  },
  {
    name: "Suriname",
    country: "SR",
    dialCode: "597",
    flagEmoji: "🇸🇷",
  },
  {
    name: "Svalbard and Jan Mayen",
    country: "SJ",
    dialCode: "47",
    flagEmoji: "🇸🇯",
  },
  {
    name: "Swaziland",
    country: "SZ",
    dialCode: "268",
    flagEmoji: "🇸🇿",
  },
  {
    name: "Sweden (Sverige)",
    country: "SE",
    dialCode: "46",
    flagEmoji: "🇸🇪",
  },
  {
    name: "Switzerland (Schweiz)",
    country: "CH",
    dialCode: "41",
    flagEmoji: "🇨🇭",
  },
  {
    name: "Syria (‫سوريا‬‎)",
    country: "SY",
    dialCode: "963",
    flagEmoji: "🇸🇾",
  },
  {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    country: "ST",
    dialCode: "239",
    flagEmoji: "🇸🇹",
  },
  { name: "Taiwan (台灣)", country: "TW", dialCode: "886", flagEmoji: "🇹🇼" },
  {
    name: "Tajikistan",
    country: "TJ",
    dialCode: "992",
    flagEmoji: "🇹🇯",
  },
  { name: "Tanzania", country: "TZ", dialCode: "255", flagEmoji: "🇹🇿" },
  {
    name: "Thailand (ไทย)",
    country: "TH",
    dialCode: "66",
    flagEmoji: "🇹🇭",
  },
  { name: "Timor-Leste", country: "TL", dialCode: "670", flagEmoji: "🇹🇱" },
  {
    name: "Togo",
    country: "TG",
    dialCode: "228",
    flagEmoji: "🇹🇬",
  },
  { name: "Tokelau", country: "TK", dialCode: "690", flagEmoji: "🇹🇰" },
  {
    name: "Tonga",
    country: "TO",
    dialCode: "676",
    flagEmoji: "🇹🇴",
  },
  {
    name: "Trincountryad and Tobago",
    country: "TT",
    dialCode: "1868",
    flagEmoji: "🇹🇹",
  },
  {
    name: "Tunisia (‫تونس‬‎)",
    country: "TN",
    dialCode: "216",
    flagEmoji: "🇹🇳",
  },
  {
    name: "Turkey (Türkiye)",
    country: "TR",
    dialCode: "90",
    flagEmoji: "🇹🇷",
  },
  {
    name: "Turkmenistan",
    country: "TM",
    dialCode: "993",
    flagEmoji: "🇹🇲",
  },
  {
    name: "Turks and Caicos Islands",
    country: "TC",
    dialCode: "1649",
    flagEmoji: "🇹🇨",
  },
  {
    name: "Tuvalu",
    country: "TV",
    dialCode: "688",
    flagEmoji: "🇹🇻",
  },
  {
    name: "U.S. Virgin Islands",
    country: "VI",
    dialCode: "1340",
    flagEmoji: "🇻🇮",
  },
  {
    name: "Uganda",
    country: "UG",
    dialCode: "256",
    flagEmoji: "🇺🇬",
  },
  {
    name: "Ukraine (Україна)",
    country: "UA",
    dialCode: "380",
    flagEmoji: "🇺🇦",
  },
  {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    country: "AE",
    dialCode: "971",
    flagEmoji: "🇦🇪",
  },
  { name: "United Kingdom", country: "GB", dialCode: "44", flagEmoji: "🇬🇧" },
  {
    name: "United States",
    country: "US",
    dialCode: "1",
    flagEmoji: "🇺🇸",
  },
  { name: "Uruguay", country: "UY", dialCode: "598", flagEmoji: "🇺🇾" },
  {
    name: "Uzbekistan (Oʻzbekiston)",
    country: "UZ",
    dialCode: "998",
    flagEmoji: "🇺🇿",
  },
  {
    name: "Vanuatu",
    country: "VU",
    dialCode: "678",
    flagEmoji: "🇻🇺",
  },
  {
    name: "Vatican City (Città del Vaticano)",
    country: "VA",
    dialCode: "39",
    flagEmoji: "🇻🇦",
  },
  { name: "Venezuela", country: "VE", dialCode: "58", flagEmoji: "🇻🇪" },
  {
    name: "Vietnam (Việt Nam)",
    country: "VN",
    dialCode: "84",
    flagEmoji: "🇻🇳",
  },
  {
    name: "Wallis and Futuna (Wallis-et-Futuna)",
    country: "WF",
    dialCode: "681",
    flagEmoji: "🇼🇫",
  },
  {
    name: "Western Sahara (‫الصحراء الغربية‬‎)",
    country: "EH",
    dialCode: "212",
    flagEmoji: "🇪🇭",
  },
  { name: "Yemen (‫اليمن‬‎)", country: "YE", dialCode: "967", flagEmoji: "🇾🇪" },
  {
    name: "Zambia",
    country: "ZM",
    dialCode: "260",
    flagEmoji: "🇿🇲",
  },
  { name: "Zimbabwe", country: "ZW", dialCode: "263", flagEmoji: "🇿🇼" },
  {
    name: "Åland Islands",
    country: "AX",
    dialCode: "358",
    flagEmoji: "🇦🇽",
  },
];
