











import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { AtomChartLinePeriodEnum } from "./period.enum";
import dayjs from "dayjs";
import { t } from "@/i18n";

// @ts-ignore
import LineChart from "./init.js";

const css = bemBuilder("atom-chart-line");

interface IAtomChartLineValues {
  x: string;
  y: number;
}

interface ITooltipItem {
  label: Date;
}

export default defineComponent({
  name: "AtomChartLine",
  components: {
    LineChart,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    values: {
      type: Array as PropType<IAtomChartLineValues[]>,
      required: true,
    },
    currentPeriod: {
      type: String as PropType<AtomChartLinePeriodEnum>,
      default: AtomChartLinePeriodEnum.DAY,
    },
  },
  setup(props) {
    const displayChart = ref(false);
    const chartData = ref();
    const options = ref();
    const aovValue = ref(0);

    watch(
      () => props,
      async () => {
        displayChart.value = false;
        await generateChart();
        displayChart.value = true;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    async function generateChart() {
      const sumTotal = props.values.reduce((n, { y }) => n + y, 0);
      aovValue.value = await Number(
        (sumTotal / props.values.length).toFixed(2)
      );

      const aovObject = {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        borderColor: "#071b4b",
        value: aovValue.value,
        borderWidth: 1,
        borderDash: [4, 4],
        label: {
          enabled: true,
          fontSize: 9,
          position: "left",
          backgroundColor: "#071b4b",
          content: t("module.dashboard.aov_x", [aovValue.value]),
        },
      };

      chartData.value = {
        label: props.label,
        borderColor: "#1258ff",
        pointBorderColor: "#1258ff",
        pointBackgroundColor: "#1258ff",
        pointHoverBorderColor: "#071C4D",
        pointHoverBackgroundColor: "#071C4D",
        pointRadius: 3,
        borderWidth: 2,
        data: props.values,
      };

      options.value = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: props.currentPeriod,
              },
              gridLines: {
                display: false,
              },
              ticks: {
                maxTicksLimit: 12,
                fontColor: "#838DA6",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "#F4F6F9",
                zeroLineColor: "#F4F6F9",
                drawBorder: false,
              },
              ticks: {
                maxTicksLimit: 8,
                fontColor: "#838DA6",
                padding: 10,
                min: 0,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
          xPadding: 14,
          yPadding: 12,
          caretSize: 7,
          caretPadding: 7,
          cornerRadius: 7,
          titleFontSize: 14,
          titleFontFamily: "Inter",
          bodyFontSize: 11,
          backgroundColor: "#071C4D",
          multiKeyBackground: "#1258ff",
          callbacks: {
            title(tooltipItem: ITooltipItem[]) {
              return dayjs(tooltipItem[0].label).format("ddd, MMM DD");
            },
          },
        },
        legend: {
          display: props.showLegend,
          position: "top",
          labels: {
            boxWidth: 5,
            padding: 30,
            usePointStyle: true,
            fontColor: "#b6bac3",
            fontSize: 12,
          },
        },
        annotation: {
          drawTime: "afterDatasetsDraw",
          annotations: [aovValue.value > 0 ? aovObject : {}],
        },
        plugins: {
          datalabels: false,
        },
      };
    }

    return {
      css,
      chartData,
      options,
      displayChart,
    };
  },
});
