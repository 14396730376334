import Enumeration from "@/utils/enums/enumeration";

/**
 * Data retrieved from:
 * https://developers.google.com/public-data/docs/canonical/countries_csv
 */

export const coordinates = new Enumeration({
  AD: { lat: 42.546245, lng: 1.601554 },
  AE: { lat: 23.424076, lng: 53.847818 },
  AF: { lat: 33.93911, lng: 67.709953 },
  AG: { lat: 17.060816, lng: -61.796428 },
  AI: { lat: 18.220554, lng: -63.068615 },
  AL: { lat: 41.153332, lng: 20.168331 },
  AM: { lat: 40.069099, lng: 45.038189 },
  AN: { lat: 12.226079, lng: -69.060087 },
  AO: { lat: -11.202692, lng: 17.873887 },
  AQ: { lat: -75.250973, lng: -0.071389 },
  AR: { lat: -38.416097, lng: -63.616672 },
  AS: { lat: -14.270972, lng: -170.132217 },
  AT: { lat: 47.516231, lng: 14.550072 },
  AU: { lat: -25.274398, lng: 133.775136 },
  AW: { lat: 12.52111, lng: -69.968338 },
  AZ: { lat: 40.143105, lng: 47.576927 },
  BA: { lat: 43.915886, lng: 17.679076 },
  BB: { lat: 13.193887, lng: -59.543198 },
  BD: { lat: 23.684994, lng: 90.356331 },
  BE: { lat: 50.503887, lng: 4.469936 },
  BF: { lat: 12.238333, lng: -1.561593 },
  BG: { lat: 42.733883, lng: 25.48583 },
  BH: { lat: 25.930414, lng: 50.637772 },
  BI: { lat: -3.373056, lng: 29.918886 },
  BJ: { lat: 9.30769, lng: 2.315834 },
  BM: { lat: 32.321384, lng: -64.75737 },
  BN: { lat: 4.535277, lng: 114.727669 },
  BO: { lat: -16.290154, lng: -63.588653 },
  BR: { lat: -14.235004, lng: -51.92528 },
  BS: { lat: 25.03428, lng: -77.39628 },
  BT: { lat: 27.514162, lng: 90.433601 },
  BV: { lat: -54.423199, lng: 3.413194 },
  BW: { lat: -22.328474, lng: 24.684866 },
  BY: { lat: 53.709807, lng: 27.953389 },
  BZ: { lat: 17.189877, lng: -88.49765 },
  CA: { lat: 56.130366, lng: -106.346771 },
  CC: { lat: -12.164165, lng: 96.870956 },
  CD: { lat: -4.038333, lng: 21.758664 },
  CF: { lat: 6.611111, lng: 20.939444 },
  CG: { lat: -0.228021, lng: 15.827659 },
  CH: { lat: 46.818188, lng: 8.227512 },
  CI: { lat: 7.539989, lng: -5.54708 },
  CK: { lat: -21.236736, lng: -159.777671 },
  CL: { lat: -35.675147, lng: -71.542969 },
  CM: { lat: 7.369722, lng: 12.354722 },
  CN: { lat: 35.86166, lng: 104.195397 },
  CO: { lat: 4.570868, lng: -74.297333 },
  CR: { lat: 9.748917, lng: -83.753428 },
  CU: { lat: 21.521757, lng: -77.781167 },
  CV: { lat: 16.002082, lng: -24.013197 },
  CX: { lat: -10.447525, lng: 105.690449 },
  CY: { lat: 35.126413, lng: 33.429859 },
  CZ: { lat: 49.817492, lng: 15.472962 },
  DE: { lat: 51.165691, lng: 10.451526 },
  DJ: { lat: 11.825138, lng: 42.590275 },
  DK: { lat: 56.26392, lng: 9.501785 },
  DM: { lat: 15.414999, lng: -61.370976 },
  DO: { lat: 18.735693, lng: -70.162651 },
  DZ: { lat: 28.033886, lng: 1.659626 },
  EC: { lat: -1.831239, lng: -78.183406 },
  EE: { lat: 58.595272, lng: 25.013607 },
  EG: { lat: 26.820553, lng: 30.802498 },
  EH: { lat: 24.215527, lng: -12.885834 },
  ER: { lat: 15.179384, lng: 39.782334 },
  ES: { lat: 40.463667, lng: -3.74922 },
  ET: { lat: 9.145, lng: 40.489673 },
  FI: { lat: 61.92411, lng: 25.748151 },
  FJ: { lat: -16.578193, lng: 179.414413 },
  FK: { lat: -51.796253, lng: -59.523613 },
  FM: { lat: 7.425554, lng: 150.550812 },
  FO: { lat: 61.892635, lng: -6.911806 },
  FR: { lat: 46.227638, lng: 2.213749 },
  GA: { lat: -0.803689, lng: 11.609444 },
  GB: { lat: 55.378051, lng: -3.435973 },
  GD: { lat: 12.262776, lng: -61.604171 },
  GE: { lat: 42.315407, lng: 43.356892 },
  GF: { lat: 3.933889, lng: -53.125782 },
  GG: { lat: 49.465691, lng: -2.585278 },
  GH: { lat: 7.946527, lng: -1.023194 },
  GI: { lat: 36.137741, lng: -5.345374 },
  GL: { lat: 71.706936, lng: -42.604303 },
  GM: { lat: 13.443182, lng: -15.310139 },
  GN: { lat: 9.945587, lng: -9.696645 },
  GP: { lat: 16.995971, lng: -62.067641 },
  GQ: { lat: 1.650801, lng: 10.267895 },
  GR: { lat: 39.074208, lng: 21.824312 },
  GS: { lat: -54.429579, lng: -36.587909 },
  GT: { lat: 15.783471, lng: -90.230759 },
  GU: { lat: 13.444304, lng: 144.793731 },
  GW: { lat: 11.803749, lng: -15.180413 },
  GY: { lat: 4.860416, lng: -58.93018 },
  GZ: { lat: 31.354676, lng: 34.308825 },
  HK: { lat: 22.396428, lng: 114.109497 },
  HM: { lat: -53.08181, lng: 73.504158 },
  HN: { lat: 15.199999, lng: -86.241905 },
  HR: { lat: 45.1, lng: 15.2 },
  HT: { lat: 18.971187, lng: -72.285215 },
  HU: { lat: 47.162494, lng: 19.503304 },
  ID: { lat: -0.789275, lng: 113.921327 },
  IE: { lat: 53.41291, lng: -8.24389 },
  IL: { lat: 31.046051, lng: 34.851612 },
  IM: { lat: 54.236107, lng: -4.548056 },
  IN: { lat: 20.593684, lng: 78.96288 },
  IO: { lat: -6.343194, lng: 71.876519 },
  IQ: { lat: 33.223191, lng: 43.679291 },
  IR: { lat: 32.427908, lng: 53.688046 },
  IS: { lat: 64.963051, lng: -19.020835 },
  IT: { lat: 41.87194, lng: 12.56738 },
  JE: { lat: 49.214439, lng: -2.13125 },
  JM: { lat: 18.109581, lng: -77.297508 },
  JO: { lat: 30.585164, lng: 36.238414 },
  JP: { lat: 36.204824, lng: 138.252924 },
  KE: { lat: -0.023559, lng: 37.906193 },
  KG: { lat: 41.20438, lng: 74.766098 },
  KH: { lat: 12.565679, lng: 104.990963 },
  KI: { lat: -3.370417, lng: -168.734039 },
  KM: { lat: -11.875001, lng: 43.872219 },
  KN: { lat: 17.357822, lng: -62.782998 },
  KP: { lat: 40.339852, lng: 127.510093 },
  KR: { lat: 35.907757, lng: 127.766922 },
  KW: { lat: 29.31166, lng: 47.481766 },
  KY: { lat: 19.513469, lng: -80.566956 },
  KZ: { lat: 48.019573, lng: 66.923684 },
  LA: { lat: 19.85627, lng: 102.495496 },
  LB: { lat: 33.854721, lng: 35.862285 },
  LC: { lat: 13.909444, lng: -60.978893 },
  LI: { lat: 47.166, lng: 9.555373 },
  LK: { lat: 7.873054, lng: 80.771797 },
  LR: { lat: 6.428055, lng: -9.429499 },
  LS: { lat: -29.609988, lng: 28.233608 },
  LT: { lat: 55.169438, lng: 23.881275 },
  LU: { lat: 49.815273, lng: 6.129583 },
  LV: { lat: 56.879635, lng: 24.603189 },
  LY: { lat: 26.3351, lng: 17.228331 },
  MA: { lat: 31.791702, lng: -7.09262 },
  MC: { lat: 43.750298, lng: 7.412841 },
  MD: { lat: 47.411631, lng: 28.369885 },
  ME: { lat: 42.708678, lng: 19.37439 },
  MG: { lat: -18.766947, lng: 46.869107 },
  MH: { lat: 7.131474, lng: 171.184478 },
  MK: { lat: 41.608635, lng: 21.745275 },
  ML: { lat: 17.570692, lng: -3.996166 },
  MM: { lat: 21.913965, lng: 95.956223 },
  MN: { lat: 46.862496, lng: 103.846656 },
  MO: { lat: 22.198745, lng: 113.543873 },
  MP: { lat: 17.33083, lng: 145.38469 },
  MQ: { lat: 14.641528, lng: -61.024174 },
  MR: { lat: 21.00789, lng: -10.940835 },
  MS: { lat: 16.742498, lng: -62.187366 },
  MT: { lat: 35.937496, lng: 14.375416 },
  MU: { lat: -20.348404, lng: 57.552152 },
  MV: { lat: 3.202778, lng: 73.22068 },
  MW: { lat: -13.254308, lng: 34.301525 },
  MX: { lat: 23.634501, lng: -102.552784 },
  MY: { lat: 4.210484, lng: 101.975766 },
  MZ: { lat: -18.665695, lng: 35.529562 },
  NA: { lat: -22.95764, lng: 18.49041 },
  NC: { lat: -20.904305, lng: 165.618042 },
  NE: { lat: 17.607789, lng: 8.081666 },
  NF: { lat: -29.040835, lng: 167.954712 },
  NG: { lat: 9.081999, lng: 8.675277 },
  NI: { lat: 12.865416, lng: -85.207229 },
  NL: { lat: 52.132633, lng: 5.291266 },
  NO: { lat: 60.472024, lng: 8.468946 },
  NP: { lat: 28.394857, lng: 84.124008 },
  NR: { lat: -0.522778, lng: 166.931503 },
  NU: { lat: -19.054445, lng: -169.867233 },
  NZ: { lat: -40.900557, lng: 174.885971 },
  OM: { lat: 21.512583, lng: 55.923255 },
  PA: { lat: 8.537981, lng: -80.782127 },
  PE: { lat: -9.189967, lng: -75.015152 },
  PF: { lat: -17.679742, lng: -149.406843 },
  PG: { lat: -6.314993, lng: 143.95555 },
  PH: { lat: 12.879721, lng: 121.774017 },
  PK: { lat: 30.375321, lng: 69.345116 },
  PL: { lat: 51.919438, lng: 19.145136 },
  PM: { lat: 46.941936, lng: -56.27111 },
  PN: { lat: -24.703615, lng: -127.439308 },
  PR: { lat: 18.220833, lng: -66.590149 },
  PS: { lat: 31.952162, lng: 35.233154 },
  PT: { lat: 39.399872, lng: -8.224454 },
  PW: { lat: 7.51498, lng: 134.58252 },
  PY: { lat: -23.442503, lng: -58.443832 },
  QA: { lat: 25.354826, lng: 51.183884 },
  RE: { lat: -21.115141, lng: 55.536384 },
  RO: { lat: 45.943161, lng: 24.96676 },
  RS: { lat: 44.016521, lng: 21.005859 },
  RU: { lat: 61.52401, lng: 105.318756 },
  RW: { lat: -1.940278, lng: 29.873888 },
  SA: { lat: 23.885942, lng: 45.079162 },
  SB: { lat: -9.64571, lng: 160.156194 },
  SC: { lat: -4.679574, lng: 55.491977 },
  SD: { lat: 12.862807, lng: 30.217636 },
  SE: { lat: 60.128161, lng: 18.643501 },
  SG: { lat: 1.352083, lng: 103.819836 },
  SH: { lat: -24.143474, lng: -10.030696 },
  SI: { lat: 46.151241, lng: 14.995463 },
  SJ: { lat: 77.553604, lng: 23.670272 },
  SK: { lat: 48.669026, lng: 19.699024 },
  SL: { lat: 8.460555, lng: -11.779889 },
  SM: { lat: 43.94236, lng: 12.457777 },
  SN: { lat: 14.497401, lng: -14.452362 },
  SO: { lat: 5.152149, lng: 46.199616 },
  SR: { lat: 3.919305, lng: -56.027783 },
  ST: { lat: 0.18636, lng: 6.613081 },
  SV: { lat: 13.794185, lng: -88.89653 },
  SY: { lat: 34.802075, lng: 38.996815 },
  SZ: { lat: -26.522503, lng: 31.465866 },
  TC: { lat: 21.694025, lng: -71.797928 },
  TD: { lat: 15.454166, lng: 18.732207 },
  TF: { lat: -49.280366, lng: 69.348557 },
  TG: { lat: 8.619543, lng: 0.824782 },
  TH: { lat: 15.870032, lng: 100.992541 },
  TJ: { lat: 38.861034, lng: 71.276093 },
  TK: { lat: -8.967363, lng: -171.855881 },
  TL: { lat: -8.874217, lng: 125.727539 },
  TM: { lat: 38.969719, lng: 59.556278 },
  TN: { lat: 33.886917, lng: 9.537499 },
  TO: { lat: -21.178986, lng: -175.198242 },
  TR: { lat: 38.963745, lng: 35.243322 },
  TT: { lat: 10.691803, lng: -61.222503 },
  TV: { lat: -7.109535, lng: 177.64933 },
  TW: { lat: 23.69781, lng: 120.960515 },
  TZ: { lat: -6.369028, lng: 34.888822 },
  UA: { lat: 48.379433, lng: 31.16558 },
  UG: { lat: 1.373333, lng: 32.290275 },
  US: { lat: 37.09024, lng: -95.712891 },
  UY: { lat: -32.522779, lng: -55.765835 },
  UZ: { lat: 41.377491, lng: 64.585262 },
  VA: { lat: 41.902916, lng: 12.453389 },
  VC: { lat: 12.984305, lng: -61.287228 },
  VE: { lat: 6.42375, lng: -66.58973 },
  VG: { lat: 18.420695, lng: -64.639968 },
  VI: { lat: 18.335765, lng: -64.896335 },
  VN: { lat: 14.058324, lng: 108.277199 },
  VU: { lat: -15.376706, lng: 166.959158 },
  WF: { lat: -13.768752, lng: -177.156097 },
  WS: { lat: -13.759029, lng: -172.104629 },
  XK: { lat: 42.602636, lng: 20.902977 },
  YE: { lat: 15.552727, lng: 48.516388 },
  YT: { lat: -12.8275, lng: 45.166244 },
  ZA: { lat: -30.559482, lng: 22.937506 },
  ZM: { lat: -13.133897, lng: 27.849332 },
  ZW: { lat: -19.015438, lng: 29.154857 },
});

export const names = new Enumeration({
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua and Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AN: "Netherlands Antilles",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AZ: "Azerbaijan",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BM: "Bermuda",
  BN: "Brunei",
  BO: "Bolivia",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BV: "Bouvet Island",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos [Keeling] Islands",
  CD: "Congo [DRC]",
  CF: "Central African Republic",
  CG: "Congo [Republic]",
  CH: "Switzerland",
  CI: "Côte d'Ivoire",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cape Verde",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands [Islas Malvinas]",
  FM: "Micronesia",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia and the South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  GZ: "Gaza Strip",
  HK: "Hong Kong",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IQ: "Iraq",
  IR: "Iran",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "Saint Kitts and Nevis",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Laos",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "Macedonia [FYROM]",
  ML: "Mali",
  MM: "Myanmar [Burma]",
  MN: "Mongolia",
  MO: "Macau",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  PN: "Pitcairn Islands",
  PR: "Puerto Rico",
  PS: "Palestinian Territories",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "Saint Helena",
  SI: "Slovenia",
  SJ: "Svalbard and Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  ST: "São Tomé and Príncipe",
  SV: "El Salvador",
  SY: "Syria",
  SZ: "Swaziland",
  TC: "Turks and Caicos Islands",
  TD: "Chad",
  TF: "French Southern Territories",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "Timor-Leste",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Turkey",
  TT: "Trinidad and Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania",
  UA: "Ukraine",
  UG: "Uganda",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Vatican City",
  VC: "Saint Vincent and the Grenadines",
  VE: "Venezuela",
  VG: "British Virgin Islands",
  VI: "U.S. Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis and Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
});
