import Api from "./index";
import BusinessesParser from "./parsers/businesses-parser";
import PartnerConstructor from "@/constructors/Partner";
import PaginationConstructor from "@/constructors/Pagination";
import CustomerConstructor from "@/constructors/Customer";

export default {
  /**
   * Get list of businesses based in the passed params.
   *
   * @param {Object} params
   * @returns {Object}
   */
  async get(params) {
    return Api.get(`/api/v3/businesses`, { params }).then((data) => {
      const parser = new BusinessesParser(data.data);
      return { data: parser.fromApi() };
    });
  },
  /**
   * Get list of partners available for the given business.
   *
   * @param {String} businessId
   * @returns {{data: Array<PartnerConstructor>}}
   */
  async partners(businessId) {
    const res = await Api.get(`/api/v3/businesses/${businessId}/partners`);

    return {
      data: res.data.map((obj) => new PartnerConstructor(obj)),
    };
  },
};
