









import { defineComponent } from "@vue/composition-api";
import AngleRightSVG from "./assets/angle-right.svg";
import { boolean } from "@/v2/util/prop/type";

export default defineComponent({
  name: "AtomCollapseIndicator",
  components: {
    AngleRightSVG,
  },
  props: {
    open: boolean().use(),
  },
});
