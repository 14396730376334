




























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AtomText,
  AtomTextColorEnum,
  MolMultiselect,
} from "@/v2/new-design-system";
import { countries, ICountry } from "./countries";

const css = bemBuilder("mol-country-code-select");

export default defineComponent({
  name: "MolCountryCodeSelect",
  components: {
    AtomText,
    MolMultiselect,
  },
  props: {
    onChange: {
      type: Function as PropType<(selected: ICountry) => void>,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const findCountry = (key: string) => {
      const selected = countries.find(({ country }) => country === key);
      return selected ? [selected] : [];
    };

    const selectedCountry = ref(findCountry(props.defaultValue));

    return {
      css,
      countries,
      selectedCountry,
      AtomTextColorEnum,
    };
  },
});
