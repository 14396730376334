/* global mixpanel */
import Vue from "vue";
import * as types from "./mutation_types";
import Auth from "@/api/auth";
import { action as fetchAction } from "@/store/fetchBase";
import { action as resetAction } from "@/store/resetBase";
import { jwtToken } from "@/utils/helpers/functions";
import { unsubscribeAllPrivate } from "@/v2/core/broadcasting";
import { fetchPermissions } from "@/v2/repo/fetch-permissions";
import TenantParser from "@/api/parsers/tenant-parser";

/**
 * Fetch the tenant based on JWT stored in the LocalStorage
 *
 * @returns {Object}
 */
export const fetch = async ({ commit, dispatch }) => {
  try {
    return (await fetchAction(commit, types.FETCH, Auth.me)).data;
  } catch (err) {
    // Only log out the user for actual auth issue only
    if (err.name === "UnauthorizedError") {
      dispatch("logout"); // auth/logout
    }

    throw err;
  }
};

/**
 * Update user profile
 *
 * @returns {Object}
 * **/

export const updateUserProfile = async ({ commit }, userInfo) => {
  commit(types.UPDATE_USER_PROFILE, userInfo);
  return userInfo;
};

/**
 * Fetch user permissions
 *
 * @returns { Array<string> }
 * **/

export const fetchUserPermissions = async ({ commit }, organizationId) => {
  const permissions = await fetchPermissions(organizationId);
  const normalizedPermissions = new TenantParser({
    permissions,
  }).normalizedPermissions();

  await commit(types.SET_PERMISSIONS, normalizedPermissions);
  return normalizedPermissions;
};

/**
 * Reset the module to the initial state
 *
 * @returns {void}
 */
export const reset = async ({ commit }) => resetAction(commit, types.RESET);

/**
 * Attempt to log into the system based on given credentials
 * and store token object into localStorage if succeeded
 *
 * @returns {String} Json Web Token
 */
export const login = async (context, credentials) => {
  const token = await Auth.getAuthToken(credentials);

  jwtToken.set(token);

  return token.access_token;
};

/**
 * Log tenant out of the system and clear app state
 *
 * @returns {void}
 */
export const logout = async ({ commit, dispatch }) => {
  // Unsubscribe from all the private broadcasting channels
  unsubscribeAllPrivate();

  // Wipe out authorization token
  jwtToken.remove();
  jwtToken.refreshServicesHeaders();

  // Close Intercom widget
  Vue.intercom.shutdown();

  // Reset mixpanel session
  if (mixpanel) {
    mixpanel.reset();
  }

  // Reset Vuex modules
  commit(types.RESET);
  dispatch("outlets/reset", null, { root: true });
  dispatch("businesses/reset", null, { root: true });
  dispatch("liveOrders/reset", null, { root: true });
};
