<template>
  <div
    class="input-tag"
    :class="{
      'input-tag--focused': isFocused,
      'input-tag--error': hasErrors,
    }"
    data-test="input-tag"
  >
    <div
      v-for="(tag, index) in value"
      :key="tag + index"
      class="input-tag__tag"
      @click="removeTag(index)"
    >
      {{ tag }}
      <Icon
        class="input-tag__remove"
        icon="times-circle"
        color="#444444"
        :size="12"
      />
    </div>
    <input
      type="text"
      :placeholder="placeholder"
      class="input-tag__input"
      :class="{ 'input-tag__input--invalid': !isValidInput }"
      data-test="input"
      :required="isRequired"
      @focus="isFocused = true"
      @blur="onBlur"
      @keydown.enter="addTag"
      @keydown.188="addTag"
      @input="checkValidation"
    />
  </div>
</template>

<script>
import Icon from "./Icon/Index.vue";
import { clone } from "ramda";

export default {
  name: "InputTag",
  components: {
    Icon,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: [RegExp, String],
      default: null,
      validator: (val) => {
        return val instanceof RegExp || val === "email";
      },
    },
    canRepeat: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFocused: false,
    isValidInput: true,
  }),
  computed: {
    isRequired() {
      return this.required && this.value.length === 0;
    },
  },
  methods: {
    addTag(event) {
      const newTag = event.target.value.trim();

      if (newTag.length === 0) return;

      // Prevent form submission only if there is value
      event.preventDefault();

      if (!this.isValid(newTag)) {
        this.isValidInput = false;
        return;
      }

      if (!this.canRepeat && this.value.includes(newTag)) {
        this.isValidInput = false;
        return;
      }

      const currentTags = clone(this.value);
      currentTags.push(newTag);

      this.$emit("input", currentTags);
      event.target.value = "";
    },
    removeTag(index) {
      const currentTags = clone(this.value);
      currentTags.splice(index, 1);

      this.$emit("input", currentTags);
    },
    onBlur(event) {
      this.addTag(event);
      this.isFocused = false;
    },
    checkValidation(event) {
      const val = event.target.value.trim();

      if (val && this.isValid(val)) {
        this.isValidInput = true;
      }
    },
    isValid(value) {
      if (!this.validation) return true;

      if (this.validation === "email") {
        // eslint-disable-next-line
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(value);
      }

      return this.validation.test(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-tag {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 11px 16px;
  font-size: 14px;
  line-height: 18px;

  &--focused:not(&--error) {
    border-color: #00a8f8;
  }

  &--error {
    border-color: #e42f46;
    color: #e42f46;
  }

  &__remove {
    opacity: 0.5;
    margin-top: -3px;
  }

  &__tag {
    display: inline-block;
    margin: -2px 10px 10px 0;
    background-color: #eee;
    padding: 3px 7px;
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;
    color: #444444;

    &:hover {
      .input-tag__delete {
        opacity: 1;
      }
    }
  }

  &__input {
    border: none;
    display: block;
    width: 100%;
    padding: 0;
    color: #444444;

    &--invalid {
      color: #e42f46;
    }
  }
}
</style>
