<template>
  <Icon
    v-tooltip="{
      trigger: 'click hover',
      content,
    }"
    icon="info"
    :size="16"
    color="none"
    class="m-1"
  />
</template>

<script>
import { VTooltip } from "v-tooltip";
import { Icon } from "@/design-system";
/**
 * @deprecated
 * use `MolInfoBulb` from `v2/new-design-system` instead
 */

export default {
  name: "HelpTooltip",
  components: {
    Icon,
  },
  directives: {
    "v-tooltip": VTooltip.VTooltip,
  },
  props: {
    content: {
      required: true,
      type: String,
    },
  },
};
</script>
