




import { bemBuilder } from "@chatfood/core-utils";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { t } from "@/i18n";
import { IAtomPaymentMethodProps } from "./props";

const css = bemBuilder("atom-payment-method");

export default defineComponent({
  name: "AtomPaymentMethod",
  props: {
    method: {
      type: String as PropType<IAtomPaymentMethodProps["method"]>,
      required: true,
    },
  },
  setup(props) {
    const label = computed(() => {
      if (
        props.method === "CARD_ONLINE" ||
        props.method === "ONLINE" ||
        props.method === "card_online"
      ) {
        return t("design_system.atom_payment_method.card_online");
      }

      if (props.method === "CARD_OFFLINE" || props.method === "card_delivery") {
        return t("design_system.atom_payment_method.card_machine");
      }

      if (props.method === "CASH" || props.method === "cash_delivery") {
        return t("design_system.atom_payment_method.cash");
      }

      if (props.method === "OFFLINE") {
        return t("design_system.atom_payment_method.custom_payment");
      }

      if (props.method === "add_to_tab") {
        return t("design_system.atom_payment_method.tab");
      }

      if (props.method === "apple_pay") {
        return t("design_system.atom_payment_method.apple_pay");
      }

      return t("design_system.atom_payment_method.other");
    });

    return {
      css,
      label,
    };
  },
});
