export enum AtomIconNameEnum {
  BOOK = "book",
  KNOWLEDGE = "knowledge",
  PLAY = "play",
  HEADPHONES = "headphones",
  BULB = "bulb",
  HELP = "help",
  LOYALTY = "loyalty",
  RATING = "raiting",
}
