
































import { defineComponent, computed, watch, ref } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../../atom/text";

const css = bemBuilder("mol-average-circle");

export default defineComponent({
  name: "MolCircleProgress",
  components: {
    AtomText,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      default: null,
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
    circleSize: {
      type: Number,
      default: 150,
    },
    progressColor: {
      type: String,
      default: "mint",
    },
    backgroundColor: {
      type: String,
      default: "cloudy",
    },
  },

  setup(props, { slots }) {
    const circleParams = {
      cx: 16,
      cy: 16,
      r: 16,
    };
    const progress = ref(100);
    const score = computed(() =>
      props.value >= props.max ? props.max : props.value
    );

    const hasSlot = computed(() => {
      return Boolean(slots.default);
    });

    const componentSize = computed(() => ({
      width: `${props.circleSize}px`,
      height: `${props.circleSize}px`,
    }));

    const progressStyles = computed(() => ({
      stroke: `var(--color-${props.progressColor})`,
      "stroke-dashoffset": progress.value,
      "stroke-width": props.strokeWidth,
    }));

    const backgroundStyles = computed(() => ({
      stroke: `var(--color-${props.backgroundColor})`,
      "stroke-width": props.strokeWidth,
    }));

    watch(
      () => score.value,
      () => {
        setTimeout(() => {
          const percentage = (score.value / props.max) * 100;
          progress.value = 100 - percentage;
        }, 500);
      },
      { immediate: true }
    );

    return {
      css,
      score,
      hasSlot,
      progress,
      circleParams,
      componentSize,
      progressStyles,
      backgroundStyles,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
