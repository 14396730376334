






















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, watch } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  MolCollapseIndicator,
} from "@/v2/new-design-system";

const css = bemBuilder("org-collapse-group");

export default defineComponent({
  name: "OrgCollapseGroup",
  components: {
    AtomText,
    MolCollapseIndicator,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    collapsedAll: {
      type: Boolean,
      default: false,
    },
    showSecondLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const collapsed = ref(props.collapsedAll);

    watch(
      () => props.collapsedAll,
      () => {
        collapsed.value = props.collapsedAll;
      },
      { immediate: true }
    );

    return {
      css,
      collapsed,
      AtomTextTypeEnum,
    };
  },
});
