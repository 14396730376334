import { billingGetState } from "@/v2/module/billing/state";

export const isCreateBusinessFlowStarted = (businessId: string): boolean => {
  const currentPlan = billingGetState("currentPlan");
  const currentBusinessId = billingGetState("business").value.id;

  if (!currentPlan.value) return false;

  return (
    currentBusinessId !== businessId || currentPlan.value?.state === "DRAFT"
  );
};
