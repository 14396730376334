import { httpInbox } from "../http-inbox";
import { IFetchInboxSetupApiResponse } from "./api-response.factory";
import { AxiosError } from "axios";
import {
  RepoInboxError,
  RepoInboxErrorEnum,
} from "@/v2/repo/inbox/error-response";

export async function fetchInboxSetup(
  businessId: string
): Promise<IFetchInboxSetupResponse> {
  try {
    const response = await httpInbox.get<IFetchInboxSetupApiResponse>(
      `/businesses/${businessId}/setup`
    );

    return {
      isWhatsAppEnabled: Boolean(response.data.whatsapp),
      isInstagramEnabled: Boolean(response.data.instagram),
    };
  } catch (err: unknown) {
    if (
      err instanceof AxiosError &&
      (err.response?.status === 403 || err.response?.status === 0)
    ) {
      throw new RepoInboxError({
        code: RepoInboxErrorEnum.COUNTRY_NOT_AVAILABLE,
        message: "The inbox is not available in your country.",
        response: err.response,
        request: err.request,
      });
    }

    throw err;
  }
}

export interface IFetchInboxSetupResponse {
  isWhatsAppEnabled: boolean;
  isInstagramEnabled: boolean;
}
