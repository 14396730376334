import {
  ISidebarProps,
  ISidebarPropsMenuItem,
  ISidebarPropsSubmenuItem,
} from "@/v2/module/sidebar/props";
import VueRouter from "vue-router";

export const generateURL = (
  routes: Array<ISidebarPropsMenuItem> = [],
  router: VueRouter,
  params: Record<string, string>
): ISidebarProps["config"]["main"] | ISidebarProps["config"]["bottom"] => {
  return routes.map(
    (route: ISidebarPropsMenuItem | ISidebarPropsSubmenuItem) => {
      // @ts-ignore
      const { action, submenu } = route;

      let actionData = { ...action, href: "#" };

      if (action?.httpLink) {
        actionData = {
          ...actionData,
          href: action.httpLink,
        };
      }

      if (action?.routerLink) {
        const { href } = router.resolve({ name: action.routerLink, params });

        actionData = {
          ...actionData,
          href,
        };
      }

      if (submenu) {
        // @ts-ignore
        const submenuList = submenu.map(({ items, ...other }) => ({
          ...other,
          items: generateURL(items, router, params),
        }));

        return {
          ...route,
          action: { ...actionData },
          submenu: submenuList,
        };
      }

      return { ...route, action: { ...actionData } };
    }
  );
};
