import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchPaymentMethod(
  businessId: string
): Promise<IFetchPaymentMethodResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.subscription.billingDetails.paymentMethod;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchPaymentMethodResponse {
  id: string;
  cardholderName: string;
  brand:
    | "UNIONPAY"
    | "AMERICAN_EXPRESS"
    | "DINERS_CLUB"
    | "VISA"
    | "MASTERCARD";
  last4: string;
  expireMonth: string;
  expireYear: string;
}
