<template>
  <VueDatePicker
    v-model="date"
    format="MMM DD, YYYY"
    :placeholder="$t('label.select_date_range')"
    no-header
    class="date-picker"
    data-test="date-picker"
    :class="{ 'date-picker--error': hasErrors }"
    color="#292929"
    no-calendar-icon
    :min-date="minDate"
    :max-date="maxDate"
    fullscreen-mobile
    :visible="visible"
    @onChange="input"
  />
</template>

<script>
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import Dates from "@/utils/helpers/dates";

/**
 * @deprecated
 * use `AtomDatepickerRange` from `@/v2/new-design-system` instead
 */

export default {
  name: "DatePicker",
  components: {
    VueDatePicker,
  },
  props: {
    value: {
      type: [Object, String],
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: new Dates().format(),
  }),
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.date = val;
      },
    },
  },
  methods: {
    input() {
      this.$emit("input", this.date.replace(/-/g, "/"));
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker ::v-deep .vd-picker__input {
  display: block;
  width: 100%;
  color: #444444;
  border-radius: 4px;
  padding: 9px 14px;
  font-size: 14px;
  border: 1px solid #dee2e6;
  opacity: 1;
  min-height: 42px;

  input {
    font-size: 14px !important;

    padding: 0;
    margin: 0;
  }

  &--is-active {
    border-color: #00a8f8;
  }
}

.date-picker--error ::v-deep .vd-picker__input {
  border-color: #e42f46;
  color: #e42f46;
}
</style>
