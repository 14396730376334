import { fetchPlans } from "@/v2/repo/fetch-plans";
import { billingGetState, billingSetState } from "../state";
import { billingPlanPriceFormatter } from "./price-formatter";

export async function billingMethodLoadPlans(
  businessId: string
): Promise<void> {
  const plans = await fetchPlans(businessId);
  billingSetState("currentOutletCount", plans[0].currentOutletsCount);

  const processedPlans = plans.map((plan) => {
    const extraOutlets =
      billingGetState("currentOutletCount").value -
      plan.plan.maximumActiveOutlets;

    return {
      recommended: plan.isRecommended,
      id: plan.plan.id,
      priceId: plan.plan.priceId,
      price: billingPlanPriceFormatter(plan.plan.price, plan.plan.currency),
      priceRaw: plan.plan.price,
      extraOutletPriceRaw: plan.plan.additionalOutletPrice,
      currency: plan.plan.currency,
      name: plan.plan.name,
      maxOutlets: plan.plan.maximumActiveOutlets,
      maxOrders: plan.plan.maximumMonthlyOrders,
      extraOutlets: extraOutlets < 0 ? 0 : extraOutlets,
      extraOrderPrice: billingPlanPriceFormatter(
        plan.plan.additionalOrderPrice,
        plan.plan.currency,
        "$0.00"
      ),
      extraOutletPrice: billingPlanPriceFormatter(
        plan.plan.additionalOutletPrice,
        plan.plan.currency
      ),
    };
  });

  billingSetState("plans", processedPlans);
}
