import { ref } from "@vue/composition-api";

export function sidebarMethodUpdateFavicon(ordersAmount: number): void {
  const favicon = document.getElementById("favicon") as HTMLLinkElement;
  const faviconSize = ref<number>(64);

  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  canvas.width = faviconSize.value;
  canvas.height = faviconSize.value;

  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  const img = document.createElement("img");
  img.src = `${process.env.BASE_URL}favicon/favicon-32x32.png`;

  img.onload = (): void => {
    context.drawImage(img, 0, 0, faviconSize.value, faviconSize.value);

    if (ordersAmount > 0) {
      context.beginPath();
      context.fillStyle = "#dc3545";
      context.fillRect(
        canvas.width - faviconSize.value / 1.5,
        faviconSize.value / 2.5,
        faviconSize.value / 1.5,
        faviconSize.value / 1.5
      );

      context.font = '34px "helvetica", sans-serif';
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = "#fff";
      context.fillText(
        String(ordersAmount),
        canvas.width - faviconSize.value / 3.1,
        faviconSize.value / 1.4
      );
    }

    favicon.href = canvas.toDataURL("image/png");
  };
}
