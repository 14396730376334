import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { CurrencyCodeEnum } from "@/v2/enum";

export async function fetchCurrentSubscription(
  businessId: string
): Promise<IFetchCurrentSubscription> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.subscription;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchCurrentSubscription {
  id: string;
  stripeSubscriptionId: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  trialEnd: string;
  state:
    | "CREATED"
    | "TRIALING"
    | "ACTIVE"
    | "PENDING_PAYMENT"
    | "CANCELED"
    | "TRIAL_ENDED"
    | "DRAFT"
    | "UNPAID";
  plan: {
    id: string;
    name: string;
    maximumActiveOutlets: number;
    maximumMonthlyOrders: number;
    priceId: string;
    currency: CurrencyCodeEnum;
    price: number;
    additionalOutletPrice: number;
    additionalOrderPrice: number;
    type: "LEGACY" | "SIGNUP" | "REGULAR" | "CUSTOM";
  };
}
