import { sidebarMenuMobileIsOpen } from "../state";
import { ISidebarPropsAction } from "../props";
import { sidebarTrack } from "@/v2/module/sidebar/track";
import { EventEnum } from "@/v2/enum";

export function routeBuilder(
  context: any,
  businessId: string,
  action: ISidebarPropsAction,
  label: string,
  openInNewTab: boolean
): void {
  if (action.func) {
    action.func();
  }

  if (action.httpLink) {
    window.open(action.httpLink, "_blank");
  }

  if (action.routerLink == "auth.login") {
    context.root.$store.dispatch("auth/logout");
  }

  if (action.routerLink && !openInNewTab) {
    const fromRoute = context.root.$route;

    if (fromRoute.name === action.routerLink) return;

    context.root.$router
      .push({
        name: action.routerLink,
        params: {
          businessId: businessId,
        },
      })
      .catch(() => {
        // https://github.com/vuejs/vue-router/issues/2873
        // intentionally not catching errors from
        // vue router push due to middlewares with
        // empty next
      });
  }

  if (action.useTracking) {
    sidebarTrack(
      context.root.$analytics,
      EventEnum.CLICKED_ON_BUTTON,
      businessId,
      {
        label,
      }
    );
  }

  document.body.style.overflow = "";
  sidebarMenuMobileIsOpen.value = false;
}
