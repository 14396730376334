<template>
  <div class="align-items-center d-flex flex-column p-5">
    <h1 class="display-5">We'll be back shortly</h1>
    <p class="text-center text-black-50">
      Sorry, we are currently not accepting orders, but we’ll be back shortly.
      Please try again later.
    </p>
    <p class="w-100 d-flex justify-content-center align-items-center mt-3">
      <a href="#" class="btn btn-primary mr-3" @click.prevent="refresh">
        Try again
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ServiceUnavailableView",
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>
