<template>
  <label class="checkbox" :for="_uid">
    <input
      :id="_uid"
      data-test="checkbox"
      class="checkbox__input"
      type="checkbox"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <span class="checkbox__checkmark"></span>
    <slot />
  </label>
</template>

<script>
/**
 * @deprecated
 * use `AtomCheckbox` from `@/v2/new-design-system` instead
 */
export default {
  name: "Checkbox",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  $check: &;

  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #dee2e6;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      background: #00a8f8;
      border-radius: 2px;
      top: 2px;
      left: 2px;
      display: none;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ #{ $check }__checkmark {
      background: white;
      border: 1px solid #00a8f8;

      &:after {
        display: block;
      }
    }

    &:disabled ~ #{ $check }__checkmark {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &:hover #{ $check }__input {
    ~ #{ $check }__checkmark {
      background: #d7dee3;
    }

    &:checked ~ #{ $check }__checkmark {
      background: white;
      border: 1px solid #00a0ec;

      &:after {
        background: #00a0ec;
      }
    }
  }
}
</style>
