import { render, staticRenderFns } from "./SocialMediaLogo.vue?vue&type=template&id=36b974d0&scoped=true&"
import script from "./SocialMediaLogo.vue?vue&type=script&lang=ts&"
export * from "./SocialMediaLogo.vue?vue&type=script&lang=ts&"
import style0 from "./SocialMediaLogo.vue?vue&type=style&index=0&id=36b974d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b974d0",
  null
  
)

export default component.exports