






















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system";
import { routeBuilder } from "./method";
import { string, boolean, object } from "@/v2/util/prop/type";

const css = bemBuilder("sidebar-submenu-item");

export default defineComponent({
  name: "SidebarSubmenuItem",
  components: {
    AtomIcon,
  },
  props: {
    businessId: string().isRequired().use(),
    label: string().isRequired().use(),
    icon: string().use(),
    action: object().use(),
    visible: boolean().use(),
  },
  setup(props, context) {
    function triggerLink({
      ctrlKey,
      metaKey,
    }: {
      ctrlKey: boolean;
      metaKey: boolean;
    }): void {
      const openInNewTab = ctrlKey || metaKey;
      routeBuilder(
        context,
        props.businessId || "",
        props.action,
        props.label,
        openInNewTab
      );
    }

    return {
      css,
      triggerLink,
    };
  },
});
