
































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { AtomSwitcherSizeEnum } from "./switcher.enum";
import { AtomText, AtomTextTypeEnum, AtomTextColorEnum } from "../text";
import { debounce } from "@/v2/util/debounce";

const css = bemBuilder("atom-switcher");

export default defineComponent({
  name: "AtomSwitcher",
  components: {
    AtomText,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      required: true,
    },
    size: {
      type: String as PropType<AtomSwitcherSizeEnum>,
      default: AtomSwitcherSizeEnum.NORMAL,
    },
  },
  setup(props) {
    const isChecked = ref(false);
    const debounceOnChange = debounce(props.onChange, 250);

    watch(
      () => props.checked,
      () => {
        isChecked.value = props.checked;
      },
      { immediate: true }
    );

    const handleOnChange = (state: boolean, event: Event) => {
      event.stopPropagation();
      // @ts-ignore
      debounceOnChange(state, event);

      isChecked.value = state;
    };

    const isMiniSwitcher = computed(
      () => props.size === AtomSwitcherSizeEnum.MINI
    );

    return {
      css,
      isChecked,
      handleOnChange,
      isMiniSwitcher,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
