import EventEmitter from "@/utils/events/eventEmitter";
import { is, isEmpty, intersection } from "ramda";

class Gates {
  constructor(permissions = []) {
    this.bus = new EventEmitter();
    this.setPermissions(permissions);
    this.userPermissions = [];
    this.userRoles = [];
    this.ready = false;
  }

  on(event, callback) {
    return this.bus.on(event, callback);
  }

  off(event, callback = undefined) {
    return this.bus.off(event, callback);
  }

  setPermissions(val) {
    this.permissions = JSON.parse(JSON.stringify(val));
  }

  setUserPermissions(permissions) {
    this.userPermissions = permissions;
    this.ready = true;
    this.bus.emit("ready", permissions);
  }

  setUserRoles(roles) {
    this.userRoles = roles;
  }

  can(permission) {
    if (!is(String, permission)) {
      throw new Error("Gates 'can' method accepts only string.");
    }

    return this.userPermissions.includes(permission);
  }

  hasRole(role) {
    if (is(String, role)) {
      return this.userRoles.includes(role);
    }

    if (is(Array, role)) {
      return !isEmpty(intersection(this.userRoles, role));
    }

    throw new Error("Gates 'hasRole' method accepts only array or string.");
  }
}

export default Gates;
