import { EDIT_DISCOUNT, VIEW_DISCOUNTS } from "@/utils/enums/permissions";

export default [
  {
    name: "discounts.index",
    path: "/business/:businessId/discounts",
    component: () =>
      import(
        /* webpackChunkName: "mod-discounts" */ "@/modules/discounts/ui/views/Index.vue"
      ),
    props: true,
    meta: {
      title: "View - Discounts",
      permission: VIEW_DISCOUNTS,
    },
  },
  {
    name: "discounts.create",
    path: "/business/:businessId/discounts/create",
    component: () =>
      import(
        /* webpackChunkName: "mod-discounts" */ "@/v2/module/discount/form/Form.vue"
      ),
    props: true,
    meta: {
      title: "Create - Discount",
      permission: EDIT_DISCOUNT,
    },
  },
  {
    name: "discounts.edit",
    path: "/business/:businessId/discounts/:discountId",
    component: () =>
      import(
        /* webpackChunkName: "mod-discounts" */ "@/v2/module/discount/form/Form.vue"
      ),
    props: true,
    meta: {
      title: "Edit - Discount",
      permission: EDIT_DISCOUNT,
    },
  },
];
