













import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed, PropType } from "@vue/composition-api";
import { AtomIcon } from "@/v2/new-design-system";

const css = bemBuilder("mol-rating");

export default defineComponent({
  name: "MolRating",
  components: {
    AtomIcon,
  },
  props: {
    rating: {
      type: Number,
      default: null,
    },
    size: {
      type: Number,
      default: 14,
    },
  },
  setup() {
    const ratingList = computed(() => {
      return [...Array(5).keys()].reduce(
        (res: Array<number>, i): Array<number> => [i + 1, ...res],
        []
      );
    });

    return {
      css,
      ratingList,
    };
  },
});
