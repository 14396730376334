import { EDIT_WIDGET } from "@/utils/enums/permissions";

export default [
  {
    path: "/business/:businessId/branding",
    component: () =>
      import(
        /* webpackChunkName: "mod-channel" */ "@/modules/branding/ui/views/Index.vue"
      ),
    props: true,
    children: [
      {
        name: "branding",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "mod-channel" */ "@/modules/branding/ui/views/widget/Design.vue"
          ),
        props: true,
        meta: {
          title: "Branding",
          permission: EDIT_WIDGET,
        },
      },
    ],
  },
];
