import * as actions from "./actions";
import * as getters from "./getters";
import * as types from "./mutation_types";
import { clone, forEach } from "ramda";
import {
  mutation as fetchMutation,
  state as fetchState,
} from "@/store/fetchBase";
import { mutation as resetMutation } from "@/store/resetBase";

/** @type {Object} Initial state for the module */
export const initialState = {
  toAcknowledge: [],
  ...fetchState(),
};

export default {
  // Clone of the initialState to set to the module
  state: clone(initialState),

  // List of available module mutations
  mutations: {
    ...fetchMutation(types.FETCH),

    ...resetMutation(types.RESET, initialState),

    [types.SAVE](state, order) {
      state.data = [...state.data.filter((obj) => obj.id !== order.id), order];
    },

    [types.DESTROY](state, id) {
      state.data = state.data.filter((obj) => obj.id !== id);
    },

    [types.UPDATE_STATUS](state, { id, status }) {
      state.data = forEach((obj) => {
        if (obj.id === id) {
          obj.status = status;
        }

        return obj;
      }, state.data);
    },

    [types.ORDER_TO_ACKNOWLEDGE](state, orderId) {
      if (state.toAcknowledge.indexOf(orderId) === -1) {
        state.toAcknowledge.push(orderId);
      }
    },

    [types.ACKNOWLEDGE_ORDER](state, orderId) {
      state.toAcknowledge = state.toAcknowledge.filter(
        (obj) => obj !== orderId
      );
    },
  },

  // List of available module actions
  actions,

  // List of available module getters
  getters,

  // Add namespace into module
  namespaced: true,
};
