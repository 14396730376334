




















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import LoadingSvg from "./assets/loading.svg";

const css = bemBuilder("atom-loading");

export default defineComponent({
  name: "AtomLoading",
  components: {
    LoadingSvg,
  },
  props: {
    size: {
      type: Number,
      default: 30,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      css,
    };
  },
});
