import {
  VIEW_PLACED_ORDERS,
  VIEW_ACCEPTED_ORDERS,
  VIEW_READY_ORDERS,
  VIEW_COMPLETE_ORDERS,
  VIEW_REJECTED_ORDERS,
  VIEW_CANCELLED_ORDERS,
  VIEW_SCHEDULED_ORDERS,
} from "@/utils/enums/permissions";
import {
  PLACED,
  ACCEPTED,
  READY,
  COMPLETE,
  CANCELLED,
  REJECTED,
  SCHEDULED,
} from "@/utils/enums/order-status";
import { concat, compose, filter, keys } from "ramda";

/**
 * List of available events under the outlet private channel for orders
 * mapped with the needed permission to listen the event
 *
 * @type {Object}
 */
export const orderBroadcastingEvents = {
  "order.new": VIEW_PLACED_ORDERS,
  "order.accepted": VIEW_ACCEPTED_ORDERS,
  "order.marked-as-ready": VIEW_READY_ORDERS,
  "delivery.created": VIEW_READY_ORDERS,
  "delivery.failed": VIEW_READY_ORDERS,
  "delivery.pending": VIEW_READY_ORDERS,
  "order.complete": VIEW_COMPLETE_ORDERS,
  "order.rejected": VIEW_REJECTED_ORDERS,
  "order.closed": VIEW_COMPLETE_ORDERS,
  "order.cancelled": VIEW_CANCELLED_ORDERS,
};

/**
 * List of available events under the outlet private channel open for every tenant
 *
 * @type {Object}
 */
const openBroadcastingEvents = [
  "outletAvailability.created",
  "orderingService.updated",
];

/**
 * List of all the live statuses mapped with the needed permissions
 * to have visibility
 *
 * @type {Object}
 */
export const liveStatusPermissions = {
  [PLACED]: VIEW_PLACED_ORDERS,
  [ACCEPTED]: VIEW_ACCEPTED_ORDERS,
  [READY]: VIEW_READY_ORDERS,
  [SCHEDULED]: VIEW_SCHEDULED_ORDERS,
};

/**
 * List of all the live statuses mapped with the needed permissions
 * to have visibility
 *
 * @type {Object}
 */
const statusPermissions = {
  [PLACED]: VIEW_PLACED_ORDERS,
  [ACCEPTED]: VIEW_ACCEPTED_ORDERS,
  [SCHEDULED]: VIEW_SCHEDULED_ORDERS,
  [READY]: VIEW_READY_ORDERS,
  [COMPLETE]: VIEW_COMPLETE_ORDERS,
  [REJECTED]: VIEW_REJECTED_ORDERS,
  [CANCELLED]: VIEW_CANCELLED_ORDERS,
};

/**
 * Get list of broadcasting events that user has permission to listen
 *
 * @param {Array} userPermissions List of permissions owned by user
 * @returns {Array} List of broadcast events
 */
export const getBroadcastingEvents = (userPermissions = []) => {
  return compose(
    concat(openBroadcastingEvents),
    keys,
    filter((obj) => userPermissions.includes(obj))
  )(orderBroadcastingEvents);
};

/**
 * Get list of live order status that user has permission to visualize
 *
 * @param {Array} userPermissions List of permissions owned by user
 * @returns {Array} List of order status
 */
export const getLiveOrderStatusList = (userPermissions = []) => {
  return compose(
    keys,
    filter((obj) => userPermissions.includes(obj))
  )(liveStatusPermissions);
};

/**
 * Get list of order status that user has permission to visualize
 *
 * @param {Array} userPermissions List of permissions owned by user
 * @returns {Array} List of order status
 */
export const getOrderStatusList = (userPermissions = []) => {
  return compose(
    keys,
    filter((obj) => userPermissions.includes(obj))
  )(statusPermissions);
};
