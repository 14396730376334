// TODO: Remove this kind of implementation for good

import Vue from "vue";
import { toPercentage } from "./index";

require("./split-by");
require("./string-beautify");
require("./status-beautify");

Vue.filter("toPercentage", toPercentage);
