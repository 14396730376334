import { VIEW_PAYMENT_LINKS } from "@/utils/enums/permissions";

export default [
  {
    name: "payment-links.index",
    path: "/business/:businessId/payment-links",
    component: () =>
      import(
        /* webpackChunkName: "payment-link-module" */ "@/v2/module/payment-link/main/Main.vue"
      ),
    props: true,
    meta: {
      title: "Payment Links",
      permission: VIEW_PAYMENT_LINKS,
    },
  },
  {
    name: "payment-links.create",
    path: "/business/:businessId/payment-links/create",
    component: () =>
      import(
        /* webpackChunkName: "payment-link-module" */ "@/v2/module/payment-link/create/Create.vue"
      ),
    props: true,
    meta: {
      title: "Payment Links - Create",
      permission: VIEW_PAYMENT_LINKS,
    },
  },
  {
    name: "payment-links.view",
    path: "/business/:businessId/payment-links/view/:paymentLinkId",
    component: () =>
      import(
        /* webpackChunkName: "payment-link-module" */ "@/v2/module/payment-link/view/View.vue"
      ),
    props: true,
    meta: {
      title: "Payment Links - View",
      permission: VIEW_PAYMENT_LINKS,
    },
  },
];
