




































































































































































































import { t, tc } from "@/i18n";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomCard,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
} from "@/v2/new-design-system";
import {
  createSubscriptionCheckout,
  ICreateSubscriptionCheckoutResponse,
} from "@/v2/repo/create-subscription-checkout";
import { bemBuilder } from "@/v2/util/bem-builder";
import { loadStripe } from "@stripe/stripe-js";
import { defineComponent, getCurrentInstance, ref } from "@vue/composition-api";
import { billingMethodPlanCalculateFinalPrice } from "../method/calculate-final-price";
import {
  billingGetState,
  billingSetState,
  BillingStageStateEnum,
} from "../state";
import { imageURLBuilder } from "@/v2/util/image-url-builder";
import { isCreateBusinessFlowStarted } from "@/v2/module/billing/utils/create-business";
import { createBusinessTrack } from "@/v2/util/analytics/createBusinessTrackEvent";
import { EventEnum } from "@/v2/enum";

const css = bemBuilder("billing-review-modal");

export default defineComponent({
  name: "BillingReviewPlanModal",
  components: {
    AtomModal,
    AtomText,
    AtomCard,
    AtomButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const vm = getCurrentInstance();

    function cardBrand(brandKey: string): string {
      if (brandKey === "UNIONPAY") {
        return "UnionPay";
      }
      if (brandKey === "AMERICAN_EXPRESS") {
        return "American Express";
      }
      if (brandKey === "DINERS_CLUB") {
        return "Diners Club";
      }

      const lowerCaseBrandKey = brandKey.toLowerCase();
      return (
        lowerCaseBrandKey.charAt(0).toUpperCase() + lowerCaseBrandKey.slice(1)
      );
    }

    const trackEvent = (): void => {
      if (isCreateBusinessFlowStarted(props.businessId)) {
        // @ts-ignore
        const analytics = vm?.proxy.$analytics;

        createBusinessTrack(
          analytics,
          EventEnum.CLICKED_ON_BUTTON,
          billingGetState("business").value.id,
          {
            label: "Clicked on Confirm",
          }
        );
      }
    };

    async function onCompleteSubscription() {
      isLoading.value = true;

      const selectedPlan = billingGetState("selectedPlan").value;

      if (!selectedPlan) return;

      trackEvent();

      let checkoutResult: ICreateSubscriptionCheckoutResponse;

      try {
        checkoutResult = await createSubscriptionCheckout(
          billingGetState("business").value.id,
          selectedPlan.priceId
        );
      } catch {
        billingSetState("stage", BillingStageStateEnum.TRANSACTION_FAIL);
        return;
      }

      if (!checkoutResult) return;

      if (
        checkoutResult.status === "SUCCESSFUL" ||
        checkoutResult.status === "UNMODIFIED"
      ) {
        billingSetState("flowSuccess", true);
        billingSetState("stage", BillingStageStateEnum.TRANSACTION_SUCCESS);
        isLoading.value = true;
        return;
      }

      if (checkoutResult.status === "FAILED") {
        const paymentStatus = checkoutResult.paymentInfo?.status;

        if (paymentStatus === "REQUIRES_PAYMENT_METHOD") {
          billingSetState("stage", BillingStageStateEnum.TRANSACTION_FAIL);
          isLoading.value = true;
          return;
        }

        if (paymentStatus === "REQUIRES_CUSTOMER_AUTHENTICATION") {
          const stripeInstance = await loadStripe(
            // @ts-ignore
            process.env.VUE_APP_STRIPE_KEY
          );
          const stripeConfirmCardResult =
            await stripeInstance?.confirmCardPayment(
              checkoutResult?.paymentInfo?.paymentIntentClientSecret || ""
            );

          const confirmationSuccess =
            !stripeConfirmCardResult?.error &&
            stripeConfirmCardResult?.paymentIntent?.status === "succeeded";

          if (confirmationSuccess) {
            billingSetState("stage", BillingStageStateEnum.TRANSACTION_SUCCESS);
            isLoading.value = true;
            return;
          }

          billingSetState("stage", BillingStageStateEnum.TRANSACTION_FAIL);
          isLoading.value = true;
        }
      }
    }

    return {
      css,
      t,
      tc,
      billingMethodPlanCalculateFinalPrice,
      selectedPlan: billingGetState("selectedPlan"),
      selectedCardInfo: billingGetState("selectedCardInfo"),
      business: billingGetState("business"),
      trialDays: billingGetState("trialDays"),
      user: billingGetState("user"),
      billingSetStageDormant: () =>
        billingSetState("stage", BillingStageStateEnum.DORMANT),
      billingSetStageChoosePlan: () =>
        billingSetState("stage", BillingStageStateEnum.CHOOSE_PLAN),
      billingSetStagePaymentMethod: () =>
        billingSetState("stage", BillingStageStateEnum.PAYMENT_METHOD),
      cardBrand,
      isLoading,
      onCompleteSubscription,
      AtomButtonTypeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      imageURLBuilder,
    };
  },
});
