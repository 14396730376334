























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { AtomAnchorSizeEnum } from "./size.enum";
import { AtomAnchorTypeEnum } from "./type.enum";
import { string, boolean } from "@/v2/util/prop/type";
import { isOneOf } from "@/v2/util/prop/validator";

const css = bemBuilder("atom-anchor");

export default defineComponent({
  name: "Anchor",
  props: {
    link: {
      type: [String, Object],
      required: true,
    },
    type: string()
      .setDefault(AtomAnchorTypeEnum.PRIMARY)
      .setValidator(isOneOf(Object.values(AtomAnchorTypeEnum)))
      .use(),
    size: string()
      .setDefault(AtomAnchorSizeEnum.MEDIUM)
      .setValidator(isOneOf(Object.values(AtomAnchorSizeEnum)))
      .use(),
    isDisabled: boolean().setDefault(false).use(),
    isLoading: boolean().setDefault(false).use(),
  },
  setup(props) {
    const isPlainUrl = computed(() => {
      return (
        typeof props.link === "string" &&
        props.link.match(/^(http(s)?|mailto):/)
      );
    });

    const component = computed(() => {
      if (isPlainUrl.value) {
        return {
          tag: "a",
          attr: "href",
        };
      }

      return {
        tag: "router-link",
        attr: "to",
      };
    });

    return {
      css,
      isPlainUrl,
      component,
      AtomAnchorTypeEnum,
    };
  },
});
