<template>
  <label class="radio" :for="_uid" :class="{ 'radio--disabled': disabled }">
    <input
      :id="_uid"
      type="radio"
      class="radio__input"
      v-bind="$attrs"
      :checked="isChecked"
      data-test="input"
      :value="value"
      :disabled="disabled"
      @change="updateInput"
    />
    <span class="radio__radiomark"></span>
    <div class="pl-3">
      <slot />
    </div>
  </label>
</template>

<script>
/**
 * @deprecated
 * use `AtomRadio` from `@/v2/new-design-system` instead
 */

export default {
  name: "Radio",
  inheritAttrs: false,
  model: {
    prop: "modelValue",
  },
  props: {
    value: {
      type: [String, Boolean],
      default: null,
    },
    modelValue: {
      type: [String, Boolean, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    updateInput() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  $radio: &;

  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &__radiomark {
    position: relative;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background: #dee2e6;
    border-radius: 100%;

    &:after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      background: #00a8f8;
      border-radius: 100%;
      top: 2px;
      left: 2px;
      display: none;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ #{ $radio }__radiomark {
      background: white;
      border: 1px solid #00a8f8;

      &:after {
        display: block;
      }
    }
  }

  &:hover #{ $radio }__input {
    ~ #{ $radio }__radiomark {
      background: #d7dee3;
    }

    &:checked ~ #{ $radio }__radiomark {
      background: white;
      border: 1px solid #00a0ec;

      &:after {
        background: #00a0ec;
      }
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
