






import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { MolSocialMediaLogoTypeEnum } from "./type.enum";
import { AtomGraphic } from "../../atom/graphic";

const css = bemBuilder("mol-social-media-logo");

export default defineComponent({
  name: "MolSocialMediaLogo",
  components: {
    AtomGraphic,
  },
  props: {
    type: {
      type: String as PropType<MolSocialMediaLogoTypeEnum>,
      required: true,
    },
  },
  setup(props) {
    const graphicMap = {
      [MolSocialMediaLogoTypeEnum.INSTAGRAM]: "instagram",
      [MolSocialMediaLogoTypeEnum.WHATSAPP]: "whatsapp-v2",
      [MolSocialMediaLogoTypeEnum.MESSENGER]: "messenger",
    };
    const graphicName = computed(() => graphicMap[props.type]);

    return {
      css,
      graphicName,
    };
  },
});
