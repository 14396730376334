
























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, watch, computed } from "@vue/composition-api";
import { AtomText, AtomTextTypeEnum } from "@/v2/new-design-system/atom/text";
import { AtomCheckbox } from "@/v2/new-design-system/atom/checkbox";
import { array, func, string } from "@/v2/util/prop/type";
import { t } from "@/i18n";

const css = bemBuilder("org-select-list");

export default defineComponent({
  name: "OrgSelectList",
  components: {
    AtomCheckbox,
    AtomText,
  },
  props: {
    data: array().isRequired().use(),
    filter: string().use(),
    noItemsMessage: string().use(),
    selectedItems: array().use(),
    onChange: func().isRequired().use(),
    isDisabled: {
      type: Boolean,
      default: false,
    },
    maxAmount: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { slots }) {
    const selectedItemsSet = ref(new Set(props.selectedItems));

    const notFoundMessage = computed((): string => {
      return (
        props.noItemsMessage ||
        t("design_system.org_select_list.no_items_message")
      );
    });

    const hasSlot = computed(() => {
      return Boolean(slots.label);
    });

    function toggleSelectItem(itemId: string): void {
      if (selectedItemsSet.value.has(itemId)) {
        selectedItemsSet.value.delete(itemId);
      } else {
        selectedItemsSet.value.add(itemId);
      }

      selectedItemsSet.value = new Set(selectedItemsSet.value);
    }

    const filteredData = computed(() => {
      if (props.filter) {
        return props.data.filter((item: { name: string }) =>
          item.name.toLowerCase().includes(props.filter.trim().toLowerCase())
        );
      }

      return props.data;
    });

    const hasItems = computed((): boolean => {
      return filteredData.value.length > 0;
    });

    const isDisabledItem = (id: string): boolean => {
      if (props.isDisabled) return true;
      if (props.maxAmount) {
        const selected = selectedItemsSet.value.has(id);
        const isInvalid = props.maxAmount <= selectedItemsSet.value.size;
        return !selected && isInvalid;
      }
      return false;
    };

    watch(
      () => props.selectedItems,
      (val, prevVal) => {
        const updatedItemSelection = new Set(val);
        if (updatedItemSelection.size != new Set(prevVal).size) {
          selectedItemsSet.value = updatedItemSelection;
        }
      }
    );

    watch(selectedItemsSet, () => {
      props.onChange(Array.from(selectedItemsSet.value));
    });

    return {
      css,
      notFoundMessage,
      selectedItemsSet,
      hasItems,
      filteredData,
      isDisabledItem,
      toggleSelectItem,
      AtomTextTypeEnum,
      hasSlot,
    };
  },
});
