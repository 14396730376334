import { makeFactory } from "factory.ts";
import * as faker from "faker";

export const FetchInboxSetupApiResponseFactory =
  makeFactory<IFetchInboxSetupApiResponse>({
    whatsapp: faker.datatype.boolean(),
    instagram: faker.datatype.boolean(),
  });

export interface IFetchInboxSetupApiResponse {
  whatsapp: boolean;
  instagram: boolean;
}
