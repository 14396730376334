import { http } from "../../http";
import {
  IAxiosErrorResponse,
  RepoBusinessSettingsError,
} from "../error-response";

export interface IFetchBusinessCuisine {
  id: string;
  name: string;
}

export class FetchBusinessCuisinesError extends RepoBusinessSettingsError {}

export async function fetchBusinessCuisines(
  locale: string
): Promise<Array<IFetchBusinessCuisine>> {
  try {
    const businessCuisines = await http.get(`api/v3/cuisines?locale=${locale}`);
    return Array.isArray(businessCuisines?.data) ? businessCuisines.data : [];
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage || "The request to fetch a business cuisines failed.",
      response: response.data,
    };

    throw new FetchBusinessCuisinesError(error);
  }
}
