/** @type {String} Action permissions for orders */
export const ACCEPT_ORDER = "ACCEPT_ORDER";
export const REJECT_ORDER = "REJECT_ORDER";
export const PRINT_ORDER = "PRINT_ORDER";
export const CLOSE_ORDER = "CLOSE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const MARK_ORDER_AS_READY = "MARK_ORDER_AS_READY";
export const COMPLETE_ORDER = "COMPLETE_ORDER";

/** @type {String} View permissions for orders */
export const VIEW_LIVE_ORDERS = "VIEW_LIVE_ORDERS";
export const VIEW_ORDERS = "VIEW_ORDERS";
export const VIEW_PLACED_ORDERS = "VIEW_PLACED_ORDERS";
export const VIEW_ACCEPTED_ORDERS = "VIEW_ACCEPTED_ORDERS";
export const VIEW_READY_ORDERS = "VIEW_READY_ORDERS";
export const VIEW_COMPLETE_ORDERS = "VIEW_COMPLETE_ORDERS";
export const VIEW_REJECTED_ORDERS = "VIEW_REJECTED_ORDERS";
export const VIEW_CANCELLED_ORDERS = "VIEW_CANCELLED_ORDERS";
export const VIEW_SCHEDULED_ORDERS = "VIEW_SCHEDULED_ORDERS";

/** @type {String} Items permissions */
export const IMPORT_ITEMS = "IMPORT_ITEMS";
export const VIEW_ITEMS = "VIEW_ITEMS";

/** @type {String} Menus permissions */
export const EDIT_MENU_NOTES = "EDIT_MENU_NOTES";
export const EDIT_MENU_IMAGES = "EDIT_MENU_IMAGES";
export const VIEW_MENUS = "VIEW_MENUS";

/** @type {String} Outlets permissions */
export const EDIT_OUTLET = "EDIT_OUTLET";
export const VIEW_OUTLETS = "VIEW_OUTLETS";
export const VIEW_OUTLET_DINE_IN_PAYMENTS = "VIEW_OUTLET_DINE_IN_PAYMENTS";

/** @type {String} Tip transactions permissions */
export const VIEW_TIPS = "VIEW_TIPS";

/** @type {String} Booking permissions */
export const VIEW_RESERVATIONS = "VIEW_RESERVATIONS";

/** @type {String} Channels permissions */
export const VIEW_WIDGET = "VIEW_WIDGET";
export const EDIT_WIDGET = "EDIT_WIDGET";

/** @type {String} Discount permissions */
export const EDIT_DISCOUNT = "EDIT_DISCOUNT";
export const VIEW_DISCOUNTS = "VIEW_DISCOUNTS";

/** @type {String} Permissions */
export const VIEW_DASHBOARD = "VIEW_DASHBOARD";
export const VIEW_CUSTOMERS = "VIEW_CUSTOMERS";
export const VIEW_INVENTORY = "VIEW_INVENTORY";
export const VIEW_SETTINGS = "VIEW_SETTINGS";
export const VIEW_MODIFIER_LISTS = "VIEW_MODIFIER_LISTS";
export const VIEW_MARKETING = "VIEW_MARKETING";
export const VIEW_BILLING = "VIEW_BILLING";
export const VIEW_ONBOARDING = "VIEW_ONBOARDING";
export const VIEW_INBOX = "VIEW_INBOX";
export const VIEW_WHATS_NEW = "VIEW_WHATS_NEW";
export const BUSINESS_CREATE = "BUSINESS_CREATE";

/** @type {String} Reports Permissions */
export const VIEW_REPORTS = "VIEW_REPORTS";

/** @type {String} Sales channel Permissions */
export const VIEW_CHANNELS = "VIEW_CHANNELS";
export const EDIT_CHANNEL = "EDIT_CHANNEL";

/** @type {String} Reactivation permissions */
export const EDIT_REACTIVATION = "EDIT_REACTIVATION";

/** @type {String} User Management permissions */
export const VIEW_USERS = "VIEW_USERS";
export const EDIT_USERS = "EDIT_USERS";

/** @type {String} Payment Link permissions */
export const VIEW_PAYMENT_LINKS = "VIEW_PAYMENT_LINKS";

/** @type {String} Payout permissions */
export const VIEW_PAYOUTS = "VIEW_PAYOUTS";

/** @type {String} BackOffice permissions */
export const VIEW_BACK_OFFICE_SETTINGS = "VIEW_BACK_OFFICE_SETTINGS";

/** @type {String} Table Payment permissions */
export const VIEW_TABLE_PAYMENTS = "VIEW_TABLE_PAYMENTS";
export const VIEW_TABLE_PAYMENT_HISTORY = "VIEW_TABLE_PAYMENT_HISTORY";

/** @type {String} Business Settings permissions */
export const VIEW_BUSINESS_SETTINGS = "VIEW_BUSINESS_SETTINGS";
export const VIEW_PAYOUT_PAYEE = "VIEW_PAYOUT_PAYEE";

/** @type {String} Reviews permissions */
export const VIEW_REVIEWS = "VIEW_REVIEWS";

export const VIEW_LOYALTY = "VIEW_LOYALTY";

/** @type {String} Super permissions */
export const USE_BETA_FEATURES = "USE_BETA_FEATURES";

/** @type {String} Spot management permissions */
export const VIEW_SPOTS_MANAGEMENT = "VIEW_SPOTS_MANAGEMENT";

/** @type {String} Tabs permissions */
export const VIEW_LIVE_TABS = "VIEW_LIVE_TABS";
export const VIEW_TABS_HISTORY = "VIEW_TABS_HISTORY";

/** @type {String} Spots occupancy permissions */
export const VIEW_SPOTS_OCCUPANCY = "VIEW_SPOTS_OCCUPANCY";

/** @type {String} Custom payment methods permissions */
export const VIEW_CUSTOM_PAYMENT_METHOD = "VIEW_CUSTOM_PAYMENT_METHOD";
export const EDIT_CUSTOM_PAYMENT_METHOD = "EDIT_CUSTOM_PAYMENT_METHOD";

/** @type {String} Void reasons permissions */
export const VIEW_VOID_REASONS = "VIEW_VOID_REASONS";

/** @type {String} Facebook commerce permissions */
export const VIEW_FACEBOOK_COMMERCE = "VIEW_FACEBOOK_COMMERCE";

/** @type {String} Campaigns permissions */
export const VIEW_CAMPAIGNS = "VIEW_CAMPAIGNS";

/**
 * Get a list of all of the available permissions
 *
 * @returns {Array} List of permissions
 */
export const getAllPermissions = (): Array<string> => [
  // Orders - Action permissions
  ACCEPT_ORDER,
  REJECT_ORDER,
  PRINT_ORDER,
  CLOSE_ORDER,
  CANCEL_ORDER,
  MARK_ORDER_AS_READY,
  COMPLETE_ORDER,
  // Orders - View permissions
  VIEW_ORDERS,
  VIEW_PLACED_ORDERS,
  VIEW_ACCEPTED_ORDERS,
  VIEW_READY_ORDERS,
  VIEW_SCHEDULED_ORDERS,
  VIEW_COMPLETE_ORDERS,
  VIEW_REJECTED_ORDERS,
  VIEW_CANCELLED_ORDERS,
  // Items
  IMPORT_ITEMS,
  VIEW_ITEMS,
  // Outlets
  EDIT_OUTLET,
  VIEW_OUTLETS,
  VIEW_OUTLET_DINE_IN_PAYMENTS,
  // Tip transactions
  VIEW_TIPS,
  // BOOKING
  VIEW_RESERVATIONS,
  // Channels
  VIEW_WIDGET,
  EDIT_WIDGET,
  // Discounts
  EDIT_DISCOUNT,
  VIEW_DISCOUNTS,
  // Menus
  EDIT_MENU_NOTES,
  EDIT_MENU_IMAGES,
  VIEW_MENUS,
  // General
  VIEW_DASHBOARD,
  VIEW_CUSTOMERS,
  VIEW_INVENTORY,
  VIEW_SETTINGS,
  VIEW_MODIFIER_LISTS,
  VIEW_MARKETING,
  VIEW_INBOX,
  // Reports
  VIEW_REPORTS,
  // Sales channel
  VIEW_CHANNELS,
  EDIT_CHANNEL,
  // User management
  VIEW_USERS,
  EDIT_USERS,
  // Payment Link
  VIEW_PAYMENT_LINKS,
  // Payout
  VIEW_PAYOUTS,
  // PAT
  VIEW_TABLE_PAYMENTS,
  VIEW_TABLE_PAYMENT_HISTORY,
  // Business Settings
  VIEW_BUSINESS_SETTINGS,
  VIEW_PAYOUT_PAYEE,
  // Loyalty
  VIEW_LOYALTY,
  // Super permissions
  USE_BETA_FEATURES,
  // Spot management
  VIEW_SPOTS_MANAGEMENT,
  // Tabs
  VIEW_LIVE_TABS,
  VIEW_TABS_HISTORY,
  // Spots Occupancy,
  VIEW_SPOTS_OCCUPANCY,
  // Custom payment method
  VIEW_CUSTOM_PAYMENT_METHOD,
  EDIT_CUSTOM_PAYMENT_METHOD,
  // Void reasons
  VIEW_VOID_REASONS,
  // Facebook commerce
  VIEW_FACEBOOK_COMMERCE,
  // Campaigns
  VIEW_CAMPAIGNS,
];
