<template>
  <div id="app" :class="[layout]">
    <div v-if="!isAppReady" data-test="appLoading" class="appLoading">
      <LoadingSVG class="w-40" />
    </div>
    <template v-else>
      <component :is="layout">
        <router-view />
      </component>
    </template>
    <modals-container />
    <v-dialog />
  </div>
</template>

<script>
import LoadingSVG from "@/assets/svg/icons/loading.svg";
import Segmentio from "@/plugins/analytics/trackers/Segmentio";
import { APP_OPENED } from "@/plugins/analytics/events";
import { mapActions, mapGetters } from "vuex";
import { pathOr, startsWith } from "ramda";
import packageJson from "../package.json";
import { listenFromTenant, listenFromGeneral } from "@/v2/core/broadcasting";
import { useHelpers } from "./v2/composable/use-helpers";

export default {
  name: "App",
  components: {
    LoadingSVG,
  },
  computed: {
    ...mapGetters({
      tenantId: "auth/getId",
      tenantRoles: "auth/getRoles",
      isTenantLoaded: "auth/isLoaded",
      isBusinessesLoaded: "businesses/isLoaded",
      hasGlobalError: "hasGlobalError",
    }),
    layout() {
      const layout = pathOr("master", ["$route", "meta", "layout"], this);

      if (["master", "raw", "register"].includes(layout)) {
        return `${layout}-layout`;
      }

      return layout;
    },
    isAppReady() {
      const routeName = this.$route.name ?? "";

      if (this.hasGlobalError) {
        return true;
      }

      if (routeName === "error.500") {
        return true;
      }

      if (startsWith("auth.", routeName)) {
        return true;
      }

      return this.isTenantLoaded && this.isBusinessesLoaded;
    },
  },
  watch: {
    tenantId: {
      handler(value) {
        if (!value) return;

        listenFromTenant(
          value,
          ["orderingService.updated", "outletAvailability.created"],
          this.fetchOutlets
        );
      },
      immediate: true,
    },
  },
  created() {
    this.setSegmentObserver();
  },
  mounted() {
    listenFromGeneral(["browser.reload"], this.hardRefresh);

    listenFromGeneral(["browser.reload.business"], (businessId) => {
      const currentBusiness = useHelpers().getCurrentBusiness(
        this.$route.params.businessId
      );

      if (currentBusiness.id === businessId) {
        this.hardRefresh();
      }
    });

    listenFromGeneral(["browser.ensure-version"], this.ensureIsLatestVarsion);

    // Track app opened event
    this.$analytics.trackEvent(APP_OPENED);
  },
  methods: {
    ...mapActions({
      fetchOutlets: "outlets/fetch",
    }),
    ensureIsLatestVarsion(version) {
      if (packageJson.version !== version) {
        this.hardRefresh();
      }
    },
    hardRefresh() {
      window.location.reload();
    },
    setSegmentObserver() {
      if (process.env.VUE_APP_SEGMENTIO_ENABLED == "true") {
        this.$analytics.registerObserver(
          new Segmentio(process.env.VUE_APP_SEGMENTIO_KEY)
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/sass/app.scss";
</style>
