export enum AtomTextColorEnum {
  CHATFOOD = "chatfood",
  CHARCOAL = "charcoal",
  MIDNIGHT = "midnight",
  ATLANTIC = "atlantic",
  STORMY = "stormy",
  RAINY = "rainy",
  CLOUDY = "cloudy",
  POWDER = "powder",
  CORAL = "coral",
  SALMON = "salmon",
  CANARY = "canary",
  LEMON_SORBET = "lemon-sorbet",
  MINT = "mint",
  PISTACHIO = "pistachio",
  PURE_WHITE = "pure-white",
}
