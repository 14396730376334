export default [
  {
    name: "auth.forgot-password",
    path: "/forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "mod-auth" */ "@/modules/auth/views/ForgotPassword.vue"
      ),
    meta: {
      isAuthRequired: false,
      title: "Forgot password",
      layout: "raw",
    },
  },
  {
    name: "auth.recover-password",
    path: "/recover-password/:token",
    component: () =>
      import(
        /* webpackChunkName: "mod-auth" */ "@/modules/auth/views/RecoverPassword.vue"
      ),
    props: true,
    meta: {
      isAuthRequired: false,
      title: "Recover password",
      layout: "raw",
    },
  },
  {
    name: "auth.signup",
    path: "/v2-signup",
    component: () =>
      import(
        /* webpackChunkName: "mod-auth" */ "@/modules/auth/ui/views/Signup.vue"
      ),
    meta: {
      isAuthRequired: false,
      title: "Signup",
      layout: "raw",
    },
  },
  {
    name: "auth.account-setup",
    path: "/:hash/account-setup",
    component: () =>
      import(
        /* webpackChunkName: "mod-auth" */ "@/modules/auth/ui/views/AccountSetup.vue"
      ),
    props: true,
    meta: {
      isAuthRequired: false,
      title: "Account Setup",
      layout: "raw",
    },
  },
];
