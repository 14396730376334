import { Toast } from "@/design-system";
import { t } from "@/i18n";

export function generalErrorToast(message?: string): void {
  new Toast().create({
    type: "error",
    title: t("label.oops"),
    text: message || t("message.general_failure"),
  });
}
