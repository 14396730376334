import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchCurrentSubscriptionMaximumOutlets(
  businessId: string
): Promise<number> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    const plan = response.data.subscription?.plan;

    if (plan && plan.type === "REGULAR") {
      return response.data.subscription.plan.maximumActiveOutlets;
    }

    return 999;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
